import React, { useEffect, useRef, useState } from "react";
import { FormHelperText, CircularProgress, FormLabel, Tooltip, Button, IconButton, MuiThemeProvider, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { RoomOutlined } from "@material-ui/icons";
/* load form styles */
import "../../styles.scss";
import { gpsFieldStyles } from "../../styles";
import { cobalt, ButtonTheme } from "../../../../theme";
import ConfirmationDialog from "../../../../common/ConfirmationDialog";
import { getCoordinates } from "./helper";
import { showNotification } from "../../../../../util/notification";
import CustomFieldLabel from "../CustomFieldLabel";
import { InputVariantType } from "../../../../../types/commonTypes";
import { EMPTY, GPSLINK, LINKTARGET, OBJECT } from "../../../../../constants";

export default function GpsFieldView(props: any): JSX.Element {
  let {
    getDialogStatus,
    getLabel,
    getError,
    name,
    getActiveStatus,
    getValue,
    getLoadingStatus,
    handleClose,
    handleUpdate,
  } = props; /* get form field label, error, active status, loading status, value */
  let label = getLabel();
  let error = getError();
  let isDisabled = getActiveStatus();
  let isLoading = getLoadingStatus();
  let value = getValue();
  let { classes, t } = props.props as any;
  let placeholder = t("gps.noLocation");
  const gpsFieldRef = useRef<HTMLDivElement>(null);
  const [gpsFieldWidth, setGpsFieldWidth] = useState<number | null>(null);
  const locationState = { value, openDialog: false, isLoading: false };
  const hasLocation = value && typeof value === OBJECT && value.length == 2 ? true : false;
  const locationCoordinates = hasLocation && `${value[0]},${value[1]}`;
  const { locationButton, gpsText, gpsButton, removeIconContainer, mapLink } = gpsFieldStyles(locationCoordinates, gpsFieldWidth);

  const commonFieldProps = {
    autoComplete: "nope",
    error: !!error,
    fullWidth: true,
    InputLabelProps: { shrink: true },
    label: <CustomFieldLabel label={label} fieldId={name} />,
    name,
    placeholder: !hasLocation && placeholder,
    value: "",
    variant: InputVariantType.OUTLINED,
  };

  const renderLocationButton = (): JSX.Element => {
    return (
      <div className={classes.coordinateLinkBox}>
        <Button
          onClick={() => {
            getCoordinates(handleUpdate, t);
          }}
          style={locationButton}
          data-testid="getLocation"
          id="getLocation"
        >
          <RoomOutlined htmlColor={cobalt} />
          <FormLabel id="gpsText" component="article" className={classes.locationButtonText}>
            {t(`gps.${hasLocation ? "update" : "getLocation"}`)}
          </FormLabel>
        </Button>
      </div>
    );
  };
  const removeLocationData = (
    <div style={removeIconContainer}>
      <MuiThemeProvider theme={ButtonTheme}>
        <Tooltip placement="bottom" title={t("gps.removeLocation")}>
          <IconButton>
            <CloseIcon
              htmlColor={cobalt}
              onClick={event => {
                event.stopPropagation();
                handleUpdate({ ...locationState, openDialog: true });
              }}
              id="removeIcon"
              data-testid="removeIcon"
            />
          </IconButton>
        </Tooltip>
      </MuiThemeProvider>
    </div>
  );
  const handleLink = (): any => {
    if (value && value.length) {
      window.open(`${GPSLINK}${locationCoordinates}`, LINKTARGET);
    }
  };

  useEffect(() => {
    if (gpsFieldRef.current) {
      setGpsFieldWidth(gpsFieldRef.current.offsetWidth);
    }
  }, [value, isLoading]);

  return (
    <div className="field">
      <ConfirmationDialog
        alignActionsOnRightSide={true}
        open={getDialogStatus()}
        title={t("gps.clearLocationTitle", { label })}
        content={t("gps.clearLocationContent", { label })}
        primaryLabel={t("common.yes")}
        secondaryLabel={t("common.no")}
        primaryHandler={() => {
          handleUpdate({ ...locationState, value: null });
          showNotification("success", t("gps.deleteSuccess", { label }));
        }}
        secondaryHandler={() => {
          handleClose();
        }}
      />
      {/* Circular Progress loaded until */}
      {isLoading ? (
        <CircularProgress classes={{ root: `${classes.circularIcon}` }} />
      ) : (
        <div style={gpsText} id={name} ref={gpsFieldRef}>
          <TextField
            data-testid="getLocationText"
            {...commonFieldProps}
            style={gpsButton}
            inputProps={{
              readOnly: isDisabled,
            }}
            InputProps={{
              startAdornment: hasLocation && (
                <p style={mapLink} onClick={handleLink} data-testid="getLocationValueText">
                  {`${value}`}
                </p>
              ),
              endAdornment: !isDisabled && removeLocationData,
            }}
          />

          <div>{!isDisabled && renderLocationButton()}</div>
        </div>
      )}
      {/* show field error if exists */}
      {error && (
        <FormHelperText key={name} className="errorMessage" data-testid="gpsError">
          {t(`validation.${error.type}`, error.params)}
        </FormHelperText>
      )}
    </div>
  );
}
