import React, { useState } from "react";
import { Button, createMuiTheme, IconButton, MuiThemeProvider, Tooltip } from "@material-ui/core";
import SignatureCanvas from "react-signature-canvas";
import CustomDialog from "../../Dialog";
import { ButtonTheme } from "../../../../theme";
import CloseIcon from "@material-ui/icons/Close";
import DownloadIcon from "../../../../common/DownloadIcon";
import { handleImageDownload } from "../PictureComponent/utils";
import { url } from "../../../../../services/apiConfig";

const DrawingModalView = (props: any): JSX.Element => {
  const {
    canvasProps,
    clearOnResize,
    getDialogStatus,
    getPenColor,
    handleCanvasAccept,
    handleCanvasRefChange,
    handleCustomHandler,
    handleDismissPopup,
    handleDiscardPopup,
    handleEnter,
    setPenColor,
    getValue,
    getLabel,
    displayOnly,
    size,
  } = props;
  const penColor = getPenColor();
  const value = getValue();
  const label = getLabel();
  const { classes, t } = props.props;
  const { disableAllFields, isReadOnly } = props.state;
  const {
    canvasPenColor,
    canvasTitleContainer,
    downloadImgIcon,
    downloadContainer,
    canvasTitleIcons,
    downloadImgStyle,
    canvasThumbnail,
    canvasTitle,
    buttonTextColor,
    downloadIcon,
  } = classes;

  const [disableDownload, setDisableDownload] = useState(false);

  const exitIcon: JSX.Element = (
    <div className={classes.closeIcon}>
      <MuiThemeProvider theme={ButtonTheme}>
        <Tooltip placement="bottom" title={t("common.close") as string}>
          <IconButton color="inherit">
            <CloseIcon id="exit" onClick={!!disableAllFields || !!isReadOnly || !!displayOnly ? handleDiscardPopup : handleDismissPopup} />
          </IconButton>
        </Tooltip>
      </MuiThemeProvider>
    </div>
  );

  const renderCanvasTitle = (): JSX.Element => {
    return disableAllFields || isReadOnly || displayOnly ? (
      <div className={downloadImgIcon}>{exitIcon}</div>
    ) : (
      <div className={canvasTitleContainer}>
        <span className={canvasTitle}>{t("canvas.drawingCanvas")}</span>
        <div className={canvasTitleIcons}>
          {/* change pen color */}
          <input
            type="color"
            name="penColor"
            value={penColor}
            className={canvasPenColor}
            onChange={e => {
              setPenColor(e.target.value);
            }}
            data-testid="penColor"
          />
          {exitIcon}
        </div>
      </div>
    );
  };

  const renderCanvasContent = (): JSX.Element => {
    return disableAllFields || isReadOnly || displayOnly ? (
      <img src={value && value.dataUrl} className={downloadImgStyle} draggable={false} />
    ) : (
      <SignatureCanvas
        ref={(ref: any) => {
          handleCanvasRefChange(ref);
        }}
        clearOnResize={clearOnResize}
        penColor={penColor}
        canvasProps={canvasProps}
      />
    );
  };

  const renderDownloadButton = (
    <div className={downloadContainer}>
      {<DownloadIcon className={downloadIcon} onClick={() => handleImageDownload(`${url.file}/${value.id}`, label, setDisableDownload)} />}
      <Button
        id="custom"
        className={buttonTextColor}
        data-testid="confirmationModal"
        onClick={() => handleImageDownload(`${url.file}/${value.id}`, label, setDisableDownload)}
      >
        {t("common.download")}
      </Button>
    </div>
  );

  return (
    <CustomDialog
      id={"signature"}
      open={getDialogStatus()}
      fullScreen={!!(size && size === "fullscreen")}
      onEnter={() => {
        handleEnter();
      }}
      disabledContent={renderDownloadButton}
      disableAllFields={disableAllFields || isReadOnly || displayOnly}
      canvasThumbnail={canvasThumbnail}
      theme={createMuiTheme()}
      title={renderCanvasTitle()}
      content={renderCanvasContent()}
      doneLabel={t("common.done")}
      eraseLabel={t("common.erase")}
      customHandler={handleCustomHandler}
      onAccept={handleCanvasAccept}
    />
  );
};

export default DrawingModalView;
