import { Divider, Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { FormItemField } from "@juakali/juakali-forms-react";
import { ArrowForwardIos, ErrorOutline, Visibility } from "@material-ui/icons";
import { formItemStyles } from "./styles";
import { updateFormState } from "../../../rxjs/formBloc";
import { IconButton } from "@material-ui/core";
import { withTranslation } from 'react-i18next'

export class FormItem extends FormItemField {
  render(): JSX.Element {
    const { isTheFormInReadOnlyState, isTheFormInaValidState, title, headerStore, isAutoFilled, t  } = this.props;
    const { divider, errorIcon,errorIconFlex, formItem, formTitle, forwardIcon, readOnlyIcon, autoFillText, autoFillBox } = formItemStyles(!isTheFormInaValidState);

    const handleOnClick = () => {
      updateFormState({
        errorMessage: "",
      });
      this.onFormItemSelected();
      headerStore.setHeaderForActiveForm(this.state)
      let mdcDrawerContent = (document as HTMLDocument).querySelector(".mdc-drawer-app-content") as HTMLElement;
      mdcDrawerContent && mdcDrawerContent.scroll(0, 0);
    };

    return (
      <Fragment>
        <Grid container item xs={12} lg={12} md={12} style={formItem}>
          <Grid style={errorIconFlex}>
            {!isTheFormInaValidState && <ErrorOutline style={errorIcon}></ErrorOutline>}
            <Typography style={formTitle}>{title}</Typography>
            {isAutoFilled && <Grid style={autoFillBox}>
              <Typography style={autoFillText}>{t("outcome.autoFillLabel")}</Typography>
            </Grid>}
          </Grid>
          <Grid style={errorIconFlex}>
            {isTheFormInReadOnlyState ? (
              <Visibility data-testid="visibilityIcon" style={readOnlyIcon} onClick={handleOnClick}></Visibility>
            ) : (
              <IconButton data-testid="forwardIcon" onClick={handleOnClick}>
                <ArrowForwardIos style={forwardIcon} />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid item style={divider} lg={12} md={12} xs={12}>
          <Divider></Divider>
        </Grid>
      </Fragment>
    );
  }
}

export default withTranslation()(FormItem as any);
