import React from "react";
import { Button, MenuList, Popper, Grow, Paper, ClickAwayListener, MenuItem } from "@material-ui/core";
import { useStyles } from "./PopperMenuButtonStyles";

export interface OwnProps<T> {
  open: boolean;
  setOpen: (flag: boolean) => void;
  title?: string;
  handleMenuItemClick: (item: T) => void;
  menuItems: T[];
  getMenuLabel: (item: T) => string;
}

function PopperMenuButton<T>(props: OwnProps<T>): JSX.Element {
  let { open, setOpen, title, handleMenuItemClick, menuItems, getMenuLabel } = props;

  /* css styles */
  const { button, buttonColor, popper } = useStyles();

  /* set anchor target to place menu items next to popper button vertically */
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  return (
    <ClickAwayListener onClickAway={() => open && setOpen(false)}>
      <div className={button}>
        <Button
          aria-owns={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          className={buttonColor}
          color="primary"
          onClick={event => {
            setAnchorEl(event.currentTarget);
            setOpen(!open);
          }}
        >
          {title}
        </Button>
        <Popper className={popper} open={open} anchorEl={anchorEl} transition disablePortal>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <MenuList>
                  {menuItems.map((menuItem: T, i: number) => (
                    <MenuItem
                      key={i}
                      onClick={() => {
                        setOpen(false);
                        handleMenuItemClick(menuItem);
                      }}
                    >
                      {getMenuLabel(menuItem)} {/* get the menu item's label */}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

export default PopperMenuButton;
