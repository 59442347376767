import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  MuiThemeProvider,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { fetchWrapper } from "../../../../../core/fetchWrapper";
import { url } from "../../../../../services/apiConfig";
import { ButtonTheme } from "../../../../theme";
import CloseIcon from "@material-ui/icons/Close";
import PictureEditor from "./ImageEditor";
import { addSentryLogs, handleImageUpload } from "./utils";
import { updateImageEditorBlocState } from "../../../../../rxjs/ImageEditorBloc";

const useStyle = makeStyles((theme: any) => ({
  modalHeight: {
    [theme.breakpoints.up("md")]: {
      maxHeight: "80vh",
      height: "80vh",
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: "80vh",
      height: "80vh",
    },
    [theme.breakpoints.up("xl")]: {
      maxHeight: "80vh",
      height: "80vh",
    },
  },
}));

export default function galleryDropZone(props: any) {
  const {
    getCurrentShot,
    isShowCamera,
    getValue,
    handleClose,
    handleSave,
    isViewScreen,
    handleCloseViewScreen,
    handlePictureEditor,
    getGalleryEditorStatus,
    selectedImage,
    handleDismiss,
    handleGalleryDialog,
    handlePictureSelector,
  } = props;
  const WebcamDialogStyle = useStyle();
  const currentShot = getCurrentShot();
  let viewScreen = isViewScreen;
  /* Stylesheet for picture Field */
  let { classes, t, name } = props.props;

  const exitIcon = (): JSX.Element => {
    return (
      <div className={classes.closeIcon} data-testid="exitIcon">
        <MuiThemeProvider theme={ButtonTheme}>
          <Tooltip placement="bottom" title={t("common.close")}>
            <IconButton color="inherit">
              <CloseIcon
                id="exit"
                data-testid="exit"
                onClick={() => {
                  viewScreen || !currentShot ? handleCloseViewScreen() : handleClose();
                }}
              />
            </IconButton>
          </Tooltip>
        </MuiThemeProvider>
      </div>
    );
  };
  const usePhotoButton = (): JSX.Element => {
    return (
      <Button
        onClick={() => {
          handleSave(url, fetchWrapper, addSentryLogs, handleImageUpload);
          handlePictureSelector(false);
          handleGalleryDialog(false);
        }}
        className={classes.dialogButtonText}
        data-testid="useGalleryPhoto"
      >
        <FormLabel component="article" className={classes.dialogButtonText}>
          {t("picture.usePhoto")}
        </FormLabel>
      </Button>
    );
  };

  const editPhotoButton = (): JSX.Element => {
    return (
      <>
        <Button
          onClick={() => {
            handlePictureEditor(true);
            handlePictureSelector(false);
            handleDismiss();
            handleGalleryDialog(false);
            updateImageEditorBlocState({
              isZoomVisible: false,
              isRotateVisible: false,
              isCroppingEnabled: true,
              isDisable: true,
            });
          }}
          className={classes.dialogButtonText}
          data-testid="editPhoto"
        >
          <FormLabel component="article" className={classes.dialogButtonText}>
            {t("common.editPhoto")}
          </FormLabel>
        </Button>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={currentShot && getGalleryEditorStatus()}
        fullWidth={true}
        classes={{ paper: `${classes.paper}`, paperScrollPaper: `${WebcamDialogStyle.modalHeight}` }}
      >
        <DialogTitle classes={{ root: `${classes.dialogTitle}` }}>
          <div className={classes.dialogTitleContainer}>
            <span className={classes.dialogTitleText}>{selectedImage ? t("common.uploadPicture") : name}</span>
            {exitIcon()}
          </div>
        </DialogTitle>
        <DialogContent classes={{ root: `${classes.thumbnailDialogContent}` }}>
          <div className={classes.webCamImageContainer}>
            <img className={classes.webCamImageFit} src={selectedImage} draggable={false} data-testid="selectImage" />
          </div>
        </DialogContent>
        <DialogActions classes={{ root: `${classes.galleryDialogActions}`, action: `${classes.galleryDialogActions}` } as any}>
          {usePhotoButton()}
          {editPhotoButton()}
        </DialogActions>
      </Dialog>
      <PictureEditor {...props} />
    </>
  );
}
