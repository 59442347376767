import React from "react";
import i18n from "i18next";
import FilterList from "@material-ui/icons/FilterList";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { MuiThemeProvider, Typography } from "@material-ui/core";
import { defaultToolbarStyles, toolbarTheme } from "../styles/toolbarStyles";
import Chip from "@material-ui/core/Chip";
import { WithTranslation, withTranslation } from "react-i18next";
import SearchFilter from "./SearchFilter";
import CustomFilterDialog from "./CustomFilterDialog";
import { getFormattedDate, getTasksCount } from "../utils/toolbarHelper";
import { QuickFilter, TaskAssignment, TaskQueryFilterParams } from "../../../types/taskQueryTypes";
import { JuakaliUser, Paging } from "../../../redux/types";
import { getCurrentAssignee } from "../utils/dashboardHelper";
import { ASSIGNEE, DUEDATE, ERROR, REASSIGN_ERROR } from "../../../constants";
import ErrorMessage from "../../common/ErrorMessage";
import { cobalt } from "../../theme";
import "../styles/styles.scss";

const toolbarStyles = (buttonSelected?: boolean) => {
  return {
    alignGrid: { alignSelf: "flex-start", display: "flex" },
    marginBottom: { marginBottom: "8px" },
    displayChip: { display: "flex", alignItems: "center", },
    displayGrid: { display: "flex", alignItems: "baseline", flexShrink: 0, paddingRight: "16px" },
    marginLeft: { marginLeft: "10px" },
    typographyColor: { color: "#000000" },
    paddingRight: { paddingRight: "16px" },
    helperText: { marginLeft: "4px", fontSize: "12px", color: "rgba(0, 0, 0, 0.6)", flexShrink: 0 },
  };
};

interface OwnProps {
  itemCount: string | number;
  status: string;
  setStatus: (assignment: TaskAssignment, status?: string) => void;
  assignment: TaskAssignment;
  defaultFilterParams?: TaskQueryFilterParams;
  filterNew?: boolean;
  applyFilter: (params?: TaskQueryFilterParams) => void;
  users?: JuakaliUser[];
  filterNewAssignedTask?: boolean;
  onNewFilterClick?: () => void;
  onTaskQuickFilter?(filterType: QuickFilter): void;
  activeQuickFilter?: QuickFilter
  pagination: Paging;
  classes: any;
}

interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

interface OwnState {
  isPopoverOpened: boolean;
  anchorEl?: HTMLAnchorElement | null;
  isMobileView: boolean;
}

class CustomToolbar extends React.Component<OwnProps, OwnState> {
  ref: any;
  inputref: any;
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      isPopoverOpened: false,
      isMobileView: window.matchMedia("(max-width: 599.95px)").matches,
    };
  }

  onScreenSizeChange = (e: MediaQueryListEvent): void => this.setState({ isMobileView: e.matches });

  componentDidMount(): void {
    // Add Event listener when screen size changes
    window.matchMedia("(max-width: 599.95px)").addListener(this.onScreenSizeChange);
  }

  componentWillUnmount(): void {
    // Remove Event listener for screen size changes
    window.matchMedia("(max-width: 599.95px)").removeListener(this.onScreenSizeChange);
  }

  // componentWillReceiveProps(nextProps: OwnProps): void {
  //   if (this.props.status !== nextProps.status && (nextProps.status === "ERROR" || nextProps.status === "REASSIGN_ERROR")) {
  //     window.scrollTo({
  //       top: 0,
  //       left: 0,
  //       behavior: "smooth",
  //     });
  //   }
  // }

  /**
   * Function that gives the toggle behaviour to open/close the custom filter dialog
   * @param event event input
   */
  handleClick = (event: any) => {
    let { isPopoverOpened } = this.state;
    this.setState({
      isPopoverOpened: !isPopoverOpened,
      anchorEl: event.currentTarget,
    });
  };

  /**
   * Function that used to close the Custom Filter dialog
   * @param params includes `isPopoverOpened` & `anchorEl` parameters for resetting it to default
   */
  handlePopoverClose = (params: { isPopoverOpened: boolean; anchorEl?: HTMLAnchorElement | null }) => {
    this.setState(params);
  };

  /**
   * Removes the applied filters
   */
  removeFilters = (type: string) => {
    switch (type) {
      case DUEDATE:
        this.props.applyFilter({ dueDate: null });
        break;
      case ASSIGNEE:
        this.props.applyFilter({ assignee: "" });
        break;
      default:
        break;
    }
  };

  applyQuickFilter(filterType: QuickFilter) {
    const { onTaskQuickFilter } = this.props;
    onTaskQuickFilter && onTaskQuickFilter(filterType);
  }

  render(): JSX.Element {
    const { t, applyFilter, defaultFilterParams, setStatus, assignment, status, users, filterNewAssignedTask, classes, pagination, activeQuickFilter, filterNew, itemCount } = this.props;
    let { dueDate, taskName, assignee } = defaultFilterParams || { dueDate: null, taskName: "", assignee: "" };
    const isFilterApplied = dueDate || taskName || assignee;
    let { isPopoverOpened, anchorEl, isMobileView } = this.state;
    let { alignGrid, marginBottom, displayGrid, displayChip, marginLeft, typographyColor, paddingRight, helperText } = toolbarStyles(filterNewAssignedTask);

    return (
      <React.Fragment>
        <MuiThemeProvider theme={toolbarTheme}>
          <ErrorMessage
            errorMessage={
              status === ERROR || status === REASSIGN_ERROR ? (status === ERROR ? t(`dashboard.technicalError`) : t(`dashboard.reassignError`)) : ""
            }
            handleClear={() => setStatus(assignment)}
          />
          <Grid container>
            <Grid item md={7} lg={8} sm={7} style={isMobileView ? { ...alignGrid, ...marginBottom } : alignGrid}>
              <Button
                className="filter-button"
                startIcon={<FilterList data-testid="FilterIcon" htmlColor={cobalt} />}
                onClick={this.handleClick}
                key="filter"
                variant="outlined"
              >
                {t(`dashboard.filters`)}
              </Button>
              {isMobileView ? (
                <div style={displayChip}>
                  {dueDate ? (
                    <Chip
                      style={{ marginRight: 8 }}
                      clickable
                      label={getFormattedDate(dueDate, "-")}
                      onDelete={() => this.removeFilters(DUEDATE)}
                      onClick={() => this.removeFilters(DUEDATE)}
                    />
                  ) : (
                    undefined
                  )}
                  {assignee ? (
                    <Chip
                      clickable
                      label={getCurrentAssignee(assignee, users || [], false, true) as JuakaliUser | undefined}
                      onDelete={() => this.removeFilters(ASSIGNEE)}
                      onClick={() => this.removeFilters(ASSIGNEE)}
                    />
                  ) : (
                    undefined
                  )}
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12} md={5} lg={4} sm={5} style={marginBottom}>
              <SearchFilter assignment={assignment} applyFilter={applyFilter} defaultFilter={defaultFilterParams && defaultFilterParams.taskName} />
            </Grid>
            <CustomFilterDialog
              defaultFilterParams={defaultFilterParams}
              anchorEl={anchorEl}
              applyFilter={applyFilter}
              isPopoverOpened={isPopoverOpened}
              handleClosePopover={this.handlePopoverClose}
              users={users}
              assignment={assignment}
              isMobileView={isMobileView}
            />
            <Grid container wrap="nowrap" alignItems="center" className={classes.filterButtons}>
              {dueDate && !isMobileView ? (
                <Grid style={{ ...displayGrid, ...paddingRight }}>
                  <Typography style={typographyColor} variant={"body2"}>
                    {dueDate ? `${t(`dashboard.dueDateBefore`)}:` : t("dashboard.assignee")}
                  </Typography>
                  <Chip
                    style={marginLeft}
                    clickable
                    label={getFormattedDate(dueDate, "-")}
                    onDelete={() => this.removeFilters(DUEDATE)}
                    onClick={() => this.removeFilters(DUEDATE)}
                  />
                </Grid>
              ) : null}
              {assignee && !isMobileView ? (
                <Grid style={displayGrid}>
                  <Typography style={typographyColor} variant={"body2"}>
                    {`${t("dashboard.assignee")}:`}
                  </Typography>
                  <Chip
                    style={marginLeft}
                    clickable
                    label={getCurrentAssignee(assignee, users || [], false, true) as JuakaliUser | undefined}
                    onDelete={() => this.removeFilters(ASSIGNEE)}
                    onClick={() => this.removeFilters(ASSIGNEE)}
                  />
                </Grid>
              ) : null}
              {isFilterApplied && (
                <Typography style={helperText} key={"result"}>{`${itemCount} ${
                  itemCount == 1 ? t("dashboard.result") : t("dashboard.results")
                }`}</Typography>
              )}
              {(assignment === TaskAssignment.TEAM || assignment === TaskAssignment.INVOLVED) && (
                <Grid container justify="flex-end" spacing={1} className={classes.quickFilterRoot}>
                  {[QuickFilter.ALL, QuickFilter.NEW, QuickFilter.TODAY, QuickFilter.WEEK, QuickFilter.OVERDUE].map(filterType => (
                    <Grid item>
                      <Button
                        variant="outlined"
                        className={classes[filterType] + (activeQuickFilter === filterType ? " active" : "")}
                        onClick={() => this.applyQuickFilter(filterType)}
                      >
                        {t(`dashboard.${filterType}`)} ({getTasksCount(filterType, pagination)})
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(withTranslation()(CustomToolbar));
