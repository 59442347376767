import { ObjectDefinitionState, defaultObjectDefinitionState, ObjectDefinition, defaultObjectDefinition } from "./dataStoreTypes";
import { StateType } from "../redux/types";

export const getObjectDefinitionState = (store: StateType): ObjectDefinitionState => {
  return store.objectDefinitionState || defaultObjectDefinitionState;
};

export const getObjectDefinition = (store: StateType, definitionId: string): ObjectDefinition => {
  if (!store || !store.objectDefinitionState || !store.objectDefinitionState.items) {
    return defaultObjectDefinition;
  }
  const result = store.objectDefinitionState.items[definitionId] || defaultObjectDefinition;
  return result;
};
