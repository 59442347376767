import React from "react";
import { withStyles } from "@material-ui/core";
import "../../styles.scss";
import { pictureFieldStyles } from "../../styles";
import { PictureField } from "@juakali/juakali-forms-react";
import { withTranslation } from "react-i18next";
import PictureFieldView from "./PictureFieldView";
class PictureComponent extends PictureField {
  initCamera() {
    navigator &&
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream: any) => {
          this.updateState({ cameraAccess: true });
          return stream;
        })
        .then((stream: any) => {
          stream.getTracks().forEach((track: any) => track.stop());
        })
        .catch(() => {
          this.updateState({ cameraAccess: false });
        });
  }
  constructor(props: any) {
    super(props);
    if (!this.props.store.disableAllFields && !this.displayOnly) {
      this.initCamera();
    }
  }

  render(): JSX.Element {
    return <PictureFieldView {...this} />;
  }
}

const wrappedPictureComponent = withStyles(pictureFieldStyles)(PictureComponent as any);
export default withTranslation()(wrappedPictureComponent as any);
