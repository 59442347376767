import React, { Fragment, useEffect, useState } from "react";
import { AddCircleOutlineRounded, RemoveCircleOutlineRounded } from "@material-ui/icons";
import clsx from "clsx";
import { useStyles } from "./styles";
import { cobalt } from "../../../theme";
import "../styles.scss";

interface OwnProps {
  displayAddIcon: boolean;
  displayRemoveIcon: boolean;
  handleAdd: () => void;
  handleRemove: () => void;
  multiBoxRef: React.MutableRefObject<any>;
}

function IconView(props: OwnProps) {
  const { displayAddIcon, displayRemoveIcon, handleAdd, handleRemove, multiBoxRef } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const displayBothIcons = isHovered && displayAddIcon && displayRemoveIcon;
  const { addIcon, removeIcon } = useStyles({ displayBothIcons });

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    const el = multiBoxRef.current;
    if (el) {
      el.addEventListener("mouseenter", handleMouseEnter);
      el.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        el.removeEventListener("mouseenter", handleMouseEnter);
        el.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, []);

  return (
    <Fragment>
      {isHovered && displayAddIcon && (
        <AddCircleOutlineRounded data-testid="addIcon" className={clsx("multiBoxIcon", addIcon)} htmlColor={cobalt} onClick={handleAdd} />
      )}
      {isHovered && displayRemoveIcon && (
        <RemoveCircleOutlineRounded data-testid="removeIcon" className={clsx("multiBoxIcon", removeIcon)} htmlColor={cobalt} onClick={handleRemove} />
      )}
    </Fragment>
  );
}

export default IconView;
