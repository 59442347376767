import React, { useState, useEffect } from "react";
import * as rxjs from "rxjs";
import { BehaviorSubject } from "rxjs";

export interface BlocProps<T> {
  initialValue: T;
  subject: rxjs.BehaviorSubject<T>;
  builder: (snapshot: BlocSnapshot<T>) => JSX.Element;
}

export interface BlocSnapshot<T> {
  data: undefined | T;
  error: undefined | Error;
}

export function Bloc<T>(props: BlocProps<T>): JSX.Element {
  const [subjectState, setSubjectState] = useState<BlocSnapshot<T>>({
    data: props.initialValue,
    error: undefined,
  });

  useEffect(() => {
    let isMounted = true;

    const sub = props.subject.subscribe(
      nextState => {
        if (isMounted) {
          setSubjectState({
            data: nextState,
            error: undefined,
          });
        }
      },
      error => {
        if (isMounted) {
          setSubjectState({
            data: undefined,
            error: error,
          });
        }
      },
      () => {
        if (isMounted) {
          setSubjectState({
            data: undefined,
            error: undefined,
          });
        }
      }
    );
    return () => {
      isMounted = false;
      sub.unsubscribe();
    };
  }, [props.subject]);

  return props.builder(subjectState);
}

export function useBloc<T>(initialValue: T, subject: BehaviorSubject<T>): BlocSnapshot<T> {
  const [subjectState, setSubjectState] = useState<BlocSnapshot<T>>({
    data: initialValue,
    error: undefined,
  });

  useEffect(() => {
    let isMounted = true;

    const sub = subject.subscribe(
      nextState => {
        if (isMounted) {
          setSubjectState({
            data: nextState,
            error: undefined,
          });
        }
      },
      error => {
        if (isMounted) {
          setSubjectState({
            data: undefined,
            error: error,
          });
        }
      },
      () => {
        if (isMounted) {
          setSubjectState({
            data: undefined,
            error: undefined,
          });
        }
      }
    );
    return () => {
      isMounted = false;
      sub.unsubscribe();
    };
  }, [subject]);

  return subjectState;
}
