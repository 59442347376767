// @flow strict
import { AnyAction, createReducer } from "redux-starter-kit";
import { call, put, takeLatest } from "redux-saga/effects";
import { UserSettings, TypedAction, defaultSettingsState } from "../types";
import { setUserSetting, updateUserSettingsRemote, getUserSettingRemote, setLoadingStatus, setErrorStatus } from "../actions";
import { getSettings, updateSettings } from "../../services/UserSettingService";
import { fetchWrapper } from "../../core/fetchWrapper";
import { throwError } from "../../core/throwError";

const settingReducer = createReducer<UserSettings, AnyAction>(defaultSettingsState, {
  [setUserSetting.type]: (state: UserSettings, action: TypedAction<UserSettings>) => {
    if (!(action && action.payload)) {
      return state;
    }
    state.settings = action.payload;
    state.status = "success";
    return state;
  },
  [updateUserSettingsRemote.type]: (state: UserSettings, action: TypedAction<UserSettings>) => {
    state.status = "loading";
    return state;
  },
  [getUserSettingRemote.type]: (state: UserSettings, action: TypedAction<UserSettings>) => {
    state.status = "loading";
    return state;
  },
  [setLoadingStatus.type]: (state: UserSettings, action: TypedAction<UserSettings>) => {
    if (action.payload) {
      state.status = action.payload;
    }
    return state;
  },
  [setErrorStatus.type]: (state: UserSettings, action: TypedAction<string>) => {
    if (action.payload) {
      state.error = action.payload;
    }
    return state;
  },
});

export default settingReducer;

export function* getUserSettings(action: TypedAction<any>): any {
  try {
    const response: Response = yield call(fetchWrapper, getSettings);

    if (response.status === 200) {
      const body: any = yield response.json();
      yield put({ type: [setUserSetting.type], payload: body });
    } else {
      throwError(response);
    }
  } catch (error) {
    yield put({ type: [setLoadingStatus.type], payload: "error" });
  }
}

export function* watchGetUserSettings(): any {
  yield takeLatest([getUserSettingRemote.type], getUserSettings);
}

export function* updateUserSettings(action: TypedAction<any>): any {
  try {
    if (!action.payload) {
      throw new Error("No Payload");
    }

    const response: Response = yield call(fetchWrapper, updateSettings, action.payload);
    if (response.status === 200) {
      yield put({ type: [setLoadingStatus.type], payload: "saved" });
      yield put({ type: [setUserSetting.type], payload: action.payload });
    } else {
      throwError(response);
    }
  } catch (error) {
    yield put({ type: [setLoadingStatus.type], payload: "error" });
    yield put({ type: [setErrorStatus.type], payload: String(error) });
  }
}

export function* watchUpdateUserSettings(): any {
  yield takeLatest([updateUserSettingsRemote.type], updateUserSettings);
}
