import React from "react";
import * as Sentry from "@sentry/browser";

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== "development") {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
