// @flow
import React from "react";
import MUIDataTable, { Responsive } from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { ReportTableData, FilterType, SelectableRows } from "../../redux/types";

const myTheme = (type: string): any =>
  createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation4: {
          boxShadow: "none",
        },
      },
      MuiTableCell: {
        root: {
          padding: "4px 4px 4px 24px",
          width: type === "loanApplication" ? "80px" : "200px",
          wordWrap: type === "loanApplication" ? "unset" : "break-word",
        },
        body: {
          wordBreak: type === "loanApplication" ? "break-all" : "unset",
        },
        head: {
          wordBreak: type === "loanApplication" ? "break-word" : "unset",
        },
      },
    },
  });

export default function ReportTable(props: { tableData: ReportTableData; onRowClick?: any; title: string; type: string }): any {
  return (
    <MuiThemeProvider theme={myTheme(props.type)}>
      <MUIDataTable
        title={props.title}
        columns={props.tableData.header || []}
        data={props.tableData.body || []}
        options={{
          filterType: "multiselect" as FilterType,
          download: false,
          viewColumns: false,
          responsive: "stacked" as Responsive,
          print: false,
          selectableRows: "none" as SelectableRows,
          pagination: false,
          onRowClick: (rowData: any) => {
            props.onRowClick && props.onRowClick(rowData[0]);
          },
        }}
      />
    </MuiThemeProvider>
  );
}
