import * as yup from "yup";
import { PasswordError, PasswordSettingParameters, ResetPasswordParams, SettingsResponse } from "../../types/passwordTypes";
import { BODY, EMPTY, HEADER, STATUS_CODES } from "../../constants";
import { defaultLoginState, updateLogInBlocState } from "../../rxjs/loginBloc";
import { fetchWrapper } from "../../core/fetchWrapper";
import { fetchForUnAuthenticatedPasswordSetting, fetchForVerifyOobCode } from "../../services/PasswordService";

const { SUCCESS } = STATUS_CODES;

export const defaultPasswordError = {
  lengthError: true,
  digitError: true,
  lowerCaseError: true,
  upperCaseError: true,
  specialCaseError: true,
};

export const validatePassword = (settings: PasswordSettingParameters, password: string): PasswordError => {
  const { maxLength, minDigits, minLength, minLowercaseCharacters, minSpecialCharacters, minUppercaseCharacters } = settings;
  const passwordValidationData = [
    { regex: /\d/g, data: minDigits, errorText: "digitError" },
    { regex: /[a-z]/g, data: minLowercaseCharacters, errorText: "lowerCaseError" },
    { regex: /[A-Z]/g, data: minUppercaseCharacters, errorText: "upperCaseError" },
    { regex: /[ `!#$%^&*()@_+\-=\[\]{};':"\\|,.<>\/?~]/g, data: minSpecialCharacters, errorText: "specialCaseError" },
  ];
  var passwordErrors: PasswordError = {};
  // check for min/max length
  if (password.length < minLength || password.length > maxLength) {
    passwordErrors.lengthError = true;
  }
  // check for digits/lower case/upper case/special characters length
  passwordValidationData.forEach((item: { regex: RegExp; data: number; errorText: string }) => {
    if (item.data) {
      var regexResult = password.match(item.regex);
      if (regexResult) {
        let allAvailableDataFromRegexResult = regexResult.join("");
        if (allAvailableDataFromRegexResult.length < item.data) (passwordErrors as any)[item.errorText] = true;
      } else {
        (passwordErrors as any)[item.errorText] = true;
      }
    }
  });
  return passwordErrors;
};

export const getPasswordInputRules = (setting: PasswordSettingParameters, passwordErrors: PasswordError, t: any): any => {
  const { maxLength, minDigits, minLength, minLowercaseCharacters, minSpecialCharacters, minUppercaseCharacters } = setting;
  const { lengthError, lowerCaseError, upperCaseError, digitError, specialCaseError } = passwordErrors;
  return [
    { type: HEADER, text: t("changePassword.makeSurePassword") },
    {
      type: BODY,
      text: t("changePassword.atLeastSixUptoFifty", { minCount: minLength, maxCount: maxLength }),
      conditionFulfilled: !lengthError,
    },
    {
      type: BODY,
      text: minLowercaseCharacters && t("changePassword.minLowercaseCharacters", { count: minLowercaseCharacters }),
      conditionFulfilled: !lowerCaseError,
    },
    {
      type: BODY,
      text: minUppercaseCharacters && t("changePassword.minUppercaseCharacters", { count: minUppercaseCharacters }),
      conditionFulfilled: !upperCaseError,
    },
    {
      type: BODY,
      text: minDigits && t("changePassword.minDigits", { count: minDigits }),
      conditionFulfilled: !digitError,
    },
    {
      type: BODY,
      text: minSpecialCharacters && t("changePassword.minSpecialCharacters", { count: minSpecialCharacters }),
      conditionFulfilled: !specialCaseError,
    },
  ];
};

export const passwordValidationSchema = (passwordErrors: PasswordError, t: any): any => {
  return yup.object().shape({
    password: yup
      .string()
      .required(t("changePassword.required"))
      .test("password", t("changePassword.weakPassword"), value => {
        value;
        return !Object.keys(passwordErrors).length;
      }),
  });
};

export const redirectToLogin = (history: any) => {
  updateLogInBlocState(defaultLoginState);
  history.push("/login");
};

export const getQueryParams = (): ResetPasswordParams => {
  const queryParams = new URLSearchParams(location.search);
  return {
    oobCode: queryParams.get("oobCode") || EMPTY,
    tenant: queryParams.get("tenant") || EMPTY,
  };
};

export const getUnAuthenticatedPasswordSettings = async (
  tenantId: String,
  settingParameters: React.Dispatch<React.SetStateAction<PasswordSettingParameters | undefined>>
) => {
  const response: Response = await fetchWrapper(fetchForUnAuthenticatedPasswordSetting, tenantId);
  const result: SettingsResponse = await response.json();
  response.status === SUCCESS && settingParameters(result.result);
};

export const validateOobCode = async (
  oobCode: String,
  tenantId: String,
  setEmail: React.Dispatch<React.SetStateAction<String | undefined>>,
  setIsLinkExpired: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const response: Response = await fetchWrapper(fetchForVerifyOobCode, oobCode, tenantId);
  const { result } = await response.json();
  response.status === SUCCESS ? setEmail(result.email) : setIsLinkExpired(true);
};
