import { TASK } from "../../constants";
import { fetchWrapper } from "../../core/fetchWrapper";
import { fetchForAllDictionaries } from "../../services/DictionayService";
import { getGrapesJSForm, getTaskForm, getVariables } from "../../services/flow/FFormService";
import { fetchForProcessInstanceDetail } from "../../services/ProcessInstanceService";
import { fetchForOpenProcessTasks, fetchForTaskDetail } from "../../services/TaskService";
import { getSettings } from "../../services/UserSettingService";
import { showNotification } from "../../util/notification";
import { CUT } from "../TeamTask/constants";
import { fetchCombinedUserTaskModelData } from "./combinedusertask";
import { CUT_FORM, DICTIONARY, FORM_DATA, FORM_MODEL, GET_VARS, GRAPES_FORM, OPEN_TASKS, PROCESS_INSTANCE_DETAIL, TASK_DETAILS } from "./constants";
import { KeyValuePair } from "./type";

export const handleResult = (result: any, type: string, stateObjects: KeyValuePair, stateParams: KeyValuePair) => {
  const { setDictionaries, setDictionariesLoadingStatus, setTask, setProcessInstanceId,
    setTaskId, setNoTasksAvailable, setFormData, setCombinedUserTaskModelData, 
    setProcessInstance, setVariablesResult, setVariablesLoaded } = stateObjects;

  const { viewType, taskType } = stateParams;
  switch (type) {
    case DICTIONARY:
      setDictionaries(result.data);
      setDictionariesLoadingStatus(true);
      break;
    case TASK_DETAILS:
      if (result) {
        setTask(result);
        // only set the processInstanceId if it was empty, we were directly loading the task
        if (viewType === TASK) {
          setProcessInstanceId(result.processInstanceId);
        }
      }
      break;
    case OPEN_TASKS:
      if (result && result.data) {
        if (result.data.length > 0) {
          // set the task Id to the first available task
          setTaskId(result.data[0].id);
        } else {
          setNoTasksAvailable(true);
        }
      } else {
        throw new Error();
      }
      break;
    case FORM_DATA:
      setFormData(result);
      break;
    case FORM_MODEL:
      taskType === GRAPES_FORM && setFormData(result);
      taskType === CUT_FORM && setCombinedUserTaskModelData(result);
      break;
    case PROCESS_INSTANCE_DETAIL:
      setProcessInstance(result.result);
      break;
    case GET_VARS:
      setVariablesResult(result.result);
      setVariablesLoaded(true);
      break;
    default:
      break;
  }
};

export function callApiFunction(apiArgs: any, t: any, type: string, stateObjects: KeyValuePair, stateParams: KeyValuePair) {
    const apiPromise = fetchWrapper(apiArgs[0], apiArgs[1], apiArgs[3]);
    return apiPromise
    .then((res: any) => {
      if (res.status === 200) {
        return res.json();
      } else {
        if (res.status !== 401) {
            if (res.status === 404 && type == "processInstanceDetail") showNotification("error", t("tasks.taskNotFound"));
            else throw new Error();
        }
      }
    })
    .then((result: any) => handleResult(result, type, stateObjects, stateParams))
    .catch(() => {
      showNotification("error", t("common.serverErrorTryAgain"));
    });
  }

export const getPromises = ({taskId, processInstanceId}: {taskId: string; processInstanceId: string}) => {
    return {
         promiseForDictionaries: [fetchForAllDictionaries],
         promiseForUserSettings: [getSettings],
         promiseForTaskDetails: [fetchForTaskDetail, taskId],
         promiseForOpenProcessTasks: [fetchForOpenProcessTasks, processInstanceId],
         promiseForTaskForm: [getTaskForm, taskId, processInstanceId],
         promiseForProcessInstanceDetail: [fetchForProcessInstanceDetail, processInstanceId],
         promiseForGetVariables: [getVariables, taskId],
         getPromiseForModel: (modelId: string, taskType: string, setElements: any, setCombinedUserTaskModelData: any) => {
            if (taskType === "GRAPES_FORM") {
              setElements(undefined); // set the elements to undefined, so that it forces to recreate the stores ,when a new form is loaded
              return [getGrapesJSForm, modelId];
            } else if (CUT) {
              setCombinedUserTaskModelData(undefined);
              return [fetchCombinedUserTaskModelData, modelId];
            }
          }
    }
}