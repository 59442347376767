import debug from 'debug';

const BASE = 'juakali-los-task';
// const COLOURS = {
//   trace: 'lightblue',
//   info: 'blue',
//   warn: 'orange',
//   error: 'red',
// }; // choose better colours :)

const generateMessage = (level: string, message: string, source?: string): void => {
  // Set the prefix which will cause debug to enable the message
  const namespace = `${BASE}:${level}`;
  const createDebug = debug(namespace);

  // Set the colour of the message based on the level
  // createDebug.color = COLOURS[level];

  if (source) { createDebug(source, message); } else { createDebug(message); }
};

const Log = {

  trace(message: string, source?: string) {
    generateMessage('trace', message, source);
  },

  info(message: string, source?: string) {
    generateMessage('info', message, source);
  },

  warn(message: string, source?: string) {
    generateMessage('warn', message, source);
  },

  error(message: string, source?: string) {
    generateMessage('error', message, source);
  },
};

export default Log;
