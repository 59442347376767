import React from "react";

const NotificationNone = (): JSX.Element => (
  <svg width="207" height="185" viewBox="0 0 207 185" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M115.541 40.4812H66.4998C65.3822 40.4825 64.3108 40.933 63.5206 41.7336C62.7304 42.5343 62.2858 43.6198 62.2844 44.7521V155.185L61.7224 155.359L49.6918 159.092C49.1217 159.268 48.5058 159.208 47.9795 158.924C47.4532 158.641 47.0594 158.157 46.8845 157.58L11.0991 39.1458C10.9249 38.5681 10.9843 37.944 11.2641 37.4106C11.5439 36.8772 12.0213 36.4782 12.5913 36.3013L31.1304 30.5497L84.8758 13.8815L103.415 8.12994C103.697 8.04196 103.993 8.01122 104.287 8.03947C104.581 8.06772 104.867 8.15441 105.127 8.29458C105.388 8.43475 105.619 8.62564 105.807 8.85633C105.995 9.08703 106.136 9.353 106.222 9.63902L115.369 39.9117L115.541 40.4812Z"
      fill="#F2F2F2"
    />
    <path
      d="M126.243 39.9126L115.218 3.42703C115.035 2.81911 114.735 2.25377 114.336 1.76331C113.937 1.27285 113.446 0.866893 112.892 0.568625C112.338 0.270356 111.731 0.0856235 111.107 0.0249902C110.482 -0.0356431 109.852 0.0290111 109.252 0.215252L83.1873 8.29879L29.4448 24.9698L3.38001 33.0562C2.16918 33.4329 1.15534 34.281 0.561014 35.4143C-0.0333085 36.5476 -0.159551 37.8736 0.210002 39.1011L37.8894 163.794C38.1897 164.785 38.7954 165.652 39.6178 166.268C40.4402 166.885 41.4359 167.218 42.4589 167.219C42.9324 167.219 43.4032 167.147 43.8555 167.005L61.7229 161.465L62.285 161.288V160.693L61.7229 160.867L43.6898 166.462C42.621 166.792 41.4666 166.679 40.4799 166.148C39.4931 165.617 38.7546 164.711 38.4262 163.629L0.749679 38.9331C0.586993 38.3969 0.530293 37.8335 0.582823 37.2751C0.635353 36.7167 0.796078 36.1743 1.0558 35.6789C1.31553 35.1835 1.66916 34.7449 2.09642 34.3882C2.52368 34.0315 3.0162 33.7636 3.54577 33.6001L29.6105 25.5137L83.3532 8.84548L109.418 0.759095C109.82 0.634895 110.237 0.571567 110.657 0.571171C111.559 0.573221 112.435 0.867621 113.16 1.41125C113.884 1.95488 114.417 2.71917 114.682 3.59218L125.655 39.9126L125.83 40.482H126.414L126.243 39.9126Z"
      fill="#3F3D56"
      fill-opacity="0.7"
    />
    <path
      d="M53.4373 12.8004C56.5414 12.8004 59.0578 10.2508 59.0578 7.10577C59.0578 3.96071 56.5414 1.41113 53.4373 1.41113C50.3333 1.41113 47.8169 3.96071 47.8169 7.10577C47.8169 10.2508 50.3333 12.8004 53.4373 12.8004Z"
      fill="#134A9F"
    />
    <path
      d="M53.4374 10.7111C55.403 10.7111 56.9965 9.09659 56.9965 7.10504C56.9965 5.11349 55.403 3.49902 53.4374 3.49902C51.4718 3.49902 49.8784 5.11349 49.8784 7.10504C49.8784 9.09659 51.4718 10.7111 53.4374 10.7111Z"
      fill="white"
      fill-opacity="0.7"
    />
    <path
      d="M169.354 165.763H74.3687C73.7354 165.763 73.1282 165.507 72.6804 165.054C72.2326 164.6 71.9807 163.985 71.98 163.343V48.0267C71.9807 47.385 72.2326 46.7698 72.6804 46.3161C73.1282 45.8624 73.7354 45.6072 74.3687 45.6064H169.354C169.987 45.6072 170.595 45.8624 171.042 46.3161C171.49 46.7698 171.742 47.385 171.743 48.0267V163.343C171.742 163.985 171.49 164.6 171.042 165.054C170.595 165.507 169.987 165.763 169.354 165.763Z"
      fill="#E6E6E6"
    />
    <path
      d="M125.655 39.9121H66.5C65.2335 39.9139 64.0194 40.4245 63.1239 41.3318C62.2284 42.2392 61.7244 43.4693 61.7227 44.7526V160.866L62.2847 160.693V44.7526C62.2861 43.6202 62.7306 42.5347 63.5208 41.734C64.3111 40.9334 65.3825 40.483 66.5 40.4816H125.829L125.655 39.9121ZM177.223 39.9121H66.5C65.2335 39.9139 64.0194 40.4245 63.1239 41.3318C62.2284 42.2392 61.7244 43.4693 61.7227 44.7526V175.16C61.7244 176.443 62.2284 177.673 63.1239 178.58C64.0194 179.488 65.2335 179.998 66.5 180H177.223C178.489 179.998 179.703 179.488 180.599 178.58C181.494 177.673 181.998 176.443 182 175.16V44.7526C181.998 43.4693 181.494 42.2392 180.599 41.3318C179.703 40.4245 178.489 39.9139 177.223 39.9121ZM181.438 175.16C181.437 176.292 180.992 177.378 180.202 178.178C179.412 178.979 178.34 179.429 177.223 179.431H66.5C65.3825 179.429 64.3111 178.979 63.5208 178.178C62.7306 177.378 62.2861 176.292 62.2847 175.16V44.7526C62.2861 43.6202 62.7306 42.5347 63.5208 41.734C64.3111 40.9334 65.3825 40.483 66.5 40.4816H177.223C178.34 40.483 179.412 40.9334 180.202 41.734C180.992 42.5347 181.437 43.6202 181.438 44.7526V175.16Z"
      fill="#3F3D56"
      fill-opacity="0.7"
    />
    <path
      d="M147.715 52.4405H96.0072C95.3367 52.4397 94.6938 52.1695 94.2196 51.6891C93.7455 51.2087 93.4788 50.5573 93.478 49.8779V37.3497C93.4788 36.6703 93.7455 36.0189 94.2197 35.5385C94.6938 35.0581 95.3367 34.7879 96.0072 34.7871H147.715C148.386 34.7879 149.029 35.0581 149.503 35.5385C149.977 36.0189 150.244 36.6703 150.244 37.3497V49.8779C150.244 50.5573 149.977 51.2087 149.503 51.6891C149.029 52.1695 148.386 52.4397 147.715 52.4405Z"
      fill="#134A9F"
    />
    <path
      d="M121.861 35.6412C124.965 35.6412 127.482 33.0917 127.482 29.9466C127.482 26.8015 124.965 24.252 121.861 24.252C118.757 24.252 116.241 26.8015 116.241 29.9466C116.241 33.0917 118.757 35.6412 121.861 35.6412Z"
      fill="#134A9F"
    />
    <path
      d="M121.861 33.4147C123.752 33.4147 125.284 31.8617 125.284 29.9461C125.284 28.0305 123.752 26.4775 121.861 26.4775C119.97 26.4775 118.438 28.0305 118.438 29.9461C118.438 31.8617 119.97 33.4147 121.861 33.4147Z"
      fill="white"
      fill-opacity="0.7"
    />
    <path
      d="M34.4757 36.3949C33.9341 36.3946 33.4068 36.2183 32.9713 35.8921C32.5357 35.5658 32.2149 35.1067 32.0557 34.5821L28.4361 22.603C28.3388 22.2812 28.305 21.9431 28.3367 21.6082C28.3684 21.2732 28.4649 20.9478 28.6208 20.6506C28.7766 20.3534 28.9887 20.0902 29.2449 19.876C29.5011 19.6619 29.7965 19.501 30.1141 19.4025L79.5568 4.06579C80.1982 3.86748 80.8911 3.93512 81.4833 4.25384C82.0755 4.57257 82.5188 5.11635 82.7157 5.76584L86.3354 17.7452C86.531 18.395 86.4642 19.097 86.1496 19.697C85.835 20.297 85.2984 20.7461 84.6575 20.9457L35.2146 36.2825C34.9752 36.3569 34.7262 36.3948 34.4757 36.3949Z"
      fill="#134A9F"
    />
    <g clip-path="url(#clip0_352_16499)">
      <path
        d="M197.947 184.767L195.005 174.265C195.005 174.265 198.241 178.092 198.241 181.233L197.66 173.97C197.66 173.97 199.32 176.62 199.154 180.644C198.988 184.668 197.947 184.767 197.947 184.767Z"
        fill="#E6E6E6"
      />
      <path
        d="M134.772 175.204L131.901 164.954C131.901 164.954 135.059 168.69 135.059 171.755L134.493 164.667C134.493 164.667 136.112 167.253 135.95 171.181C135.788 175.108 134.772 175.204 134.772 175.204Z"
        fill="#E6E6E6"
      />
      <path
        d="M179.584 179.933C179.589 186.676 168.285 184.602 154.446 184.616C140.607 184.63 129.474 186.727 129.469 179.984C129.464 173.242 140.764 171.764 154.603 171.75C168.442 171.736 179.58 173.191 179.584 179.933Z"
        fill="#E6E6E6"
      />
      <path d="M207 184.612H104V184.921H207V184.612Z" fill="#3F3D56" />
      <path d="M169.886 170.828H168.077L167.216 162.573H169.886V170.828Z" fill="#A0616A" />
      <path
        d="M166.583 170.216H170.273V172.815H164.587V172.577C164.587 171.951 164.797 171.351 165.172 170.908C165.546 170.465 166.054 170.216 166.583 170.216Z"
        fill="#2F2E41"
      />
      <path d="M183.184 183.013H181.375L180.514 174.757H183.184V183.013Z" fill="#A0616A" />
      <path
        d="M179.882 182.401H183.572V185H177.886V184.762C177.886 184.136 178.096 183.535 178.47 183.092C178.845 182.65 179.352 182.401 179.882 182.401Z"
        fill="#2F2E41"
      />
      <path
        d="M191.53 158.487C191.655 158.308 191.745 158.098 191.795 157.873C191.844 157.648 191.851 157.412 191.815 157.184C191.779 156.955 191.701 156.738 191.586 156.549C191.472 156.36 191.324 156.203 191.152 156.089L186.95 135.839L184.263 137.206L189.262 156.786C189.119 157.139 189.09 157.542 189.181 157.919C189.273 158.295 189.477 158.619 189.757 158.829C190.037 159.038 190.372 159.119 190.698 159.056C191.025 158.993 191.321 158.791 191.53 158.487Z"
        fill="#A0616A"
      />
      <path
        d="M188.252 117.562C188.102 117.376 187.92 117.232 187.717 117.139C187.515 117.046 187.298 117.006 187.082 117.023C186.865 117.04 186.655 117.113 186.465 117.237C186.274 117.361 186.11 117.532 185.982 117.739L179.478 118.623L180.467 121.59L186.382 120.281C186.691 120.512 187.06 120.601 187.421 120.532C187.781 120.462 188.108 120.239 188.338 119.904C188.569 119.569 188.687 119.145 188.671 118.713C188.655 118.282 188.506 117.872 188.252 117.562Z"
        fill="#A0616A"
      />
      <path
        d="M177.534 156.387L180.372 181.585L183.905 180.725C183.905 180.725 183.385 162.374 185.151 160.162C186.918 157.949 182.88 154.074 182.88 154.074L177.534 156.387Z"
        fill="#2F2E41"
      />
      <path
        d="M178.708 149.447L177.404 151.76C177.404 151.76 167.365 146.825 167.365 154.691C167.365 162.557 166.79 167.895 166.582 168.264C166.374 168.632 168.986 169.589 170.233 169.343C170.233 169.343 171.771 156.662 171.667 155.925C171.667 155.925 181.515 161.637 183.385 161.145C185.255 160.653 185.697 158.913 186.009 157.93C186.321 156.947 185.878 149.139 185.878 149.139L179.645 146.888L178.708 149.447Z"
        fill="#2F2E41"
      />
      <path
        d="M178.883 135.556L178.512 135.029C178.512 135.029 174.35 123.95 175.985 120.961C177.62 117.971 184.755 117.532 184.978 117.708C185.201 117.884 184.445 120.167 184.966 121.222C184.966 121.222 179.438 122.639 179.067 122.815C178.695 122.991 181.931 129.489 181.931 129.489L181.559 133.446L178.883 135.556Z"
        fill="#CCCCCC"
      />
      <path
        d="M186.167 130.105C186.167 130.105 182.302 130.632 182.154 130.632C182.005 130.632 181.931 129.489 181.931 129.489L178.512 135.028C178.512 135.028 176.827 151.098 177.273 150.834C177.719 150.571 185.944 152.086 186.019 151.382C186.093 150.679 185.944 147.162 186.167 146.986C186.39 146.81 190.032 135.908 190.032 135.908C190.032 135.908 189.661 133.622 188.397 132.83C187.134 132.039 186.167 130.105 186.167 130.105Z"
        fill="#CCCCCC"
      />
      <path
        d="M188.843 134.414L190.032 135.908C190.032 135.908 191.799 153.084 190.833 153.611C189.867 154.139 187.965 154.074 187.965 154.074L186.093 145.932L188.843 134.414Z"
        fill="#CCCCCC"
      />
      <path
        d="M182.453 129.017C184.343 129.017 185.874 127.205 185.874 124.97C185.874 122.735 184.343 120.923 182.453 120.923C180.564 120.923 179.032 122.735 179.032 124.97C179.032 127.205 180.564 129.017 182.453 129.017Z"
        fill="#A0616A"
      />
      <path
        d="M181.651 125.602C181.605 124.762 180.709 124.734 179.998 124.726C179.286 124.718 178.436 124.748 178.033 124.054C177.767 123.595 177.818 122.959 178.038 122.467C178.258 121.975 178.619 121.597 178.975 121.234C179.893 120.295 180.839 119.363 181.941 118.762C183.043 118.161 184.335 117.918 185.478 118.4C186.882 118.993 188.781 122.044 188.945 123.797C189.108 125.55 188.516 127.337 187.518 128.647C186.52 129.957 184.235 129.429 182.797 129.895C183.671 128.431 183.095 125.772 181.694 125.088L181.651 125.602Z"
        fill="#2F2E41"
      />
      <path
        d="M151.161 177.949C155.697 177.949 159.374 173.6 159.374 168.234C159.374 162.869 155.697 158.519 151.161 158.519C146.626 158.519 142.949 162.869 142.949 168.234C142.949 173.6 146.626 177.949 151.161 177.949Z"
        fill="#134A9F"
      />
      <path
        d="M145.677 157.828C144.538 157.829 143.425 157.438 142.47 156.705C141.515 155.972 140.761 154.927 140.298 153.697L127.266 118.98C126.834 117.83 126.606 116.588 126.595 115.33C126.584 114.072 126.791 112.824 127.203 111.664C127.614 110.504 128.222 109.455 128.989 108.583C129.756 107.711 130.665 107.034 131.661 106.593C132.658 106.152 133.72 105.956 134.782 106.019C135.844 106.081 136.885 106.4 137.839 106.955C138.793 107.511 139.642 108.291 140.332 109.249C141.022 110.206 141.539 111.321 141.852 112.523L151.288 148.832C151.558 149.871 151.619 150.971 151.464 152.043C151.31 153.115 150.945 154.129 150.4 155.004C149.854 155.879 149.142 156.59 148.322 157.081C147.502 157.571 146.596 157.827 145.677 157.828H145.677Z"
        fill="#134A9F"
      />
    </g>
    <defs>
      <clipPath id="clip0_352_16499">
        <rect width="103" height="79" fill="white" transform="translate(104 106)" />
      </clipPath>
    </defs>
  </svg>
);

export default NotificationNone;
