import React, { useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import LogoutButton from "./LogoutButton";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { logoutStyles } from "../styles";
import { AccountContext } from "../../../App";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function Logout(props: any) {
  const classes: any = logoutStyles();
  const { open, handleClose, handleLogout } = props;

  const accountContext = useContext(AccountContext);

  return (
    <Popover
      id="open"
      open={open}
      onClose={handleClose}
      className={classes.popup}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Paper elevation={3} className={classes.logoutPaper}>
        <div className={classes.logoutPaperEmail}>
          <AccountCircleOutlinedIcon className={classes.userIconLarge} />
          <Typography
            variant="overline"
            className={classes.bold + " " + classes.textTransform + " " + classes.emailText}
            color="primary"
            display="block"
          >
            {accountContext ? accountContext.email : ""}
          </Typography>
        </div>
        <div className={classes.logoutMain}>
          <hr className={classes.logoutHr} />
          <div className={classes.logoutPaperBtn}>
            <LogoutButton handleLogout={handleLogout} />
          </div>
        </div>
      </Paper>
    </Popover>
  );
}

export default Logout;
