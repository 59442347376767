import { DynamicJson } from "../../../datastore/dataStoreTypes";
import { QuickFilter, TaskAssignment, TaskTableColumn, TaskTableType } from "../../../types/taskQueryTypes";
import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { JuakaliUser } from "../../../redux/types";
import SearchAssignee from "./SearchAssignee";
import { toLocaleShortDate, toTimeFromNow } from "../../../util/dateUtils";
import { cobalt } from "../../theme";
import i18n from "../../../i18n";
import { Checkbox, Grid, Tooltip } from "@material-ui/core";
import { getDueDateColor } from "../utils/dashboardHelper";
import { HelpOutlineOutlined } from "@material-ui/icons";

let whiteSpace = { whiteSpace: "nowrap" as any };
let whiteSpacePre = { whiteSpace: "pre" };
let marginBottom = {
  marginBottom: "16px",
};
let typographyFont = {
  fontWeight: 600,
};
let newText = { ...whiteSpace, color: cobalt, fontWeight: 550, fontSize: "12px", marginRight: '5px' };
let dateTextWrapper: any = { display: "flex", flexDirection: "column", alignItems: 'flex-start' };

export const getTaskTableColumnProperties = (
  taskTableType: TaskTableType,
  properties: {
    currentTeamTask?: (string | undefined)[] | null;
    usersList?: JuakaliUser[];
    setAssignee?: (parameter: JuakaliUser | undefined) => void;
    assignee?: JuakaliUser | undefined;
  }
): DynamicJson => {
  let { usersList } = properties;

  return {
    [TaskTableColumn.PROCESS_INSTANCE_ID]: {
      name: TaskTableColumn.PROCESS_INSTANCE_ID,
      displayParam: "excluded",
      isShownInCandidateTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
      isShownInInvolvedTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
      isShownInTeamTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
    },
    [TaskTableColumn.TASK_ID]: {
      name: TaskTableColumn.TASK_ID,
      displayParam: "excluded",
      isShownInCandidateTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
      isShownInInvolvedTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
      isShownInTeamTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
    },
    [TaskTableColumn.DUE_DATE]: {
      name: TaskTableColumn.DUE_DATE,
      sort: true,
      isShownInCandidateTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
      isShownInInvolvedTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
      isShownInTeamTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
      setCellHeaderProps: () => ({ style: whiteSpacePre }),
      // eslint-disable-next-line react/display-name
      customBodyRender: (value: string): JSX.Element => {
        const [taskDueDate, taskStatus, taskDueDateISO] = value.split(",");
        const isTaskStatusNew = taskStatus === QuickFilter.NEW;

        return (
          <div style={dateTextWrapper}>
            {isTaskStatusNew && (
              <Grid container alignItems="center">
                <Typography style={newText}>{taskStatus}</Typography>
                <Tooltip title={i18n.t('dashboard.newlyAssignedTask') as string}>
                  <HelpOutlineOutlined fontSize={'inherit'} />
                </Tooltip>
              </Grid>
              ) 
            }
            <span style={{...whiteSpace, color: (taskTableType === TaskTableType.TEAM || taskTableType === TaskTableType.INVOLVED) && getDueDateColor(taskDueDateISO)  || undefined}}>{taskDueDate}</span>
          </div>
        );
      },
    },
    [TaskTableColumn.CREATED_ON]: {
      name: TaskTableColumn.CREATED_ON,
      sort: true,
      isShownInCandidateTable: { isVisibleInMobile: false, isVisibleInDesktop: true },
      isShownInInvolvedTable: { isVisibleInMobile: false, isVisibleInDesktop: true },
      isShownInTeamTable: { isVisibleInMobile: false, isVisibleInDesktop: false },
      setCellHeaderProps: () => ({ style: whiteSpacePre }),
      // eslint-disable-next-line react/display-name
      customBodyRender: (value: any): JSX.Element => {
        return (
          <div style={dateTextWrapper}>
            <span style={whiteSpace}>{toLocaleShortDate(value)}</span>
            <span style={whiteSpace}>{toTimeFromNow(value)}</span>
          </div>
        );
      },
    },
    [TaskTableColumn.TASK_NAME]: {
      name: TaskTableColumn.TASK_NAME,
      sort: true,
      isShownInCandidateTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
      isShownInInvolvedTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
      isShownInTeamTable: { isVisibleInMobile: false, isVisibleInDesktop: true },
    },
    [TaskTableColumn.PROCESS_NAME]: {
      name: TaskTableColumn.PROCESS_NAME,
      sort: true,
      isShownInCandidateTable: { isVisibleInMobile: false, isVisibleInDesktop: true },
      isShownInInvolvedTable: { isVisibleInMobile: false, isVisibleInDesktop: true },
      isShownInTeamTable: { isVisibleInMobile: false, isVisibleInDesktop: true },
    },
    [TaskTableColumn.ASSIGNEE_NAME]: {
      name: TaskTableColumn.ASSIGNEE_NAME,
      sort: true,
      isShownInCandidateTable: { isVisibleInMobile: false, isVisibleInDesktop: false },
      isShownInInvolvedTable: { isVisibleInMobile: false, isVisibleInDesktop: false },
      isShownInTeamTable: { isVisibleInMobile: false, isVisibleInDesktop: true },
      customBodyRender: (value: string, tableMeta: any): JSX.Element | string => {
        usersList = usersList || [];
        let searchProps = { ...properties, tableMeta, usersList, value };
        let customBody = <SearchAssignee {...searchProps} />;
        return customBody;
      },
    },
    [TaskTableColumn.CANDIDATES]: {
      name: TaskTableColumn.CANDIDATES,
      sort: false,
      isShownInCandidateTable: { isVisibleInMobile: false, isVisibleInDesktop: true },
      isShownInInvolvedTable: { isVisibleInMobile: false, isVisibleInDesktop: false },
      isShownInTeamTable: { isVisibleInMobile: false, isVisibleInDesktop: false },
    },
    [TaskTableColumn.TASK_DETAILS]: {
      name: TaskTableColumn.TASK_DETAILS,
      sort: false,
      isShownInCandidateTable: { isVisibleInMobile: false, isVisibleInDesktop: false },
      isShownInInvolvedTable: { isVisibleInMobile: false, isVisibleInDesktop: false },
      isShownInTeamTable: { isVisibleInMobile: true, isVisibleInDesktop: false },
      customBodyRender: (value: any, tableMeta: any): JSX.Element => {
        let searchProps = { ...properties, tableMeta, usersList, value: tableMeta.rowData[6] };
        let customBody = (
          <div>
            <Typography style={{ ...marginBottom, ...typographyFont }}>{tableMeta.rowData[4]}</Typography>
            <Typography style={{ ...marginBottom }}>{tableMeta.rowData[5]}</Typography>
            <Typography>{<SearchAssignee {...searchProps} />}</Typography>
          </div>
        );
        return customBody;
      },
    },
    [TaskTableColumn.ASSIGNEE]: {
      name: TaskTableColumn.ASSIGNEE,
      displayParam: "excluded",
      isShownInCandidateTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
      isShownInInvolvedTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
      isShownInTeamTable: { isVisibleInMobile: true, isVisibleInDesktop: true },
    },
  };
};

export const checkAndAddSelectColumn = (newProps: DynamicJson, tableState: DynamicJson) => {
  if (newProps.assignment === TaskAssignment.TEAM && newProps.tasksHeader[0].name !== TaskTableColumn.SELECT_ROW) {
    newProps.tasksHeader.unshift({
      name: TaskTableColumn.SELECT_ROW,
      label: <Fragment />,
      filter: false,
      sort: false,
      empty: true,
      options: {
        customHeadRender: () => {
          const currentTotalTasks = newProps.tasks.length;
          const { onRowSelect, state } = tableState;
          const noOfRowsSelected = state.rowsSelected.length;
          const title =
            noOfRowsSelected === currentTotalTasks
              ? i18n.t("dashboard.unSelectAll")
              : noOfRowsSelected
              ? i18n.t("dashboard.unSelect")
              : i18n.t("dashboard.selectAll");
          return (
            <th className="select-head-cell MuiTableCell-head MuiTableCell-root">
              <Tooltip title={title as string}>
                <Checkbox
                  checked={!!noOfRowsSelected}
                  indeterminate={!!(noOfRowsSelected && noOfRowsSelected !== currentTotalTasks)}
                  onChange={e => onRowSelect(-1, e.target.checked)}
                />
              </Tooltip>
            </th>
          );
        },
        customBodyRenderLite: (dataIndex: number) => {
          const { onRowSelect, state } = tableState;
          return (
            <Tooltip title={i18n.t("dashboard.select") as string}>
              <Checkbox checked={state.rowsSelected.includes(dataIndex)} onChange={e => onRowSelect(dataIndex, e.target.checked)} />
            </Tooltip>
          );
        },
      },
    });
  }
};
