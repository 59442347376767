import React from "react";
import { WebhookButtonField } from "@juakali/juakali-forms-react";
import { withTranslation } from "react-i18next";
import WebhookButtonView from "./WebhookButtonView";

class WebhookButtonComponent extends WebhookButtonField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <WebhookButtonView {...this} />;
  }
}

export default withTranslation()(WebhookButtonComponent as any);
