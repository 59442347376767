import React, { useState } from "react";
import { JuakaliUser } from "../../../redux/types";
import { Box, Grid, MuiThemeProvider, Tooltip, Typography } from "@material-ui/core";
import ForwardIcon from "../../common/ForwardIcon";
import ConfirmationDialogRaw from "../../common/ConfirmationDialogRaw";
import SearchableDropdown from "../../common/SearchableDropdown";
import ExpandArrowIcon from "../../common/ExpandArrowIcon";
import { EMPTY, MAX_REASSIGNABLE_ROWS } from "../../../constants";
import { assigneeDialogTheme, confirmDialogTheme, selectBarStyle, selectBarTheme } from "../styles/tableStyles";
import { useTranslation } from "react-i18next";
import { getCurrentAssignee } from "../utils/dashboardHelper";

interface OwnProps {
  handleReassign: (assignee: JuakaliUser) => Promise<void>;
  rowsSelected: number[];
  users?: JuakaliUser[];
}

interface OwnState {
  assignee?: JuakaliUser;
  openAssigneeDialog: boolean;
  openConfirmDialog: boolean;
}

function CustomToolbarSelect({ handleReassign, rowsSelected, users }: OwnProps) {
  const selectedCount: number = rowsSelected.length;
  const defaultState = { assignee: undefined, openConfirmDialog: false, openAssigneeDialog: false };
  const [state, setState] = useState<OwnState>(defaultState);
  const { assignee, openAssigneeDialog, openConfirmDialog } = state;
  const { bar, countBox, countText, flex, warningMsg } = selectBarStyle();
  const { t } = useTranslation();

  const handleAssigneeChange = (_: object, value?: JuakaliUser) => setState({ ...state, assignee: value });

  const handleCancel = () => setState(defaultState);

  const handleOpenAssigneeDialog = () => setState({ ...state, openAssigneeDialog: false, openConfirmDialog: true });

  const onAssigneeConfirm = () => (handleReassign(assignee as JuakaliUser), setState(defaultState));

  const openDialog = () => setState({ ...state, openAssigneeDialog: true });

  return (
    <MuiThemeProvider theme={selectBarTheme}>
      <Grid alignItems="center" className={bar}>
        <Grid alignItems="center" className={flex}>
          <Box alignItems="center" borderRadius="50%" className={countBox} height={30} justifyContent="center" width={30}>
            {selectedCount < 10 ? `0${selectedCount}` : selectedCount}
          </Box>
          <Typography className={countText}>{t("dashboard.selected")}</Typography>
          <Tooltip title={t("dashboard.reassignTasks") as string}>
            <Box alignItems="center" className="hover-button reassign-button" height={40} justifyContent="center" onClick={openDialog} width={40}>
              <ForwardIcon data-testid="forwardIcon" />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
      {selectedCount >= MAX_REASSIGNABLE_ROWS && (
        <Typography className={warningMsg}>{t("dashboard.maxMsg", { count: MAX_REASSIGNABLE_ROWS })}</Typography>
      )}

      <MuiThemeProvider theme={assigneeDialogTheme}>
        <ConfirmationDialogRaw
          cancelLabel={t("common.cancel")}
          confirmLabel={t("dashboard.reassign")}
          content={
            <SearchableDropdown
              handleOnChange={handleAssigneeChange}
              ListboxProps={{ style: { maxHeight: 112 } }}
              popupIcon={<ExpandArrowIcon />}
              options={users || []}
              value={(getCurrentAssignee(assignee ? assignee.id : EMPTY, users || []) as JuakaliUser | undefined | null) || null}
            />
          }
          disabled={!assignee}
          displayDividers
          id="Select assignee"
          onCancel={handleCancel}
          onConfirm={handleOpenAssigneeDialog}
          open={openAssigneeDialog}
          title={t("dashboard.selectNewAssignee")}
        />
      </MuiThemeProvider>
      <MuiThemeProvider theme={confirmDialogTheme}>
        <ConfirmationDialogRaw
          cancelLabel={t("common.cancel")}
          confirmLabel={t("common.confirm")}
          content={t("dashboard.reassignConfirmMsg", { assignee: assignee ? assignee.firstName : EMPTY, selectedCount }) as string}
          displayDividers
          id="Confirm"
          onCancel={handleCancel}
          onConfirm={onAssigneeConfirm}
          open={openConfirmDialog}
          title={t("dashboard.reassign")}
        />
      </MuiThemeProvider>
    </MuiThemeProvider>
  );
}

export default CustomToolbarSelect;
