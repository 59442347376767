import { Task, TaskForm, StateType, FormField } from "../../redux/types";
import React, { useEffect } from "react";
import { fetchTaskForm } from "../../redux/actions";
import { connect } from "react-redux";
import { getTaskFormsForTask } from "../../redux/selectors/taskFormSelector";
import { useTranslation } from "react-i18next";
import { Grid, Row, Cell } from "@material/react-layout-grid";
import { TextField, Paper } from "@material-ui/core";

interface OwnProps {
  //
  task: Task;
}

interface StateProps {
  taskForm?: TaskForm;
}

interface DispatchProps {
  fetchTaskForm: (taskId: string) => {};
}

function ReportDetailTaskTable(props: OwnProps & StateProps & DispatchProps): any {
  const { t } = useTranslation();
  const taskFormBlock = props.taskForm
    ? props.taskForm.fields.map((field: FormField) => {
        const value = field.value as string | number | undefined;
        if (!["horizontal-line", "headline", "headline-with-line", "spacer", "hyperlink"].includes(field.type))
          return (
            <Cell desktopColumns={3} tabletColumns={4} phoneColumns={2} key={field.name}>
              <TextField
                id="outlined-read-only-input"
                label={field.name}
                defaultValue={value}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Cell>
          );
      })
    : undefined;

  useEffect(() => {
    if (props.task && !props.taskForm) {
      props.fetchTaskForm(props.task.id);
    }
  }, [props.task]);

  return (
    <div className="mx-1 my-1">
      <Grid>
        <Row className="border-bottom-line pb-1">
          <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
            {t("reports.taskName", { taskName: props.task.name })}
          </Cell>
        </Row>
        <Row className="pt-1">{taskFormBlock}</Row>
      </Grid>
    </div>
  );
}

function mapStateToProps(state: StateType, ownProps: OwnProps): StateProps {
  const taskForm: TaskForm | undefined = getTaskFormsForTask(state, ownProps.task.id);

  return {
    taskForm,
  };
}

const mapDispatchToProps: DispatchProps = {
  fetchTaskForm: (taskId: string) => fetchTaskForm(taskId),
};

export default connect<StateProps, DispatchProps, OwnProps, StateType>(
  mapStateToProps,
  mapDispatchToProps
)(ReportDetailTaskTable);
