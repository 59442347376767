// @flow
import fetch from "isomorphic-fetch";
import { url } from "./apiConfig";

export interface StartDeleteProcessInstanceRequest {
  processDefinitionId: string;
  name: string;
  values: {
    metaData: {
      JUAKALI_BUSINESS_OBJECT_ID: string;
      JUAKALI_DATASTORE_LINK_TYPE: string;
      OBJECT_DEFINITION_KEY: string;
      OBJECT_DEFINITION_VERSION: number;
    };
  };
}

export function startProcessInstance(startProcessInstanceRequest: { processDefinitionId: string; name: string }): Promise<Response> {
  return fetch(url.processInstance, {
    method: "POST",
    body: JSON.stringify(startProcessInstanceRequest),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function fetchForStartDeleteProcessInstance(startDeleteProcessInstanceRequest: StartDeleteProcessInstanceRequest): Promise<Response> {
  return fetch(url.processInstance, {
    method: "POST",
    body: JSON.stringify(startDeleteProcessInstanceRequest),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function fetchForDeleteProcessInstance(processInstanceId: string): Promise<Response> {
  return fetch(url.processInstanceExtended + processInstanceId, {
    method: "DELETE",
  });
}

export function fetchForProcessInstanceDetail(processInstanceId: string): Promise<Response> {
  return fetch(url.processInstanceDetail + processInstanceId, {
    method: "GET",
  });
}

export function fetchForVariables(processInstanceId: string): Promise<Response> {
  return fetch(url.variables + processInstanceId, {
    method: "GET",
  });
}

export function fetchForProcessInstances(type: "LOAN" | "CUSTOMER"): Promise<Response> {
  return fetch(url.queryProcessInstanceType, {
    method: "POST",
    body: JSON.stringify({
      sort: "created-desc",
      page: 0,
      state: "all",
      type: type,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
