import { Button, Grid, Switch, Typography, makeStyles } from "@material-ui/core";
import React, { Fragment } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { black, cobalt } from "../theme";
import i18n from "../../i18n";
import ReportFilterDialogBloc, {
  ReportFilterDialogState,
  defaultReportFilterDialogState,
  updateReportFilterDialogBlocState,
} from "../../rxjs/reportFilterDialogBloc";
import { useBloc } from "../../rxjs/blocBuilder";

interface OwnProps {
  applyFilter: (filterList: string[][]) => void;
  filterList: string[][];
  isExactSearch: boolean;
  t: i18n.TFunction;
}

interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

interface StyleProps {
  isExactSearch: boolean;
}

const useStyles = makeStyles({
  exactSearchText: ({ isExactSearch }: StyleProps) => ({
    color: isExactSearch ? cobalt : black,
    fontWeight: isExactSearch ? 500 : 400,
  }),
  partialSearchText: ({ isExactSearch }: StyleProps) => ({ fontWeight: !isExactSearch ? 500 : 400 }),
});

const CustomFilterDialogFooter = ({ applyFilter, filterList, isExactSearch: exactSearch, t }: OwnProps) => {
  const reportFilterDialogBloc = ReportFilterDialogBloc.getInstance();
  const state = useBloc<ReportFilterDialogState>({ isExactSearch: exactSearch }, reportFilterDialogBloc.getSubject());
  const { isExactSearch } = state && state.data ? state.data : defaultReportFilterDialogState;
  const { exactSearchText, partialSearchText } = useStyles({ isExactSearch });

  const handleApplyFilter = () => applyFilter(filterList);

  const handleClick = () => updateReportFilterDialogBlocState({ isExactSearch: !isExactSearch });

  return (
    <Fragment>
      <Grid alignItems="center" className="my-2" container>
        <Grid item>
          <Typography className={partialSearchText}>{t("reports.partialSearch")}</Typography>
        </Grid>
        <Grid item>
          <Switch checked={isExactSearch} data-testid="switch" onClick={handleClick} />
        </Grid>
        <Grid item>
          <Typography className={exactSearchText}>{`${t("reports.exactSearch")} (${t("common.default")})`}</Typography>
        </Grid>
      </Grid>
      <Button className="confirm-button" data-testid="applyFilter" onClick={handleApplyFilter} variant="contained">
        {t("common.applyFilters")}
      </Button>
    </Fragment>
  );
};

export default withTranslation()(CustomFilterDialogFooter);
