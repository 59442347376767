import { MuiThemeProvider, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { messageTheme, typographyStyle } from "./theme";
import ClearIcon from "@material-ui/icons/Clear";
import { STATUS_CODES } from "../../constants";

const { SERVER_TOO_BUSY, DEFAULT } = STATUS_CODES;

interface WarningMessageProps {
  message?: string;
  status: number;
  clearWarning: any;
  style?: any;
}

const defaultProps = (t: any) => {
  return { message: t("common.serverIsBusy"), status: DEFAULT, style: {} }
};

export default function WarningMessage (props: WarningMessageProps): JSX.Element | null {
  const { t } = useTranslation();
  const { message, status, clearWarning, style } = {...defaultProps(t), ...props};
  return <>
  {status == SERVER_TOO_BUSY ?           
    <MuiThemeProvider theme={messageTheme(status)}>
      <div data-testid="successMessage">
        <Typography variant={"body2"} style={style}>
          <span style={typographyStyle as any}>
            {message}
          </span>
          <ClearIcon
            onClick={clearWarning}
            data-testid="clearIcon"
          />
        </Typography>
      </div>
    </MuiThemeProvider> : null}
    </>
}
