// @flow
import { StateType, Paging, defaultPaging, defaultOpenTaskState, JuakaliUser } from "../types";
import {
  TaskQueryParams,
  TaskViewRepresentation,
  QueryParams,
  ApiResultType,
  TasksLoadingType,
  TaskQueryParamsType,
  TasksStatusType,
} from "../../types/taskQueryTypes";
import { PagedApiResult, PagedResult } from "../../types/commonTypes";

export const getTasks = (store: StateType, type: ApiResultType): TaskViewRepresentation[] => {
  return store.openTasks &&
    store.openTasks[type] &&
    (store.openTasks[type] as PagedApiResult<TaskViewRepresentation>).result &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    store.openTasks[type]!.result!.content
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      store.openTasks[type]!.result!.content
    : [];
};

export const getTasksPaging = (store: StateType, type: ApiResultType): Paging => {
  if (store.openTasks && store.openTasks[type] && (store.openTasks[type] as PagedApiResult<TaskViewRepresentation>).result) {
    var apiResult = (store.openTasks[type] as PagedApiResult<TaskViewRepresentation>).result as PagedResult<TaskViewRepresentation>;
    return {
      totalCount: apiResult.totalElements,
      totalAll: apiResult.totalAll,
      totalNew: apiResult.totalNew,
      totalToday: apiResult.totalToday,
      totalThisWeek: apiResult.totalThisWeek,
      totalOverdue: apiResult.totalOverdue,
      page: apiResult.page,
      size: apiResult.pageSize,
    };
  } else {
    return defaultPaging;
  }
};

export const isTasksLoading = (store: StateType, type: TasksLoadingType): boolean => (store.openTasks ? store.openTasks[type] : false);

export const getTaskQueryParams = (store: StateType, type: TaskQueryParamsType): TaskQueryParams | QueryParams =>
  store.openTasks ? store.openTasks[type] : defaultOpenTaskState[type];

export const getTasksStatus = (store: StateType, type: TasksStatusType): string =>
  store.openTasks ? store.openTasks[type] : defaultOpenTaskState[type];

export const getUsers = (store: StateType): JuakaliUser[] => {
  return store.openTasks ? store.openTasks.users : defaultOpenTaskState.users;
};
