import { fetchWrapper } from "../../../core/fetchWrapper";
import i18n from "../../../i18n";
import { fetchForNotificationsRead } from "../../../services/TaskService";
import { defaultNotificationTask, NotificationData, TaskNotification } from "../../../types/taskQueryTypes";
import { showNotification } from "../../../util/notification";
import { EMPTY, GRANTED, SHOW_NOTIFICATION_POPUP, STATUS_CODES, TRUE } from "../../../constants";
import { updateNotificationBlocState } from "../../../rxjs/notificationBloc";
import { tenantId } from "../../../core/tenantIdentifier";
import addNotification from "react-push-notification";

const { FORBIDDEN, NOT_AUTHENTICATED, SUCCESS } = STATUS_CODES;

export const checkAndUpdateTitle = (count: number | "9+") => {
  const favicon = document.getElementById("favTitleIcon");
  if (count) {
    document.title = `(${count}) Juakali LOS Task`;
    favicon && favicon.setAttribute("href", "/juakalidoticon.svg");
  } else {
    document.title = "Juakali LOS Task";
    favicon && favicon.setAttribute("href", "/favicon.ico");
  }
};

export const totalNotifications = (notification: TaskNotification | undefined) => {
  let count = 0;
  if (notification) {
    const { assignedTasks, candidateTasks } = notification;
    count = assignedTasks.count + candidateTasks.count;
    return count;
  }
  return count;
};

export const markNotificationsRead = async (
  setNotification: React.Dispatch<React.SetStateAction<TaskNotification | undefined>>,
  history: any,
  notification: TaskNotification,
  isAssignedTask?: boolean
) => {
  const notificationList = isAssignedTask ? notification.assignedTasks.notificationData : notification.candidateTasks.notificationData;
  const notificationIds = notificationList.map(({ id }) => id);
  try {
    const { status }: Response = await fetchWrapper(fetchForNotificationsRead, notificationIds);
    if (status === SUCCESS) {
      setNotification({
        assignedTasks: isAssignedTask ? defaultNotificationTask : notification.assignedTasks,
        candidateTasks: isAssignedTask ? notification.candidateTasks : defaultNotificationTask,
      });
      /* update notification status to rxjs store (isAssignedTaskSelected - true) */
      isAssignedTask && updateNotificationBlocState({ isAssignedTaskSelected: true });
      history.push("/");
    } else {
      if (status !== FORBIDDEN && status !== NOT_AUTHENTICATED) {
        throw new Error();
      }
    }
  } catch (error) {
    showNotification("error", i18n.t("common.serverErrorTryAgain"));
  }
};

const isNotificationSupported = (): boolean => {
  if (!window.Notification || !Notification || !Notification.requestPermission || !navigator) return false;
  if (Notification.permission === GRANTED) {
    const mobileRegExp = /android|iphone|kindle|ipad|mobile|linux|ipod|blackberry|iemobile|opera mini/i;
    return !mobileRegExp.test(navigator.userAgent);
  }
  try {
    new Notification(EMPTY);
  } catch (e) {
    return false;
  }
  return true;
};

export function notify({ assignedTasks, candidateTasks }: TaskNotification, t: i18n.TFunction) {
  try {
    if (!isNotificationSupported()) return;
    const { notificationData: assignedNotificationData } = assignedTasks;
    const { notificationData: candidateNotificationData } = candidateTasks;
    const uniqueTaskIds: string[] = [];

    assignedNotificationData.forEach(({ taskName }: NotificationData) =>
      showNativeNotificationPopup(t("notification.assignedTaskMsg", { taskName }))
    );

    candidateNotificationData.forEach(({ groupName, taskId, taskName }: NotificationData) => {
      if (!uniqueTaskIds.includes(taskId)) {
        showNativeNotificationPopup(t("notification.candidateTaskMsg", { groupName, taskName }));
        uniqueTaskIds.push(taskId);
      }
    });
  } catch (e) {
    return;
  }
}

function showNativeNotificationPopup(message: string) {
  try {
    addNotification({
      title: message,
      native: true,
      onClick: () => {
        window.open(window.location.href);
        localStorage.setItem(`${tenantId()}-${SHOW_NOTIFICATION_POPUP}`, TRUE);
      },
      duration: 86400000, //in milliseconds for 24 hours
      icon: `${process.env.PUBLIC_URL}/images/favicon.svg`,
    });
  } catch (error) {
    return;
  }
}
