import { createReducer, AnyAction } from "redux-starter-kit";
import { AppDefinition, AppDefinitions, defaultAppDefinitions } from "../types";
import { fetchForAppDefinitions } from "../../services/AppDefinitionService";
import Log from "../../core/Log";
import { setAppDefinitions, setAppDefinitionsLoading } from "../actions";
import { fetchWrapper } from "../../core/fetchWrapper";

const appDefinitionsReducer = createReducer<AppDefinitions, AnyAction>(defaultAppDefinitions, {
  [setAppDefinitionsLoading.type]: (state: AppDefinitions, action: AnyAction) => {
    state.isLoading = action.payload;
  },
  [setAppDefinitions.type]: (state: AppDefinitions, action: AnyAction) => {
    state.appDefinitions = action.payload;
  },
});

export default appDefinitionsReducer;

export function fetchAppDefinitions() {
  return (dispatch: any) => {
    dispatch(setAppDefinitionsLoading(true));
    return fetchWrapper(fetchForAppDefinitions)
      .then(
        (response: Response) => response.json(),
        (error: string) => {
          dispatch(setAppDefinitionsLoading(false));
          Log.error(error);
        }
      )
      .then((result: AppDefinition[]) => {
        dispatch(setAppDefinitions(result));
        dispatch(setAppDefinitionsLoading(false));
      })
      .catch(() => {
        dispatch(setAppDefinitionsLoading(false));
      });
  };
}
