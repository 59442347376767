import React, { useEffect, useState } from "react";
import LanguageIcon from "@material-ui/icons/Language";
import { FormControl, Select } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme, makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { Languages, JUAKALI_LANGUAGE } from "../idm/IdmEnum";
import { grey, solitude, aviaryBlue } from "../theme";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { checkAndGetSelectedLanguage } from "../Dashboard/utils/dashboardHelper";

const myThemes = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:before": { borderBottom: "none" },
        "&:after": { borderBottom: "none" },
        "&:hover:not(.Mui-disabled):before": { borderBottom: "none" },
      },
    },
    MuiSelect: {
      select: { paddingRight: "32px !important", "&:focus": { backgroundColor: "transparent !important" } },
    },
    MuiListItem: {
      root: {
        "&$selected": { backgroundColor: solitude },
        "&$selected:hover": { backgroundColor: aviaryBlue },
      },
      button: {
        "&:hover": { backgroundColor: aviaryBlue },
      },
    },
  },
});

const useStyles = makeStyles({
  languageIcon: {
    width: 18,
    height: 18,
    color: grey,
  },
  languageText: {
    fontSize: 14,
    letterSpacing: "0.02em",
    margin: "0px 8px",
    color: grey,
  },
  languageBar: {
    display: "flex",
    alignItems: "center",
  },
});

export default function LanguageSelector(): JSX.Element {
  const { t } = useTranslation(["translation", "flow"]);
  const classes = useStyles();
  const [language, setLanguage] = useState(checkAndGetSelectedLanguage());
  let timeOut: NodeJS.Timeout;
  const handleLanguageChange = (event: any): void => {
    const selectedLanguage: string = event.target.value;
    localStorage.setItem(JUAKALI_LANGUAGE, selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    timeOut = setTimeout(() => {
      setLanguage(selectedLanguage);
    }, 10);
  };
  useEffect(() => {
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <div className={classes.languageBar}>
      <LanguageIcon className={classes.languageIcon}></LanguageIcon>
      <div className={classes.languageText}>{t("dashboard.language")}:</div>
      <FormControl>
        <MuiThemeProvider theme={myThemes}>
          <Select id="language" name="language" value={language} onChange={handleLanguageChange}>
            {Object.values(Languages).map(({ key, label }) => {
              return (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </MuiThemeProvider>
      </FormControl>
    </div>
  );
}
