import { createAction, createReducer, AnyAction, PayloadActionCreator } from "redux-starter-kit";
import {
  StoreState,
  EntityDefinition,
  EntityLoadingStatus,
  EntitySavingStatus,
  GenericActionParams,
  GenericApiActionResponse,
  GenericActionPayload,
} from "./reduxTypes";
import { call, put, takeLatest } from "redux-saga/effects";
import { TypedAction, StateType } from "../redux/types";
import { DynamicJson } from "../datastore/dataStoreTypes";
import { fetchWrapper } from "../core/fetchWrapper";
import i18n from "../i18n";
import { throwError } from "../core/throwError";

export default class JuakaliReducer<EntityType extends EntityDefinition<EntityType>, EntityStateType extends StoreState<EntityType>> {
  // Name of the entity for which reducer types to be created. For e.g ObjectDefinition
  private entityName: string;

  //default objects
  private defaultEntityState: EntityStateType;
  private defaultEntityObject: EntityType;

  //actions
  public getEntities: PayloadActionCreator;
  public setEntities: PayloadActionCreator;
  public setRemoteEntities: PayloadActionCreator;
  public setEntityStateLoadingStatus: PayloadActionCreator;
  public setEntityLoadingStatus: PayloadActionCreator;
  public setEntityStateSavingStatus: PayloadActionCreator;
  public getEntity: PayloadActionCreator;
  public setEntity: PayloadActionCreator;
  public setEntityState: PayloadActionCreator;
  public setEntityStateError: PayloadActionCreator;
  public genericApiAction: PayloadActionCreator;
  public setGenericApiResponse: PayloadActionCreator;

  //dynamic reducer
  public entityReducer: any;

  //Apis to be called from generator functions
  public fetchRemoteEntities: any;
  public fetchRemoteEntity: any;
  public setRemoteEntitiesApi: any;
  public apiArray: any[];

  //generator functions
  public fetchRemoteEntitiesGenerator: any;
  public fetchRemoteEntityGenerator: any;
  public setRemoteEntitiesGenerator: any;
  public genericApiActionGenerator: any;

  // This should match the property name in store (StateType)
  public nameOfPropertyInStore = "";
  public uniquePropertyName = "key";

  //declare action watchers
  public getEntitiesActionWatcher: any;
  public getEntityActionWatcher: any;
  public setEntitiesActionWatcher: any;
  public genericApiActionWatcher: any;

  constructor(
    entityName: string,
    defaultEntityState: EntityStateType,
    defaultEntityObject: EntityType,
    fetchRemoteEntities: any,
    fetchRemoteEntity: any,
    nameOfPropertyInStore: string,
    uniquePropertyName: string,
    setRemoteEntitiesApi: any,
    apiArray: any[]
  ) {
    this.entityName = entityName;
    this.defaultEntityState = defaultEntityState;
    this.nameOfPropertyInStore = nameOfPropertyInStore;
    this.uniquePropertyName = uniquePropertyName;

    //Create Actions
    this.getEntities = createAction("getRemote" + this.entityName + "s");
    this.setRemoteEntities = createAction("setRemote" + this.entityName + "s");
    this.setEntities = createAction("set" + this.entityName + "s");
    this.setEntityStateLoadingStatus = createAction("set" + entityName + "StateLoadingStatus");
    this.setEntityLoadingStatus = createAction("set" + entityName + "LoadingStatus");
    this.getEntity = createAction("getRemote" + this.entityName);
    this.setEntity = createAction("set" + this.entityName);
    this.setEntityState = createAction("set" + this.entityName + "state");
    this.setEntityStateError = createAction("set" + this.entityName + "error");
    this.setEntityStateSavingStatus = createAction("set" + entityName + "StateSavingStatus");
    this.genericApiAction = createAction("generic" + entityName + "Action");
    this.setGenericApiResponse = createAction("generic" + entityName + "ActionResponse");

    //assign default
    this.defaultEntityObject = defaultEntityObject;

    //Assign incoming Apis to class fields
    this.fetchRemoteEntities = fetchRemoteEntities;
    this.fetchRemoteEntity = fetchRemoteEntity;
    this.setRemoteEntitiesApi = setRemoteEntitiesApi;
    this.apiArray = apiArray;

    //Create reducer
    this.entityReducer = createReducer<any, AnyAction>(this.defaultEntityState, {
      [this.getEntities.type]: (state: EntityStateType) => {
        state.remoteLoadingStatus = "loading";
        return state;
      },
      [this.setRemoteEntities.type]: (state: EntityStateType) => {
        state.remoteSavingStatus = "saving";
        return state;
      },
      [this.setEntities.type]: (state: EntityStateType, action: TypedAction<EntityType[]>) => {
        if (action.payload) {
          state.items = {};
          var entities = action.payload as EntityType[];
          entities
            ? entities.forEach(entity => {
                state.items[entity[this.uniquePropertyName]] = entity;
                entity.remoteLoadingStatus = "partiallyLoaded";
                entity.remoteSavingStatus = "unknown";
              })
            : null;
        }
        return state;
      },
      [this.setEntityState.type]: (state: EntityStateType, action: TypedAction<EntityStateType>) => {
        action.payload
          ? Object.keys(action.payload).forEach(entityStateProperty => {
              var propName = `${entityStateProperty}`;
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              state = { ...state, [propName]: action.payload![propName] };
            })
          : null;
        return state;
      },
      [this.setEntityStateLoadingStatus.type]: (state: EntityStateType, action: TypedAction<EntityLoadingStatus>) => {
        if (action.payload) {
          state.remoteLoadingStatus = action.payload;
        }
        return state;
      },
      [this.setEntityStateSavingStatus.type]: (state: EntityStateType, action: TypedAction<EntitySavingStatus>) => {
        if (action.payload) {
          state.remoteSavingStatus = action.payload;
        }
        return state;
      },
      [this.setEntityLoadingStatus.type]: (state: EntityStateType, action: TypedAction<EntityLoadingStatus>) => {
        if (action.payload) {
          state.currentObject ? (state.currentObject.remoteLoadingStatus = action.payload) : null;
        }
        return state;
      },
      [this.getEntity.type]: (state: EntityStateType, action: TypedAction<{ key: string }>) => {
        if (action.payload) {
          state.currentObject = defaultEntityObject;
          state.currentObject.remoteLoadingStatus = "loading";
          state.currentObject.remoteSavingStatus = "unknown";
        }
        return state;
      },
      [this.setEntity.type]: (state: EntityStateType, action: TypedAction<EntityType>) => {
        if (action.payload) {
          if (action.payload) {
            action.payload.remoteLoadingStatus = "fullyLoaded";
            state.items[action.payload[this.uniquePropertyName]] = action.payload;
          }
        }
        return state;
      },
      [this.setEntityStateError.type]: (state: EntityStateType, action: TypedAction<string>) => {
        if (action.payload) {
          state.error = action.payload;
        }
        return state;
      },
      [this.setGenericApiResponse.type]: (state: EntityStateType, action: TypedAction<GenericApiActionResponse>) => {
        var payload: GenericApiActionResponse | undefined = action.payload ? action.payload : undefined;
        if (!payload) return state;
        var propertyName = payload.genericActionParams.actionName;
        var responseBody = propertyName + "ResponseBody";
        var responseStatus = propertyName + "ResponseStatus";
        var status = payload.response ? payload.response.status : null;
        state = { ...state, [responseBody]: payload.responseBody };
        state = { ...state, [responseStatus]: status };

        return state;
      },
    });

    //Assign generator functions
    this.fetchRemoteEntitiesGenerator = function* fetchRemoteObjectDefinitions(action: TypedAction<DynamicJson>): any {
      try {
        var parameters: DynamicJson = {};
        action.payload ? (parameters = action.payload) : null;
        var params = Object.values(parameters);
        const response: Response = yield call(fetchWrapper, fetchRemoteEntities, ...params);
        if (response.status === 200) {
          if (defaultEntityState.isPagedResponse) {
            const pagedResponse: EntityStateType = yield response.json();
            if (pagedResponse.content) {
              const items: EntityType[] = pagedResponse.content;
              yield put({ type: [this.setEntities.type], payload: items });
              yield put({ type: [this.setEntityState.type], payload: pagedResponse });
              pagedResponse.remoteLoadingStatus = "fullyLoaded";
              pagedResponse.remoteSavingStatus = "unknown";
              yield put({ type: [this.setEntityStateLoadingStatus.type], payload: "fullyLoaded" });
            } else {
              throw new Error(i18n.t("common.noExpectedProperty"));
            }
          } else {
            const body: EntityType[] = yield response.json();
            yield put({ type: [this.setEntities.type], payload: body });
            yield put({ type: [this.setEntityStateLoadingStatus.type], payload: "fullyLoaded" });
          }
        } else {
          throwError(response);
        }
      } catch (error) {
        yield put({ type: [this.setEntityStateLoadingStatus.type], payload: "loadingFailed" });
        yield put({ type: [this.setEntityStateError.type], payload: error.message });
      }
    };

    //Bind the class this context to function
    this.fetchRemoteEntitiesGenerator = this.fetchRemoteEntitiesGenerator.bind(this);

    //Assign watcher function
    this.getEntitiesActionWatcher = function* watchFetchRemoteEntities(): any {
      yield takeLatest([this.getEntities.type], this.fetchRemoteEntitiesGenerator);
    };
    this.getEntitiesActionWatcher = this.getEntitiesActionWatcher.bind(this);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.fetchRemoteEntityGenerator = function* fetchRemoteEntity(action: TypedAction<DynamicJson>): any {
      try {
        if (!action.payload) {
          throw new Error(i18n.t("common.noPayload"));
        }
        var parameters: DynamicJson = {};
        action.payload ? (parameters = action.payload) : null;
        var params = Object.values(parameters);
        const response: Response = yield call(fetchWrapper, fetchRemoteEntity, ...params);
        if (response.status === 200) {
          const body: EntityType = yield response.json();
          body.remoteLoadingStatus = "fullyLoaded";
          body.remoteSavingStatus = "unknown";
          yield put({ type: [this.setEntity.type], payload: body });
        } else {
          throwError(response);
        }
      } catch (error) {
        yield put({ type: [this.setEntityLoadingStatus.type], payload: "loadingFailed" });
      }
    };

    this.fetchRemoteEntityGenerator = this.fetchRemoteEntityGenerator.bind(this);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.getEntityActionWatcher = function* watchFetchRemoteEntity(): any {
      yield takeLatest([this.getEntity.type], this.fetchRemoteEntityGenerator);
    };

    this.getEntityActionWatcher = this.getEntityActionWatcher.bind(this);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.setRemoteEntitiesGenerator = function* setRemoteEntities(action: TypedAction<DynamicJson>): any {
      try {
        if (!action.payload) {
          throw new Error(i18n.t("common.noPayload"));
        }
        var parameters: DynamicJson = {};
        action.payload ? (parameters = action.payload) : null;
        var params = Object.values(parameters);
        const response: Response = yield call(fetchWrapper, setRemoteEntitiesApi, ...params);
        if (response.status === 200) {
          const body: EntityType[] = yield response.json();
          yield put({ type: [this.setEntities.type], payload: body });
          yield put({ type: [this.setEntityStateSavingStatus.type], payload: "success" });
        } else {
          throwError(response);
        }
      } catch (error) {
        yield put({ type: [this.setEntityStateError.type], payload: error.message });
        yield put({ type: [this.setEntityStateSavingStatus.type], payload: "error" });
      }
    };

    this.setRemoteEntitiesGenerator = this.setRemoteEntitiesGenerator.bind(this);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.setEntitiesActionWatcher = function* watchSetRemoteEntities(): any {
      yield takeLatest([this.setRemoteEntities.type], this.setRemoteEntitiesGenerator);
    };

    this.setEntitiesActionWatcher = this.setEntitiesActionWatcher.bind(this);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.genericApiActionGenerator = function* perFormGenericApiActionRequest(action: TypedAction<GenericActionPayload>): any {
      try {
        if (!action.payload) {
          throw new Error(i18n.t("common.noPayload"));
        }
        var parameters: DynamicJson = {};
        action.payload ? (parameters = action.payload.params) : null;
        var params = Object.values(parameters);
        var genericActionParams: GenericActionParams = action.payload.genericActionParams;
        yield put({
          type: [this.setGenericApiResponse.type],
          payload: { genericActionParams, responseBody: null, response: null },
        });
        var apiToBeCalled = genericActionParams.fetchFunctionIndex;
        const response: Response = yield call(fetchWrapper, this.apiArray[apiToBeCalled], ...params);
        if (response.status === genericActionParams.successHttpCode) {
          const responseBody: any = yield response.json();
          yield put({
            type: [this.setGenericApiResponse.type],
            payload: { genericActionParams, responseBody, response },
          });
        } else {
          yield put({
            type: [this.setGenericApiResponse.type],
            payload: { genericActionParams, responseBody: null, response },
          });
        }
      } catch (error) {
        // yield put({ type: [this.setGenericApiErrorResponse.type], payload: error.message });
      }
    };

    this.genericApiActionGenerator = this.genericApiActionGenerator.bind(this);

    this.genericApiActionWatcher = function* watchGenericApiAction(): any {
      yield takeLatest([this.genericApiAction.type], this.genericApiActionGenerator);
    };

    this.genericApiActionWatcher = this.genericApiActionWatcher.bind(this);
  }

  //declare selectors to get the state corresponding to the entity from store

  public getEntityStateSelector = (store: StateType): EntityStateType => {
    return store[this.nameOfPropertyInStore] || this.defaultEntityState;
  };

  public getnEntitySelector = (store: StateType, uniqueProperty: string): EntityType => {
    const result = store[this.nameOfPropertyInStore].items[uniqueProperty] || this.defaultEntityObject;
    return result;
  };
}
