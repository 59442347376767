import React from "react";
import { PDF_ICON_COLOR, cobalt, white } from "../theme";

const PdfUploadIcon = (props: { color?: string }): JSX.Element => {
  const htmlColor = props.color || PDF_ICON_COLOR;
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_134_2194)">
        <path
          d="M19.4125 5.01483L15.9167 1.57362C15.5417 1.20448 15.0333 0.9953 14.5042 0.9953H6C4.89583 0.999402 4 1.88124 4 2.96815V20.0307C4 21.1176 4.89583 21.9994 6 21.9994H18C19.1042 21.9994 20 21.1176 20 20.0307V6.40936C20 5.88847 19.7875 5.38397 19.4125 5.01483ZM17.8375 6.2494H14.6667V3.12811L17.8375 6.2494ZM6 20.0307V2.96815H12.6667V7.23378C12.6667 7.77929 13.1125 8.21815 13.6667 8.21815H18V20.0307H6ZM16.425 14.1367C15.9167 13.6445 14.4667 13.7799 13.7417 13.8701C13.025 13.4394 12.5458 12.8447 12.2083 11.9711C12.3708 11.3107 12.6292 10.3058 12.4333 9.67421C12.2583 8.5996 10.8583 8.70624 10.6583 9.43222C10.475 10.0926 10.6417 11.0113 10.95 12.1844C10.5333 13.1646 9.9125 14.4812 9.475 15.2359C8.64167 15.6584 7.51667 16.3105 7.35 17.1308C7.2125 17.7789 8.43333 19.3949 10.5208 15.8512C11.4542 15.5476 12.4708 15.1744 13.3708 15.0267C14.1583 15.4451 15.0792 15.724 15.6958 15.724C16.7583 15.724 16.8625 14.5674 16.425 14.1367ZM8.17083 17.3277C8.38333 16.7658 9.19167 16.1178 9.4375 15.8922C8.64583 17.135 8.17083 17.3564 8.17083 17.3277ZM11.5708 9.51015C11.8792 9.51015 11.85 10.8267 11.6458 11.1836C11.4625 10.6135 11.4667 9.51015 11.5708 9.51015V9.51015ZM10.5542 15.1129C10.9583 14.4197 11.3042 13.5953 11.5833 12.8693C11.9292 13.4887 12.3708 13.985 12.8375 14.3254C11.9708 14.5017 11.2167 14.8627 10.5542 15.1129V15.1129ZM16.0375 14.9078C16.0375 14.9078 15.8292 15.1539 14.4833 14.5879C15.9458 14.4812 16.1875 14.8094 16.0375 14.9078Z"
          fill={htmlColor}
        />
      </g>
      <rect x="14" y="14" width="12" height="12" rx="6" fill={white} />
      <path
        d="M20 15C17.245 15 15 17.245 15 20C15 22.755 17.245 25 20 25C22.755 25 25 22.755 25 20C25 17.245 22.755 15 20 15ZM19.5 19V17.5C19.5 17.225 19.725 17 20 17C20.275 17 20.5 17.225 20.5 17.5V19H21.395C21.62 19 21.73 19.27 21.57 19.425L20.175 20.82C20.075 20.92 19.92 20.92 19.82 20.82L18.425 19.425C18.27 19.27 18.38 19 18.605 19H19.5ZM22 22.5H18C17.725 22.5 17.5 22.275 17.5 22C17.5 21.725 17.725 21.5 18 21.5H22C22.275 21.5 22.5 21.725 22.5 22C22.5 22.275 22.275 22.5 22 22.5Z"
        fill={cobalt}
      />
      <defs>
        <clipPath id="clip0_134_2194">
          <rect width="16" height="21" fill={white} transform="translate(4 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PdfUploadIcon;
