import React, { Fragment, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import i18n from "../../i18n";
import _ from "lodash";
import CustomLoader from "../common/CustomLoader";
import WarningMessage from "../common/WarningMessage";
import DeleteProcessList from "./DeleteProcessList";
import PopperMenuButton from "./PopperMenuButton";
import { Document } from "../../redux/types";
import { SortParams } from "../../types/commonTypes";
import { EMPTY, EXCEL, REPORTS } from "../../constants";
import { useStyles } from "./objectDataStyles";
import { defaultObjectData, ObjectData } from "../../datastore/dataStoreTypes";
import { generateDocument } from "./objectDataReportHelper";

interface OwnProps {
  content: ObjectData[];
  documents: Document[];
  filterParams: { filters: any; pageNumber: number; pagingSize: number; sortingOrder: SortParams };
  history: any;
  id: string;
  t: i18n.TFunction;
}

const ObjectDataToolkit = (props: OwnProps): JSX.Element => {
  const { content, documents, filterParams, history, id, t } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [statusOfDocumentBeingGenerated, setStatusForDocumentGeneration] = useState<number>(200);
  const { dataVersion, id: businessObjectId, key, version } = content.find((object: ObjectData) => object.id === id) || defaultObjectData;

  const handleClearWarning = () => setStatusForDocumentGeneration(200);
  const handleMenuItemClick = (doc: Document) => {
    setStatusForDocumentGeneration(0);
    generateDocument({
      processInstanceId: id,
      contentId: doc.id,
      setStatusForDocumentGeneration: setStatusForDocumentGeneration,
      type: REPORTS,
      label: EMPTY,
      documentType: EXCEL,
    });
  };
  const { displayFlex, grid, versionBox, versionContainer, versionText } = useStyles();

  const renderVersionInfo = (
    <div className={versionContainer}>
      <Box className={versionBox}>
        <Typography className={versionText}>{`V${dataVersion}`}</Typography>
      </Box>
    </div>
  );

  return (
    <Fragment>
      <WarningMessage clearWarning={handleClearWarning} status={statusOfDocumentBeingGenerated} />
      <Grid className={grid}>
        <div className={displayFlex}>
          {renderVersionInfo}
          <DeleteProcessList
            dataDefinitionkey={key}
            version={version}
            businessObjectId={businessObjectId}
            handleCreateProcess={processInstanceId => {
              history.push(`/process/instance/${processInstanceId}`, filterParams);
            }}
          />
          {!statusOfDocumentBeingGenerated ? (
            <CustomLoader status={statusOfDocumentBeingGenerated} />
          ) : (
            <PopperMenuButton<Document>
              open={open}
              setOpen={setOpen}
              title={t("reports.generate")}
              menuItems={documents}
              handleMenuItemClick={handleMenuItemClick}
              getMenuLabel={(doc: Document) => doc.name}
            />
          )}
        </div>
      </Grid>
    </Fragment>
  );
};

export default ObjectDataToolkit;
