import { createMuiTheme } from "@material-ui/core/styles";
import { textFieldColorTheme } from "./commonStyles";
import { black, cobalt, darkLiver, errorBackground, grey, hoverShadowBlack, lightRed, limeGreen, orange, rgbaLightGrey, solitude, strongRed, successBackground, warningBackground } from "../../theme";
const theme = createMuiTheme();
/**
 * Text Search Filter Theme
 */
export const searchFilterTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    ...textFieldColorTheme,
    MuiInputLabel: {
      root: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "75%",
      },
      outlined: {
        transform: "translate(16px, 14px) scale(1)",
      },
      shrink: {
        overflow: "unset",
        maxWidth: "none !important",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiOutlinedInput: {
      input: { padding: "12px 16px" },
    },
  },
});

/**
 * Date Filter Theme
 */
export const dateFilterTheme = (createMuiTheme as any)({
  overrides: {
    MuiIconButton: {
      root: {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    MuiButton: {
      outlined: {
        padding: "8px 16px",
      },
      text: {
        padding: "8px 16px",
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        paddingLeft: 0,
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        paddingLeft: 0,
      },
    },
    MuiTypography: {
      body1: { fontSize: "20px", fontWeight: 500, lineHeight: "23px" },
      h6: { color: black },
    },
    MuiDialogActions: {
      root: {
        flexDirection: "row-reverse",
        alignSelf: "flex-start",
        justifyContent: "unset",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiFormHelperText: {
      contained: {
        margin: "4px 14px 0 0 !important",
        lineHeight: "140%",
      },
    },
    MuiPopover: {
      paper: {
        [theme.breakpoints.only("xs")]: {
          width: "280px",
        },
        [theme.breakpoints.up("sm")]: {
          width: "560px",
        },
        marginTop: "-5px",
      },
    },
    MuiSvgIcon: {
      root: {
        fill: "rgba(0,0,0,0.6) !important",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: `solid 2px ${grey}`,
        borderColor: grey,
      },
    },
  },
});

export const toolbarTheme = createMuiTheme({
  overrides: {
    MuiListItem: {
      gutters: {
        padding: "0 16px 8px 0 !important",
      },
      root: {
        alignItems: "flex-end",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(242, 242, 242, 1)",
      },
      deleteIcon: {
        color: "rgba(189, 189, 189, 1)",
        paddingLeft: "8px",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 0,
        margin: "0 0 -8px -8px",
      },
    },
    MuiTypography: {
      body1: {
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "23px",
      },
    },
    MuiListItemText: {
      root: {
        padding: 0,
        minWidth: 0,
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
        color: "#134A9F",
      },
    },
  },
});

export const assigneeFilterTheme = (createMuiTheme as any)({
  overrides: {
    ...textFieldColorTheme,
    MuiAutocomplete: {
      root: {
        width: "100% !important",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: `solid 2px ${grey}`,
        borderColor: grey,
      },
    },
  },
});

export const customFilterStyles = (isMobileView: boolean) => {
  return {
    actions: { display: "flex", justifyContent: "end" },
    assigneeBody: { padding: `${isMobileView ? 24 : 0}px 0px 0px ${isMobileView ? 0 : 24}px` },
    filterText: { paddingTop: "10px", display: "flex" },
    resetButton: {
      color: cobalt,
      fontSize: "14px",
      fontWeight: 550,
      height: "35px",
      letterSpacing: "0.0200012em",
      lineHeight: "19px",
      padding: "8px 16px",
    },
    contentBody: {
      border: `1px solid ${rgbaLightGrey}`,
      borderRadius: 5,
      display: "flex",
      margin: "16px 0px 24px",
      padding: `24px 20px ${isMobileView ? 50 : 80}px`,
    },
  };
};

export const defaultToolbarStyles = {
  filterButtons: {
    margin: '8px 0 16px',
  },

  quickFilterRoot: {
    '& button': {
      fontSize: '13.125px',
      fontWeight: 600,
      padding: '8px 10px',
      lineHeight: '19px',
      color: darkLiver,
      borderRadius: '5px',

      '&.active, &:hover': {
        boxShadow: `0px 3px 6px ${hoverShadowBlack}`,
      }
    }
  },
  ALL: {
    '&.active, &:hover': {
      border: `1px solid ${darkLiver}`,
      background: lightRed,
    }
  },
  NEW: {
    '&.active, &:hover': {
      color: cobalt,
      border: `1px solid ${cobalt}`,
      background: solitude,
    }
  },
  TODAY: {
    '&.active, &:hover': {
      border: `1px solid ${limeGreen}`,
      background: successBackground,
      color: limeGreen,
    }
  },
  WEEK: {
    '&.active, &:hover': {
      border: `1px solid ${orange}`,
      background: warningBackground,
      color: orange,
    }
  },
  OVERDUE: {
    '&.active, &:hover': {
      border: `1px solid ${strongRed}`,
      background: errorBackground,
      color: strongRed,
    }
  }
}
