import React from "react";
import { SvgIcon, SvgIconTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import { cobalt } from "../theme";

function ExpandArrowIcon(props: DefaultComponentProps<SvgIconTypeMap>): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M11.4532 4.61816L4.54658 4.61816C4.01991 4.61816 3.69991 5.19816 3.98658 5.64483L7.43991 11.0715C7.69991 11.4848 8.29991 11.4848 8.56658 11.0715L12.0132 5.64483C12.2999 5.19816 11.9799 4.61816 11.4532 4.61816Z"
        fill={cobalt}
      />
    </SvgIcon>
  );
}

export default ExpandArrowIcon;
