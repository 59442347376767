import { showNotification } from "../util/notification";
import i18n from "../i18n";
import history from "./history";
import { updateLogInBlocState } from "../rxjs/loginBloc";
import { url } from "../services/apiConfig";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchWrapper(...args: any[]): any {
  const { changePassword, login } = url;
  const { pathname } = history.location;
  const currentPath = pathname && (pathname !== login && pathname !== changePassword) ? pathname : "";
  var func: any = args[0];
  var argsArray = args.slice(1);
  var fetchPromise: Promise<Response>;
  if (argsArray.length === 0) {
    fetchPromise = func();
    return fetchPromise.then((res: Response) => {
      if (res.status === 401) {
        /* update session status to rxjs store (session out - true) */
        updateLogInBlocState({ sessionOut: true, serverError: false, currentPath });
        history.push(login);
      }
      if (res.status === 403) {
        showNotification("error", i18n.t("common.insufficientPriviliges"));
        return res;
      } else {
        return res;
      }
    });
  } else {
    fetchPromise = func(...argsArray);
    return fetchPromise.then((res: Response) => {
      if (res.status === 401) {
        /* update session status to rxjs store (session out - true) */
        updateLogInBlocState({
          sessionOut: true,
          serverError: false,
          currentPath,
        });
        history.push(login);
      }
      if (res.status === 403) {
        showNotification("error", i18n.t("common.insufficientPriviliges"));
        return res;
      } else {
        return res;
      }
    });
  }
  // return fetchPromise;
}
