// src/components/ImageEditor.tsx
import React, { useState, useCallback, useRef } from "react";
import { Button, Tooltip, makeStyles, withStyles } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { url } from "../../../../../services/apiConfig";
import { pictureFieldStyles } from "../../styles";
import { getCroppedImg } from "./editor";
import { TRANSPARENT_BUTTON_BACKGROUND, solitude } from "../../../../theme";
import AntiClockWise from "../../../../common/AntiClockWise";
import ClockWise from "../../../../common/ClockWise";
import { NONE } from "../ErrorMessageComponent/constants";
import { useDebounceEffect } from "./useDebounce";
import Cropper from "react-easy-crop";

const useStyle = makeStyles(() => ({
  transparentButton: {
    "&:hover": {
      backgroundColor: TRANSPARENT_BUTTON_BACKGROUND,
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  },
  cropButton: {
    marginRight: "8px",
    "&:hover": {
      backgroundColor: solitude,
      borderRadius: "4px",
    },
  },
}));

const PrettoSlider = withStyles({
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
const EditorControls: React.FC = (props: any) => {
  const { getCurrentShot, effect, getValue, handleDisable, handleRef } = props;

  const value = getValue();
  const ButtonHoverStyle = useStyle();
  const currentShot = getCurrentShot();
  let { classes, t } = props.props;
  const imgUrl = currentShot ? currentShot : `${url.file}/${value}`;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [aspect, setAspect] = useState(4 / 3);

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [crop]
  );

  const { zoomVisible, rotateVisible, zoomSlider, aspectButtonStyle, aspectButtonLabel, cropButtonRoot, activeButtonStyle } = pictureFieldStyles(
    effect.isDisable,
    effect.isRotateVisible,
    effect.isZoomVisible
  );

  useDebounceEffect(
    async () => {
      if (imgUrl && croppedAreaPixels) {
        getCroppedImg(imgUrl, croppedAreaPixels, rotation).then(canvasRef => {
          handleRef(canvasRef);
        });
      }
    },
    300,
    [croppedAreaPixels, rotation]
  );

  return (
    <div>
      {imgUrl && (
        <React.Fragment>
          <div className={classes.cropContainer} data-testid="Crop Image">
            <Cropper
              image={imgUrl}
              rotation={rotation}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onRotationChange={setRotation}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              maxZoom={2}
              minZoom={0.1}
            />
          </div>
          <div className="controls">
            {effect.isZoomVisible && (
              <>
                <div style={zoomVisible} data-testid="ZoomLabel">
                  <label htmlFor="scale-input">{t("common.zoom")} </label>
                  {Math.round(zoom * 100)}%
                </div>
                <div style={zoomSlider} data-testid="ZoomSlider">
                  <PrettoSlider
                    id="scale-input"
                    defaultValue={zoom}
                    step={0.1}
                    min={0.1}
                    max={2.0}
                    value={zoom}
                    disabled={!imgUrl}
                    onChange={(_, newValue) => setZoom(newValue as number)}
                  />
                </div>
              </>
            )}
            {effect.isRotateVisible && (
              <div style={rotateVisible} data-testid="RotateControls">
                <Tooltip placement="bottom" title={t("common.rotateRight")}>
                  <Button
                    data-testid="RotateRight"
                    classes={{ root: ButtonHoverStyle.transparentButton }}
                    onClick={() => setRotation(prevState => prevState - 90)}
                  >
                    <AntiClockWise />
                  </Button>
                </Tooltip>
                <Tooltip placement="bottom" title={t("common.rotateLeft")}>
                  <Button classes={{ root: ButtonHoverStyle.transparentButton }} onClick={() => setRotation(prevState => prevState + 90)}>
                    <ClockWise />
                  </Button>
                </Tooltip>
              </div>
            )}

            {effect.isCroppingEnabled && (
              <div style={rotateVisible} data-testid="AspectControls">
                <span style={aspectButtonLabel}>{t("common.aspectTitle")} </span>
                <Button
                  data-testid="Aspect Ratio 3/2"
                  classes={{ root: ButtonHoverStyle.cropButton }}
                  onClick={() => setAspect(3 / 2)}
                  style={{
                    border: aspect === 3 / 2 ? activeButtonStyle : NONE,
                    backgroundColor: aspect === 3 / 2 ? solitude : TRANSPARENT_BUTTON_BACKGROUND,
                    ...cropButtonRoot,
                  }}
                >
                  <span style={aspectButtonStyle}>3:2</span>
                </Button>
                <Button
                  data-testid="Aspect Ratio 3/4"
                  classes={{ root: ButtonHoverStyle.cropButton }}
                  onClick={() => setAspect(3 / 4)}
                  style={{
                    border: aspect === 3 / 4 ? activeButtonStyle : NONE,
                    backgroundColor: aspect === 3 / 4 ? solitude : TRANSPARENT_BUTTON_BACKGROUND,
                    ...cropButtonRoot,
                  }}
                >
                  <span style={aspectButtonStyle}>3:4</span>
                </Button>
                <Button
                  data-testid="Aspect Ratio 4/3"
                  classes={{ root: ButtonHoverStyle.cropButton }}
                  onClick={() => setAspect(4 / 3)}
                  style={{
                    border: aspect === 4 / 3 ? activeButtonStyle : NONE,
                    backgroundColor: aspect === 4 / 3 ? solitude : TRANSPARENT_BUTTON_BACKGROUND,
                    ...cropButtonRoot,
                  }}
                >
                  <span style={aspectButtonStyle}>4:3</span>
                </Button>
                <Button
                  data-testid="Aspect Ratio 16/9"
                  classes={{ root: ButtonHoverStyle.cropButton }}
                  onClick={() => setAspect(16 / 9)}
                  style={{
                    border: aspect === 16 / 9 ? activeButtonStyle : NONE,
                    backgroundColor: aspect === 16 / 9 ? solitude : TRANSPARENT_BUTTON_BACKGROUND,
                    ...cropButtonRoot,
                  }}
                >
                  <span style={aspectButtonStyle}>16:9</span>
                </Button>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default EditorControls;
