// @flow strict
import { AnyAction, createReducer, Action } from 'redux-starter-kit';
import { TypedAction, Task } from '../types';
import { addAlert, setAlertShown as setAlertExpired, removeAlerts } from '../actions';
import {v4} from 'uuid';

export interface Alert {
  sender: string;
  message: string;
  expired: boolean;
  variant: AlertVariants;
  ref: string;
}

export type AlertVariants = "success" | "error" | "default";

const alertReducer = createReducer<Alert[], AnyAction>([], {
  [addAlert.type]: (state: Alert[], action: TypedAction<Alert>) => {
    if (action.payload){
      state.push(action.payload)
    }

    return state
  },
  [setAlertExpired.type]: (state: Alert[], action: TypedAction<string>) => {
    state.forEach((alert: Alert) => {
      if (alert.ref === action.payload){
        alert.expired = true;
      }
    })

    return state;
  },

  [removeAlerts.type]: (state: Alert[], action: Action) => {
    state = state.filter((alert: Alert) => !alert.expired)
    return state
  },
})

export function createAlert(sender: string, message: string, variant: AlertVariants): Alert {
  return {
    sender: sender,
    message: message,
    expired: false,
    ref: v4(),
    variant: variant
  }
}

export default alertReducer;

