import { StateType, TaskForm, Task } from "../types";
import { getAll } from "../../collections/Dictionary";

export const getTaskFormsForTask = (store: StateType, taskId: string): TaskForm | undefined => {
  if (store.taskForms) {
    const tasks = getAll(store.taskForms).filter((taskForm: TaskForm) => taskForm.taskId === taskId);
    if (tasks && tasks.length > 0) {
      return tasks[0];
    }
  }

  return undefined;
};

export const getTaskForms = (store: StateType, tasks: Task[]): TaskForm[]  => {
  if (store.taskForms) {
    return getAll(store.taskForms).filter((taskForm: TaskForm) => tasks.find((task: Task) => task.id == taskForm.taskId));
  }
  return [];
};
