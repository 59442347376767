import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { Headline4 } from "@material/react-typography";
import i18n from "../../i18n";
import TimeExpire from "../common/TimeExpire";
import { passwordStyles } from "./styles";

interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

const LinkExpireView = (props: OwnProps): JSX.Element => {
  const { t } = props;
  const { linkMessage } = passwordStyles();
  return (
    <div className="mdc-top-app-bar--fixed-adjust">
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <TimeExpire />
        </Grid>
        <Grid item>
          <Headline4>{t("resetPassword.linkExpireHeading")}</Headline4>
        </Grid>
        <Grid item>
          <Typography className={linkMessage}>{t("resetPassword.linkExpireInfo")}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation()(LinkExpireView);
