import { BehaviorSubject } from "rxjs";

export const FORM_STATE = {
  errorMessage: "",
};

export interface FormState {
  errorMessage: string;
}

export const defaultFormState = {
  errorMessage: "",
};

export default class FormBloc {
  private static instance: FormBloc;
  private subject: BehaviorSubject<FormState>;

  private constructor() {
    this.subject = new BehaviorSubject(defaultFormState);
  }

  static getInstance(): FormBloc {
    if (!FormBloc.instance) {
      FormBloc.instance = new FormBloc();
    }
    return FormBloc.instance;
  }

  getSubject = (): BehaviorSubject<FormState> => {
    return this.subject;
  };
  updateState = (value: any): void => {
    this.subject.next({ ...this.subject.value, ...value });
  };
}

export function updateFormState(value: any): void {
  const formBloc = FormBloc.getInstance();
  formBloc.updateState(value);
}

export function getSubject(): any {
  const formBloc = FormBloc.getInstance();
  return formBloc.getSubject().getValue();
}
