import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { aviaryBlue, cobalt, paleBlue, strongRed } from "../theme";

export const masterStyles = makeStyles(() =>
  createStyles({
    drawerPaperBody: { display: "flex", flexDirection: "column", height: "100%" },
    drawerContent: {
      flex: "1 0 auto",
    },
    drawerLanguageBar: { flexShrink: 0 },
    logo: {
      position: "absolute",
      left: "0%",
      right: "0%",
      top: "0%",
      bottom: "0%",
      width: "176.92px",
      height: "40px",
      paddingTop: "5px",
    },
    drawerHeader: { paddingRight: 0 },
    languageBar: {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingBottom: "8px",
      paddingTop: "4px",
    },
    logoutPaper: { height: 200, width: 250, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" },
    logoutPaperEmail: { display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" },
    logoutPaperBtn: { display: "flex", justifyContent: "center" },
    logoutHr: { borderColor: "#d2e0f7" },
    userIcon: { fontSize: 28, color: "#9b51e0" },
    userIconLarge: { fontSize: 42, color: "#9b51e0" },
    logoutMain: { width: "100%" },
    userInfoBlock: {
      padding: useTheme().spacing(1),
      paddingLeft: useTheme().spacing(2),
      paddingRight: useTheme().spacing(2),
      backgroundColor: "#f2f2f2",
      borderRadius: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
    bold: {
      fontWeight: "bold",
    },
    textTransform: {
      textTransform: "none",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "black",
      marginRight: useTheme().spacing(1),
    },
  })
);

export const logoutStyles = makeStyles(() =>
  createStyles({
    logout: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.02em",
      textAlign: "left",
      textTransform: "capitalize",
      padding: "6px",
      color: "#134A9F",
      border: "1px solid #134A9F",
    },
    exitIcon: {
      marginLeft: 10,
    },
    logoutPaper: {
      height: 200,
      width: "auto",
      minWidth: 250,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    logoutPaperEmail: { display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginBottom: "16px" },
    logoutPaperBtn: { display: "flex", justifyContent: "center", marginTop: "16px" },
    logoutHr: { borderColor: "#d2e0f7" },
    userIcon: { fontSize: 28, color: "#9b51e0" },
    userIconLarge: { fontSize: 42, color: "#9b51e0", marginBottom: "10px" },
    logoutMain: { width: "100%" },
    userInfoBlock: {
      padding: useTheme().spacing(1),
      paddingLeft: useTheme().spacing(2),
      paddingRight: useTheme().spacing(2),
      backgroundColor: "#f2f2f2",
      borderRadius: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
    bold: {
      fontWeight: "bold",
    },
    textTransform: {
      textTransform: "none",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "black",
      marginRight: useTheme().spacing(2),
      marginLeft: useTheme().spacing(2),
    },
    userInfoText: {
      textTransform: "none",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "black",
      marginRight: useTheme().spacing(1),
    },
    popup: { marginTop: "50px" },
  })
);

export const notificationStyles = (count: number): any => {
  return {
    paper: { width: 400, height: "70vh", padding: "16px" },
    titleBody: { display: "flex", justifyContent: "space-between", borderBottom: `1px solid ${aviaryBlue}` },
    titleContent: { display: "flex", flexDirection: "column", alignItems: "flex-end", color: cobalt },
    titleText: { fontSize: "1.25rem" },
    countText: { color: "inherit", fontSize: "2.2rem" },
    unreadText: { color: "inherit", fontSize: "0.9rem" },
    notificationIcon: { marginRight: "12px" },
    noNotificationBody: { display: "flex", alignItems: "center", marginTop: "89px", flexDirection: "column" },
    noNotificationText: { fontSize: "0.9rem", marginTop: "18px", color: "black" },
    listBody: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      border: `1px solid ${aviaryBlue}`,
      borderRadius: "4px",
      height: "52px",
      marginTop: "16px",
      cursor: "pointer",
      padding: "0px",
    },
    listText: { fontSize: "0.9rem", color: "black", marginLeft: "16px" },
    button: { backgroundColor: paleBlue, color: cobalt, textTransform: "none", height: "20px", fontSize: "12px", marginRight: "8px" },
    circle: { width: 10, marginRight: "16px" },
    countBody: {
      position: "relative",
    },
    notificationCount: {
      backgroundColor: strongRed,
      position: "absolute",
      top: "4%",
      left: "60%",
      borderRadius: "25px",
      padding: count > 9 ? "1px" : "1px 5px",
      color: "white",
      fontSize: "12px",
    },
    listButtonBody: { display: "flex" },
    popup: { marginTop: "50px" },
  };
};
