import { CircularProgress, MuiThemeProvider, Typography } from "@material-ui/core";
import MUIDataTable, { MUIDataTableColumn, TableFilterList } from "mui-datatables";
import React, { useState, Fragment } from "react";
import { CONTAINS, EMPTY, EQUALS, TableKeys } from "../../constants";
import { DataTableProps } from "../../datastore/dataStoreTypes";
import { SelectableRows } from "../../redux/types";
import { setRowsPerPageSize } from "../../util/helper";
import { muiTableTheme } from "../common/theme";
import CustomToolBar from "./CustomToolBar";
import { getColumnsArray, getFilterParams } from "./objectDataReportHelper";
import { useStyles } from "./objectDataStyles";
import { loaderWrapper, loaderStyle } from "../Dashboard/styles/tableStyles";
import { showNotification } from "../../util/notification";
import { CUT, GRAPES_JS_FORM } from "../TeamTask/constants";
import { ReportTableState } from "./reportTypes";
import CustomFilterDialogFooter from "./CustomFilterDialogFooter";
import ReportFilterDialogBloc, { defaultReportFilterDialogState, updateReportFilterDialogBlocState } from "../../rxjs/reportFilterDialogBloc";

function ObjectDataTable(props: DataTableProps) {
  const {
    filterBody,
    formType,
    handleFilterData,
    isMobileView,
    listState,
    objectDefinition,
    remoteObjectData,
    rerenderTable,
    setListState,
    t,
    handleSelectedRow,
  } = props;
  const { clonedFormData: formData, filterList, isExactSearch, objectDataArray, sortOrder } = listState;
  const [state, setState] = useState<ReportTableState>({ isExcelDownloading: false, showReportDownloadError: false });
  const { isExcelDownloading, showReportDownloadError } = state;
  const { errorMessage, messageContainer, warningMessage } = useStyles();
  const { key, label, version } = objectDefinition;
  const { count, page, pageSize } = remoteObjectData;

  const sortParams = { param: sortOrder.name, order: sortOrder.direction.toUpperCase() };
  const searchType = isExactSearch ? EQUALS : CONTAINS;

  const applyFilter = (filterList: string[][]): void => {
    const { isExactSearch } = ReportFilterDialogBloc.getInstance().getSubject().value;
    const filterParams = getFilterParams(columnsArray, filterList, isExactSearch ? EQUALS : CONTAINS);
    setListState(state => ({ ...state, filterList, isExactSearch, filterParams }));
    handleFilterData({
      key: key,
      filterParams: filterParams,
      pageSize,
      sortParams
    });
  };

  let columnsArray = getColumnsArray(objectDefinition, isMobileView, filterList, sortOrder, applyFilter);
  return (
    <div style={loaderWrapper}>
      {(rerenderTable || isExcelDownloading) && (
        <div style={loaderStyle}>
          <CircularProgress size={24} />
        </div>
      )}
      <MuiThemeProvider theme={muiTableTheme}>
        <MUIDataTable
          key={rerenderTable ? "loading" : "loaded"}
          title={label}
          columns={columnsArray}
          data={objectDataArray || []}
          options={{
            download: false,
            search: false,
            filter: true,
            sort: true,
            filterType: "custom",
            onFilterChange: (_: string | MUIDataTableColumn | null, filterList, type) => {
              if (type === "chip") {
                setListState(state => ({ ...state, filterList }));
                handleFilterData({
                  ...filterBody,
                  page: 0,
                  pageSize,
                  sortParams,
                  filterParams: getFilterParams(columnsArray, filterList, searchType),
                });
                return;
              }
              if (type === "reset") {
                updateReportFilterDialogBlocState(defaultReportFilterDialogState);
              }
            },
            customFilterDialogFooter: filterList => (
              <CustomFilterDialogFooter applyFilter={applyFilter} filterList={filterList} isExactSearch={isExactSearch} />
            ),
            viewColumns: false,
            responsive: "simple",
            print: false,
            selectableRows: "none" as SelectableRows,
            pagination: true,
            serverSide: true,
            count: count,
            page: page,
            rowsPerPage: pageSize,
            rowsPerPageOptions: [10, 25, 50],
            onChangeRowsPerPage: pageSize => {
              setRowsPerPageSize(TableKeys.OBJECT_DATA_LISTS, pageSize);
            },
            customToolbar: () => (
              <CustomToolBar
                objectDefinitionKey={key}
                objectDefinitionLabel={label}
                version={version}
                filterParams={getFilterParams(columnsArray, filterList, searchType)}
                searchType={searchType}
                setState={setState}
              />
            ),
            textLabels: {
              body: {
                noMatch: EMPTY,
                toolTip: t("reports.sort"),
              } as any,
              pagination: {
                rowsPerPage: t("reports.rowsPerPage"),
                displayRows: t("reports.displayRows"),
              },
              filter: {
                all: t("reports.all"),
                title: t("reports.title"),
                reset: t("reports.reset"),
              },
              toolbar: {
                filterTable: t("reports.filterTable"),
              },
            } as any,
            onRowClick: (rowData: string[]) => {
              if (formType === GRAPES_JS_FORM) {
                formData && formData.modelJson ? handleSelectedRow(rowData) : showNotification("error", t("datastore.reportFormError"));
              } else if (formType === CUT) {
                const modelData = formData && formData.grapesForms.map((form: any) => form.model);
                formData && modelData ? handleSelectedRow(rowData) : showNotification("error", t("datastore.reportFormError"));
              }
            },
            onTableChange: (action, tableState: any) => {
              action !== "propsUpdate" && showReportDownloadError && setState({ ...state, showReportDownloadError: false });
              switch (action) {
                case "changePage":
                  handleFilterData({
                    ...filterBody,
                    page: tableState.page,
                    pageSize: tableState.rowsPerPage,
                    filterParams: getFilterParams(columnsArray, tableState.filterList, searchType),
                    sortParams,
                  });
                  break;
                case "changeRowsPerPage": {
                  handleFilterData({
                    ...filterBody,
                    page: 0,
                    pageSize: tableState.rowsPerPage,
                    filterParams: getFilterParams(columnsArray, tableState.filterList, searchType),
                    sortParams,
                  });
                  break;
                }
                case "sort": {
                  if (tableState && tableState.columns && tableState.activeColumn) {
                    var column = tableState.columns[tableState.activeColumn];
                    if (column) {
                      const { name, direction } = sortOrder;
                      const sortDirection = name ? (direction === "desc" ? "asc" : "desc") : "desc";
                      handleFilterData({
                        ...filterBody,
                        page: 0,
                        pageSize: tableState.rowsPerPage,
                        sortParams: { param: column.name, order: sortDirection.toUpperCase() },
                        filterParams: getFilterParams(columnsArray, tableState.filterList, searchType),
                      });
                      setListState(state => ({ ...state, sortOrder: { name: column.name, direction: sortDirection } }));
                    }
                  }
                  break;
                }
              }
            },
            sortOrder: { ...sortOrder, direction: sortOrder.direction === "none" ? "asc" : sortOrder.direction },
          }}
          components={{
            TableFilterList: props => (
              <Fragment>
                <div className={messageContainer}>
                  <Typography variant="h6" className={warningMessage}>
                    {t("datastore.reportWarning")}
                  </Typography>
                  {showReportDownloadError && (
                    <Typography variant="h6" className={errorMessage}>
                      {t("datastore.reportDownloadError")}
                    </Typography>
                  )}
                </div>
                <TableFilterList {...props} serverSideFilterList={filterList} />
              </Fragment>
            ),
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}

export default ObjectDataTable;
