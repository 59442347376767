import { Box, Collapse, List, ListItem, ListItemIcon, ListItemText, MuiThemeProvider } from "@material-ui/core";
import { AddRounded, ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";
import { toggleListStyles, toggleListTheme } from "./styles";

interface ListItem {
  itemName: string;
  onItemClick: () => void;
}

export interface ToggleListProps {
  header: string;
  items: ListItem[];
}

const ToggleList = ({ header, items }: ToggleListProps): JSX.Element => {
  const [open, setOpen] = useState(true);
  const handleClick = () => setOpen(open => !open);
  const { container, expandIcon, headerText, itemText, list, listButton } = toggleListStyles();

  return (
    <MuiThemeProvider theme={toggleListTheme}>
      <ListItem button className={container} onClick={handleClick}>
        <ListItemText className={headerText} primary={header} />
        <Box className={expandIcon}>{open ? <ExpandLess /> : <ExpandMore />}</Box>
      </ListItem>
      <Collapse className="mb-1" in={open} timeout={1}>
        <List className={list} disablePadding>
          {items.map(({ itemName, onItemClick }, i) => (
            <ListItem button className={listButton} data-testid={`item-${i}`} key={i} onClick={onItemClick}>
              <List className="new-process" component="div">
                <ListItemIcon>
                  <AddRounded />
                </ListItemIcon>
                <ListItemText className={itemText} primary={itemName} />
              </List>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </MuiThemeProvider>
  );
};

export default ToggleList;
