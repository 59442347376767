import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useTranslation } from "react-i18next";
import { JuakaliUser } from "../../redux/types";
import { EMPTY } from "../../constants";

export interface SearchableDropdownProps {
  handleOnChange: (event: object, value: any, reason: string) => void;
  options: JuakaliUser[];
  value?: JuakaliUser | undefined | null;
  defaultValue?: JuakaliUser | undefined | null;
  ListboxProps?: object;
  popupIcon?: React.ReactNode;
}

export default function SearchableDropdown({ handleOnChange, ...rest }: SearchableDropdownProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div>
      <Autocomplete
        {...rest}
        data-testid="autoComplete"
        id="assignee-select"
        style={{ width: 232 }}
        onChange={handleOnChange}
        autoHighlight
        getOptionLabel={option => (option ? option.firstName : EMPTY)}
        renderOption={option => (option ? option.firstName : EMPTY)}
        noOptionsText={t("dashboard.noSuchUser")}
        clearText={t("common.clear")}
        closeText={t("common.close")}
        openText={t("common.open")}
        renderInput={params => (
          <TextField
            {...params}
            label={t("tasks.assigneeName")}
            placeholder={t("dashboard.noAssignee")}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </div>
  );
}
