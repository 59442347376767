import moment from "moment";

export interface RowData {
  id: string;
  [extraProps: string]: any;
}

export interface RowMapper {
  header: string;
  name: string;
  propName: string;
  type: "string" | "number" | "date";
  options?: any;
}

export interface Cell {
  options?: any;
  name: string;
  label: string;
}

function valueHandler(rowData: RowData, propName: string, type: "string" | "number" | "date"): any {
  const propId = propName.split(".");
  let value = "";
  if (propId.length === 1) {
    value = rowData[propId[0]] || "";
  } else {
    const inner = rowData[propId[0]];
    if (inner) {
      value = inner[propId[1]] || "";
    }
  }

  if (type === "date" && value != "") {
    return Number(new Date(value));
  }
  return value;
}

export function toReportDataTable(origin: RowData[], mapper: RowMapper[]): { header: Cell[]; body: any[] } {
  const data: { header: Cell[]; body: any[] } = { header: [], body: [] };

  mapper.forEach((item, hIndex) => {
    data.header.push({
      name: item.name,
      label: item.header,
      options: item.options,
    });
  });

  if (origin && origin instanceof Object) {
    // loop through all the rows of data
    Object.keys(origin).forEach((rowKey: any) => {
      let cell: any = [];
      // using the mapper find the correct property
      mapper.map(item => {
        cell[item.name] = valueHandler(origin[rowKey], item.propName, item.type);
      });
      if (Object.keys(cell).length !== 0) {
        // flowlint-next-line all:off
        data.body.push({ ...cell });
      }
    });
  }

  return data;
}
