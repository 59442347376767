import React, { useEffect, useState } from "react";
import { Grid, LinearProgress, useMediaQuery } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Headline3 } from "@material/react-typography";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Formik, FormikActions } from "formik";
import { useTranslation } from "react-i18next";
import { AccountResponse, NewPassword, PasswordError, PasswordSetting, PasswordSettingParameters } from "../../types/passwordTypes";
import { defaultPasswordError, passwordValidationSchema, redirectToLogin } from "./utils";
import { ApiExecutor } from "../../rxjs/ApiExecutor";
import { fetchForAccount } from "../../services/AppDefinitionService";
import { fetchForPasswordSetting, updateUserPassword } from "../../services/PasswordService";
import Header from "../layout/Header";
import PasswordSettings from "./PasswordSettings";
import ChangeSuccessView from "./ChangeSuccessView";
import EmptyColumn from "./EmptyColumn";
import ErrorMessage from "../common/ErrorMessage";
import { passwordTheme } from "./styles";
import { fetchWrapper } from "../../core/fetchWrapper";
import { EMPTY, STATUS_CODES } from "../../constants";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps extends RouteComponentProps<{ key: string }> {}

const ChangePassword = (props: OwnProps): JSX.Element => {
  const { t } = useTranslation(["translation"]);
  const [accountResponse, setAccountResponse] = useState<AccountResponse>();
  const [passwordSetting, setPasswordSetting] = useState<PasswordSetting>();
  const [email, setEmail] = useState<string>(EMPTY);
  const [settingParams, settingParameters] = useState<PasswordSettingParameters>();
  const fullScreen = useMediaQuery(createMuiTheme().breakpoints.down("sm"));
  const [passwordErrors, setPasswordErrors] = useState<PasswordError>(defaultPasswordError);
  const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);
  const { BAD_REQUEST, SUCCESS } = STATUS_CODES;

  const goToLogin = () => redirectToLogin(props.history);

  //fetch password setting
  useEffect(() => {
    var apiExecutor = new ApiExecutor(fetchForPasswordSetting);
    var subscription = apiExecutor.subscribe(setPasswordSetting);
    apiExecutor.callApi(undefined);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (passwordSetting && passwordSetting.status == SUCCESS) {
      passwordSetting.pagedApiResult && settingParameters(passwordSetting.pagedApiResult.result);
    }
  }, [passwordSetting]);

  //fetch user account
  useEffect(() => {
    var apiExecutor = new ApiExecutor(fetchForAccount);
    var subscription = apiExecutor.subscribe(setAccountResponse);
    apiExecutor.callApi(undefined);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (accountResponse && accountResponse.status == SUCCESS) {
      const { pagedApiResult } = accountResponse;
      pagedApiResult && setEmail(pagedApiResult.email);
    }
  }, [accountResponse]);

  const handleFormSubmit = async (values: NewPassword, { setError, setSubmitting }: FormikActions<NewPassword>) => {
    setSubmitting(true);
    setError(false);
    try {
      const response: Response = await fetchWrapper(updateUserPassword, { email, password: values.password });
      await response.json();
      setSubmitting(false);
      const { status } = response;
      status === SUCCESS ? setIsPasswordChanged(true) : setError(status);
    } catch (e) {
      setError(true);
      setSubmitting(false);
    }
  };

  return email && settingParams ? (
    <Formik
      initialValues={{ password: EMPTY }}
      enableReinitialize={true}
      validationSchema={passwordValidationSchema(passwordErrors, t)}
      onSubmit={handleFormSubmit}
    >
      {props => {
        const { error, handleSubmit, setError } = props;

        const renderErrorMessage = (): JSX.Element => {
          return (
            <ErrorMessage
              errorMessage={error && (error === BAD_REQUEST ? t("changePassword.emailMismatchError", { email }) : t("changePassword.serverError"))}
              handleClear={() => {
                setError(false);
              }}
            />
          );
        };

        return (
          <MuiThemeProvider theme={passwordTheme}>
            <form onSubmit={handleSubmit}>
              <Header />
              <div className="mdc-top-app-bar--fixed-adjust">
                <Grid container>
                  <EmptyColumn />
                  <Grid item xs={10} sm={8} md={8} lg={8} xl={6}>
                    <Headline3 data-testid="headerText">{t("changePassword.changePassword")}</Headline3>
                    {renderErrorMessage()}
                    {isPasswordChanged ? (
                      <ChangeSuccessView handleClick={goToLogin} />
                    ) : (
                      <PasswordSettings
                        {...props}
                        email={email}
                        fullScreen={fullScreen}
                        handleAccountClick={goToLogin}
                        isChangePasswordPage={true}
                        setPasswordErrorChange={setPasswordErrors}
                        settingParams={settingParams}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
            </form>
          </MuiThemeProvider>
        );
      }}
    </Formik>
  ) : (
    <LinearProgress />
  );
};

export default withRouter(ChangePassword);
