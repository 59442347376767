import React from "react";
import { Grid } from "@material-ui/core";
import { Grid as GrapesGridField } from "@juakali/juakali-forms-react";
import { BoxRenderType } from "@juakali/juakali-forms-react";
import { boxContainerstyle, emptyBlockStyles, paddingBoxStyles } from "./styles";

/* Field Box Item */
export class BoxItem extends GrapesGridField {
  render(): JSX.Element {
    let { id, phoneWidth, desktopWidth, tabWidth, getRenderType } = this;
    let renderType = getRenderType();
    switch (renderType) {
      case BoxRenderType.RENDER_BOX:
        return (
          <Grid key={id} container item xs={phoneWidth} sm={tabWidth} md={desktopWidth} lg={desktopWidth} xl={desktopWidth} className="align-self">
            <div style={emptyBlockStyles}></div>
          </Grid>
        );
      case BoxRenderType.DONT_RENDER:
        return <></>;
      case BoxRenderType.RENDER_BOX_WITH_CHILDREN:
      default:
        return (
          <Grid
            key={id}
            container
            alignItems="flex-start"
            item
            xs={phoneWidth}
            sm={tabWidth}
            md={desktopWidth}
            lg={desktopWidth}
            xl={desktopWidth}
            style={paddingBoxStyles(this.props.component)}
          >
            {this.props.children}
          </Grid>
        );
    }
  }
}
/* Box Container for fields */
export class BoxContainer extends GrapesGridField {
  render(): JSX.Element {
    let { children } = this.props;
    return (
      <form noValidate className="form">
        <Grid container style={boxContainerstyle}>
          {children}
        </Grid>
      </form>
    );
  }
}
