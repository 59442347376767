import { url } from "../../../services/apiConfig";

export function fetchCombinedUserTaskModelData(combinedUserTaskId: string): Promise<Response> {
  return fetch(url.combinedUserTaskModelData + "/" + combinedUserTaskId, {
    method: "GET",
    // headers: {
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    // },
  });
}
