import { ERROR_MESSAGE_BORDER, ERROR_MESSAGE_BACKGROUND } from "../../../../theme";
import { ERROR, NONE } from "./constants";

export const errorMessageFieldMainContainerStyle = (background: string, fontColor: string, borderColor: string) => {
  return {
    display: "flex",
    flexDirection: "row",
    border: `2px solid  ${borderColor}`,
    backgroundColor: background,
    padding: "15px",
    borderRadius: "5px",
  };
};

export const iconTextStyle = (fontColor: string, typeOfMessage: string, value?: number | boolean) => {
  if (typeOfMessage === ERROR) {
    return { color: fontColor, wordWrap: "break-word", transform: value ? "rotate(0deg)" : "rotate(180deg)" };
  }
  return { color: fontColor, wordWrap: "break-word" };
};

export const textContainerStyle = {
  marginLeft: "10px",
  wordWrap: "break-word",
  wordBreak: "break-all",
  whiteSpace: "pre-wrap",
};

export const paperContainerStyle = {
  width: "100%",
};
