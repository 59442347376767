import { DynamicJson, JuakaliUser, TableItem } from "../../../redux/types";
import i18n from "../../../i18n";
import {
  MultipleReassignTaskResponse,
  ProcessStarterDispatchProps,
  ProcessStarterStateProps,
  QueryParams,
  TaskQueryParams,
  TaskViewRepresentation,
} from "../../../types/taskQueryTypes";
import { ConfirmationDialogProps } from "../../common/ConfirmationDialog";
import { toLocaleShortDate } from "../../../util/dateUtils";
import { Languages, JUAKALI_LANGUAGE } from "../../idm/IdmEnum";
import { fetchWrapper } from "../../../core/fetchWrapper";
import { fetchForReassignMultipleTasks, fetchForTaskStarted } from "../../../services/TaskService";
import { showNotification } from "../../../util/notification";
import { MAX_REASSIGNABLE_ROWS, OTHER_PROCESSES, STATUS_CODES } from "../../../constants";
import { ApiResult } from "../../../types/commonTypes";
import { ToggleListProps } from "../../common/ToggleList";
import moment from "moment";
import { cobalt, darkLiver, limeGreen, strongRed } from "../../theme";

const { FORBIDDEN, NOT_AUTHENTICATED, SUCCESS } = STATUS_CODES;

/**
 * Get currently assigned user information from assigneeId
 * @param assigneeId string attribute used to filter the current user from the users list based on id.
 * @param usersList users List
 * @param returnEmail boolean attribute 'true' will return email of the filtered user.
 * @param returnFirstName boolean attribute 'true' will return firstName of the filtered user.
 */
export const getCurrentAssignee = (
  assigneeId: string,
  usersList: JuakaliUser[],
  returnEmail?: boolean,
  returnFirstName?: boolean
): string | JuakaliUser | undefined => {
  if (usersList && usersList.length) {
    let filteredUser = usersList.find((user: JuakaliUser) => user.id === assigneeId);
    return returnEmail ? filteredUser && filteredUser.email : returnFirstName ? filteredUser && filteredUser.firstName : filteredUser;
  }
  return;
};

export const getStyledCandidates = (candidateString: string): string => {
  var candidates = "";
  var isFirstLink = true;
  candidateString
    ? candidateString.split(",").forEach((item: string) => {
        var candidateName = item.charAt(0).toUpperCase() + item.slice(1);
        isFirstLink ? (candidates = candidateName) : (candidates = candidates + ", " + candidateName);
        isFirstLink = false;
      })
    : "";
  return candidates;
};

/* Get Tasks (Assigned/Candidate/Team) */
export const getMappedTasks = (tasks: TaskViewRepresentation[]): TableItem[] => {
  return tasks
    ? tasks.map((ct: TaskViewRepresentation) => ({
        processInstanceId: ct.processInstanceId,
        taskId: ct.id,
        processName: ct.processDefinitionName,
        dueDate: ct.dueDate ? `${toLocaleShortDate(ct.dueDate)},${ct.taskStatus},${ct.dueDate}` : `--,${ct.taskStatus}`,
        taskName: ct.name,
        assignee: ct.assignee,
        assigneeName: ct.assigneeName ? ct.assigneeName : "--",
        candidates: getStyledCandidates(ct.candidates),
        createTime: ct.createTime,
      }))
    : [];
};

/**
 * Function to get the body text of the table
 * when there is no tasks with & without filter
 * @param params input holds task query parameters
 */
export const getBodyText = (params: TaskQueryParams | QueryParams): string => {
  let { filterParams } = params;
  if (filterParams && (filterParams.dueDate || filterParams.taskName)) {
    return i18n.t("flow:PROCESS.MESSAGE.NO-FILTERED-TASKS");
  }
  return i18n.t("flow:PROCESS.MESSAGE.NO-TASKS");
};

export const defaultConfirmationPropertiesOnCancel = (): ConfirmationDialogProps => ({
  alignActionsOnRightSide: true,
  open: false,
  title: i18n.t("translation:confirmation.cancelChangesTitle"),
  content: i18n.t("translation:confirmation.cancelChangesContent"),
  primaryLabel: i18n.t("translation:common.yes"),
  secondaryLabel: i18n.t("translation:common.no"),
});

export const defaultConfirmationPropertiesOnOtherAction = (): ConfirmationDialogProps => ({
  open: false,
  title: i18n.t("translation:confirmation.unsavedChangesTitle"),
  content: i18n.t("translation:confirmation.unsavedChangesContent", { name: "" }),
  primaryLabel: i18n.t("translation:common.continue"),
  secondaryLabel: i18n.t("translation:common.cancel"),
});

export function checkAndGetSelectedLanguage() {
  const choosenLanguage = localStorage && localStorage.getItem(JUAKALI_LANGUAGE);
  const choosenLanguageKey: string = choosenLanguage ? choosenLanguage : window.navigator.language;
  const selectedLanguage = Object.values(Languages).find(({ key }) => key === choosenLanguageKey.slice(0, 2));
  return selectedLanguage ? selectedLanguage.key : Languages.ENGLISH.key;
}

export const markTaskStarted = async (taskId: string) => {
  try {
    const { status }: Response = await fetchWrapper(fetchForTaskStarted, taskId);
    if (status !== FORBIDDEN && status !== NOT_AUTHENTICATED && status !== SUCCESS) {
      throw new Error();
    }
  } catch (error) {
    showNotification("error", i18n.t("common.serverErrorTryAgain"));
  }
};

export const handleMultipleReassign = async (assignee: JuakaliUser, tableState: DynamicJson) => {
  const { state, props } = tableState;

  tableState.setState({ loading: true });
  const taskDetails = state.rowsSelected.map((row: number) => {
    const { assignee, taskId } = props.tasks[row];
    return { fromUserId: assignee, taskId: taskId };
  });
  const updatedState = { ...state, loading: false };
  try {
    const response: Response = await fetchWrapper(fetchForReassignMultipleTasks, { taskDetails, toUserId: assignee.id });
    const { status } = response;
    if (status === SUCCESS) {
      const { result }: ApiResult<MultipleReassignTaskResponse> = await response.json();
      const { failedTaskIds, noOfTasksReassigned } = result;
      if (!noOfTasksReassigned) {
        showNotification("error", i18n.t("dashboard.multiAssignErrorMsg", { count: failedTaskIds.length }));
        return;
      }

      if (!failedTaskIds.length) {
        showNotification("success", i18n.t("dashboard.multiAssignSuccessMsg", { assignee: assignee.firstName, count: noOfTasksReassigned }));
        updatedState.rowsSelected = [];
        tableState.tableRef.current.state.rowsSelected = [];
      } else {
        updatedState.failedTaskIds = failedTaskIds;
        showNotification("error", i18n.t("dashboard.multiAssignErrorMsg", { count: failedTaskIds.length }));
      }

      tableState.handleReloadTasks();
      return;
    }
    if (status !== FORBIDDEN && status !== NOT_AUTHENTICATED) {
      throw new Error();
    }
  } catch (e) {
    showNotification("error", i18n.t("common.serverErrorTryAgain"));
  } finally {
    tableState.setState(updatedState);
  }
};

export const handleRowSelect = (rowIndex: number, checked: boolean, tableState: DynamicJson) => {
  const { state, props } = tableState;
  let rowsSelected = [...state.rowsSelected];

  if (!checked) {
    rowsSelected = rowIndex === -1 ? [] : rowsSelected.filter(index => index !== rowIndex);
    tableState.tableRef.current.state.rowsSelected = rowsSelected;
    tableState.setState({ rowsSelected });
    return;
  }

  if (rowsSelected.length >= MAX_REASSIGNABLE_ROWS) {
    return;
  }

  const { fetchTasks, pagination, taskQueryParams } = props;
  const { page: currentPage, size, totalCount } = pagination;

  if (rowIndex === -1) {
    const isLastPage = Math.floor(totalCount / size) === currentPage;
    const keysPending = totalCount - currentPage * size;
    if (!isLastPage && size < MAX_REASSIGNABLE_ROWS) {
      rowsSelected = [...Array(totalCount > MAX_REASSIGNABLE_ROWS ? MAX_REASSIGNABLE_ROWS : totalCount).keys()];
      fetchTasks({ ...taskQueryParams, page: 0, size: MAX_REASSIGNABLE_ROWS });
    } else {
      rowsSelected = [...Array(keysPending < MAX_REASSIGNABLE_ROWS ? keysPending : MAX_REASSIGNABLE_ROWS).keys()];
    }
  } else {
    rowsSelected.push(rowIndex);
  }

  tableState.tableRef.current.state.rowsSelected = rowsSelected;
  tableState.setState({ rowsSelected });
};

export const getProcessDefinitionItemList = ({
  handleDialogClose,
  createProcessInstance,
  appDefinitions,
  setCreateProcessInstance,
}: ProcessStarterDispatchProps & ProcessStarterStateProps & DynamicJson): ToggleListProps[] => {
  if (!appDefinitions.length) return [];

  const categories = Array.from(
    new Set(appDefinitions.map(({ category }) => (!category || category === OTHER_PROCESSES ? OTHER_PROCESSES : category)))
  );
  return categories.map(category => ({
    header: category,
    items: appDefinitions
      .filter(pd => pd.category === category || (category === OTHER_PROCESSES && !pd.category))
      .map(({ processDefinitionId, processDefinitionName }) => ({
        itemName: processDefinitionName,
        onItemClick: () => {
          handleDialogClose();
          setCreateProcessInstance({ ...createProcessInstance, askConfirmation: true, processDefinitionId, processName: processDefinitionName });
        },
      })),
  }));
};

export function getDueDateColor(date: string) {
  const dueDate = moment(date);

  // due date today
  if (dueDate.format("MM/DD/YYYY") === moment().format("MM/DD/YYYY")) {
    return limeGreen;
  }
  // due date overdue
  if (dueDate.diff(moment(), "days") < 0) {
    return strongRed;
  }
  // due date within this week
  if (dueDate.isBetween(moment(), moment().weekday(6))) {
    return darkLiver;
  }
  // due date ahead of this week
  if (dueDate.diff(moment().weekday(7), "days") >= 0) {
    return cobalt;
  }
}
