import { ErrorMessageField } from "@juakali/juakali-forms-react";
import React from "react";
import ErrorMessageFieldView from "./ErrorMessageFieldView";

class ErrorMessageComponent extends ErrorMessageField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <ErrorMessageFieldView {...this} />;
  }
}

export default ErrorMessageComponent;
