import React from "react";
import i18n from "i18next";
import { WithTranslation, withTranslation } from "react-i18next";
import { IconButton, InputAdornment, TextField, MuiThemeProvider } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { searchFilterTheme } from "../styles/toolbarStyles";
import { TaskQueryFilterParams } from "../../../types/taskQueryTypes";

interface OwnPropsA {
  applyFilter: (params?: TaskQueryFilterParams) => void;
  assignment: string;
  defaultFilter?: string;
}

interface OwnPropsB extends WithTranslation {
  t: i18n.TFunction;
}

type OwnProps = OwnPropsA & OwnPropsB;

interface OwnState {
  searchClicked: boolean;
  taskName: string;
}

class SearchFilter extends React.Component<OwnProps, OwnState> {
  textInput: any;
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      searchClicked: false,
      taskName: props.defaultFilter || "",
    };
  }
  onChange = (e: any) => {
    this.setState({ taskName: e.target.value });
  };
  onFocus = () => {
    this.setState({ searchClicked: false });
  };
  onPressEnter = (e: any) => {
    if (e.keyCode == 13) {
      this.textInput.blur();
      return this.handleSearch();
    }
  };

  componentDidMount() {
    this.setState({ taskName: "", searchClicked: false });
  }
  componentWillReceiveProps(newProp: OwnProps): void {
    if (newProp.assignment !== this.props.assignment) {
      this.setState({ taskName: "", searchClicked: false });
    }
  }

  handleSearch = () => {
    let { applyFilter } = this.props;
    let { taskName, searchClicked } = this.state;
    let taskNameAppliedInFilters = searchClicked ? "" : taskName;
    this.setState({ searchClicked: !taskName ? false : !searchClicked, taskName: taskNameAppliedInFilters });
    applyFilter({ taskName: taskNameAppliedInFilters });
  };

  render(): JSX.Element {
    const { t, assignment } = this.props;

    let { searchClicked, taskName } = this.state;
    return (
      <MuiThemeProvider theme={searchFilterTheme}>
        <TextField
          inputRef={r => (this.textInput = r)}
          id={`${assignment}-search`}
          name={`${assignment}-search`}
          placeholder={t(`dashboard.taskNameFilterLabel`)}
          label={t(`dashboard.taskNameFilterLabel`)}
          variant="outlined"
          onChange={this.onChange}
          onFocus={this.onFocus}
          onKeyDown={this.onPressEnter}
          value={taskName}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label={searchClicked ? "clear" : "search"} onClick={this.handleSearch}>
                  {searchClicked ? <ClearIcon /> : <SearchIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default withTranslation()(SearchFilter);
