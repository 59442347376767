import { EntitySavingStatus, EntityLoadingStatus } from "../../genericRedux/reduxTypes";

/**
 * Represents a DataStoreLink in the DataStore.
 * @export
 * @interface DataStoreLink
 */
export interface DataStoreLink {
  /**
   * unique identifier
   * @type {string}
   * @memberof DataStoreLink
   */
  id: string | null;

  /**
   * Id of the Process Definition that is linked to the object definition by (key and version) .
   * @type {string}
   * @memberof DataStoreLink
   */
  processDefinitionKey: string;

  /**
   * Key of the definition, together with version the composite unique identifier.
   * @type {string}
   * @memberof DataStoreLink
   */
  key: string;
  /**
   * Version of the definition, together with key the composite unique identifier.
   *
   * @type {number}
   * @memberof DataStoreLink
   */
  version: number;
  /**
   * Label is the string that gets displayed in the dashboard when the definition is listed.
   * @type {string}
   * @memberof DataStoreLink
   */
  label: string;
  /**
   * Category as on the object definition. It represents the sections in the dashboard.
   * @type {string}
   * @memberof DataStoreLink
   */
  category: string;
  /**
   * Any additional data to represent link between process and object definition is stored here.
   * @type {DataStoreLinkOtherData}
   * @memberof DataStoreLink
   */
  otherData: DataStoreLinkOtherData | null;
  /**
   * Server-side definition creation date
   * @type {Date}
   * @memberof DataStoreLink
   */
  created: Date;
  /**
   * Server-side definition update date
   * @type {Date}
   * @memberof DataStoreLink
   */
  updated: Date;
  /**
   * Indicates the status of the object with regard to server-side loading.
   * @type {ObjectDefinitionRemoteLoadingStatus}
   * @memberof DataStoreLink
   */
  remoteLoadingStatus: EntityLoadingStatus;
  /**
   * Indicates the status of the object with regard to server-side saving.
   * @type {ObjectDefinitionRemoteSavingStatus}
   * @memberof ObjectDDataStoreLinkefinition
   */
  remoteSavingStatus: EntitySavingStatus;

  /**
   * Indicates the action of the object with regard to server-side saving.
   * For example, if it is INSERT , an object is inserted to the database.
   * @type {DataStoreActionType}
   * @memberof DataStoreLink
   */
  actionType: DataStoreActionType;

  /**
   * Indicates the link type between DataStoreLink and ProcessDefinition.
   * @type {DataStoreLinkType}
   * @memberof DataStoreLink
   */
  type: DataStoreLinkType;
}

/**
 * Represents the json stored in otherData of DataStoreLink record.
 * @export
 * @interface DataStoreLinkOtherData
 */
export interface DataStoreLinkOtherData {
  /**
   * Stores the name of the process definition.
   * @type {string}
   * @memberof DataStoreLinkOtherData
   */
  processDefinitionName: string;
}

export enum DataStoreActionType {
  INSERT = "INSERT",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  NONE = "NONE",
}

export enum DataStoreLinkType {
  INSERT = "INSERT",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

/**
 * Default instance of [DataStoreLinkState]
 */
export const defaultDataStoreLink: DataStoreLink = {
  id: "",
  processDefinitionKey: "",
  key: "",
  label: "",
  category: "",
  actionType: DataStoreActionType.INSERT,
  type: DataStoreLinkType.INSERT,
  version: 1,
  otherData: { processDefinitionName: "" },
  created: new Date(),
  updated: new Date(),
  remoteLoadingStatus: "fullyLoaded",
  remoteSavingStatus: "unknown",
};

export interface AppDefinition {
  defaultAppId: string;
  name: string;
  description: string;
  theme: string;
  icon: string;
  appDefinitionId: string;
  appDefinitionKey: string;
  tenantId: string;
  type: string;
  version: number;
  processDefinitionKey: string;
  processDefinitionId: string;
}

export const defaultAppDefinition: AppDefinition = {
  defaultAppId: "",
  name: "",
  description: "",
  theme: "",
  icon: "",
  appDefinitionId: "",
  appDefinitionKey: "",
  tenantId: "",
  type: "",
  version: 1,
  processDefinitionKey: "",
  processDefinitionId: "",
};

export interface DataStoreDeleteProcess {
  appDefinition: AppDefinition;
  dataStoreLink: DataStoreLink;
}

export const defaultDataStoreDeleteProcess: DataStoreDeleteProcess = {
  appDefinition: defaultAppDefinition,
  dataStoreLink: defaultDataStoreLink,
};

export type DataStoreDeleteProcessesLoadingStatus =
  | "unknown" // loading status unknown
  | "loading" // currently loading the object from servery
  | "fullyLoaded" // object is fully loaded from server
  | "loadingFailed"; // loading object from server failed

export type StartProcessIntanceStatus = "unknown" | "pending" | "success" | "error";
/**
 * Represents the Redux Store state for storing DataStoreLink.
 */
export interface DataStoreDeleteProcessState {
  items: DataStoreDeleteProcess[];
  remoteLoadingStatus: DataStoreDeleteProcessesLoadingStatus;
  startProcessInstanceStatus: StartProcessIntanceStatus;
  processInstanceId: string;
  error: string;
}

/**
 * Default instance of [DataStoreDeleteProcessState]
 */
export const defaultDataStoreDeleteProcessState: DataStoreDeleteProcessState = {
  items: [],
  remoteLoadingStatus: "unknown",
  startProcessInstanceStatus: "unknown",
  processInstanceId: "",
  error: "",
};
