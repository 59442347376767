import { BehaviorSubject } from "rxjs";

export interface LoginState {
  sessionOut: boolean;
  serverError: boolean;
  isAccount: boolean;
  currentPath: string;
  isRedirect: boolean;
}

export const defaultLoginState = {
  sessionOut: false,
  serverError: false,
  isAccount: false,
  currentPath: "",
  isRedirect: false,
};

export default class LoginBloc {
  private static instance: LoginBloc;
  private subject: BehaviorSubject<LoginState>;

  private constructor() {
    this.subject = new BehaviorSubject(defaultLoginState);
  }

  static getInstance(): LoginBloc {
    if (!LoginBloc.instance) {
      LoginBloc.instance = new LoginBloc();
    }
    return LoginBloc.instance;
  }

  getSubject = (): BehaviorSubject<LoginState> => {
    return this.subject;
  };
  updateState = (value: LoginState): void => {
    this.subject.next({
      ...this.subject.value,
      ...value,
    });
  };
}

export const updateLogInBlocState = (args: any): void => {
  const loginStateBloc = LoginBloc.getInstance();
  loginStateBloc.updateState({ ...args });
};
