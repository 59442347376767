import { StateType, ProcessInstance, defaultProcessInstanceState, ProcessInstances } from "../types";


export const getProcessInstance = 
  (store: StateType, processInstanceId: string): ProcessInstance | undefined => store.processInstances.ProcessInstance[processInstanceId];

  export const getProcessInstanceState = (store: StateType): ProcessInstances => {
    return store.processInstances || defaultProcessInstanceState;
  };

