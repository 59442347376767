import { AnyAction, createReducer, createAction } from "redux-starter-kit";
import { takeEvery, call, put } from "redux-saga/effects";
import i18n from "i18next";

import { url } from "../../services/apiConfig";
import {
  DataStoreDeleteProcessState,
  defaultDataStoreDeleteProcessState,
  DataStoreDeleteProcess,
  DataStoreDeleteProcessesLoadingStatus,
  StartProcessIntanceStatus,
} from "./dataStoreDeleteProcessTypes";
import { TypedAction } from "../../redux/types";
import { fetchWrapper } from "../../core/fetchWrapper";
import { StartDeleteProcessInstanceRequest, fetchForStartDeleteProcessInstance } from "../../services/ProcessInstanceService";
import { showNotification } from "../../util/notification";
import { throwError } from "../../core/throwError";

const fetchDataStoreDeleteProcesses = (payload: { key: string; version: number }): Promise<Response> => {
  return fetch(`${url.dataStoreProcesses}/?key=${payload.key}&version=${payload.version}&type=DELETE`, {
    method: "GET",
  });
};

export const resetDataStoreDeleteProcessState = createAction("resetdataStoreDeleteProcessState");
export const getRemoteDataStoreDeleteProcesses = createAction("getRemoteDataStoreDeleteProcesses");
export const setDataStoreDeleteProcesses = createAction("setDataStoreDeleteProcesses");
export const setDataStoreDeleteProcessLoadingStatus = createAction("setDataStoreDeleteProcessLoadingStatus");
export const createStartDeleteProcessInstance = createAction("createStartDeleteProcessInstance");
export const setStartProcessInstanceStatus = createAction("setStartProcessInstanceStatus");
export const setProcessInstanceId = createAction("setProcessInstanceId");

export const dataStoreDeleteProcessesReducer = createReducer<DataStoreDeleteProcessState, AnyAction>(defaultDataStoreDeleteProcessState, {
  [setDataStoreDeleteProcessLoadingStatus.type]: (state: DataStoreDeleteProcessState, action: TypedAction<DataStoreDeleteProcessesLoadingStatus>) => {
    if (action.payload) {
      state.remoteLoadingStatus = action.payload;
    }
    return state;
  },
  [setDataStoreDeleteProcesses.type]: (state: DataStoreDeleteProcessState, action: TypedAction<DataStoreDeleteProcess[]>) => {
    if (action.payload) {
      state.items = action.payload;
    }
    return state;
  },
  [setStartProcessInstanceStatus.type]: (state: DataStoreDeleteProcessState, action: TypedAction<StartProcessIntanceStatus>) => {
    if (action.payload) {
      state.startProcessInstanceStatus = action.payload;
    }
    return state;
  },
  [setProcessInstanceId.type]: (state: DataStoreDeleteProcessState, action: TypedAction<string>) => {
    if (action.payload) {
      state.processInstanceId = action.payload;
    }
    return state;
  },
  [resetDataStoreDeleteProcessState.type]: (state: DataStoreDeleteProcessState, action: TypedAction<void>) => {
    return defaultDataStoreDeleteProcessState;
  },
});

export function* getRemoteDataStoreDeleteProcessesGenerator(action: TypedAction<{ key: string; version: number }>): any {
  try {
    if (!action.payload) {
      throw new Error(i18n.t("common.noProperPayladPassed"));
    }
    yield put({ type: [setDataStoreDeleteProcessLoadingStatus.type], payload: "loading" });
    const response: Response = yield call(fetchWrapper, fetchDataStoreDeleteProcesses, action.payload);
    if (response.status === 200) {
      const body: any = yield response.json();
      yield put({ type: [setDataStoreDeleteProcesses.type], payload: body.result });
      yield put({ type: [setDataStoreDeleteProcessLoadingStatus.type], payload: "fullyLoaded" });
    } else {
      throwError(response);
    }
  } catch (error) {
    yield put({ type: [setDataStoreDeleteProcessLoadingStatus.type], payload: "loadingFailed" });
    showNotification("error", i18n.t("process.failedtoLoadDeleteProcesses"));
  }
}

export function* watchGetRemoteDataStoreDeleteProcesses(): any {
  yield takeEvery([getRemoteDataStoreDeleteProcesses.type], getRemoteDataStoreDeleteProcessesGenerator);
}

export function* createStartDeleteProcessInstanceGenerator(action: TypedAction<StartDeleteProcessInstanceRequest>): any {
  try {
    if (!action.payload) {
      throw new Error(i18n.t("common.noProperPayladPassed"));
    }
    yield put({ type: [setStartProcessInstanceStatus.type], payload: "pending" });
    const response: Response = yield call(fetchWrapper, fetchForStartDeleteProcessInstance, action.payload);
    if (response.status === 200) {
      const body: any = yield response.json();
      yield put({ type: [setProcessInstanceId.type], payload: body.data.id });
      yield put({ type: [setStartProcessInstanceStatus.type], payload: "success" });
    } else {
      throw new Error("Status: " + response.status);
    }
  } catch (error) {
    yield put({ type: [setStartProcessInstanceStatus.type], payload: "error" });
    showNotification("error", i18n.t("process.failedToCreateProcess"));
  }
}

export function* watchStartDeleteProcessInstance(): any {
  yield takeEvery([createStartDeleteProcessInstance.type], createStartDeleteProcessInstanceGenerator);
}
