import React from "react";
import { TextField } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { MultilineTextViewProps } from "../types";
import { multilineTheme } from "../styles";

export const renderMultilineTextView = ({ commonFieldProps, props, handlers }: MultilineTextViewProps): JSX.Element => {
  const { type, maxRows, maximumLength, getValue } = props;
  const value: string | undefined = getValue();
  const { handleChange } = handlers;

  const length = value ? value.length : "0";

  return (
    <div>
      <ThemeProvider theme={multilineTheme}>
        <TextField
          {...commonFieldProps}
          data-testid="multilineField"
          type={type}
          onChange={handleChange}
          multiline
          rows={maxRows}
          inputProps={{
            maxLength: maximumLength,
          }}
          InputLabelProps={{ shrink: true }}
        />
      </ThemeProvider>
      <span className="float-right" style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)", marginTop: "5px", marginLeft: "5px" }}>
        {maximumLength ? `${length}/${maximumLength}` : `${length}`}
      </span>
    </div>
  );
};
