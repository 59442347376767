import { cobalt, grey, poloBlue, solitude } from "../../../../theme";

export const phoneNumberFieldStyles = (fieldWidth: any, isInvalid: any, showToolTip: any): any => {
  const color = isInvalid ? poloBlue : cobalt;
  return {
    callBlock: {
      display: "flex",
      alignItems: "center",
      width: fieldWidth,
    },
    call: {
      width: 18,
      height: 18,
      color,
      margin: "4px",
    },
    callButton: {
      border: `2px solid ${color}`,
      boxSizing: "border-box",
      borderRadius: "4px",
      backgroundColor: "transparent",
      padding: "2px",
      marginLeft: "16px",
      width: 34,
      height: 34,
      cursor: isInvalid ? "initial" : "pointer",
      color,
    },
    rectangle: {
      position: "absolute",
      backgroundColor: solitude,
      width: 20,
      height: 20,
      transform: "rotate(-45deg)",
      top: "-10px",
      left: "180px",
      overflow: "visible",
    },
    errorText: { color: grey, fontSize: "14px", fontWeight: 500, fontStyle: "normal" },
    errorPaper: {
      backgroundColor: solitude,
      padding: "8px",
      position: "relative",
      width: "200px",
      zIndex: 1,
    },
    errorBlock: { position: "absolute", right: "-8px" },
    errorOuterBlock: {
      position: "relative",
      width: fieldWidth,
      display: showToolTip ? "block" : "none",
    },
  };
};
