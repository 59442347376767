import React, { Fragment } from "react";
import { CircularProgress, Grid, MuiThemeProvider, Typography } from "@material-ui/core";
import MUIDataTable, { SelectableRows, Responsive, MUIDataTableProps, MUIDataTableState } from "mui-datatables";
import { loaderStyle, taskTableTheme, wrapper } from "./../styles/tableStyles";
import {
  TableItem,
  Paging,
  defaultAssignedTaskQueryParams,
  defaultCandidateTaskQueryParams,
  defaultTeamTaskQueryParams,
  JuakaliUser,
} from "../../../redux/types";
import CustomToolbar from "../CustomToolbar";
import {
  TaskQueryParams,
  TaskAssignment,
  SortType,
  columnAndSortTypeMapper,
  TaskQueryFilterParams,
  QueryParams,
  TaskStatus,
  sortTypeAndColumnMapper,
  QuickFilter,
} from "../../../types/taskQueryTypes";
import { checkIfFilterParamsPresent } from "../utils/toolbarHelper";
import { SortParams } from "../../../types/commonTypes";
import i18n from "../../../i18n";
import { setRowsPerPageSize } from "../../../util/helper";
import { TableKeys } from "../../../constants/index";
import { Subscription } from "rxjs";
import CustomToolbarSelect from "../CustomToolbar/CustomToolbarSelect";
import Spinner from "../../common/Spinner";
import { handleMultipleReassign, handleRowSelect } from "../utils/dashboardHelper";
import { checkAndAddSelectColumn } from "./ColumnProperties";

interface OwnProps<T> {
  title: string;
  tasks: TableItem[];
  isLoading: boolean;
  tasksHeader: any;
  pagination: Paging;
  noItemsText: string;
  assignment: TaskAssignment;
  taskQueryParams: T;
  fetchTasks: (params: T) => void;
  status: string;
  setStatus: (assignment: TaskAssignment, status?: string) => void;
  users?: JuakaliUser[];
  filterNewAssignedTask?: boolean;
  onNewFilterClick?: () => void;
  onTaskQuickFilter?(filterType: QuickFilter): void;
  activeQuickFilter?: QuickFilter
}

interface OwnState {
  failedTaskIds: string[];
  rowsSelected: number[];
  isMobileView: boolean;
  sortOrder: SortParams;
}

export default class TaskTable extends React.Component<OwnProps<Partial<QueryParams & TaskQueryParams>>, OwnState> {
  subscription: Subscription | undefined;
  tableRef: React.RefObject<React.Component<MUIDataTableProps, MUIDataTableState> | null | undefined>;
  constructor(props: OwnProps<Partial<QueryParams & TaskQueryParams>>) {
    super(props);
    const defaultQueryParams =
      props.assignment === TaskAssignment.INVOLVED
        ? defaultAssignedTaskQueryParams
        : props.assignment === TaskAssignment.CANDIDATE
        ? defaultCandidateTaskQueryParams
        : defaultTeamTaskQueryParams;
    const sortType = sortTypeAndColumnMapper[props.taskQueryParams.sortType || defaultQueryParams.sortType];
    this.state = {
      rowsSelected: [],
      failedTaskIds: [],
      isMobileView: window.matchMedia("(max-width: 599.95px)").matches,
      sortOrder: { name: sortType.columnName, direction: sortType.sortDirection },
    };
    this.tableRef = React.createRef();
  }

  onScreenSizeChange = (e: MediaQueryListEvent): void => this.setState({ isMobileView: e.matches });

  componentDidMount(): void {
    // Add Event listener when screen size changes
    window.matchMedia("(max-width: 599.95px)").addListener(this.onScreenSizeChange);
  }

  componentWillReceiveProps(newProps: OwnProps<Partial<QueryParams & TaskQueryParams>>): void {
    let { assignment } = this.props;
    if (assignment !== newProps.assignment) {
      this.setState({ failedTaskIds: [], rowsSelected: [],});
    }
    if (this.state.failedTaskIds.length) {
      const rowsSelected: number[] = [];
      newProps.tasks.map((task, index) => this.state.failedTaskIds.includes(task.taskId) && rowsSelected.push(index));
      this.setState({ failedTaskIds: [], rowsSelected });
      (this.tableRef.current as any).state.rowsSelected = rowsSelected;
    }
    checkAndAddSelectColumn(newProps, this);
  }

  componentWillUnmount(): void {
    const { onScreenSizeChange, subscription } = this;
    // Remove Event listener for screen size changes
    window.matchMedia("(max-width: 599.95px)").removeListener(onScreenSizeChange);
    subscription && subscription.unsubscribe();
  }

  onReassign = async (assignee: JuakaliUser) => await handleMultipleReassign(assignee, this);

  handleReloadTasks = async (params?: TaskQueryFilterParams) => {
    const { assignment, fetchTasks, setStatus, taskQueryParams } = this.props;
    const taskFilterParams = { ...taskQueryParams.filterParams, ...params };
    const current = {
      ...taskQueryParams,
      filterParams: taskFilterParams,
      page: checkIfFilterParamsPresent(taskFilterParams)
        ? assignment === TaskAssignment.INVOLVED
          ? defaultAssignedTaskQueryParams.page
          : assignment === TaskAssignment.CANDIDATE
          ? defaultCandidateTaskQueryParams.page
          : defaultTeamTaskQueryParams.page
        : taskQueryParams.page,
    };
    try {
      if (typeof params !== "undefined") {
        this.resetSelectState();
      }
      await fetchTasks(current);
      
    } catch (e) {
      setStatus(assignment, TaskStatus.ERROR);
    }
  };

  resetSelectState = () => {
    if (this.state.rowsSelected.length) {
      this.setState({ rowsSelected: [] });
      (this.tableRef.current as any).state.rowsSelected = [];
    }
  };

  onRowSelect = (rowIndex: number, checked: boolean) => handleRowSelect(rowIndex, checked, this);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render(): JSX.Element {
    let {
      assignment,
      noItemsText,
      title,
      tasks,
      isLoading,
      tasksHeader,
      pagination,
      fetchTasks,
      taskQueryParams,
      status,
      setStatus,
      users,
      filterNewAssignedTask,
      onTaskQuickFilter,
      activeQuickFilter
    } = this.props;
    let { isMobileView, sortOrder, rowsSelected } = this.state;
    const { TEAM_TASK_LISTS, ASSIGNED_TASK_LISTS, CANDIDATE_TASK_LISTS } = TableKeys;
    let options = {
      download: false,
      filter: false,
      search: false,
      viewColumns: false,
      pagination: true,
      responsive: "scrollMaxHeight" as Responsive,
      print: false,
      serverSide: true,
      count: pagination.totalCount,
      rowsPerPageOptions: [5, 10, 20, 100, 200],
      selectableRows: "none" as SelectableRows,
      sortOrder: { ...sortOrder, direction: sortOrder.direction === "none" ? "asc" : sortOrder.direction },
      customToolbar: () => {
        return (
          <Fragment>
            <CustomToolbar
              filterNewAssignedTask={filterNewAssignedTask}
              itemCount={pagination.totalCount}
              status={status}
              setStatus={setStatus}
              assignment={assignment}
              users={users}
              defaultFilterParams={taskQueryParams.filterParams}
              filterNew={taskQueryParams.filterNew}
              applyFilter={this.handleReloadTasks}
              onTaskQuickFilter={onTaskQuickFilter}
              pagination={pagination}
              activeQuickFilter={activeQuickFilter}
            />
            {rowsSelected.length ? <CustomToolbarSelect handleReassign={this.onReassign} rowsSelected={rowsSelected} users={users} /> : undefined}
          </Fragment>
        );
      },
      //selectToolbarPlacement: "none" as SelectToolbarPlacement,
      textLabels: {
        body: {
          noMatch: noItemsText,
          toolTip: i18n.t("reports.sort"),
        },
        pagination: {
          rowsPerPage: i18n.t("reports.rowsPerPage"),
          displayRows: i18n.t("reports.displayRows"),
        },
      } as any,
      onTableChange: (action: any, tableState: any) => {
        switch (action) {
          case "propsUpdate" /* update page number and rows per page table properties from component properties */:
            // if (this.props.assignment === TaskAssignment.INVOLVED || this.props.assignment === TaskAssignment.CANDIDATE) {
            if (this.props.pagination.page != tableState.page) {
              tableState.page = this.props.taskQueryParams.page;
            }
            // }
            if (this.props.pagination.size != tableState.rowsPerPage) {
              tableState.rowsPerPage = this.props.taskQueryParams.size;
            }

            // }
            break;
          case "changeRowsPerPage" /* case handled when any changes in rows per page option */:
            tableState.page = 0; /* reset page number whenever row per page changes */
            var params = { ...this.props.taskQueryParams };
            params.page = 0;
            params.size = tableState.rowsPerPage;
            setRowsPerPageSize(
              assignment === TaskAssignment.CANDIDATE
                ? CANDIDATE_TASK_LISTS
                : assignment === TaskAssignment.TEAM
                ? TEAM_TASK_LISTS
                : ASSIGNED_TASK_LISTS,
              tableState.rowsPerPage
            );
            fetchTasks(params); /* call reducer action to fetch tasks from server */
            this.resetSelectState();
            break;
          case "changePage" /* case handled when any changes in page number */:
            var paramsChangePage = { ...this.props.taskQueryParams };
            paramsChangePage.page = tableState.page;
            paramsChangePage.size = tableState.rowsPerPage;
            fetchTasks(paramsChangePage); /* call reducer action to fetch tasks from server */
            this.resetSelectState();
            break;
          case "sort":
            var paramsChangeSort = { ...this.props.taskQueryParams };
            if (tableState && tableState.columns) {
              var column = tableState.columns[tableState.activeColumn];
              if (column) {
                const { name, direction } = sortOrder;
                const sortDirection = name ? (direction === "desc" ? "asc" : "desc") : "desc";
                var sortType = columnAndSortTypeMapper[column.name + sortDirection];
                paramsChangeSort.page = 0;
                paramsChangeSort.sortType = sortType ? sortType : SortType.DUE_DATE_ASCENDING;
                fetchTasks(paramsChangeSort); /* call reducer action to fetch tasks from server */
                this.setState({ ...this.state, sortOrder: { name: column.name, direction: sortDirection } });
                this.resetSelectState();
              }
            }
            break;
        }
      },
    };
    const showLoader = isLoading

    return (
      <Grid className={assignment === TaskAssignment.INVOLVED ? "pb-2" : undefined} style={wrapper}>
        {assignment !== TaskAssignment.TEAM && showLoader ? (
          <div style={loaderStyle}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <Spinner open={showLoader} />
        )}
        <MuiThemeProvider theme={taskTableTheme(isMobileView, assignment, tasks && tasks.length)}>
          {/* re-usable MUI Table to list Assigned/Candidate/Team Tasks */}
          <MUIDataTable
            title={
              <Typography variant="h4">
                {title} ({pagination.totalAll})
              </Typography>
            }
            key={assignment}
            columns={tasksHeader}
            data={tasks}
            options={options}
            innerRef={this.tableRef}
          />
        </MuiThemeProvider>
      </Grid>
    );
  }
}
