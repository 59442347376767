import { InputField } from "@juakali/juakali-forms-react";
import React from "react";
import TextView from "./TextView";

class TextComponent extends InputField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <TextView {...this} />;
  }
}

export default TextComponent;
