import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import SearchableDropdown from "../common/SearchableDropdown";
import { JuakaliUser } from "../../redux/types";
import { assigneeSelectionStyles } from "./styles";

interface OwnProps {
  usersList: JuakaliUser[];
  onSelect: () => void;
  onClear: () => void;
  defaultValue: JuakaliUser | undefined;
  onChange: (event: object, value: any, reason: string) => void;
}

export default function SelectAssignee(props: OwnProps): JSX.Element {
  const { t } = useTranslation(["translation", "flow"]);
  let { usersList, onSelect, onClear, onChange, defaultValue } = props;
  let { btnSmall, cancelButton, buttonWrapper } = assigneeSelectionStyles;
  return (
    <>
      <SearchableDropdown handleOnChange={onChange} defaultValue={defaultValue} options={usersList} />
      <div className="float-right" style={buttonWrapper}>
        <Tooltip title={t("common.save") as string}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            type="submit"
            style={btnSmall}
            onClick={onSelect}
            className="primary-button"
            data-testid="checkButton"
          >
            <CheckIcon />
          </Button>
        </Tooltip>
        <Tooltip title={t("common.cancel") as string}>
          <Button
            size="small"
            variant="contained"
            style={{ ...btnSmall, ...cancelButton }}
            className="secondary-button"
            data-testid="clearButton"
            onClick={onClear}
          >
            <ClearIcon />
          </Button>
        </Tooltip>
      </div>
    </>
  );
}
