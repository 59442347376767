import React from "react";
import { CircularProgress, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { LOADER_BG_COLOR } from "../theme";

interface OwnProps {
  open: boolean;
}

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
    inset: 0,
    backgroundColor: LOADER_BG_COLOR,
    position: "fixed",
  },
});

export const spinnerTheme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        outline: "none !important",
      },
    },
  },
});

const Spinner = ({ open }: OwnProps): JSX.Element => {
  const { modal } = useStyles();
  return (
    <>
      {open && (
        <div className={modal}>
          <MuiThemeProvider theme={spinnerTheme}>
            <CircularProgress />
          </MuiThemeProvider>
        </div>
      )}
    </>
  );
};

Spinner.defaultProps = {
  open: false,
};

export default Spinner;
