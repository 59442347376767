import React from "react";
import { DialogTitle, Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";
import { dialogStyles } from "./style";
import { drawingFieldStyles } from "../styles";

interface OwnProps {
  dialogContent?: any;
  disableAllFields?: boolean;
  canvasThumbnail?: any;
  disabledContent?: any;
  open: boolean;
  id: string;
  title?: any;
  content: any;
  discardLabel?: string;
  cancelLabel?: string;
  doneLabel?: string;
  eraseLabel?: string;
  theme?: any;
  fullScreen?: boolean;
  onAccept?: () => void;
  onCancel?: () => void;
  customHandler?: () => void;
  onDiscard?: () => void;
  onEnter?: () => void;
}

/* The new material-ui dialog component, which may replace
   the current material dialog component in future. */
function CustomDialog(props: OwnProps): any {
  let {
    theme,
    fullScreen,
    title,
    disableAllFields,
    disabledContent,
    discardLabel,
    cancelLabel,
    doneLabel,
    eraseLabel,
    onAccept,
    customHandler,
    onCancel,
    onEnter,
    onDiscard,
    id,
    open,
  } = props;

  const classes = dialogStyles({ theme, fullScreen });
  const { buttonColor, buttonTextColor, dangerButton, titleHeader } = drawingFieldStyles();
  return (
    <Dialog
      id={id}
      open={open}
      onEnter={onEnter}
      fullWidth={true}
      classes={{ paper: `${classes.dialogPaper}`, paperFullWidth: `${classes.dialogWidth}` }}
    >
      {title && <DialogTitle style={titleHeader}>{title}</DialogTitle>}
      <DialogContent classes={{ root: `${classes.dialogContent}` }}>{props.content}</DialogContent>
      <DialogActions classes={{ root: `${classes.dialogActions}`, action: `${classes.dialogAction}` } as any}>
        {disableAllFields ? (
          disabledContent && disabledContent
        ) : (
          <>
            {eraseLabel && (
              <Button id="custom" onClick={customHandler} variant="contained" style={buttonColor} color="primary">
                {eraseLabel}
              </Button>
            )}
            {doneLabel && (
              <Button id="accept" onClick={onAccept} variant="contained" style={buttonColor} color="primary">
                {doneLabel}
              </Button>
            )}
            {discardLabel && (
              <Button id="accept" data-testid={`${discardLabel}`} onClick={onDiscard} variant="text" style={dangerButton}>
                {discardLabel}
              </Button>
            )}
            {cancelLabel && (
              <Button id="accept" onClick={onCancel} variant="text" style={buttonTextColor}>
                {cancelLabel}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
