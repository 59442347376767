// @flow
import fetch from "isomorphic-fetch";
import { url } from "./apiConfig";
import { HTTP_METHOD } from "../constants";
import { ChangePasswordPayload, ResetPasswordPayload } from "../types/passwordTypes";

const { GET, POST } = HTTP_METHOD;
const { accounts, passwordSetting, resetPassword, unAuthenticatedPasswordSetting, updatePassword } = url;

export function fetchForPasswordSetting(): Promise<Response> {
  return fetch(passwordSetting, {
    method: GET,
  });
}

export const updateUserPassword = (payload: ChangePasswordPayload): any => {
  return fetch(updatePassword, {
    method: POST,
    body: JSON.stringify(payload),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export function fetchForUnAuthenticatedPasswordSetting(tenantId: String): Promise<Response> {
  return fetch(unAuthenticatedPasswordSetting + tenantId, {
    method: GET,
  });
}

export const resetUserPassword = (payload: ResetPasswordPayload): any => {
  return fetch(resetPassword, {
    method: POST,
    body: JSON.stringify(payload),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const fetchForVerifyOobCode = (oobCode: String, tenantId: String): Promise<Response> => {
  return fetch(`${accounts}/${tenantId}/verify/reset-password?oobCode=${oobCode}`, {
    method: POST,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};
