/* eslint-disable react/jsx-filename-extension */
// @flow
/* globals localStorage, document */
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./i18n";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import * as serviceWorker from "./serviceWorker";

import Loader from "./views/common/Loader";

import App from "./App";

import store from "./redux/Store";

import "./index.css";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import ErrorBoundary from "./components/ErrorBoundary";

// Add these lines:
if (process.env.NODE_ENV !== "production") {
  localStorage.setItem("debug", "juakali-los-task:*");
}

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: window.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing()],
    // sampleRate will be between 0 - 1. 
    tracesSampleRate: window.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
  });
}

const root = document.getElementById("root");

if (root !== null) {
  ReactDOM.render(
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ErrorBoundary>
            <App />
            <ToastContainer />
          </ErrorBoundary>
        </MuiPickersUtilsProvider>
      </Provider>
    </Suspense>,
    root
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
