import { FormHelperText, FormLabel, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import DrawingModalView from "./DrawingModalView";
import CustomDialog from "../../Dialog";
import ThumbnailView from "./ThumbnailView";
import CustomFieldLabel from "../CustomFieldLabel";

const DrawingFieldView = (props: any): JSX.Element => {
  let {
    canvasData,
    getConfirmDialogStatus,
    getContent,
    canvasThumbnail,
    handleDiscardPopup,
    getLabel,
    getError,
    getLoadingStatus,
    getValue,
    handleAccept,
    handleDismiss,
    name,
    getActiveStatus,
  } = props;
  let label = getLabel(); /* get form field label */
  let error = getError(); /* get form field error */
  let dialogContent = getContent();
  const value = getValue();
  let disabled = getActiveStatus();
  const isLoading = getLoadingStatus(); /* uploading signature to cloud status */
  const ERASE = "erase";

  const { classes, t } = props.props;
  const { emptyFieldText, fieldText, dialogHeader } = classes;

  const renderErrorMessage = (): JSX.Element => {
    return (
      error && (
        <FormHelperText key={name} className="errorMessage" data-testid="drawingError">
          {t(`validation.${error.type}`, error.params)}
        </FormHelperText>
      )
    );
  };

  const renderConfirmDialog = (): JSX.Element => {
    return (
      <CustomDialog
        dialogContent={dialogContent}
        canvasThumbnail={canvasThumbnail}
        id={"confirmation"}
        open={getConfirmDialogStatus()}
        title={
          dialogContent !== ERASE && (
            <Typography data-testid="exitlabel" className={dialogHeader}>
              {t("common.exit")}
            </Typography>
          )
        }
        content={dialogContent === ERASE ? t("canvas.clearConfirmation") : t("common.exitConfirmation")}
        cancelLabel={dialogContent === ERASE ? t("common.no") : t("common.cancel")}
        discardLabel={dialogContent === ERASE ? t("common.yes") : t("common.exit")}
        onCancel={handleDismiss}
        onDiscard={() => {
          if (dialogContent === ERASE) {
            canvasData && canvasData.clear();
            handleAccept();
          } else {
            handleDiscardPopup();
          }
        }}
      />
    );
  };

  return (
    <div className="field">
      {renderConfirmDialog()}
      <DrawingModalView {...props} />
      <Fragment>
        <FormLabel className={fieldText} component="article" id={name} error={error}>
          <CustomFieldLabel label={label} fieldId={name} />
        </FormLabel>
        {!value && disabled && (
          <FormLabel component="article" className={emptyFieldText}>
            {t("canvas.noSignature") as string}
          </FormLabel>
        )}
        <ThumbnailView {...props} />
        {/* show field error if exists */}
        {renderErrorMessage()}
      </Fragment>
    </div>
  );
};

export default DrawingFieldView;
