import {
  RadioComponent,
  FormulaComponent,
  TextComponent,
  BoxItem,
  BoxContainer,
  DropdownComponent,
  DocumentComponent,
  ErrorMessageComponent,
  CheckBoxComponent,
  ExpressionComponent,
  DrawingComponent,
  PictureComponent,
  GpsComponent,
  WebhookButtonComponent,
  PhoneNumberComponent,
  MultiBoxItem,
  UploadComponent,
} from "./GrapesForm/FormFields";
import Outcomes from "./GrapesForm/Outcomes";

export const fields = {
  RadioComponent: RadioComponent,
  FormulaComponent: FormulaComponent,
  TextComponent: TextComponent,
  DropdownComponent: DropdownComponent,
  DocumentComponent: DocumentComponent,
  ErrorMessageComponent: ErrorMessageComponent,
  CheckBoxComponent: CheckBoxComponent,
  ExpressionComponent: ExpressionComponent,
  DrawingComponent: DrawingComponent,
  PictureComponent: PictureComponent,
  GpsComponent: GpsComponent,
  UploadComponent: UploadComponent,
  BoxContainer: BoxContainer,
  BoxItem: BoxItem,
  Outcomes: Outcomes,
  WebhookButtonComponent: WebhookButtonComponent,
  PhoneNumberComponent: PhoneNumberComponent,
  MultiBoxItem: MultiBoxItem,
};
