// import { createMuiTheme } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import { ERROR_MESSAGE_TEXT, black, cobalt, grey, lightGreen, limeGreen, white } from "../../theme";


export const combinedUserTaskStyle = (assigne: boolean) => ({
  gridStyle: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    flexFlow: "wrap",
    flexDirection: "column",
  },
})

export const formItemStyles = (isInvalid: boolean) => ({
  formItem: {
    padding: "16px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
  },
  errorIconFlex: {
    display: 'flex',
  },
  divider: {
    padding: "0px",
  },
  formTitle: {
    color: isInvalid ? ERROR_MESSAGE_TEXT : black,
  },
  errorIcon: {
    width: "18px",
    height: "18px",
    alignSelf: "center",
    color: ERROR_MESSAGE_TEXT,
    paddingRight: "6px"
  },
  forwardIcon: {
    color: grey,
    width: "20px",
    height: "20px",
    cursor: "pointer",
  },
  readOnlyIcon: {
    color: cobalt,
    cursor: "pointer",
  },
  autoFillText: {
    color: limeGreen,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "normal"
  },
  autoFillBox: {
    display: "flex",
    borderRadius: "20px",
    backgroundColor: lightGreen,
    padding: "6px 12px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    marginLeft: "16px"
  }
});

export const formHeaderStyles = makeStyles(() => ({
  backArrowIcon: {
    color: "#163B75",
    cursor: "pointer",
  },
  iconButton: { padding: "16px", margin: "-4px -4px -4px -0.6em" },
  goBackButton: { textTransform: "unset" as any, marginLeft: "-8px", color: "#134A9F" },
  formHeaderWrapper: { marginBottom: "8px" },
  headerViewStyle: { paddingLeft: 24, paddingTop: 20, paddingBottom: 20, paddingRight: 24 }
}));

export const formListStyle = {
  formHelperTextError: { color: ERROR_MESSAGE_TEXT, fontSize: "16px", fontWeight: 400, paddingLeft: "24px"},
  topDivider: { marginLeft: "10px", marginRight: "10px", paddingBottom: "10px" },
  formListViewStyle: { paddingLeft: "24px", paddingRight: "24px" }
};
