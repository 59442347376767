import React from "react";
import { Grid } from "@material-ui/core";
import { formListStyle } from "./styles";

export const FormList = (props: any): JSX.Element => {
  return (
    <Grid container xs={12} lg={12} md={12} style={formListStyle.formListViewStyle}>
        {props.children}
    </Grid>
  );
};
