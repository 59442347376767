import { NUMERIC, TWO_DIGIT } from "../constants";

export interface ApiErrorResult {
  code: string;
  errors: string[];
}

export interface ApiResult<T> {
  error: ApiErrorResult;
  result: T;
}

export interface PagedResult<T> {
  content: T[];
  page: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
  totalAll: number;
  totalNew: number;
  totalToday: number;
  totalThisWeek: number;
  totalOverdue: number;
  elementsInPage: number;
  lastPage: boolean;
  firstPage: boolean;
}

export interface PagedApiResult<T> {
  error: ApiErrorResult | null;
  result?: PagedResult<T> | null;
}

export interface SortParams {
  name: string;
  direction: "asc" | "desc" | "none";
}

type DateOptionsType = typeof TWO_DIGIT | typeof NUMERIC | undefined;

export interface DateOptions {
  day: DateOptionsType;
  month: DateOptionsType;
  year: DateOptionsType;
}

export interface FilterType {
  type: "dropdown" | "checkbox" | "multiselect" | "textField" | "custom";
}

export enum InputVariantType {
  OUTLINED = "outlined",
}
