// @flow strict
import { AnyAction, createReducer } from "redux-starter-kit";
import { call, put, takeLatest } from "redux-saga/effects";
import { TypedAction, Task } from "../types";
import Log from "../../core/Log";
import { setTasks, fetchCompletedTasksByProcessInstanceId } from "../actions";
import Dictionary from "../../collections/Dictionary";
import { fetchForCompletedTasksByProcessInstanceId } from "../../services/TaskService";
import { fetchWrapper } from "../../core/fetchWrapper";

const taskReducer = createReducer<Dictionary<Task>, AnyAction>(
  {},
  {
    [setTasks.type]: (state: Dictionary<Task>, action: TypedAction<Task[]>) => {
      if (!(action && action.payload)) {
        return state;
      }

      const payload = action.payload;
      payload.forEach((task: Task): void => {
        state[task.id] = task;
      });
      return state;
    },
    [fetchCompletedTasksByProcessInstanceId.type]: (state: Dictionary<Task>) => {
      return state;
    },
  }
);

export default taskReducer;

export function* getCompletedTasksByProcessInstanceId(action: TypedAction<any>): any {
  try {
    const response: Response = yield call(fetchWrapper, fetchForCompletedTasksByProcessInstanceId, action.payload);
    if (response.status === 200) {
      const result: any = yield response.json();
      yield put({ type: [setTasks.type], payload: result.data });
    } else {
      throw new Error("Status: " + response.status);
    }
  } catch (error) {
    Log.error(error);
  }
}

export function* watchGetCompletedTasksByProcessInstanceId(): any {
  yield takeLatest([fetchCompletedTasksByProcessInstanceId.type], getCompletedTasksByProcessInstanceId);
}
