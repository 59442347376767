import React from "react";
import { useTranslation } from "react-i18next";
import Dialog, { DialogTitle, DialogContent, DialogFooter, DialogButton } from "@material/react-dialog";
import LinearProgress from "@material/react-linear-progress";

interface OwnProps {
  onClose?: () => void;
  open: boolean;
  title: string;
}

export default function FullScreenLoader({ onClose, open, title }: OwnProps) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <LinearProgress indeterminate />
      </DialogContent>
      <DialogFooter>
        <DialogButton action="dismiss" isDefault>
          {t("common.close")}
        </DialogButton>
      </DialogFooter>
    </Dialog>
  );
}
