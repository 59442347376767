import { GrapesForm } from "../../../types/formtypes";

//TODO Check how to move these types to library
export interface CombinedUserTaskConfiguration {
  id: string;
  name: string;
  version: number;
  definition?: CombinedUserTaskDefinition;
  otherData?: CombinedUserTaskOtherData;
  created: Date | null;
  updated: Date | null;
}

export interface CombinedUserTaskDefinition {
  sections: CombinedUserTaskSectionDefinition[];
}

export enum CombinedUserTaskStepType {
  FORM = "FORM",
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CombinedUserTaskOtherData {}

export interface CombinedUserTaskFormStepDefinition {
  formKey: string;
  formId: string;
  hasVisibilityCondition: boolean;
  visibilityCondition: string;
}

export interface CombinedUserTaskStepDefinition<T> {
  stepType: CombinedUserTaskStepType;
  stepName: string;
  stepDefinition: T;
}

export interface CombinedUserTaskSectionDefinition {
  sectionName: string;
  steps: CombinedUserTaskStepDefinition<CombinedUserTaskFormStepDefinition>[];
}

export interface CombinedUserTaskModelData {
  combinedUserTask: CombinedUserTaskConfiguration;
  grapesForms: GrapesForm[];
}
