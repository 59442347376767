import { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

function sendPageView(location) {
  ReactGA.pageview(location.pathname);
}

function GAListener({ children, history, location }) {
  useEffect(() => {
    ReactGA.initialize(window.REACT_APP_GOOGLE_ANALYTICS_ID);
    sendPageView(location);
    return history.listen(sendPageView);
  }, []);

  return children;
}

GAListener.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }),
};

export default withRouter(GAListener);
