import { AnyAction, createReducer } from "redux-starter-kit";
import { TaskForm, TypedAction } from "../types";
import Log from "../../core/Log";
import { call, put, takeEvery } from "redux-saga/effects";
import { setTaskForm, fetchTaskForm } from "../actions";
import Dictionary from "../../collections/Dictionary";
import { fetchForTaskForm } from "../../services/TaskService";
import { fetchWrapper } from "../../core/fetchWrapper";

const taskFormReducer = createReducer<Dictionary<TaskForm>, AnyAction>(
  {},
  {
    [setTaskForm.type]: (state: Dictionary<TaskForm>, action: TypedAction<TaskForm>) => {
      if (!(action && action.payload)) {
        return state;
      }

      const payload = action.payload;

      state[payload.taskId] = payload;

      return state;
    },
    [fetchTaskForm.type]: (state: Dictionary<TaskForm>) => {
      return state;
    },
  }
);

export default taskFormReducer;

export function* getTaskForm(action: TypedAction<any>): any {
  try {
    const response: Response = yield call(fetchWrapper, fetchForTaskForm, action.payload);
    if (response.status === 200) {
      const body: any = yield response.json();
      yield put({ type: [setTaskForm.type], payload: { ...body, taskId: action.payload } });
    } else {
      throw new Error("Status: " + response.status);
    }
  } catch (error) {
    Log.error(error);
  }
}

export function* watchGetTaskForm(): any {
  yield takeEvery([fetchTaskForm.type], getTaskForm);
}
