import React from "react";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import { withTranslation } from "react-i18next";
import { getDrawerStatus } from "../../../../../redux/selectors/drawerSelector";
import { setDrawerStatus } from "../../../../../redux/actions";
import { errorMessageFieldMainContainerStyle, iconTextStyle, textContainerStyle, paperContainerStyle } from "./styles";
import {
  ERROR_MESSAGE_TEXT,
  ALERT_MESSAGE_TEXT,
  ALERT_MESSAGE_BACKGROUND,
  INFO_MESSAGE_BACKGROUND,
  INFO_MESSAGE_TEXT,
  SUCCESS_MESSAGE_FORM_BACKGROUND,
  SUCCESS_MESSAGE_FORM_TEXT,
  ERROR_MESSAGE_BACKGROUND,
  NONE_MESSAGE_BACKGROUND,
  NONE_MESSAGE_TEXT,
  ERROR_MESSAGE_BORDER,
  ALERT_MESSAGE_BORDER,
  INFO_MESSAGE_BORDER,
  NONE_MESSAGE_BORDER,
  SUCCESS_MESSAGE_FORM_BORDER,
} from "../../../../theme";

import { ALERT, ERROR, ERROR_FORM, INFO, SUCCESS_FORM, NONE } from "./constants";

const ErrorMessageFieldView = (props: any) => {
  let { getErrorMessage, t, typeOfMessage } = props;

  let { messageIfFalse, messageIfTrue, isValid, value } = getErrorMessage(); /* get Expression value, messages and validity */

  const fieldClassName = value ? SUCCESS_FORM : !isValid ? ERROR_FORM : `${typeOfMessage} form`;
  const fieldValue = value ? (messageIfTrue as any) : !isValid ? t("formula.invalidExpression") : (messageIfFalse as any);

  let background = NONE_MESSAGE_BACKGROUND;
  let fontColor = NONE_MESSAGE_TEXT;
  let borderColor = NONE_MESSAGE_BORDER;

  if (typeOfMessage === ERROR || fieldClassName === ERROR_FORM) {
    background = ERROR_MESSAGE_BACKGROUND;
    fontColor = ERROR_MESSAGE_TEXT;
    borderColor = ERROR_MESSAGE_BORDER;
  }
  if (typeOfMessage === ALERT) {
    background = ALERT_MESSAGE_BACKGROUND;
    fontColor = ALERT_MESSAGE_TEXT;
    borderColor = ALERT_MESSAGE_BORDER;
  }

  if (typeOfMessage === INFO) {
    background = INFO_MESSAGE_BACKGROUND;
    fontColor = INFO_MESSAGE_TEXT;
    borderColor = INFO_MESSAGE_BORDER;
  }

  if (fieldClassName === SUCCESS_FORM) {
    background = SUCCESS_MESSAGE_FORM_BACKGROUND;
    fontColor = SUCCESS_MESSAGE_FORM_TEXT;
    borderColor = SUCCESS_MESSAGE_FORM_BORDER;
  }

  const classNameForMainDiv = props.drawerStatus ? "error-message-field-drawer-open" : "error-message-field-drawer-close";

  const renderErrorIcon = (): JSX.Element => {
    if (typeOfMessage === ALERT) {
      if (value) {
        return <InfoIcon style={iconTextStyle(fontColor, typeOfMessage) as Object} />;
      }
      return <WarningIcon style={iconTextStyle(fontColor, typeOfMessage) as Object} />;
    }
    if (typeOfMessage === NONE || !typeOfMessage) {
      return <React.Fragment></React.Fragment>;
    }
    return <InfoIcon style={iconTextStyle(fontColor, typeOfMessage, value) as Object} />;
  };

  return (
    /* Error Message Field */
    <div className={classNameForMainDiv}>
      <div className="flex">
        <Paper elevation={0} style={paperContainerStyle}>
          <div style={errorMessageFieldMainContainerStyle(background, fontColor, borderColor) as Object}>
            {renderErrorIcon()}
            <div style={textContainerStyle as object}>
              <div data-testid="errorMessageField" style={iconTextStyle(fontColor, typeOfMessage, true) as Object}>
                {fieldValue}
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch: any) {
  const actions = {
    setDrawerStatus: (status: any) => dispatch(setDrawerStatus(status)),
  };
  return actions;
}

function mapStateToProps(state: any) {
  const drawerStatus = getDrawerStatus(state);
  return {
    drawerStatus,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ErrorMessageFieldView) as any) as any;
