import React from "react";
import { GpsField } from "@juakali/juakali-forms-react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core";
import GpsFieldView from "./GpsFieldView";
import { gpsFieldStyles } from "../../styles";

class GpsComponent extends GpsField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <GpsFieldView {...this} />;
  }
}

const wrappedGpsComponent = withStyles(gpsFieldStyles)(GpsComponent as any);
export default withTranslation()(wrappedGpsComponent as any);
