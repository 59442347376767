import { createMuiTheme } from "@material-ui/core/styles";

export const multilineTheme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      outlined: {
        "&$shrink": {
          transform: "translate(14px, calc(-75% + 6px)) scale(0.75) !important",
        },
      },
    },
  },
});
