import React, { useRef, useState } from "react";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import { Box, FormHelperText, Paper, TextField, useMediaQuery, createMuiTheme } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import { phoneNumberFieldStyles } from "./styles";
import { getFieldWidth } from "../../../../../util/helper";
import { InputVariantType } from "../../../../../types/commonTypes";
import CustomFieldLabel from "../CustomFieldLabel";

export default function PhoneNumberView(props: any) {
  let {
    inputMask,
    isFigures,
    name,
    getActiveStatus,
    getError,
    getInvalidStatus,
    getLabel,
    getPlaceholder,
    getRequiredStatus,
    getValue,
    onChange,
  } = props;

  let isInvalid = getInvalidStatus(); /* get invalid status */
  let label = getLabel(); /* get form field label */
  let value = getValue(); /* get form field value */
  let error = getError(); /* get form field error */
  let isDisabled = getActiveStatus(); /* get active status */
  let placeholder = getPlaceholder(); /* get form field placeholder */
  let required = getRequiredStatus();
  const { t } = props.props as any;

  const [showToolTip, setShowToolTip] = useState(false);
  const phoneNumberFieldRef = useRef<any>(null);
  const theme = createMuiTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("xs"));
  const isTabView = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktopView = !(isMobileView || isTabView);
  const fieldWidth = getFieldWidth(phoneNumberFieldRef, isMobileView);

  /* Stylesheet for phone Number Field */
  const { callBlock, call, callButton, errorBlock, errorOuterBlock, errorPaper, errorText, rectangle } = phoneNumberFieldStyles(
    fieldWidth,
    isInvalid,
    showToolTip
  );

  /* handles input value changes */
  const handleChange = (e: any): void => {
    !isDesktopView && setShowToolTip(false);
    onChange(e.target.value);
  };

  const phoneFieldProps = {
    name,
    value,
    label: <CustomFieldLabel label={label} fieldId={name} />,
    required,
    placeholder,
    autoComplete: "nope",
    variant: InputVariantType.OUTLINED,
    error: !!error,
    fullWidth: true,
    InputLabelProps: { shrink: true },
    InputProps: {
      readOnly: isDisabled,
      disabled: isDisabled,
    },
    inputProps: {
      max: "",
    },
  };

  var phoneTextField = (): JSX.Element => {
    return isFigures ? (
      <NumberFormat
        {...phoneFieldProps}
        customInput={TextField}
        onValueChange={values => {
          !isDesktopView && setShowToolTip(false);
          onChange(values.value);
        }}
      />
    ) : (
      <TextField {...phoneFieldProps} onChange={handleChange} />
    );
  };

  var telephoneIcon = (): JSX.Element => {
    return <CallIcon style={call} />;
  };

  var telephoneButton = (): JSX.Element => {
    return (
      <Box
        style={callButton}
        onMouseEnter={e => {
          isDesktopView && isInvalid && setShowToolTip(true);
        }}
        onMouseLeave={e => {
          isDesktopView && setShowToolTip(false);
        }}
        onClick={() => {
          !isDesktopView && isInvalid && setShowToolTip(true);
        }}
      >
        {isInvalid ? (
          telephoneIcon()
        ) : (
          <a href={`tel:${value}`} data-testid="telephoneLink">
            {telephoneIcon()}
          </a>
        )}
      </Box>
    );
  };

  var invalidPhoneNumberToolTip = (): JSX.Element => {
    return (
      <div style={errorOuterBlock}>
        <div style={errorBlock}>
          <Paper style={errorPaper}>
            <Box style={rectangle}></Box>
            <span style={errorText}>{t("phoneNumber.tooltip", { name })}</span>
          </Paper>
        </div>
      </div>
    );
  };

  return (
    <div className="field" ref={phoneNumberFieldRef}>
      <div className="flex" style={callBlock}>
        {inputMask ? (
          /* Input mask Field */
          <InputMask required={required} mask={inputMask} value={value} onChange={handleChange}>
            {() => phoneTextField()}
          </InputMask>
        ) : (
          phoneTextField()
        )}
        {telephoneButton()}
      </div>
      {invalidPhoneNumberToolTip()}
      {/* show field error if exists */}
      {error && (
        <FormHelperText key={name} className="errorMessage" data-testid="phoneError">
          {t(`validation.${error.type}`, error.params)}
        </FormHelperText>
      )}
    </div>
  );
}
