import { Button, IconButton, Paper, Popover, Tooltip, Typography } from "@material-ui/core";
import { FiberManualRecord, NotificationsNoneRounded } from "@material-ui/icons";
import { ListItem } from "@material/react-list";
import React, { Fragment } from "react";
import NotificationNone from "./NotificationNone";
import { TaskNotification } from "../../../types/taskQueryTypes";
import { cobalt } from "../../theme";
import { notificationStyles } from "../styles";
import { WithTranslation, withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { checkAndUpdateTitle } from "./util";

interface OwnProps extends WithTranslation {
  count: number;
  notification: TaskNotification;
  onClickAssignedTask: () => void;
  onClickCandidateTask: () => void;
  onClose: () => void;
  onOpen: () => void;
  open: boolean;
  t: i18n.TFunction;
}

function NotificationView(props: OwnProps) {
  const { count, notification, onClickAssignedTask, onClickCandidateTask, onClose, onOpen, open, t } = props;
  const {
    button,
    circle,
    countBody,
    countText,
    listBody,
    listButtonBody,
    listText,
    noNotificationBody,
    noNotificationText,
    notificationCount,
    notificationIcon,
    paper,
    popup,
    titleBody,
    titleContent,
    titleText,
    unreadText,
  } = notificationStyles(count);
  const totalNotification = count > 9 ? "9+" : count;
  checkAndUpdateTitle(totalNotification);
  const { assignedTasks, candidateTasks } = notification;

  const listContent = (info: string, onClick: () => void) => (
    <ListItem style={listBody} onClick={onClick} data-testid="listItem">
      <Typography variant="h6" style={listText}>
        {info}
      </Typography>
      <div style={listButtonBody}>
        <Button variant="contained" onClick={onClick} style={button}>
          {t("common.new")}
        </Button>
        <FiberManualRecord htmlColor={cobalt} fontSize="small" style={circle} data-testid="circle" />
      </div>
    </ListItem>
  );

  const renderNotificationContent = (
    <Fragment>
      {assignedTasks.count ? listContent(t("notification.assignedTaskText", { count: assignedTasks.count }), onClickAssignedTask) : null}
      {candidateTasks.count ? listContent(t("notification.candidateTaskText", { count: candidateTasks.count }), onClickCandidateTask) : null}
    </Fragment>
  );

  const renderEmptyNotificationContent = (
    <div style={noNotificationBody} data-testid="emptyNotification">
      <NotificationNone />
      <Typography variant="h6" style={noNotificationText}>
        {t("notification.noNotification")}
      </Typography>
    </div>
  );

  const renderNotificationPopUp = (
    <Popover
      id="open"
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      data-testid="popup"
      style={popup}
    >
      <Paper elevation={3} style={paper}>
        <div style={titleBody}>
          <Typography variant="h5" style={titleText}>
            {t("notification.notifications")}
          </Typography>
          <div style={titleContent}>
            <Typography variant="h3" style={countText}>
              ({count})
            </Typography>
            <Typography variant="h6" style={unreadText}>
              {t("notification.unreadNotification")}
            </Typography>
          </div>
        </div>
        {count ? renderNotificationContent : renderEmptyNotificationContent}
      </Paper>
    </Popover>
  );

  return (
    <Fragment>
      <Tooltip title={t("notification.tooltipTitle", { count }) as string}>
        <IconButton onClick={onOpen} style={notificationIcon}>
          <div style={countBody}>
            <NotificationsNoneRounded htmlColor={cobalt} data-testid="notificationIcon" />
            {totalNotification ? <div style={notificationCount}>{totalNotification}</div> : null}
          </div>
        </IconButton>
      </Tooltip>
      {renderNotificationPopUp}
    </Fragment>
  );
}

export default withTranslation()(NotificationView);
