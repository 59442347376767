import React from "react";
import { FormulaField } from "@juakali/juakali-forms-react";
import FormulaFieldView from "./FormulaFieldView";

class FormulaComponent extends FormulaField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <FormulaFieldView {...this} />;
  }
}

export default FormulaComponent;
