import React from "react";
export const GalleryIcon = (): JSX.Element => {
  return (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64.9441 32.5C64.9441 50.4493 50.4058 65 32.472 65C14.5382 65 0 50.4493 0 32.5C0 14.5507 14.5382 0 32.472 0C50.4058 0 64.9441 14.5507 64.9441 32.5Z"
        fill="#F2F7FF"
      />
      <path
        d="M40.2585 35.1668C42.0074 35.1668 43.4251 33.7491 43.4251 32.0002C43.4251 30.2513 42.0074 28.8335 40.2585 28.8335C38.5096 28.8335 37.0918 30.2513 37.0918 32.0002C37.0918 33.7491 38.5096 35.1668 40.2585 35.1668Z"
        fill="#FEE187"
      />
      <path
        d="M40.2585 35.1668C38.5124 35.1668 37.0918 33.7463 37.0918 32.0002C37.0918 30.2541 38.5124 28.8335 40.2585 28.8335C42.0045 28.8335 43.4251 30.2541 43.4251 32.0002C43.4252 33.7462 42.0046 35.1668 40.2585 35.1668ZM40.2585 30.3405C39.3433 30.3405 38.5989 31.085 38.5989 32.0001C38.5989 32.9151 39.3434 33.6596 40.2585 33.6596C41.1736 33.6596 41.918 32.9151 41.918 32.0001C41.918 31.085 41.1736 30.3405 40.2585 30.3405Z"
        fill="#FFC61B"
      />
      <path d="M40.6379 45.9063L32.6812 33.0183L22.9297 45.9063H40.6379Z" fill="#80D440" />
      <path
        d="M40.6379 46.92H22.9297C22.545 46.92 22.1934 46.7023 22.0221 46.3577C21.8508 46.0133 21.8891 45.6015 22.1213 45.2947L31.8728 32.4067C32.0746 32.1399 32.396 31.9901 32.7291 32.0058C33.0632 32.0216 33.3679 32.2012 33.5436 32.4857L41.5003 45.3737C41.6933 45.6864 41.702 46.0791 41.5231 46.4C41.3441 46.7212 41.0054 46.92 40.6379 46.92ZM24.9677 44.8927H38.8208L32.5964 34.8105L24.9677 44.8927Z"
        fill="#5F961D"
      />
      <path d="M50.3938 45.9066L42.4372 37.0857L34.4805 45.9066H50.3938Z" fill="#80D440" />
      <path
        d="M49.468 46.92H33.5547C33.1542 46.92 32.7912 46.6842 32.6285 46.3182C32.4658 45.9523 32.5337 45.5247 32.802 45.2274L40.7587 36.4065C40.9508 36.1933 41.2245 36.0718 41.5114 36.0718C41.7983 36.0718 42.0718 36.1934 42.2641 36.4065L50.2208 45.2274C50.4891 45.5248 50.557 45.9523 50.3944 46.3182C50.2315 46.6843 49.8685 46.92 49.468 46.92ZM35.8341 44.8928H47.1887L41.5114 38.5989L35.8341 44.8928Z"
        fill="#5F961D"
      />
      <path
        d="M49.4098 46.9572H22.0611C20.6338 46.9572 19.4727 45.7959 19.4727 44.3686V25.1057C19.4727 23.6784 20.6338 22.5171 22.0611 22.5171H49.4098C50.8371 22.5171 51.9983 23.6783 51.9983 25.1057V39.4624C51.9983 40.0223 51.5444 40.4761 50.9846 40.4761C50.4248 40.4761 49.9709 40.0223 49.9709 39.4624V25.1057C49.9709 24.7962 49.7192 24.5445 49.4098 24.5445H22.0611C21.7516 24.5445 21.4999 24.7963 21.4999 25.1057V44.3686C21.4999 44.6781 21.7516 44.9298 22.0611 44.9298H49.4098C49.9696 44.9298 50.4235 45.3837 50.4235 45.9435C50.4234 46.5033 49.9696 46.9572 49.4098 46.9572Z"
        fill="#3B8BC0"
      />
      <path
        d="M20.4899 25.1055C20.4899 24.2356 21.195 23.5306 22.0648 23.5306H45.514V19.6312C45.514 18.7614 44.8089 18.0564 43.9391 18.0564H16.5905C15.7207 18.0563 15.0156 18.7614 15.0156 19.6312V38.8941C15.0156 39.764 15.7207 40.469 16.5905 40.469H20.4899V25.1055Z"
        fill="#4EB9FF"
      />
      <path
        d="M20.488 41.4829H16.5885C15.1612 41.4829 14 40.3218 14 38.8945V19.6316C14 18.2043 15.1612 17.043 16.5886 17.043H43.9372C45.3645 17.043 46.5257 18.2042 46.5257 19.6316V23.531C46.5257 24.0909 46.0718 24.5447 45.512 24.5447H22.0628C21.7533 24.5447 21.5017 24.7965 21.5017 25.1059V40.4694C21.5017 41.0291 21.0477 41.4829 20.488 41.4829ZM16.5886 19.0703C16.2791 19.0703 16.0274 19.3222 16.0274 19.6316V38.8945C16.0274 39.2039 16.2792 39.4557 16.5886 39.4557H19.4744V25.1058C19.4744 23.6785 20.6355 22.5172 22.0628 22.5172H44.4983V19.6316C44.4983 19.3221 44.2467 19.0703 43.9372 19.0703H16.5886Z"
        fill="#3B8BC0"
      />
      <path
        d="M27.3905 28.273H20.4863C19.9266 28.273 19.4727 27.8191 19.4727 27.2593C19.4727 26.6995 19.9266 26.2456 20.4863 26.2456H27.3905C27.9502 26.2456 28.4041 26.6995 28.4041 27.2593C28.4041 27.8191 27.9503 28.273 27.3905 28.273Z"
        fill="#3B8BC0"
      />
      <path
        d="M25.6853 31.9221H20.4863C19.9266 31.9221 19.4727 31.4683 19.4727 30.9085C19.4727 30.3486 19.9266 29.8948 20.4863 29.8948H25.6853C26.245 29.8948 26.699 30.3486 26.699 30.9085C26.699 31.4683 26.245 31.9221 25.6853 31.9221Z"
        fill="#3B8BC0"
      />
    </svg>
  );
};
