import { fetchWrapper } from "../core/fetchWrapper";
import { fetchForObjectDefinition } from "./objectDefinitionReducer";
import { ObjectDefinition } from "./dataStoreTypes";
import { showNotification } from "../util/notification";
import i18n from "../i18n";

export const getObjectDefinitionHelper = (key: string, version: number, setObjectDefinition: any): void => {
  fetchWrapper(fetchForObjectDefinition, key, version).then(
    function(response: Response) {
      if (response.status === 200) {
        response.json().then((result: ObjectDefinition) => {
          result.remoteLoadingStatus = "fullyLoaded";
          setObjectDefinition(result);
        });
      } else {
        if (response.status !== 403 && response.status !== 401) {
          showNotification("error", response && response.statusText ? response.statusText : i18n.t("common.serverErrorTryAgain"));
        }
      }
    },
    function(errorResponse: any) {
      showNotification(
        "error",
        i18n.t("common.errorMessage", errorResponse && errorResponse.message ? errorResponse.message : i18n.t("common.serverErrorTryAgain"))
      );
    }
  );
};
