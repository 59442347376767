import React from "react";
import { cobalt, lightGrey, white } from "../theme";

interface ContentCopyIconProps {
  backgroundColor?: string;
  iconColor?: string;
}

const ContentCopyIcon = (props: ContentCopyIconProps): JSX.Element => {
  const { backgroundColor, iconColor } = props
  const bgColor = backgroundColor || lightGrey;
  const icon = iconColor || cobalt;
  
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle r="12" className="contentCopyIcon" transform="matrix(1 0 0 -1 12 12)" fill={bgColor} />
      <path
        transform="translate(5,5)"
        d="M9.33334 0.756104H2.33334C1.69167 0.756104 1.16667 1.2811 1.16667 1.92277V10.0894H2.33334V1.92277H9.33334V0.756104ZM11.0833 3.08944H4.66667C4.02501 3.08944 3.50001 3.61444 
          3.50001 4.2561V12.4228C3.50001 13.0644 4.02501 13.5894 4.66667 13.5894H11.0833C11.725 13.5894 12.25 13.0644 12.25 12.4228V4.2561C12.25 3.61444 11.725 3.08944 11.0833 
          3.08944ZM11.0833 12.4228H4.66667V4.2561H11.0833V12.4228Z"
        fill={icon}
      />
      <defs>
        <clipPath id="clip0_308_4853">
          <rect width="14" height="14" className="contentCopyIcon" fill={white} transform="translate(0 0.172852)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContentCopyIcon;
