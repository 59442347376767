import React, { Fragment } from "react";
import i18n from "i18next";
import { WithTranslation, withTranslation } from "react-i18next";
import { InputAdornment, TextField } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import InputMask from "react-input-mask";
import { validateDate, getFormattedDate } from "../utils/toolbarHelper";
import { FilterParams, DateErrorFormat } from "../../../types/filterTypes";

interface OwnProps {
  onFilterDataChange: (params: FilterParams) => void;
  handleSearch: () => void;
  dueDate?: string | null;
  dateFormatError?: DateErrorFormat | null;
  showError: boolean;
}

interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

class DateFilter extends React.Component<OwnProps, {}> {
  dateinputRef: any;
  textInput?: HTMLInputElement | null;
  constructor(props: OwnProps) {
    super(props);
  }

  /**
   * Triggered when date input changes from date picker dialog
   * @param e date input which will be stored in dd/mm/yyyy format
   */
  handleDateChange = (e: any) => {
    let { onFilterDataChange } = this.props;
    onFilterDataChange({ dueDate: e.format("DD/MM/YYYY"), dateFormatError: null });
  };

  /**
   * Triggered when date key-in changes from text input field
   * @param e date input which will be stored in dd/mm/yyyy format
   */
  onChange = (e: any) => {
    let { onFilterDataChange } = this.props;
    onFilterDataChange({ dueDate: e.target.value });
  };

  /**
   * Triggered when focus out from date text input field.
   * This Function validates the typed input format and apply 0 padding to
     the make it as dd/mm/yyy format if it correct or else stores the error code.
   * @param e date input which will be stored in dd/mm/yyyy format
   */
  onBlur = (e: any) => {
    let { onFilterDataChange } = this.props;
    if (e.target.value) {
      let v = validateDate(e.target.value, false);
      let { type, value } = v;
      if (type === "invalidDateFormat") {
        onFilterDataChange({ dateFormatError: v });
      } else {
        onFilterDataChange({ dueDate: value });
      }
    }
  };

  /**
   * Function used to apply the filter on clicking `Enter' key
   * @param e is used to find the keycode here.
   */
  onPressEnter = async (e: any) => {
    let { handleSearch } = this.props;
    if (e.keyCode == 13) {
      handleSearch();
    }
  };

  /**
   * Function used to Open the Date picker dialog
   */
  onDateIconClick = () => {
    this.dateinputRef && this.dateinputRef.open();
  };
  render(): JSX.Element {
    let { dueDate, dateFormatError, showError, t } = this.props;
    return (
      <Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            value={dueDate && !dateFormatError ? getFormattedDate(dueDate, "/") : ""}
            ref={(r: any) => (this.dateinputRef = r)}
            onChange={this.handleDateChange}
            disableOpenOnEnter
            animateYearScrolling={false}
            TextFieldComponent={() => <></>}
            id="datePicker"
          />
        </MuiPickersUtilsProvider>
        <InputMask mask={"99/99/9999"} value={dueDate ? dueDate : ""} onChange={this.onChange} onBlur={this.onBlur}>
          {() => (
            <TextField
              id={"date"}
              inputRef={r => (this.textInput = r)}
              error={showError ? !!dateFormatError : false}
              helperText={
                showError && dateFormatError
                  ? t(`dashboard.${dateFormatError.type}`, { value: dateFormatError.value, dateLimit: dateFormatError.dateLimit })
                  : ""
              }
              name={"dateFilter"}
              variant="outlined"
              label={t(`dashboard.dueDateBefore`)}
              value={dueDate ? dueDate : ""}
              placeholder="DD/MM/YYYY"
              onKeyDown={this.onPressEnter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" onClick={this.onDateIconClick}>
                    <DateRangeIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </InputMask>
      </Fragment>
    );
  }
}

export default withTranslation()(DateFilter);
