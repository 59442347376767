import { CloseRounded } from "@material-ui/icons";
import React, { useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ToggleList from "../common/ToggleList";
import { ProcessStarterDispatchProps, ProcessStarterStateProps } from "../../types/taskQueryTypes";
import { Box, Button, CircularProgress, Drawer, Grid, IconButton, MuiThemeProvider, Paper, Typography } from "@material-ui/core";
import { CANCEL_CREATE, CREATE_PROCESS_INSTANCE, ERROR, PROCESSINSTANCE } from "../../constants";
import { showNotification } from "../../util/notification";
import { defaultCreateProcessInstance } from "../../redux/types";
import ConfirmationDialogRaw from "../common/ConfirmationDialogRaw";
import FullScreenLoader from "../FullScreenLoader";
import { mapProcessStarterDispatchToProps, mapProcessStarterStateToProps } from "./utils/mapProps";
import { getProcessDefinitionItemList } from "./utils/dashboardHelper";
import EmptySvg from "../layout/notification/NotificationNone";
import { processStarterDialogTheme, processStarterStyles, processStarterTheme } from "./styles/dashboardStyles";

interface OwnProps extends RouteComponentProps {}

const ProcessStarter = (props: OwnProps & ProcessStarterDispatchProps & ProcessStarterStateProps): JSX.Element => {
  const { createProcessInstance, createProcessInstanceOnline, fetchAppDefinitions, history, isLoading, setCreateProcessInstance } = props;
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = () => (fetchAppDefinitions(), setOpenDialog(true));

  const handleDialogClose = () => setOpenDialog(open => !open);
  const listItems = getProcessDefinitionItemList({ ...props, handleDialogClose });
  const { button, closeIcon, emptyGrid, emptyText, header, headerContainer, iconButton, loader, loaderBox, paper } = processStarterStyles();

  const createNewProcessInstance = async () => {
    const processName = `${createProcessInstance.processName} - ${new Date().toISOString()}`;

    const response = await createProcessInstanceOnline({
      ...createProcessInstance,
      processName,
      askConfirmation: false,
    });

    response && response.data && response.data.id
      ? history.push(`process/instance/${response.data.id}`)
      : showNotification(ERROR, t("common.serverErrorTryAgain"));
  };

  const handleCancel = (): void => {
    ReactGA.event({
      category: PROCESSINSTANCE,
      action: CANCEL_CREATE,
      label: CANCEL_CREATE,
    });
    setCreateProcessInstance(defaultCreateProcessInstance);
  };

  const handleConfirm = (): void => {
    ReactGA.event({
      category: PROCESSINSTANCE,
      action: CREATE_PROCESS_INSTANCE,
      label: CREATE_PROCESS_INSTANCE,
    });
    createNewProcessInstance();
  };

  const renderProcessDrawer = (
    <Drawer
      anchor="right"
      keepMounted
      ModalProps={{
        BackdropProps: {
          onClick: handleDialogClose,
        },
      }}
      open={openDialog}
    >
      <Paper className={`mxf-3 myf-3 ${paper}`}>
        <Grid className={headerContainer} container>
          <Typography className={header} variant="h6">
            {t("dashboard.startNewProcess")}
          </Typography>
          <IconButton className={iconButton} onClick={handleDialogClose}>
            <CloseRounded className={closeIcon} />
          </IconButton>
        </Grid>
        {isLoading ? (
          <Box className={loaderBox}>
            <CircularProgress className={loader} />
          </Box>
        ) : listItems.length ? (
          listItems.map(({ header, items }, i) => <ToggleList header={header} items={items} key={i} />)
        ) : (
          <Grid className={emptyGrid}>
            <EmptySvg />
            <Typography className={emptyText} variant="h6">
              {t("dashboard.noProcessConfigured")}
            </Typography>
          </Grid>
        )}
      </Paper>
    </Drawer>
  );

  const renderStartButton = (
    <Button className={`${button} confirm-button`} onClick={handleClick} variant="contained">
      {t("dashboard.startNewProcess")}
    </Button>
  );

  return (
    <>
      <MuiThemeProvider theme={processStarterTheme}>
        {renderStartButton}
        {renderProcessDrawer}
      </MuiThemeProvider>
      <MuiThemeProvider theme={processStarterDialogTheme}>
        <ConfirmationDialogRaw
          cancelLabel={t("common.cancel")}
          confirmLabel={t("dashboard.createNew")}
          content={t("dashboard.createNewDialogContent", {
            processName: createProcessInstance.processName || "...",
          })}
          id={CREATE_PROCESS_INSTANCE}
          maxWidth="lg"
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          open={createProcessInstance.askConfirmation}
          title={t("dashboard.createNewDialogTitle", {
            processName: createProcessInstance.processName || "...",
          })}
        />
      </MuiThemeProvider>
      <FullScreenLoader open={createProcessInstance.isCreating} title={t("process.createNewProcess")} />
    </>
  );
};

export default connect(
  mapProcessStarterStateToProps,
  mapProcessStarterDispatchToProps
)(withRouter(ProcessStarter));
