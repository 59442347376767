import React from "react";
import i18n from "i18next";
import { WithTranslation, withTranslation } from "react-i18next";
import { Grid, Typography, Popover, Button, MuiThemeProvider } from "@material-ui/core";
import DateFilter from "./DateFitler";
import { getFormattedDate, validateDate } from "../utils/toolbarHelper";
import { TaskAssignment, TaskQueryFilterParams } from "../../../types/taskQueryTypes";
import { ClosePopoverParams, DateErrorFormat, FilterParams } from "../../../types/filterTypes";
import { dateFilterTheme, customFilterStyles } from "../styles/toolbarStyles";
import AssigneeFilter from "./AssigneeFilter";
import { JuakaliUser } from "../../../redux/types";
import { EMPTY } from "../../../constants";

interface OwnProps {
  isPopoverOpened: boolean;
  anchorEl?: HTMLAnchorElement | null;
  applyFilter: (params?: TaskQueryFilterParams) => void;
  handleClosePopover: (params: ClosePopoverParams) => void;
  defaultFilterParams?: TaskQueryFilterParams;
  users?: JuakaliUser[];
  assignment: string;
  isMobileView: boolean;
}

interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

interface OwnState {
  dueDate?: string | null;
  dateFormatError?: DateErrorFormat | null;
  showError: boolean;
  assignee: string;
}

const defaultState = {
  dueDate: null,
  dateFormatError: null,
  showError: false,
  assignee: EMPTY,
};

class CustomFilterDialog extends React.Component<OwnProps, OwnState> {
  textInput?: HTMLInputElement | null;
  constructor(props: OwnProps) {
    super(props);
    this.state = defaultState;
  }

  /**
   * Function is for apply the custom filter params
   * @param params search filter parameters
   */
  onFilterDataChange = (params: FilterParams) => {
    this.setState({ ...this.state, ...params });
  };

  /**
   * apply filters when validation passed
   */
  handleSearch = () => {
    let { handleClosePopover, applyFilter } = this.props;
    let { dueDate, assignee } = this.state;
    // validates the date format
    let validatedResult = dueDate && dueDate.replace(/[/_]/g, EMPTY) ? validateDate(dueDate, true) : { type: EMPTY };
    let { type, value } = validatedResult;
    // show the error when validation fails
    if (type && type != "result") {
      this.setState({ dateFormatError: validatedResult, showError: true });
    } else {
      dueDate = value;
      this.setState({
        dateFormatError: null,
        showError: false,
      });
      handleClosePopover({ isPopoverOpened: false });
      // filter applied when validation passed
      return applyFilter({ dueDate: getFormattedDate(dueDate, "-"), assignee });
    }
  };

  /**
   * set default parameters for filters related parameters when closing & opening the custom filter dialog
   */
  setDefaultParams = () => {
    let { defaultFilterParams } = this.props;
    let { dueDate, assignee } = defaultFilterParams ? defaultFilterParams : { dueDate: null, assignee: EMPTY };
    this.setState({
      ...defaultState,
      dueDate: getFormattedDate(dueDate, EMPTY),
      assignee: assignee || EMPTY,
    });
  };

  /**
   * closes the custom filter dialog
   */
  onCancel = () => {
    let { handleClosePopover } = this.props;
    this.setDefaultParams();
    handleClosePopover({ isPopoverOpened: false, anchorEl: null });
  };

  /**
   * set default filter params when opening the filter dialog
   */
  onEnter = () => {
    this.setDefaultParams();
  };

  handleReset = () => {
    this.setState(defaultState);
  };

  render(): JSX.Element {
    const { t, isPopoverOpened, anchorEl, users, assignment, isMobileView } = this.props;
    let { dueDate, dateFormatError, showError, assignee } = this.state;
    let { actions, assigneeBody, filterText, contentBody, resetButton } = customFilterStyles(isMobileView);

    return (
      <MuiThemeProvider theme={dateFilterTheme}>
        <Grid>
          {isPopoverOpened ? (
            <Popover
              open={isPopoverOpened}
              anchorEl={anchorEl}
              onEnter={this.onEnter}
              onClose={this.onCancel}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              id="popOver"
            >
              <Grid className="px-2 py-2">
                <Grid justify="space-between" alignItems="center" style={filterText}>
                  <Typography variant="h6">{t(`dashboard.filters`)}</Typography>
                  <Button onClick={this.handleReset} key="resetFitler" variant="text" style={resetButton}>
                    {t(`dashboard.resetFilter`)}
                  </Button>
                </Grid>
                <Grid style={contentBody} direction={isMobileView ? "column" : "row"}>
                  <Grid className={assignment === TaskAssignment.TEAM ? "fullWidth" : undefined}>
                    <DateFilter
                      dueDate={dueDate}
                      handleSearch={this.handleSearch}
                      onFilterDataChange={this.onFilterDataChange}
                      showError={showError}
                      dateFormatError={dateFormatError}
                    />
                  </Grid>
                  {assignment === TaskAssignment.TEAM ? (
                    <Grid className="fullWidth" style={assigneeBody}>
                      <AssigneeFilter assignee={assignee} onFilterDataChange={this.onFilterDataChange} users={users || []} />
                    </Grid>
                  ) : (
                    undefined
                  )}
                </Grid>
                <Grid style={actions}>
                  <Button className="cancel-button" onClick={this.onCancel} key="cancel" variant="contained">
                    {t(`common.cancel`)}
                  </Button>
                  <Button className="confirm-button ml-1" onClick={this.handleSearch} key="filter" variant="contained">
                    {t(`dashboard.filter`)}
                  </Button>
                </Grid>
              </Grid>
            </Popover>
          ) : (
            undefined
          )}
        </Grid>
      </MuiThemeProvider>
    );
  }
}

export default withTranslation()(CustomFilterDialog);
