import { Document, DocumentParams, StateType } from "../types";
import { getAll } from "../../collections/Dictionary";
import { DOCUMENT, DOCX, SHEET, TASKS, XLSX } from "../../constants";
var FileSaver = require("file-saver");

export const getDocuments = (store: StateType): Document[] => {
  if (store.documents) {
    return getAll(store.documents.Documents);
  }
  return [];
};

export const getDocumentName = (documentId: string, documents: Document[]): string | undefined => {
  if (documents && documents.length) {
    return (documents.find(document => document.id == documentId) || {}).name;
  }
  return undefined;
};

export const downloadFile = (body: any, params: DocumentParams): void => {
  const { label, type } = params;
  const mimeType = body.type && body.type.split(".")[body.type.split(".").length - 1];
  const extension = mimeType === SHEET ? XLSX : mimeType === DOCUMENT ? DOCX : "";
  const currentDate = new Date().toJSON();
  FileSaver.saveAs(body, `${type == TASKS ? currentDate.split("T")[0] + " " + label : currentDate}.${extension}`);
};
