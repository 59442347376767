import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Fetcher from "../../components/Fetcher";
import { latestProcessDefinition } from "../../services/ProcessDefinitionService";
import { fetchWrapper } from "../../core/fetchWrapper";

const TYPES = {
  LOAN: "loan",
  CUSTOMER: "customer",
  warn: "orange",
  error: "red",
};

export default function ProcessEngineView(props) {
  const { t } = useTranslation();

  const appDefinitionKey = props.match.params.appDefinitionKey;
  const type = props.match.params.type;

  const [processDefinition, setProcessDefinition] = useState({});
  const [newProcessName, setNewProcessName] = useState("");

  // set labels based on type
  const lblNewProcess = type === TYPES.LOAN ? t("process.startNewLoan") : t("process.startNewCustomer");

  function getProcessDefinitions() {
    var promise = fetchWrapper(latestProcessDefinition, appDefinitionKey);
    return promise.then(res => {
      return res.json();
    });
  }

  function getProcessDefinitionsCallback(result) {
    if (result && result.size === 1 && result.data && result.data.length === 1) {
      // it seems we have a process def
      setProcessDefinition(result.data[0]);
    }
  }

  function handleTextChange(event) {
    if (event.target.name === "newProcessName") {
      setNewProcessName(event.target.value);
    }
  }

  return (
    <div className="row mt-5">
      <div className="col-lg-8 col-md-12">
        <div className="card">
          <h5 className="card-header h5">
            {t("process.newApplication")} : {processDefinition.name}
          </h5>
          <div className="card-body">
            <Fetcher fetch={getProcessDefinitions} callback={getProcessDefinitionsCallback} failedToLoad={t("process.failedToLoadProcesses")}>
              {t("login.username")}: <br />
              <input type="text" name="newProcessName" value={newProcessName} onChange={handleTextChange} />
              <a href="#!" className="btn btn-primary ml-4">
                {lblNewProcess}
              </a>
            </Fetcher>
            <h5 className="card-title">Special title treatment</h5>
            <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
