import React, { Fragment, useState } from "react";
import { Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Done, Remove, Visibility, VisibilityOff } from "@material-ui/icons";
import ReactButton from "@material/react-button";
import { WithTranslation, withTranslation } from "react-i18next";
import { defaultPasswordError, getPasswordInputRules, validatePassword } from "./utils";
import { PasswordError, PasswordSettingParameters } from "../../types/passwordTypes";
import clsx from "clsx";
import { cobalt } from "../theme";
import { passwordStyles } from "./styles";
import i18n from "../../i18n";

interface OwnProps {
  email: String;
  fullScreen: boolean;
  handleAccountClick: () => void;
  handleChange: any;
  errors: any;
  isSubmitting: boolean;
  isChangePasswordPage: boolean;
  settingParams: PasswordSettingParameters;
  setPasswordErrorChange: React.Dispatch<React.SetStateAction<PasswordError>>;
  values: any;
}

interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

const PasswordSettings = (props: OwnProps): JSX.Element => {
  const {
    email,
    errors,
    fullScreen,
    handleAccountClick,
    handleChange,
    isChangePasswordPage,
    isSubmitting,
    settingParams,
    setPasswordErrorChange,
    t,
    values,
  } = props;
  const {
    changeAccountButton,
    containerWrapper,
    informationText,
    marginButton,
    marginBodyText,
    marginDesktopRules,
    marginHeaderText,
    marginMobileChangeButton,
    marginMobileRules,
    restrictionTextWrapper,
    svgDoneIcon,
    svgIcon,
    svgRemoveIcon,
  } = passwordStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState<PasswordError>(defaultPasswordError);
  const PASSWORD = "password";

  const renderAccountButton = (): JSX.Element => {
    return (
      <Button className={changeAccountButton} onClick={handleAccountClick}>
        {t("changePassword.changeAccount")}
      </Button>
    );
  };

  const renderInformationText = (): JSX.Element => {
    return (
      <Grid item xl={4} lg={4} md={4}>
        <Typography variant="body1" className={informationText}>
          {isChangePasswordPage ? t("changePassword.information") : null}
          <b>{email}</b>
        </Typography>
      </Grid>
    );
  };

  const renderSubmitButton = (): JSX.Element => {
    return (
      <Grid item data-testid="changePassword" className={clsx(marginButton, fullScreen && marginMobileChangeButton)}>
        {isSubmitting ? (
          <CircularProgress data-testid="loader" />
        ) : (
          <ReactButton type="submit" raised>
            {isChangePasswordPage ? t("changePassword.changePassword") : t("resetPassword.resetPassword")}
          </ReactButton>
        )}
      </Grid>
    );
  };

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e: any): void => {
    if (e.target.name == PASSWORD) {
      const passwordErrors = validatePassword(settingParams, e.target.value);
      setPasswordErrors(passwordErrors);
      setPasswordErrorChange(passwordErrors);
    }
    handleChange(e);
  };

  const renderPasswordField = (): JSX.Element => {
    return (
      <TextField
        fullWidth={true}
        variant="outlined"
        label={t("changePassword.newPassword")}
        name={PASSWORD}
        error={!!errors.password}
        value={values.password}
        id={PASSWORD}
        onChange={handlePasswordChange}
        type={showPassword ? "text" : PASSWORD}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="Toggle password visibility" data-testid="passwordVisibility" onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff htmlColor={cobalt} /> : <Visibility htmlColor={cobalt} />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        helperText={errors.password}
        data-testid="newPassword"
      />
    );
  };

  const renderPasswordRules = (): JSX.Element => {
    return (
      <Grid item xl={6} lg={6} md={6} className={fullScreen ? marginMobileRules : marginDesktopRules}>
        {getPasswordInputRules(settingParams, passwordErrors, t).map((inputRule: any) => {
          const { text, type, conditionFulfilled } = inputRule;
          return text ? (
            <Grid key={text} className={clsx(restrictionTextWrapper, type == "header" ? marginHeaderText : marginBodyText)}>
              {type === "body" ? (
                conditionFulfilled ? (
                  <Done classes={{ root: clsx(svgIcon, svgDoneIcon) }} />
                ) : (
                  <Remove classes={{ root: clsx(svgIcon, svgRemoveIcon) }} />
                )
              ) : (
                undefined
              )}
              <Typography variant="body1">{text}</Typography>
            </Grid>
          ) : (
            undefined
          );
        })}
      </Grid>
    );
  };

  return (
    <Fragment>
      {renderInformationText()}
      {isChangePasswordPage && renderAccountButton()}
      <Grid container className={containerWrapper}>
        <Grid container direction="column" item xl={4} lg={4} md={4}>
          {renderPasswordField()}
          {fullScreen && renderPasswordRules()}
          {renderSubmitButton()}
        </Grid>
        {!fullScreen && renderPasswordRules()}
      </Grid>
    </Fragment>
  );
};

export default withTranslation()(PasswordSettings);
