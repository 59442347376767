import { UploadField } from "@juakali/juakali-forms-react";
import React from "react";
import UploadFieldView from "./UploadFieldView";

class UploadComponent extends UploadField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <UploadFieldView {...this} />;
  }
}
export default UploadComponent;



