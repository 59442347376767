var FileSaver = require("file-saver");
import { showNotification } from "../../../../../util/notification";
import i18n from "../../../../../i18n";
import { PDF_FORMAT, STATUS_CODES } from "../../../../../constants";

export const handleFileDownload = (url: string, label: string, setDownloadingStatus: any): void => {
  let fileType = label.split(".").pop();
  setDownloadingStatus(0);
  if (fileType == PDF_FORMAT) {
    var promise = fetch(url);
    promise
      .then((res: Response) =>{
        setDownloadingStatus(res.status);
        if(res.status == 200) {
          res.blob().then((blob: any) => {
            FileSaver.saveAs(blob, `${label}`);
          })
        }
      })
      .catch((error) => {
        const { message } = error as { message: any };
        const { SERVER_TOO_BUSY, NOT_AUTHENTICATED, FORBIDDEN } = STATUS_CODES;
        const notifyOnApiError = message != SERVER_TOO_BUSY && message != NOT_AUTHENTICATED && message != FORBIDDEN;
        setDownloadingStatus(message);
        notifyOnApiError ? showNotification("error", i18n.t("common.serverErrorTryAgain")) : undefined;
      });
  }
}