import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { Box, createMuiTheme, IconButton, MuiThemeProvider, Tooltip, useMediaQuery } from "@material-ui/core";
import { CombinedUserTaskConfiguration, fetchCombinedUserTaskModelData } from "../tasks/combinedusertask";
import { fetchWrapper } from "../../core/fetchWrapper";
import { fetchForAllDictionaries } from "../../services/DictionayService";
import { getGrapesJSForm } from "../../services/flow/FFormService";
import { REASSIGN_ERROR, SELECT_OPTION, REASSIGN_SUCCESS, CLEAR } from "../../constants";
import Spinner from "../common/Spinner";
import ErrorMessage from "../common/ErrorMessage";
import Log from "../../core/Log";
import { CUT, GRAPES_JS_FORM } from "./constants";
import ReadOnlyFormSection from "./ReadOnlyFormSection";
import { getCurrentAssignee } from "../Dashboard/utils/dashboardHelper";
import SelectAssignee from "./SelectAssignee";
import { JuakaliUser, ProcessInstance, ReassignParams } from "../../redux/types";
import { teamTaskDetailStyles, contentStyles } from "./styles";
import { TaskAssignment, TaskViewRepresentation } from "../../types/taskQueryTypes";
import { GrapesForm } from "../../types/formtypes";
import ForwardIcon from "../common/ForwardIcon";
import { fetchForProcessInstanceDetail, fetchForVariables } from "../../services/ProcessInstanceService";
import ConfirmationDialog, { ConfirmationDialogProps } from "../common/ConfirmationDialog";
import { defaultConfirmationPropertiesOnBackClick } from "./utils";
interface OwnProps {
  setTeamDetailView: (param: boolean) => void;
  teamTask: TaskViewRepresentation;
  assigneeParams: { assignee: JuakaliUser | undefined; setAssignee: (param: JuakaliUser | undefined) => void };
  currentTeamTaskParams: { currentTeamTask: any[] | null; setCurrentTeamTask: (param: any[] | null) => void };
  usersList: JuakaliUser[];
  reassignTask: (param: ReassignParams) => void;
  statusParams: { status: string; setStatus: (param: TaskAssignment) => void };
}
function TeamTaskDetailView(props: OwnProps): JSX.Element {
  const { t } = useTranslation(["translation", "flow"]);
  let { setTeamDetailView, teamTask, assigneeParams, currentTeamTaskParams, usersList, reassignTask, statusParams } = props;
  let { setCurrentTeamTask } = currentTeamTaskParams;
  let { status, setStatus } = statusParams;
  let { assignee, setAssignee } = assigneeParams;
  const [formData, setFormData] = useState<CombinedUserTaskConfiguration | GrapesForm | undefined>(undefined);
  const [dictionaries, setDictionaries] = useState([]);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState<ConfirmationDialogProps>(defaultConfirmationPropertiesOnBackClick());
  const [processInstance, setProcessInstance] = useState<ProcessInstance | undefined>(undefined);
  const [variables, setVariables] = useState(undefined);
  const [canChangeAssignee, changeAssigneeFlag] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const theme = createMuiTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("xs"));
  let formType = teamTask.formKey ? (teamTask.formKey.startsWith(GRAPES_JS_FORM) ? GRAPES_JS_FORM : teamTask.formKey.startsWith(CUT) ? CUT : "") : "";
  let formKey = teamTask.formKey
    ? teamTask.formKey.startsWith(GRAPES_JS_FORM)
      ? teamTask.formKey.replace(GRAPES_JS_FORM, "")
      : teamTask.formKey.startsWith(CUT)
      ? teamTask.formKey.replace(CUT, "")
      : ""
    : "";
  const resetState = (): void => {
    setTeamDetailView(false);
    setCurrentTeamTask(null);
    setStatus(TaskAssignment.TEAM);
    setAssignee(undefined);
    setConfirmationDialogProps(defaultConfirmationPropertiesOnBackClick());
  };
  const handleGoBack = (): void => {
    if (canChangeAssignee) {
      setConfirmationDialogProps({
        ...defaultConfirmationPropertiesOnBackClick(),
        content: t("confirmation.goBackContent", { name: teamTask ? teamTask.name : "" }),
        open: true,
        primaryHandler: () => {
          resetState();
        },
        secondaryHandler: () => {
          setConfirmationDialogProps(defaultConfirmationPropertiesOnBackClick());
        },
      });
    } else {
      resetState();
    }
  };
  const handleChangeAssignee = (_: object, value: any, reason: string): void => {
    if (reason === SELECT_OPTION) return setAssignee(value);
    else if (reason === CLEAR) return setAssignee(undefined);
  };
  const handleClearAssignee = (): void => {
    changeAssigneeFlag(false);
    setAssignee(undefined);
  };
  const handleSelectAssignee = (): void => {
    let fromUser = (getCurrentAssignee(teamTask.assignee, usersList, true) as string | undefined) || "";
    reassignTask({
      fromUser: fromUser,
      toUser: assignee ? assignee.email : fromUser,
      taskId: teamTask.id,
    });
    setLoader(true);
    changeAssigneeFlag(false);
  };
  /* Fetch Dictionaries */
  function loadDictionaries(): void {
    var promise = fetchWrapper(fetchForAllDictionaries);
    promise
      .then((res: any) => {
        if (res.status === 200) {
          return res.json();
        } else {
          if (res.status !== 401) {
            throw new Error();
          }
        }
      })
      .then((result: any) => {
        setDictionaries(result.data);
      })
      .catch((e: any) => {
        Log.error(e);
      });
  }
  /* fetch Form model data (SUT/CUT)*/
  function loadModelData(): void {
    var promise = null;
    if (formType === GRAPES_JS_FORM) {
      promise = fetchWrapper(getGrapesJSForm, formKey);
    } else if (formType === CUT) {
      promise = fetchWrapper(fetchCombinedUserTaskModelData, formKey);
    }
    promise &&
      promise
        .then((res: any) => {
          if (res.status === 200) {
            return res.json();
          } else {
            if (res.status !== 401) {
              throw new Error();
            }
          }
        })
        .then((result: any) => {
          setFormData(result);
        })
        .catch((e: any) => {
          Log.error(e);
        });
  }
  function loadProcessInstance(): void {
    var processInstanceDetailPromise = fetchWrapper(fetchForProcessInstanceDetail, teamTask.processInstanceId);
    processInstanceDetailPromise
      .then((res: any) => {
        if (res.status === 200) {
          return res.json();
        } else {
          if (res.status === 404) {
            // showNotification("error", t("tasks.taskNotFound"));
          } else if (res.status !== 401) {
            throw new Error();
          }
        }
      })
      .then((result: any) => {
        setProcessInstance(result.result);
      })
      .catch(() => {
        // showNotification("error", t("common.serverErrorTryAgain"));
      });
  }
  function loadVariables(): void {
    var variablesPromise = fetchWrapper(fetchForVariables, teamTask.id);
    variablesPromise
      .then((res: any) => {
        if (res.status === 200) {
          return res.json();
        } else {
          if (res.status === 404) {
            // showNotification("error", t("tasks.taskNotFound"));
          } else if (res.status !== 401) {
            throw new Error();
          }
        }
      })
      .then((response: any) => {
        setVariables(response.result);
      })
      .catch(() => {
        // showNotification("error", t("common.serverErrorTryAgain"));
      });
  }
  useEffect(() => {
    loadDictionaries();
    loadModelData();
    loadProcessInstance();
    loadVariables();
  }, []);
  useEffect(() => {
    if (status === REASSIGN_SUCCESS) {
      changeAssigneeFlag(false);
      setLoader(false);
      resetState();
    } else if (status === REASSIGN_ERROR) {
      changeAssigneeFlag(true);
      setLoader(false);
    }
  }, [status]);
  let { paperElevation, iconButton } = contentStyles(isMobileView);
  const taskDetails = {
    ...teamTask,
    processDefinitionName: (processInstance && processInstance.name) || (processInstance && processInstance.processDefinitionName),
    assigneeName: assignee ? assignee.displayName : teamTask.assigneeName,
  };
  const assigneeComponent = () => {
    return {
      canChangeAssignee: canChangeAssignee,
      icon: (
        <Tooltip title={t("dashboard.changeAssignee") as string}>
          <IconButton
            onClick={() => {
              changeAssigneeFlag(true);
            }}
            style={iconButton}
          >
            <ForwardIcon />
          </IconButton>
        </Tooltip>
      ),
      select: (
        <SelectAssignee
          onChange={handleChangeAssignee}
          usersList={usersList}
          defaultValue={getCurrentAssignee(assignee ? assignee.id : teamTask.assignee, usersList) as JuakaliUser | undefined}
          onSelect={handleSelectAssignee}
          onClear={handleClearAssignee}
        />
      ),
    };
  };

  return (
    <MuiThemeProvider theme={teamTaskDetailStyles(isMobileView)}>
      <Spinner open={isLoading} />
      {teamTask && processInstance ? (
        <Box style={paperElevation}>
          <ErrorMessage
            errorMessage={status === REASSIGN_ERROR ? t(`dashboard.reassignError`) : ""}
            handleClear={() => setStatus(TaskAssignment.TEAM)}
          />
          {formData && variables ? (
            <ReadOnlyFormSection
              handleGoBackOnTeamView={handleGoBack}
              taskDetails={taskDetails}
              processName={processInstance.processDefinitionName}
              processInstance={processInstance}
              assigneeComponent={assigneeComponent()}
              formType={formType}
              teamTask={teamTask}
              formData={formData}
              dictionaries={dictionaries}
              variables={variables}
              isReport={false}
            />
          ) : (
            undefined
          )}
          <ConfirmationDialog {...confirmationDialogProps} />
        </Box>
      ) : (
        <Spinner open={!(teamTask && processInstance)} />
      )}
    </MuiThemeProvider>
  );
}
export default TeamTaskDetailView;
