
export default interface Dictionary<T> {
  [key: string]: T;
}

export function size<T>(dictionary: Dictionary<T>): number {
  if (!dictionary) {
    return 0;
  }

  var keys = Object.keys(dictionary);
  return keys.length;
}

export function getAll<T>(dictionary: Dictionary<T>): T[] {
  if (!dictionary) {
    return [];
  }

  var keys = Object.keys(dictionary);
  return keys.map((key: string): T => dictionary[key]);
}

// const test: Dictionary<number> = {};
// test[1] = 2;


// export default class Dictionary2<T> {
//   private values: DictionaryItem<T>[] = [];

//   public push(key: string, value: T): void {
//     let foundId = false;
//     this.values.forEach((i): void => {
//       if (i.key === key) {
//         i.value = value;
//         foundId = true;
//       }
//     });

//     if (!foundId) {
//       this.values.push({key, value});
//     }
//   }

//   public hasKey(key: string): boolean {
//     for(let x = 0; x < this.values.length; x += 1) {
//       if (this.values[x].key === key) {
//         return true;
//       }
//     }
    
//     return false;
//   }

//   public get(key: string): T {
//     for(let x = 0; x < this.values.length; x += 1) {
//       if (this.values[x].key === key) {
//         return this.values[x].value;
//       }
//     }

//     throw new Error(`key ${key} not found in dictionary`)
//   }

//   public getAll(): T[] {
//     return this.values.map((v: DictionaryItem<T>) => {
//       return v.value;
//     });
//   }

//   public remove(key: string): boolean {
//     for(let x = 0; x < this.values.length; x += 1) {
//       if (this.values[x].key === key) {
//         this.values.splice(x, 1);
//         return true;
//       }
//     }

//     return false;
//   }

//   public size(): number {
//     return this.values.length;
//   }
// }