import React from "react";

export const CameraIcon = (): JSX.Element => {
  return (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6_48)">
        <path
          d="M64.9441 32.5C64.9441 50.4493 50.4058 65 32.472 65C14.5382 65 0 50.4493 0 32.5C0 14.5507 14.5382 0 32.472 0C50.4058 0 64.9441 14.5507 64.9441 32.5Z"
          fill="#F2F7FF"
        />
        <g clip-path="url(#clip1_6_48)">
          <g clip-path="url(#clip2_6_48)">
            <path
              d="M33.3495 20.1417C33.0932 19.5381 32.352 19.0442 31.7022 19.0442H25.744C25.0943 19.0442 24.3436 19.5338 24.0759 20.1324L22.5781 23.4802L28.6724 24.9588L34.7667 23.4802L33.3495 20.1417Z"
              fill="#3B8BC0"
            />
            <path
              d="M42.8556 21.2622H41.3782C40.1542 21.2622 39.1621 22.2552 39.1621 23.4802L42.1169 24.9589L45.0717 23.4802C45.0717 22.2552 44.0796 21.2622 42.8556 21.2622Z"
              fill="#3B8BC0"
            />
            <path
              d="M19.8083 23.48C18.1832 23.48 16.8535 24.8108 16.8535 26.4373V42.9985C16.8535 44.6251 18.1832 45.9559 19.8083 45.9559H45.0719C46.697 45.9559 48.0267 44.6251 48.0267 42.9985V26.4373C48.0267 24.8108 46.697 23.48 45.0719 23.48H19.8083Z"
              fill="#4EB9FF"
            />
            <path
              d="M28.6718 42.1853C32.7923 42.1853 36.1326 38.8421 36.1326 34.718C36.1326 30.5939 32.7923 27.2507 28.6718 27.2507C24.5513 27.2507 21.2109 30.5939 21.2109 34.718C21.2109 38.8421 24.5513 42.1853 28.6718 42.1853Z"
              fill="#174461"
            />
            <path
              d="M28.6725 39.9673C31.5691 39.9673 33.9173 37.6171 33.9173 34.718C33.9173 31.8189 31.5691 29.4688 28.6725 29.4688C25.7759 29.4688 23.4277 31.8189 23.4277 34.718C23.4277 37.6171 25.7759 39.9673 28.6725 39.9673Z"
              fill="#C2E7FF"
            />
            <path
              d="M28.6732 37.7491C27.0032 37.7491 25.6445 36.3892 25.6445 34.7178C25.6445 33.0464 27.0032 31.6865 28.6732 31.6865C29.0811 31.6865 29.4119 32.0176 29.4119 32.4259C29.4119 32.8342 29.0811 33.1652 28.6732 33.1652C27.8179 33.1652 27.1219 33.8617 27.1219 34.7179C27.1219 35.5739 27.8178 36.2705 28.6732 36.2705C29.5286 36.2705 30.2245 35.574 30.2245 34.7179C30.2245 34.3096 30.5553 33.9785 30.9632 33.9785C31.3711 33.9785 31.7019 34.3096 31.7019 34.7179C31.7019 36.3893 30.3432 37.7491 28.6732 37.7491Z"
              fill="white"
            />
            <path d="M45.0737 26.4373H39.1641V29.9861H45.0737V26.4373Z" fill="#FFD86C" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6_48">
          <rect width="65" height="65" rx="5" fill="white" />
        </clipPath>
        <clipPath id="clip1_6_48">
          <rect width="40.189" height="39" fill="white" transform="translate(12.9883 13)" />
        </clipPath>
        <clipPath id="clip2_6_48">
          <rect width="31.1732" height="31.2" fill="white" transform="translate(16.8535 16.8999)" />
        </clipPath>
      </defs>
    </svg>
  );
};
