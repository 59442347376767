import React, { Fragment } from "react";
import { FormLabel, CircularProgress, Button, Tooltip, IconButton, useMediaQuery } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "../../styles.scss";
import RefreshIcon from "@material-ui/icons/Refresh";
import { url } from "../../../../../services/apiConfig";
import { fetchWrapper } from "../../../../../core/fetchWrapper";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import { cobalt, ButtonTheme, CommonToolTipTheme } from "../../../../theme";
import CloseIcon from "@material-ui/icons/Close";
import { EditRounded } from "@material-ui/icons";
import { pictureFieldStyles } from "../../styles";
import { getFieldWidth } from "../../../../../util/helper";
import PictureEditor from "./ImageEditor";
import { CAMERA } from "../../../constants";
import { updateImageEditorBlocState } from "../../../../../rxjs/ImageEditorBloc";

export default function ThumbnailView(props: any) {
  const {
    getActiveStatus,
    getValue,
    getLoadingStatus,
    onClickThumbnail,
    getTemporaryUpload,
    handleDelete,
    pictureFieldRef,
    handlePictureSelector,
    handlePictureEditor,
    handleGalleryDialog,
  } = props;
  let isDisabled = getActiveStatus();
  let temporaryUpload = getTemporaryUpload();
  let isLoading = getLoadingStatus();
  let value = getValue();

  /* Stylesheet for picture Field */
  let { classes, t, name, component } = props.props;
  const pictureSource = component.attributes.pictureSource;
  const theme = createMuiTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("xs"));
  const { thumbnailImage, addPhotoButton, imageFit } = pictureFieldStyles();

  const refreshIcon = (): JSX.Element => {
    return (
      <MuiThemeProvider theme={ButtonTheme}>
        <Tooltip placement="bottom" title={t("picture.refresh") as string}>
          <IconButton classes={{ root: `${classes.refreshIcon}` }}>
            <RefreshIcon
              onClick={() => {
                onClickThumbnail(url, fetchWrapper);
              }}
            />
          </IconButton>
        </Tooltip>
      </MuiThemeProvider>
    );
  };

  const removePhotoIcon = (): JSX.Element => {
    return (
      <MuiThemeProvider theme={CommonToolTipTheme}>
        <Tooltip placement="bottom" title={t("picture.removePhoto") as string}>
          <IconButton className={classes.removeIcon}>
            <CloseIcon
              htmlColor={cobalt}
              onClick={() => {
                handleDelete();
              }}
              id="deleteIcon"
              data-testid="deletePhoto"
            />
          </IconButton>
        </Tooltip>
      </MuiThemeProvider>
    );
  };

  const addPictureButton = (): JSX.Element => {
    return (
      <Button
        onClick={() => {
          if (pictureSource === CAMERA) {
            onClickThumbnail(url, fetchWrapper);
            handleGalleryDialog(false);
          } else {
            handlePictureSelector(true);
          }
        }}
        style={addPhotoButton}
        data-testid="addPhoto"
      >
        <PhotoCameraOutlinedIcon htmlColor={cobalt} />
        <FormLabel component="article" className={classes.addPhotoText}>
          {t("picture.addPhoto")}
        </FormLabel>
      </Button>
    );
  };

  const editPhotoIcon = (): JSX.Element => {
    return (
      <>
        <MuiThemeProvider theme={ButtonTheme}>
          <Tooltip placement="bottom" title={t("common.editPhoto") as string}>
            <IconButton className={classes.editIcon}>
              <EditRounded
                htmlColor={cobalt}
                onClick={() => {
                  handlePictureEditor(true);
                  handlePictureSelector(false);
                  updateImageEditorBlocState({
                    isZoomVisible: false,
                    isRotateVisible: false,
                    isCroppingEnabled: true,
                    isDisable: true,
                  });
                }}
              />
            </IconButton>
          </Tooltip>
        </MuiThemeProvider>
      </>
    );
  };

  return (
    <div style={{ width: getFieldWidth(pictureFieldRef, isMobileView) }}>
      <span id="thumbnail" style={thumbnailImage} className="field">
        {/* Circular Progress loaded when saving the picture to server */}
        {isLoading ? (
          <div>
            <CircularProgress classes={{ root: `${classes.circularIcon}` }} />
          </div>
        ) : temporaryUpload && temporaryUpload.base64 ? (
          <div className={classes.pictureField}>
            {/* Thumnail image - temporarily captured content */}
            <img style={imageFit} src={temporaryUpload.base64} draggable={false} />
            {refreshIcon()}
          </div>
        ) : value && value.trim() ? (
          // Thumnail image - already stored content
          <div className={classes.pictureField}>
            <img
              data-testid="Img"
              draggable={false}
              className={classes.pictureImage}
              src={`${url.file}/${value}`}
              onClick={() => {
                onClickThumbnail(url, fetchWrapper, true);
              }}
            />
            {!isDisabled ? (
              <div className={classes.thumbnailImageOptions}>
                {editPhotoIcon()}
                {removePhotoIcon()}
              </div>
            ) : null}
          </div>
        ) : (
          // Camera Icon when no picture has captured.
          !isDisabled && addPictureButton()
        )}
      </span>
    </div>
  );
}
