import React from "react";
import { useTranslation } from "react-i18next";
import { handleExportClick } from "./objectDataReportHelper";
import ExcelExportIcon from "../common/ExcelExportIcon";
import { IconButton, Tooltip } from "@material-ui/core";
import { customToolBarStyle } from "./objectDataStyles";
import { CustomToolbarProps } from "./reportTypes";
import { DynamicJson } from "../../datastore/dataStoreTypes";
import { EQUAL, EQUALS, LIKE } from "../../constants";

function CustomToolBar(props: CustomToolbarProps): JSX.Element {
  const { t } = useTranslation(["translation"]);

  const handleClick = (): void => {
    props.setState(state => ({ ...state, showReportDownloadError: false }));
    let filterParamObject = props.filterParams.reduce((acc: DynamicJson, item: DynamicJson): DynamicJson => {
      acc[item.key] = item.value;
      return acc;
    }, {});
    handleExportClick({
      ...props,
      filterParams: filterParamObject,
      searchType: props.searchType == EQUALS ? EQUAL : LIKE,
    });
  };

  return (
    <Tooltip title={t("reports.export") as string} style={customToolBarStyle.button}>
      <IconButton onClick={handleClick}>
        <ExcelExportIcon />
      </IconButton>
    </Tooltip>
  );
}

export default CustomToolBar;
