import { createMuiTheme, makeStyles } from "@material-ui/core";
import { cobalt, rgbaLightGrey, solitude, white } from "../theme";

export const toggleListStyles = makeStyles({
  container: {
    backgroundColor: `${cobalt} !important`,
    borderRadius: "4px 4px 0px 0px",
  },
  expandIcon: {
    alignItems: "center",
    backgroundColor: solitude,
    borderRadius: 5,
    display: "flex",
    height: 30,
    justifyContent: "center",
    width: 30,
  },
  headerText: {
    color: white,
    width: "98%",
  },
  itemText: {
    color: cobalt,
  },
  list: { border: `1px solid ${solitude}`, boxShadow: `0px 2px 6px 0px ${rgbaLightGrey}`, padding: "8px 0px" },
  listButton: {
    backgroundColor: `${white} !important`,
    padding: "8px 16px",
  },
});

export const toggleListTheme = createMuiTheme({
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: 30,
      },
    },
    MuiListItemText: {
      primary: {
        wordBreak: "break-word",
      },
      root: {
        margin: "0px !important",
      },
    },
    MuiSvgIcon: {
      root: {
        color: cobalt,
        height: 24,
        width: 24,
      },
    },
  },
});
