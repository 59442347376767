export const textFieldColorTheme = {
  MuiFormLabel: {
    root: {
      "&$focused": {
        color: "#134A9F",
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      "&$focused $notchedOutline": {
        borderColor: "#134A9F",
      },
    },
  },
};
