import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Log from '../core/Log';

export default function Fetcher(props) {
  const { t } = useTranslation();

  const [showLoader, setShowLoader] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const failedToLoad = props.failedToLoad ? props.failedToLoad : t('common.failedToLoad');

  function failedToLoadContent() {
    return (
      <span className="alert alert-warning mx-3 mt-3" role="alert">
        {failedToLoad}
        {' '}
        <button onClick={executeFetch} className="btn btn-primary btn-sm">{t('common.tryAgain')}</button>
      </span>
    );
  }

  function loader() {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary my-4" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  function executeFetch() {
    // first set the loader back to initial state
    Log.trace('executeFetch');
    setShowLoader(true);
    setShowFailed(false);
    setShowContent(false);

    props.fetch()
      .then((res) => {
        if (res.status && res.status !== 200) {
          throw new Error('API call, expect a 200 status code');
        }
        return res;
      }).then((result) => {
        // return the result
        if (props.callback) {
          props.callback(result);
        }

        // hide the loader, show the content
        setShowLoader(false);
        setShowFailed(false);
        setShowContent(true);
      }).catch((error) => {
        Log.error('[fetch] threw an error', error);

        // hide the loader, show the error
        setShowLoader(false);
        setShowFailed(true);
        setShowContent(false);
      });
  }

  useEffect(() => {
    if (!props.fetch) {
      throw new Error('Loadable requires an [fetch] which returns a promise');
    } else {
      executeFetch();
    }
  }, []);
  // call the fetcher

  // decide what to return
  if (showLoader) {
    return loader();
  } if (showFailed) {
    return failedToLoadContent();
  } if (showContent) {
    return props.children;
  }
  return (<div>[Configure Fetcher]</div>);
}
