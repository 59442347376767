import { DrawingField } from "@juakali/juakali-forms-react";
import React from "react";
import { fetchWrapper } from "../../../../../core/fetchWrapper";
import { fetchToUploadFile } from "../../../../../services/TaskService";
import DrawingFieldView from "./DrawingFieldView";
import { drawingFieldStyles } from "../../styles";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";

class DrawingComponent extends DrawingField {
  constructor(props: any) {
    super(props);
    this.initCanvas();
    this.handleCanvasAccept = this.handleCanvasAccept.bind(this);
    this.handleCanvasRefChange = this.handleCanvasRefChange.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  initCanvas() {
    let self = this as any;
    // update the canvas size and data when orientation changes.
    window.addEventListener("orientationchange", function() {
      if (self.canvasData) {
        self.resizeCanvas(self.canvasData);
        // if (!canvasData.isEmpty()) {
        // If you don't check that the pad is empty then writing data (even if that data is a blank image) will make it think you have data in there!
        self.canvasData.fromDataURL(self.canvasData.toDataURL());
        // }
      }
    });
    window.addEventListener("resize", function() {
      let { value } = self.state;
      if (self.canvasData) {
        self.resizeCanvas(self.canvasData);
        let mappedValue = value && value.dataUrl ? value.dataUrl : [];
        self.canvasData.fromDataURL(mappedValue);
      }
    });
  }

  //resize the width/height of canvas
  resizeCanvas = (canvasData: any): void => {
    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvasData._canvas.width = canvasData._canvas.offsetWidth * ratio;
    canvasData._canvas.height = canvasData._canvas.offsetHeight * ratio;
    canvasData._canvas.getContext("2d").scale(ratio, ratio);
    this.canvasData = canvasData;
  };

  /* set canvas data when opening signature screen */
  handleEnter = () => {
    let { canvasData, getValue, resizeCanvas } = this as any;
    let value = getValue();
    if (canvasData) {
      canvasData._canvas.style.width = "100%";
      canvasData._canvas.style.height = "100%";
      canvasData._canvas.style.border = "1px solid darkgray";
      resizeCanvas(canvasData);
      let mappedValue = value && value.dataUrl ? value.dataUrl : [];
      canvasData.fromDataURL(mappedValue);
    }
  };

  handleCanvasRefChange = (canvasRef: any) => {
    this.canvasData = canvasRef;
  };

  handleCanvasAccept = () => {
    let { canvasData, getValue, handleSave } = this as any;
    const value = getValue();

    let data =
      canvasData && ((value && value.type && !canvasData.isEmpty()) || (canvasData.toData() && canvasData.toData().length))
        ? {
            type: "signature",
            dataUrl: canvasData.toDataURL(),
          }
        : "";
    handleSave(data, getSignatureUploadWrapper);
  };

  handleRefresh = () => {
    let { handleReUpload } = this as any;
    handleReUpload(getSignatureUploadWrapper);
  };

  render(): JSX.Element {
    return <DrawingFieldView {...this} />;
  }
}

const getSignatureUploadWrapper = (formData: any, id?: string): any => {
  return fetchWrapper(fetchToUploadFile, { formData, id });
};

const wrappedDrawingComponent = withStyles(drawingFieldStyles)(DrawingComponent as any);
export default withTranslation()(wrappedDrawingComponent as any);
