import { BehaviorSubject } from "rxjs";

export interface NotificationState {
  isAssignedTaskSelected: boolean;
  isNotificationUpdate: boolean;
}

export const defaultNotificationState = {
  isAssignedTaskSelected: false,
  isNotificationUpdate: false,
};

export default class NotificationBloc {
  private static instance: NotificationBloc;
  private subject: BehaviorSubject<NotificationState>;

  private constructor() {
    this.subject = new BehaviorSubject(defaultNotificationState);
  }

  static getInstance(): NotificationBloc {
    if (!NotificationBloc.instance) {
      NotificationBloc.instance = new NotificationBloc();
    }
    return NotificationBloc.instance;
  }

  getSubject = (): BehaviorSubject<NotificationState> => {
    return this.subject;
  };
  updateState = (value: NotificationState): void => {
    this.subject.next({
      ...this.subject.value,
      ...value,
    });
  };
}

export const updateNotificationBlocState = (args: any): void => {
  const notificationStateBloc = NotificationBloc.getInstance();
  notificationStateBloc.updateState({ ...args });
};
