import React, { useState } from "react";
import { Box, InputAdornment, MuiThemeProvider, TextField, Paper, Button, IconButton, Tooltip } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { withTranslation } from "react-i18next";
import { BLANK, CALCULATION, EMPTY, FORMULA, INVALID } from "../../../../../constants";
import { InputVariantType } from "../../../../../types/commonTypes";
import { formulaFieldTheme, styles } from "../../styles";
import CustomFieldLabel from "../CustomFieldLabel";
import FormulaIcon from "../../../../common/FormulaIcon";
import { CopyContentButtonTheme, buttonStylesForToolTip, formulaButtonStyles } from "./styles";
import "../../styles.scss";
import { cobalt, grey, lightGrey, solitude } from "../../../../theme";
import ContentCopyIcon from "../../../../common/ContentCopyIcon";
import { checkForAccessTestRole } from "../helper";

const FormulaFieldView = (props: any): JSX.Element => {
  const { getLabel, getValue, name, t, getExpression, getReplacedExpression, getToolTipStatus, handleToolTip } = props;
  const label = getLabel(); /* get formula label */
  const value = getValue(); /* get formula value */
  const expression = getExpression();
  const replacedExpression = getReplacedExpression();
  const hasAccessTestRoleEnabled = checkForAccessTestRole();
  const { root, error, formulaTextStyle } = styles({});
  const isInvalid = value === INVALID;
  const ifError = isInvalid ? error : {};
  const [changeButtonColor, setChangeButtonColor] = useState<string | undefined>(CALCULATION);
  const [copyStatus, setCopyStatus] = useState("");
  const showToolTip = getToolTipStatus();
  const formulaFieldProps = {
    InputLabelProps: { shrink: true },
    label: <CustomFieldLabel label={label} fieldId={name} />,
    name,
    style: { ...root, ...ifError },
    value: isInvalid ? t("formula.invalidExpression") : value,
    variant: InputVariantType.OUTLINED,
  };
  const { innerBlock, outerBlock, paper, text, rectangle } = formulaButtonStyles({ showToolTip, copyStatus });
  const { calculationButtonStyle, formulaButtonStyle } = buttonStylesForToolTip(changeButtonColor);

  function handleOnCopy() {
    let copyValue = changeButtonColor == CALCULATION ? replacedExpression : expression;
    navigator.clipboard.writeText(copyValue);
    setCopyStatus(t("formula.copyMessage", { copyName: changeButtonColor }));
  }
  function handleButtonChange(buttonType?: string | undefined) {
    setChangeButtonColor(buttonType);
    setCopyStatus(EMPTY);
  }
  const FormulaToolTip = (
    <div className={outerBlock} data-testid="formulaToolTipId">
      <div className={innerBlock}>
        <Paper className={paper} id="paperId">
          <Box className={rectangle}></Box>
          <Button style={calculationButtonStyle} onClick={() => handleButtonChange(CALCULATION)}>
            <span className="formulaText"> {CALCULATION} </span>
          </Button>
          <Button style={formulaButtonStyle} disabled={!hasAccessTestRoleEnabled} onClick={() => handleButtonChange(FORMULA)}>
            <FormulaIcon
              backgroundColor={changeButtonColor == FORMULA ? solitude : lightGrey}
              iconColor={changeButtonColor == FORMULA ? cobalt : grey}
            />
            {BLANK}
            <span className="formulaText">{FORMULA}</span>
          </Button>
          <MuiThemeProvider theme={CopyContentButtonTheme}>
            <Tooltip placement="bottom" title="Copy">
              <IconButton onClick={handleOnCopy}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </MuiThemeProvider>
          <p className={text} data-testid="formulaText">
            {changeButtonColor == CALCULATION ? replacedExpression : expression}
          </p>
          <p className="formulaSuccessMessage" data-testid="copySuccessMessage">
            {copyStatus}
          </p>
        </Paper>
      </div>
    </div>
  );

  return (
    /* Formula Field */
    <div className="field" data-testid="formulaField" onMouseLeave={() => handleToolTip(false)}>
      <div className="flex">
        <MuiThemeProvider theme={formulaFieldTheme}>
          {isInvalid ? (
            <TextField
              {...formulaFieldProps}
              InputProps={{
                readOnly: true,
                style: { color: error.color },
              }}
              data-testid="textField"
            />
          ) : (
            <NumberFormat
              {...formulaFieldProps}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <Box
                    onMouseEnter={() => {
                      handleToolTip(true);
                      setCopyStatus(EMPTY);
                    }}
                  >
                    <InputAdornment position="start">
                      <FormulaIcon />
                    </InputAdornment>
                  </Box>
                ),
                inputProps: {
                  style: formulaTextStyle as Object,
                },
              }}
              customInput={TextField}
              thousandSeparator={BLANK}
              data-testid="numberField"
            />
          )}
          {showToolTip && FormulaToolTip}
        </MuiThemeProvider>
      </div>
    </div>
  );
};

export default withTranslation()(FormulaFieldView);
