import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getRemoteDataStoreDeleteProcesses,
  createStartDeleteProcessInstance,
  resetDataStoreDeleteProcessState,
  setDataStoreDeleteProcessLoadingStatus,
  setStartProcessInstanceStatus,
} from "../../datastore/dataStoreDeleteProcess/dataStoreDeleteProcessesReducer";
import {
  DataStoreDeleteProcessState,
  StartProcessIntanceStatus,
  DataStoreDeleteProcessesLoadingStatus,
  DataStoreDeleteProcess,
} from "../../datastore/dataStoreDeleteProcess/dataStoreDeleteProcessTypes";
import { StateType } from "../../redux/types";
import { StartDeleteProcessInstanceRequest } from "../../services/ProcessInstanceService";
import Spinner from "../common/Spinner";
import PopperMenuButton from "./PopperMenuButton";

interface OwnProps {
  dataDefinitionkey: string;
  version: number;
  businessObjectId: string;
  handleCreateProcess: (processInstanceId: string) => void;
}

interface StateProps {
  dataStoreDeleteProcessState: DataStoreDeleteProcessState;
}

interface DispatchProps {
  getRemoteDataStoreDeleteProcesses: (key: string, version: number) => any;
  createStartDeleteProcessInstance: (startDeleteProcessInstanceRequest: StartDeleteProcessInstanceRequest) => any;
  resetDataStoreDeleteProcessState: () => any;
  setDataStoreDeleteProcessLoadingStatus: (status: DataStoreDeleteProcessesLoadingStatus) => any;
  setStartProcessInstanceStatus: (status: StartProcessIntanceStatus) => any;
}

function DeleteProcessList(props: OwnProps & StateProps & DispatchProps): JSX.Element {
  const { t } = useTranslation(["translation"]);
  const [open, setOpen] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);
  const { dataDefinitionkey, version, dataStoreDeleteProcessState, businessObjectId } = props;

  useEffect(() => {
    props.resetDataStoreDeleteProcessState();
    props.getRemoteDataStoreDeleteProcesses(dataDefinitionkey, version);
    setInit(true);
  }, [dataDefinitionkey, version]);

  useEffect(() => {
    if (init) {
      if (dataStoreDeleteProcessState.startProcessInstanceStatus === "success")
        props.handleCreateProcess(dataStoreDeleteProcessState.processInstanceId);
    }
  }, [dataStoreDeleteProcessState.startProcessInstanceStatus]);

  return init ? (
    <div>
      <PopperMenuButton<DataStoreDeleteProcess>
        open={open}
        setOpen={setOpen}
        title={t("common.delete")}
        menuItems={dataStoreDeleteProcessState.items}
        handleMenuItemClick={(deleteProcess: DataStoreDeleteProcess) => {
          let startDeleteProcessInstanceRequest: StartDeleteProcessInstanceRequest = {
            processDefinitionId: deleteProcess.appDefinition.processDefinitionId,
            name: `${deleteProcess.appDefinition.name} - ${new Date().toISOString()}`,
            values: {
              metaData: {
                JUAKALI_BUSINESS_OBJECT_ID: businessObjectId,
                JUAKALI_DATASTORE_LINK_TYPE: "DELETE",
                OBJECT_DEFINITION_KEY: dataDefinitionkey,
                OBJECT_DEFINITION_VERSION: version,
              },
            },
          };
          props.createStartDeleteProcessInstance(startDeleteProcessInstanceRequest);
        }}
        getMenuLabel={(deleteProcess: DataStoreDeleteProcess) => deleteProcess.dataStoreLink.label}
      />
      <Spinner
        open={dataStoreDeleteProcessState.remoteLoadingStatus === "loading" || dataStoreDeleteProcessState.startProcessInstanceStatus === "pending"}
      />
    </div>
  ) : (
    <div></div>
  );
}

function mapStateToProps(state: StateType): StateProps {
  const dataStoreDeleteProcessState = state.dataStoreDeleteProcessState;
  return { dataStoreDeleteProcessState };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapDispatchToProps(dispatch: any): DispatchProps {
  const actions = {
    getRemoteDataStoreDeleteProcesses: (key: string, version: number) => dispatch(getRemoteDataStoreDeleteProcesses({ key, version })),
    createStartDeleteProcessInstance: (startDeleteProcessInstanceRequest: StartDeleteProcessInstanceRequest) =>
      dispatch(createStartDeleteProcessInstance(startDeleteProcessInstanceRequest)),
    resetDataStoreDeleteProcessState: () => dispatch(resetDataStoreDeleteProcessState()),
    setDataStoreDeleteProcessLoadingStatus: (status: DataStoreDeleteProcessesLoadingStatus) =>
      dispatch(setDataStoreDeleteProcessLoadingStatus(status)),
    setStartProcessInstanceStatus: (status: StartProcessIntanceStatus) => dispatch(setStartProcessInstanceStatus(status)),
  };
  return actions;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteProcessList);
