import { Alert } from "../../../redux/reducers/alertReducer";
import { getUsers, getTasks, getTasksPaging, isTasksLoading, getTasksStatus, getTaskQueryParams } from "../../../redux/selectors/openTasksSelectors";
import getCreateProcessInstance from "../../../redux/selectors/createProcessInstanceSelector";
import { fetchTasks, fetchUsers, reassignTask, resetTasks, setStatus } from "../../../redux/reducers/openTasksReducer";
import { createProcessInstanceOnline } from "../../../redux/reducers/createProcessInstanceReducer";
import { addAlert, setCreateProcessInstance } from "../../../redux/actions";
import { StateType, CreateProcessInstance, DownloadUserRequest, ReassignParams } from "../../../redux/types";
import {
  TaskQueryParams,
  TaskAssignment,
  QueryParams,
  DashboardDispatchProps,
  DashboardStateProps,
  ProcessStarterDispatchProps,
  ProcessStarterStateProps,
} from "../../../types/taskQueryTypes";
import { getAppDefinitions, isAppDefinitionsLoading } from "../../../redux/selectors/appDefinitionsSelector";
import { fetchAppDefinitions } from "../../../redux/reducers/appDefinitionsReducer";

export function mapStateToProps(state: StateType): DashboardStateProps {
  const pendingTasks = getTasks(state, "involvedTasksApiResult");
  const candidateTasks = getTasks(state, "candidateTasksApiResult");
  const teamTasks = getTasks(state, "teamTasksApiResult");
  const involvedTasksPaging = getTasksPaging(state, "involvedTasksApiResult"); /* get pending tasks pagination from state object */
  const candidateTasksPaging = getTasksPaging(state, "candidateTasksApiResult"); /* get candidate tasks pagination from state object */
  const teamTasksPaging = getTasksPaging(state, "teamTasksApiResult");
  const involvedTasksStatus = getTasksStatus(state, "involvedTasksStatus");
  const candidateTasksStatus = getTasksStatus(state, "candidateTasksStatus");
  const teamTasksStatus = getTasksStatus(state, "teamTasksStatus");
  const involvedTasksLoading = isTasksLoading(state, "involvedTasksLoading");
  const candidateTasksLoading = isTasksLoading(state, "candidateTasksLoading");
  const teamTasksLoading = isTasksLoading(state, "teamTasksLoading");
  const assignedTaskQueryParams = getTaskQueryParams(state, "assignedTaskQueryParams") as TaskQueryParams;
  const candidateTaskQueryParams = getTaskQueryParams(state, "candidateTaskQueryParams") as TaskQueryParams;
  const teamTaskQueryParams = getTaskQueryParams(state, "teamTaskQueryParams");
  const usersList = getUsers(state);

  return {
    pendingTasks,
    candidateTasks,
    teamTasks,
    involvedTasksStatus,
    candidateTasksStatus,
    teamTasksStatus,
    involvedTasksLoading,
    candidateTasksLoading,
    teamTasksLoading,
    involvedTasksPaging,
    candidateTasksPaging,
    teamTasksPaging,
    assignedTaskQueryParams,
    candidateTaskQueryParams,
    teamTaskQueryParams,
    usersList,
  };
}

export function mapDispatchToProps(dispatch: any): DashboardDispatchProps {
  const actions = {
    fetchTasks: (params: Partial<QueryParams & TaskQueryParams>) => dispatch(fetchTasks(params)),
    resetTasks: () => dispatch(resetTasks()),
    reassignTask: (params: ReassignParams) => dispatch(reassignTask(params)),
    fetchUsers: (params: DownloadUserRequest) => dispatch(fetchUsers(params)),
    setStatus: (assignment: TaskAssignment, status?: string) => dispatch(setStatus(assignment, status)),
    addAlert: (alert: Alert) => dispatch(addAlert(alert)),
  };
  return actions;
}

export function mapProcessStarterStateToProps(state: StateType): ProcessStarterStateProps {
  const createProcessInstance = getCreateProcessInstance(state);
  const isLoading = isAppDefinitionsLoading(state);
  const appDefinitions = getAppDefinitions(state);
  return {
    appDefinitions,
    createProcessInstance,
    isLoading,
  };
}

export function mapProcessStarterDispatchToProps(dispatch: any): ProcessStarterDispatchProps {
  return {
    createProcessInstanceOnline: (payload: CreateProcessInstance) => dispatch(createProcessInstanceOnline(payload)),
    fetchAppDefinitions: () => dispatch(fetchAppDefinitions()),
    setCreateProcessInstance: (payload: CreateProcessInstance) => dispatch(setCreateProcessInstance(payload)),
  };
}
