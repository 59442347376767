import { ObjectDefinition, defaultObjectDefinitionState, defaultObjectDefinition, ObjectDefinitionState } from "./dataStoreTypes";
import { url } from "../services/apiConfig";
import JuakaliReducer from "../genericRedux/JuakaliReducer";

export const fetchForAllObjectDefinitionList = (): Promise<Response> => {
  return fetch(`${url.objectDefinitions}?filterDefinitionBasedOnGroups=true`, {
    method: "GET",
  });
};

export const fetchForObjectDefinition = (key: string, version: number): Promise<Response> => {
  return fetch(`${url.objectDefinitions}/${key}/${version}?filterDefinitionBasedOnGroups=true`, {
    method: "GET",
  });
};

export const objectDefinitionReducer = new JuakaliReducer<ObjectDefinition, ObjectDefinitionState>(
  "ObjectDefinition",
  defaultObjectDefinitionState,
  defaultObjectDefinition,
  fetchForAllObjectDefinitionList,
  fetchForObjectDefinition,
  "objectDefinitionState", //this should be same as the property name in store.ts in StateType
  "key", //property that uniquley identifies the entity
  null,
  []
);
