import React, { Fragment } from "react";
import { Button } from "@material/react-button";
import { FormHelperText } from "@material-ui/core";
import Spinner from "../../../../common/Spinner";
import RefreshSpinner from "../../../../common/RefreshSpinner";
import { fetchWrapper } from "../../../../../core/fetchWrapper";
import { fetchForWebhook } from "../../../../../services/TaskService";

export default function WebhookButtonView(props: any): JSX.Element {
  /* get webhook,loadingStatus,webhookStatus and label*/
  const { getLabel, getLoadingStatus, getWebhookStatus, handleClick, getActiveStatus, name } = props;
  let { t } = props.props;
  const webhookStatus = getWebhookStatus();
  const isLoading = getLoadingStatus();
  const isDisabled = getActiveStatus();
  const label = getLabel();

  const getWebhookWrapper = (fetchData: any): any => {
    return fetchWrapper(fetchForWebhook, fetchData);
  };
  const webhookButtonId = `webhookButton${name}`;

  const buttonStyle = {
    width: (isLoading ? document.getElementById(webhookButtonId)!.offsetWidth : "0") + "px",
  };

  return (
    <div className="field">
      <div className="flex">
        {isLoading ? (
          <Fragment>
            <Spinner open={true} />
            <Button className="webhookButton" disabled style={buttonStyle}>
              <RefreshSpinner />
            </Button>
          </Fragment>
        ) : (
          <Button
            outlined={isDisabled}
            className="webhookButton"
            id={webhookButtonId}
            disabled={isDisabled}
            onClick={() => {
              handleClick(getWebhookWrapper);
            }}
            data-testid="webhookButton"
          >
            {label}
          </Button>
        )}
      </div>
      {webhookStatus && (
        <FormHelperText className={webhookStatus.isSuccess ? "successMessage" : "errorMessage"}>
          {webhookStatus.isServerError
            ? t("common.serverErrorTryAgain")
            : t(`webhookButton.${webhookStatus.isSuccess ? "httpSuccess" : webhookStatus.isHttpError ? "httpError" : "scriptError"}`, {
                code: webhookStatus.httpErrorCode,
                script: webhookStatus.isPreScriptError ? "prescript" : "postscript",
              })}
        </FormHelperText>
      )}
    </div>
  );
}
