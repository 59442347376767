import { EMPTY, PROCESS, TASK } from "../../constants";
import { CUT, GRAPES_JS_FORM } from "../TeamTask/constants";
import { CUT_FORM, DELETE, GRAPES_FORM, OTHERS } from "./constants";
import { KeyValuePair, ProcessVariables } from "./type";

export const isItADataStoreDeleteProcess = (processVariablesArgument: ProcessVariables) => {
    if (
      processVariablesArgument.metaData &&
      processVariablesArgument.metaData.JUAKALI_DATASTORE_LINK_TYPE === DELETE &&
      processVariablesArgument.metaData.OBJECT_DEFINITION_KEY &&
      processVariablesArgument.metaData.OBJECT_DEFINITION_VERSION
    ) {
      return true;
    } else {
      return false;
    }
  };

  export const resetHandler = (stateObjects : KeyValuePair, stateParams: KeyValuePair): void => {
    const { setTask, setProcessInstanceId, setTaskId, setTaskType, 
      setNoTasksAvailable, setFormData, loadProcessInstanceData } = stateObjects;
  
    let { viewType, task, history } = stateParams;
    let redirectProcessInstanceId = task ? task.processInstanceId : undefined;
    setTask(undefined);
    setTaskId("");
    setFormData(undefined);
    setTaskType(OTHERS);
    setNoTasksAvailable(false);
    loadProcessInstanceData();
    if (viewType === TASK && task) {
      setProcessInstanceId(task.processInstanceId);
      loadProcessInstanceData(PROCESS);
      if (redirectProcessInstanceId) {
        history.push(`/process/instance/${task.processInstanceId}`);
      } else {
        history.push("");
      }
    }
  }

  export const getFormType = (type: string): string => {
    return type.startsWith(GRAPES_JS_FORM) ? GRAPES_FORM : type.startsWith(CUT) ? CUT_FORM : EMPTY
  }


