import React from "react";
import { checkForAccessTestRole } from "./helper";
import { fieldLabelStyle } from "../styles";

interface OwnProps {
  fieldId: string;
  label: string;
}

function CustomFieldLabel(props: OwnProps) {
  const { fieldId, label } = props;
  const hasAccessTestRole = checkForAccessTestRole();

  return hasAccessTestRole ? (
    <span data-testid="customLabel">
      {label} <span data-testid="fieldId" style={fieldLabelStyle} onClick={() => navigator.clipboard.writeText(fieldId)}>{`(${fieldId})`}</span>
    </span>
  ) : (
    <span data-testid="label">{label}</span>
  );
}

export default CustomFieldLabel;
