/* eslint-disable react/display-name */
// @flow
import { createMuiTheme, MuiThemeProvider, Paper, Tab, Tabs, Typography, Grid as GridItem, Tooltip, Toolbar } from "@material-ui/core";
import { Grid, Row, Cell } from "@material/react-layout-grid";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useState, useContext } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ConfirmationDialog, { ConfirmationDialogProps } from "../common/ConfirmationDialog";
import ForwardIcon from "../common/ForwardIcon";
import TaskTable, { getTaskTableColumnProperties } from "./TaskTable";
import TeamTaskDetailView from "../TeamTask/TeamTaskDetailView";
import { showNotification } from "../../util/notification";
import { defaultDownloadUserRequest, defaultPaging, JuakaliUser } from "../../redux/types";
import { fetchWrapper } from "../../core/fetchWrapper";
import { fetchForTaskDetail, canClaimTask } from "../../services/TaskService";
import { AccountContext } from "../../App";
import { claimTaskCall } from "../../services/flow/FTaskService";
import {
  TaskState,
  TaskAssignment,
  SortType,
  TaskTableType,
  sortTypeAndColumnMapper,
  DashboardStateProps,
  DashboardDispatchProps,
  QuickFilter
} from "../../types/taskQueryTypes";
import { DynamicJson } from "../../datastore/dataStoreTypes";
import { IconButtonTheme, SuccessMessageTheme, tabsTheme, inlineStyles, dashboardCustomStyles } from "./styles/dashboardStyles";
import {
  defaultConfirmationPropertiesOnCancel,
  defaultConfirmationPropertiesOnOtherAction,
  getBodyText,
  getCurrentAssignee,
  getMappedTasks,
  markTaskStarted,
} from "./utils/dashboardHelper";
import { CANCEL, SAVE, TEAMVIEW, CHANGEASSIGNEE, EDIT_PROCESS_INSTANCE, PROCESSINSTANCE } from "../../constants";
import { getRowsPerPageSize } from "../../util/helper";
import { TableKeys } from "../../constants/index";
import NotificationBloc, { updateNotificationBlocState } from "../../rxjs/notificationBloc";
import { mapDispatchToProps, mapStateToProps } from "./utils/mapProps";
import { cobalt } from "../theme";
import ProcessStarter from "./ProcessStarter";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps extends RouteComponentProps<{}> {
  history: any;
}

function Dashboard(props: OwnProps & DashboardStateProps & DashboardDispatchProps): JSX.Element {
  const { t } = useTranslation(["translation", "flow"]);
  /* get currently logged in user account */
  const account = useContext(AccountContext);
  /* set loading state for fetch task and claim api calls */
  const [isLoading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentTeamTask, setCurrentTeamTask] = useState<any[] | null>(null);
  const [assignee, setAssignee] = useState<JuakaliUser | undefined>(undefined);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showTeamDetailView, setTeamDetailView] = useState(false);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState<ConfirmationDialogProps>(defaultConfirmationPropertiesOnCancel());
  /* get notification status from rxjs store */
  const notificationStateBloc = NotificationBloc.getInstance();
  let notificationState = notificationStateBloc.getSubject();
  let { isAssignedTaskSelected } = notificationState.value;
  const [filterNewAssignedTask, setFilterNewAssignedTask] = useState<boolean>(isAssignedTaskSelected);
  const theme = createMuiTheme();
  const isItMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeQuickFilter, setActiveQuickFilter] = useState<QuickFilter>(QuickFilter.ALL);
  let {
    candidateTasksStatus,
    involvedTasksStatus,
    assignedTaskQueryParams,
    candidateTaskQueryParams,
    teamTaskQueryParams,
    usersList,
    fetchTasks,
    fetchUsers,
    reassignTask,
    teamTasksStatus,
  } = props;
  const { TEAM_TASK_LISTS, CANDIDATE_TASK_LISTS, ASSIGNED_TASK_LISTS } = TableKeys;

  let { iconColor, successMessage, padding, tabs, tabsWrapper } = inlineStyles(isItMobile);
  const { taskEditIcon } = dashboardCustomStyles();

  if (isAssignedTaskSelected) {
    currentTab && setCurrentTab(0);
    !filterNewAssignedTask && setFilterNewAssignedTask(true);
    updateNotificationBlocState({ isAssignedTaskSelected: false });
    handleTaskQuickFilter(TaskAssignment.INVOLVED, QuickFilter.NEW)
  }
  
  var involvedQueryParams = {
    assignment: TaskAssignment.INVOLVED,
    sortType: SortType.DUE_DATE_ASCENDING,
    state: TaskState.OPEN,
    page: defaultPaging.page,
    size: getRowsPerPageSize(ASSIGNED_TASK_LISTS),
    filterNew: filterNewAssignedTask,
  };

  var candidateQueryParams = {
    assignment: TaskAssignment.CANDIDATE,
    sortType: SortType.DUE_DATE_ASCENDING,
    state: TaskState.OPEN,
    page: defaultPaging.page,
    size: getRowsPerPageSize(CANDIDATE_TASK_LISTS),
  };

  var teamQueryParams = {
    sortType: SortType.DUE_DATE_ASCENDING,
    page: defaultPaging.page,
    size: getRowsPerPageSize(TEAM_TASK_LISTS),
  };

  useEffect(() => {
    if (currentTab == 1) {
      if (showTeamDetailView == true) {
        setTeamDetailView(false);
        setCurrentTeamTask(null);
      }
      filterNewAssignedTask && setFilterNewAssignedTask(false);
      fetchTasks(teamQueryParams);
      fetchUsers(defaultDownloadUserRequest);
    } else {
      fetchTasks(involvedQueryParams);
      fetchTasks(candidateQueryParams);
    }
  }, [currentTab]);

  useEffect(() => {
    if (currentTab == 1 && teamTasksStatus === "REASSIGN_SUCCESS") {
      /* set showSuccessMessage state to `true` when reassignment is succeed  */
      setShowSuccessMessage(true);
      /* showSuccessMessage state automatically reset to false after 7 seconds  */
      setTimeout((): void => {
        setShowSuccessMessage(false);
      }, 7000);
      if (showTeamDetailView) return;
      setCurrentTeamTask(null);
      setAssignee(undefined);
      fetchTasks(props.teamTaskQueryParams);
    } else if (currentTab == 1 && teamTasksStatus === "REASSIGN_ERROR") {
      /* scroll to the top of dashboard page when re-assignment failed */
      let mdcDrawerContent = (document as HTMLDocument).querySelector(".mdc-drawer-app-content") as HTMLElement;
      mdcDrawerContent && mdcDrawerContent.scroll(0, 0);
    }
  }, [teamTasksStatus]);

  useEffect(() => {
    /* team tasks fetched when we go to team task table screen from team task detail view */
    if (!showTeamDetailView && currentTab == 1) {
      fetchTasks(props.teamTaskQueryParams);
    }
  }, [showTeamDetailView]);

  useEffect(() => {
    updateNotificationBlocState({ isNotificationUpdate: true });
    return () => {
      props.resetTasks();
    };
  }, []);

  function handleTaskQuickFilter(taskAssignment: TaskAssignment, filterType: QuickFilter) {
    setActiveQuickFilter(filterType);
    const queryParams = {page: defaultPaging.page, quickFilterType: filterType, filterNew: filterType === QuickFilter.NEW};

    switch (taskAssignment) {
      case TaskAssignment.TEAM:
        fetchTasks({ ...teamTaskQueryParams, ...queryParams});
        break;
      case TaskAssignment.INVOLVED:
        fetchTasks({ ...assignedTaskQueryParams, ...queryParams });
        break;
    }
  }

  function claimCall(taskId: string): any {
    /* set loading status to 'true' when fetch task call starts */
    setLoading(true);
    var promise = fetchWrapper(fetchForTaskDetail, taskId);
    promise
      .then((res: any) => {
        if (res.status === 200) {
          return res.json();
        } else {
          if (res.status !== 401) throw new Error(res.status);
        }
      })
      .then((result: any) => {
        if (canClaimTask(result, account)) {
          var promise = fetchWrapper(claimTaskCall, result.id);
          promise
            .then((claimResponse: any) => {
              if (claimResponse.status === 200) {
                markTaskStarted(taskId);
                /* set loading status to 'false' when fetch task and claim call ends */
                setLoading(false);
                // path redirection to `process/task/{taskId}`
                props.history.push(`process/task/${taskId}`);
              } else {
                if (claimResponse.status !== 401) throw new Error(claimResponse.status);
              }
            })
            .catch((err: any) => {
              /* set loading status to 'false' if any problem with server */
              setLoading(false);
              // show error snackbar if any problem with server after the api call triggered
              if (err.message && err.message != 401 && err.message != 403) showNotification("error", t("common.serverErrorTryAgain"));
            });
        } else {
          /* set loading status to 'false' when the task has been assinged already */
          setLoading(false);
          // Show error snackbar whenever task already has assignee
          showNotification("error", t("common.insufficientPriviliges"));
        }
      })
      .catch((e: any) => {
        /* set loading status to 'false' if any problem with server */
        setLoading(false);
        // show error snackbar if any problem with server after the api call triggered
        if (e.message && e.message != 401 && e.message != 403) showNotification("error", t("common.serverErrorTryAgain"));
      });
  }

  const getIconButton = (buttonType: string, tableMeta: any): JSX.Element => {
    const { rowIndex, tableState } = tableMeta;
    const { rowsSelected } = tableState;
    const disableIcon = !!(rowsSelected && rowsSelected.includes(rowIndex));

    let iconButton;
    let handleClickIconButton = (): void => {
      if (currentTeamTask) {
        let fromUser = (getCurrentAssignee(currentTeamTask[10], usersList, true) as string | undefined) || "";
        reassignTask({
          fromUser: fromUser,
          toUser: assignee ? assignee.email : fromUser,
          taskId: currentTeamTask[2],
        });
      }
    };
    switch (buttonType) {
      case SAVE:
        iconButton = <DoneIcon style={iconColor} />;
        break;
      case CANCEL:
        iconButton = <ClearIcon style={iconColor} />;
        handleClickIconButton = (): void => {
          setConfirmationDialogProps({
            ...defaultConfirmationPropertiesOnCancel(),
            open: true,
            primaryHandler: () => {
              setConfirmationDialogProps(defaultConfirmationPropertiesOnCancel());
            },
            secondaryHandler: () => {
              setAssignee(undefined);
              setCurrentTeamTask(null);
              setConfirmationDialogProps(defaultConfirmationPropertiesOnCancel());
            },
          });
        };
        break;
      case CHANGEASSIGNEE:
      case TEAMVIEW:
        const commonProps = { htmlColor: cobalt, opacity: disableIcon ? 0.5 : 1 };
        iconButton = buttonType === CHANGEASSIGNEE ? <ForwardIcon {...commonProps} /> : <VisibilityIcon {...commonProps} />;
        handleClickIconButton = (): void => {
          if (currentTeamTask) {
            setConfirmationDialogProps({
              ...defaultConfirmationPropertiesOnOtherAction(),
              open: true,
              content: t("confirmation.unsavedChangesContent", { name: currentTeamTask[5] }),
              primaryHandler: () => {
                buttonType === CHANGEASSIGNEE ? setAssignee(undefined) : setTeamDetailView(true);
                setCurrentTeamTask(tableMeta.rowData);
                setConfirmationDialogProps(defaultConfirmationPropertiesOnOtherAction());
              },
              secondaryHandler: () => {
                setConfirmationDialogProps(defaultConfirmationPropertiesOnOtherAction());
              },
            });
          } else {
            buttonType === CHANGEASSIGNEE ? setAssignee(undefined) : setTeamDetailView(true);
            setCurrentTeamTask(tableMeta.rowData);
          }
        };
        break;
    }
    return (
      <Tooltip title={t(`${buttonType === CHANGEASSIGNEE || buttonType === TEAMVIEW ? `dashboard` : `common`}.${buttonType}`) as string}>
        <IconButton disabled={disableIcon} onClick={handleClickIconButton}>
          {iconButton}
        </IconButton>
      </Tooltip>
    );
  };

  const getEditColumn = (taskTableType: TaskTableType): any => {
    return {
      name: "Actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value: any, tableMeta: any): any => {
          const { history } = props;
          let editIconStyle = { color: "rgb(63, 81, 181)", marginRight: "18px" };
          const display = { display: "flex" };
          return taskTableType === TaskTableType.TEAM ? (
            <MuiThemeProvider theme={IconButtonTheme()}>
              {currentTeamTask && currentTeamTask[1] === tableMeta.rowData[1] ? (
                <div style={display}>
                  {getIconButton(SAVE, tableMeta)}
                  {getIconButton(CANCEL, tableMeta)}
                </div>
              ) : (
                <div style={display}>
                  {getIconButton(CHANGEASSIGNEE, tableMeta)}
                  {getIconButton(TEAMVIEW, tableMeta)}
                </div>
              )}
            </MuiThemeProvider>
          ) : (
            <Tooltip title={t('common.edit') as string} placement="bottom-start">
              <EditIcon
                className={taskEditIcon}
                style={editIconStyle}
                onClick={() => {
                  ReactGA.event({
                    category: PROCESSINSTANCE,
                    action: EDIT_PROCESS_INSTANCE,
                    label: EDIT_PROCESS_INSTANCE,
                  });
                  let { rowData } = tableMeta;
                  if (rowData && rowData.length) {
                    if (taskTableType === TaskTableType.CANDIDATE) {
                      /* claim the unassigned tasks with the currently logged in user */
                      claimCall(rowData[1]);
                    } else {
                      const isNewTask = rowData[2].split(",")[1] === QuickFilter.NEW;
                      isNewTask && markTaskStarted(rowData[1]);
                      history.push(`process/task/${rowData[1]}`);
                    }
                  }
                }}
              />
            </Tooltip>
          );
        },
      },
    };
  };

  /* Header for Assigned/Candidate tasks list */
  const tasksHeader = (taskTableType: TaskTableType): any => {
    var queryParams =
      taskTableType === TaskTableType.CANDIDATE
        ? props.candidateTaskQueryParams
        : taskTableType === TaskTableType.INVOLVED
        ? props.assignedTaskQueryParams
        : props.teamTaskQueryParams;
    if (!queryParams) {
      return [];
    }
    var columnArray = [];
    // build the columns for task table
    let propertiesForTeamTask =
      taskTableType === TaskTableType.TEAM
        ? {
            currentTeamTask,
            usersList,
            setAssignee,
            assignee,
          }
        : {};
    let TaskTableColumnProperties = getTaskTableColumnProperties(taskTableType, propertiesForTeamTask);
    Object.keys(TaskTableColumnProperties).forEach((key: string) => {
      var columnProperties = TaskTableColumnProperties[key];
      if (columnProperties) {
        let { isShownInTeamTable, isShownInCandidateTable, isShownInInvolvedTable } = columnProperties;

        /**
         * Skip if the following conditions occured
         * - if the current view is mobile and the current column is not the one for mobile
         * - if the current column need not be shown in team table
         */
        if (taskTableType === TaskTableType.TEAM) {
          let { isVisibleInMobile, isVisibleInDesktop } = isShownInTeamTable;
          if (isItMobile ? !isVisibleInMobile : !isVisibleInDesktop) {
            columnProperties.display = "excluded";
          } else {
            columnProperties.display = columnProperties.displayParam !== "excluded" ? true : "excluded";
          }
        }
        /**
         * Skip if the following conditions occured
         * - if the current view is mobile and the current column is not the one for mobile
         * - if the current column need not be shown in candidate table
         */
        if (taskTableType === TaskTableType.CANDIDATE) {
          let { isVisibleInMobile, isVisibleInDesktop } = isShownInCandidateTable;
          if (isItMobile ? !isVisibleInMobile : !isVisibleInDesktop) {
            columnProperties.display = "excluded";
          } else {
            columnProperties.display = columnProperties.displayParam !== "excluded" ? true : "excluded";
          }
        }
        /**
         * Skip if the following conditions occured
         * - if the current view is mobile and the current column is not the one for mobile
         * - if the current column need not be shown in involved table
         */
        if (taskTableType === TaskTableType.INVOLVED) {
          let { isVisibleInMobile, isVisibleInDesktop } = isShownInInvolvedTable;
          if (isItMobile ? !isVisibleInMobile : !isVisibleInDesktop) {
            columnProperties.display = "excluded";
          } else {
            columnProperties.display = columnProperties.displayParam !== "excluded" ? true : "excluded";
          }
        }
        var muiColumnDefinition: DynamicJson = { name: columnProperties.name, label: t(`tasks.${columnProperties.name}`) };

        //build the options like sort, display for each column
        var options: DynamicJson = {};
        columnProperties.display ? (options.display = columnProperties.display) : null;
        options.sort = columnProperties.sort;
        if (options.sort) {
          //find out and assign sort direction if the current column is the one sorted currently
          options.sortDirection =
            sortTypeAndColumnMapper[queryParams.sortType].columnName === muiColumnDefinition.name
              ? sortTypeAndColumnMapper[queryParams.sortType].sortDirection
              : "none";
        }

        columnProperties.setCellHeaderProps ? (options.setCellHeaderProps = columnProperties.setCellHeaderProps) : null;
        columnProperties.customBodyRender ? (options.customBodyRender = columnProperties.customBodyRender) : null;

        muiColumnDefinition.options = options;
        columnArray.push(muiColumnDefinition);
      }
    });
    let actions = getEditColumn(taskTableType);
    columnArray.push(actions);
    return columnArray;
  };

  let teamDetail = currentTeamTask && props.teamTasks.find(t => t.id === currentTeamTask[2]);
  return (
    <Paper>
      <MuiThemeProvider theme={tabsTheme}>
        <MuiThemeProvider theme={SuccessMessageTheme()}>
          <GridItem item xs={6} md={6} lg={6} className="float-right">
            {showSuccessMessage ? (
              <div data-testid="successMessage">
                <Typography variant="body1">
                  <span style={{ ...successMessage, ...padding }}>{t("dashboard.savedSuccessfully")}</span>
                  <ClearIcon
                    style={padding}
                    onClick={() => {
                      setShowSuccessMessage(false);
                    }}
                    data-testid="clearIconSuccess"
                  />
                </Typography>
              </div>
            ) : null}
          </GridItem>
        </MuiThemeProvider>
        <Paper className={isItMobile ? "mx-1" : "mxf-3"} square>
          <div style={tabsWrapper}>
            <Tabs
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, newValue) => {
                if (currentTab !== newValue) {
                  props.resetTasks();
                  setActiveQuickFilter(QuickFilter.ALL);
                  setCurrentTab(newValue);
                }
              }}
              aria-label="disabled tabs example"
              style={tabs}
            >
              <Tab label={t("tasks.myWork")} />
              <Tab label={t("tasks.teamWork")} />
            </Tabs>
            <ProcessStarter />
          </div>
        </Paper>
      </MuiThemeProvider>
      {currentTab == 0 ? (
        <Grid>
          <Row>
            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              <TaskTable
                tasks={getMappedTasks(props.pendingTasks)}
                pagination={props.involvedTasksPaging}
                isLoading={props.involvedTasksLoading}
                tasksHeader={tasksHeader(TaskTableType.INVOLVED)}
                fetchTasks={fetchTasks}
                setStatus={props.setStatus}
                assignment={TaskAssignment.INVOLVED}
                title={t("tasks.assignedTasks")}
                status={involvedTasksStatus}
                taskQueryParams={assignedTaskQueryParams}
                noItemsText={getBodyText(assignedTaskQueryParams)}
                filterNewAssignedTask={filterNewAssignedTask}
                onTaskQuickFilter={(filterType) => handleTaskQuickFilter(TaskAssignment.INVOLVED, filterType)}
                activeQuickFilter={activeQuickFilter}
              />
              <TaskTable
                tasks={getMappedTasks(props.candidateTasks)}
                isLoading={props.candidateTasksLoading || isLoading}
                tasksHeader={tasksHeader(TaskTableType.CANDIDATE)}
                pagination={props.candidateTasksPaging}
                fetchTasks={fetchTasks}
                setStatus={props.setStatus}
                assignment={TaskAssignment.CANDIDATE}
                status={candidateTasksStatus}
                title={t("tasks.candidateTasks")}
                taskQueryParams={candidateTaskQueryParams}
                noItemsText={getBodyText(candidateTaskQueryParams)}
              />
            </Cell>
          </Row>
        </Grid>
      ) : (
        <Grid>
          <Row>
            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              {showTeamDetailView && teamDetail ? (
                <TeamTaskDetailView
                  setTeamDetailView={setTeamDetailView}
                  currentTeamTaskParams={{ currentTeamTask, setCurrentTeamTask }}
                  usersList={usersList}
                  reassignTask={reassignTask}
                  assigneeParams={{ assignee, setAssignee }}
                  statusParams={{ status: props.teamTasksStatus, setStatus: props.setStatus }}
                  teamTask={teamDetail}
                />
              ) : (
                <TaskTable
                  tasks={getMappedTasks(props.teamTasks)}
                  pagination={props.teamTasksPaging}
                  isLoading={props.teamTasksLoading}
                  tasksHeader={tasksHeader(TaskTableType.TEAM)}
                  fetchTasks={fetchTasks}
                  setStatus={props.setStatus}
                  assignment={TaskAssignment.TEAM}
                  title={t("tasks.teamTasks")}
                  users={usersList}
                  status={props.teamTasksStatus}
                  taskQueryParams={props.teamTaskQueryParams}
                  noItemsText={getBodyText(props.teamTaskQueryParams)}
                  onTaskQuickFilter={(filterType) => handleTaskQuickFilter(TaskAssignment.TEAM, filterType)}
                  activeQuickFilter={activeQuickFilter}
                />
              )}
            </Cell>
          </Row>
        </Grid>
      )}
      <ConfirmationDialog {...confirmationDialogProps} />
    </Paper>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
