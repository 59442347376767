import { Button, CircularProgress, FormLabel, IconButton, MuiThemeProvider, Tooltip } from "@material-ui/core";
import React from "react";
import { EditRounded, Refresh } from "@material-ui/icons";
import { ButtonTheme, cobalt } from "../../../../theme";

const ThumbnailView = (props: any): JSX.Element => {
  const { getActiveStatus, getLoadingStatus, getTemporaryUpload, getValue, handleRefresh, onClickThumbnail } = props;
  let isDisabled = getActiveStatus(); /* get active status */
  const value = getValue();
  const isLoading = getLoadingStatus(); /* uploading signature to cloud status */
  const temporaryUpload = getTemporaryUpload(); /* current canvasdata */

  const { classes, t } = props.props;

  const { addSignatureText, canvasThumbnail, imageBody, refreshIconButton, signatureButton } = classes;

  var refreshIcon = (): JSX.Element => {
    return (
      <MuiThemeProvider theme={ButtonTheme}>
        <Tooltip placement="bottom" title={t("canvas.refresh") as string}>
          <IconButton className={refreshIconButton}>
            <Refresh onClick={handleRefresh} data-testid="refreshIcon" />
          </IconButton>
        </Tooltip>
      </MuiThemeProvider>
    );
  };
  var addSignatureButton = (): JSX.Element => {
    return (
      <Button onClick={onClickThumbnail} className={signatureButton} data-testid="addSignature" id="addSignature">
        <EditRounded htmlColor={cobalt} />
        <FormLabel component="article" className={addSignatureText}>
          {t("canvas.addSignature")}
        </FormLabel>
      </Button>
    );
  };
  return (
    <span data-testid="thumbnail" id="drawing">
      {isLoading ? (
        <CircularProgress data-testid="loadingProgress" />
      ) : temporaryUpload && temporaryUpload.dataUrl ? (
        <div className={imageBody}>
          <img
            className={canvasThumbnail}
            src={temporaryUpload.dataUrl}
            draggable={false}
            onClick={onClickThumbnail}
            data-testid="temporaryThumbnailImg"
          />
          {refreshIcon()}
        </div>
      ) : value && value.dataUrl ? (
        <img className={canvasThumbnail} src={value.dataUrl} data-testid="thumbnailImg" draggable={false} onClick={onClickThumbnail} />
      ) : !isDisabled ? (
        addSignatureButton()
      ) : null}
    </span>
  );
};

export default ThumbnailView;
