import { AnyAction, createReducer } from "redux-starter-kit";
import { TypedAction, ProcessInstance, ProcessInstances, defaultProcessInstanceState } from "../types";
import Log from "../../core/Log";
import { call, put, takeLatest } from "redux-saga/effects";
import { setProcessInstances, deleteProcessInstanceAction, fetchProcessInstances, setProcessLoadingStatus } from "../actions";
import { fetchForProcessInstances, fetchForDeleteProcessInstance } from "../../services/ProcessInstanceService";
import { fetchWrapper } from "../../core/fetchWrapper";
import { throwError } from "../../core/throwError";

const processInstanceReducer = createReducer<ProcessInstances, AnyAction>(defaultProcessInstanceState, {
  [setProcessInstances.type]: (state: ProcessInstances, action: TypedAction<ProcessInstance[]>) => {
    if (!(action && action.payload)) {
      return state;
    }

    const payload = action.payload;
    state.ProcessInstance = {};
    payload.forEach((pi: ProcessInstance) => {
      state.ProcessInstance[pi.id] = pi;
    });
    state.status = "loaded";
    return state;
  },
  [fetchProcessInstances.type]: (state: ProcessInstances, action: TypedAction<string>) => {
    state.status = "loading";
    return state;
  },
  [setProcessLoadingStatus.type]: (state: ProcessInstances, action: TypedAction<any>) => {
    if (action.payload) {
      state.status = action.payload;
    }
    return state;
  },
  [deleteProcessInstanceAction.type]: (state: ProcessInstances, action: TypedAction<string>) => {
    if (action.payload) {
      delete state.ProcessInstance[action.payload];
    }
    return state;
  },
});
export default processInstanceReducer;

export function* getProcessInstances(action: TypedAction<any>): any {
  try {
    const response: Response = yield call(fetchWrapper, fetchForProcessInstances, action.payload);

    if (response.status === 200) {
      const result: any = yield response.json();
      if (result && result.data && result.data instanceof Array) {
        result.data.forEach((pi: ProcessInstance) => {
          pi.type = action.payload;
          if (pi.ended === null) {
            pi.status = "On-going";
          } else {
            pi.status = "Completed";
          }
        });
      }
      yield put({ type: [setProcessInstances.type], payload: result.data });
    } else {
      throw new Error("Status: " + response.status);
    }
  } catch (error) {
    Log.error(error);
  }
}

export function* watchGetProcessInstances(): any {
  yield takeLatest([fetchProcessInstances.type], getProcessInstances);
}

export function* deleteProcessInstance(action: TypedAction<any>): any {
  try {
    const response: Response = yield call(fetchWrapper, fetchForDeleteProcessInstance, action.payload.processInstanceId);
    if (response.status === 200) {
      yield put({ type: [deleteProcessInstanceAction.type], payload: action.payload.processInstanceId });
      if (action.payload.callback) action.payload.callback(true);
    } else {
      throwError(response);
    }
  } catch (error) {
    Log.error(error);
    if (action.payload.callback) action.payload.callback(false);
  }
}

export function* watchDeleteProcessInstance(): any {
  yield takeLatest([deleteProcessInstanceAction.type], deleteProcessInstance);
}
