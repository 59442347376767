import fetch from "isomorphic-fetch";
import { url } from "./apiConfig";
import { HTTP_METHOD, INCLUDE } from "../constants";

const { account, appDefinitions } = url;
const { GET } = HTTP_METHOD;

export function fetchForAccount() {
  return fetch(account, {
    method: GET,
    credentials: INCLUDE,
  });
}

export function fetchForAppDefinitions() {
  return fetch(appDefinitions, {
    method: GET,
  });
}
