import React from "react"; /* import abstract class for dropdown field */
import { SelectGroupField } from "@juakali/juakali-forms-react";
import DropdownFieldView from "./DropdownFieldView";

class DropdownComponent extends SelectGroupField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <DropdownFieldView {...this} />;
  }
}

export default DropdownComponent;
