import React, { useEffect, useState } from "react";
import { DialogTitle, Dialog, DialogContent, Button, IconButton, MuiThemeProvider, Tooltip, makeStyles } from "@material-ui/core";
import WebcamDialog from "./WebcamDialog";
import { ALERT_MESSAGE_TEXT, ButtonTheme, strongRed } from "../../../../theme";
import CloseIcon from "@material-ui/icons/Close";
import GalleryDialog from "./GalleryDialog";
import { pictureFieldStyles } from "../../styles";
import { url } from "../../../../../services/apiConfig";
import { fetchWrapper } from "../../../../../core/fetchWrapper";
import { CameraIcon } from "../../../../common/CameraIcon";
import Dropzone from "react-dropzone";
import { ACCEPTED_PICTUREFORMATS } from "../../../../../constants";
import { GalleryIcon } from "../../../../common/GalleryIcon";
import Spinner from "../../../../common/Spinner";
import { memo } from "react";
const useStyle = makeStyles((theme: any) => ({
  modalHeight: {
    [theme.breakpoints.up("md")]: {
      height: "251px",
      width: "500px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "251px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "251px",
    },
  },
}));

const uploadTextStatusStyle = (error: boolean) => ({
  imageUploadTextStyle: {
    color: error ? strongRed : ALERT_MESSAGE_TEXT,
  },
});

export default memo(function PictureSelector(props: any): JSX.Element {
  const {
    handleCloseViewScreen,
    handleClose,
    isViewScreen,
    getCurrentShot,
    onClickThumbnail,
    getPictureConfirmationStatus,
    handleGalleryDialog,
    imageCapture,
    getImageQuailty,
  } = props;
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { dialogStyle, pictureSelectorIconContainer } = pictureFieldStyles();
  const { imageUploadTextStyle } = uploadTextStatusStyle(error);
  const WebcamDialogStyle = useStyle();
  let viewScreen = isViewScreen();
  const currentShot = getCurrentShot();
  let { classes, t, name } = props.props;

  const handleError = (value: boolean) => {
    setError(value);
  };
  const exitIcon = (): JSX.Element => {
    return (
      <div className={classes.closeIcon} data-testid="exitIcon">
        <MuiThemeProvider theme={ButtonTheme}>
          <Tooltip placement="bottom" title={t("common.close") as string}>
            <IconButton color="inherit">
              <CloseIcon
                id="exit"
                onClick={() => {
                  handleError(false);
                  viewScreen || !currentShot ? handleCloseViewScreen() : handleClose();
                }}
              />
            </IconButton>
          </Tooltip>
        </MuiThemeProvider>
      </div>
    );
  };
  const handleOnDrop = async (acceptedFiles: any) => {
    setLoading(true);

    if (acceptedFiles && acceptedFiles.length > 0) {
      const image = acceptedFiles[0];

      // Check if the file size exceeds 5MB
      const fileSizeLimit = 5 * 1024 * 1024; // 5MB in bytes
      if (image.size > fileSizeLimit) {
        setLoading(false);
        handleError(true);
        return;
      }
      handleError(false);
      const reader = new FileReader();
      reader.onload = () => {
        const imageElement = new Image();
        imageElement.src = reader.result as string;

        imageElement.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = imageElement.width;
          canvas.height = imageElement.height;

          const context = canvas.getContext("2d");
          if (!context) {
            console.error("Canvas context is null.");
            return;
          }
          context.drawImage(imageElement, 0, 0);
          const pngBlob = canvas.toBlob(blob => {
            if (!blob) {
              console.error("Failed to convert image to PNG.");
              return;
            }
            setSelectedImage(URL.createObjectURL(image));
            // Convert the blob to base64
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String = reader.result as string;
              imageCapture(base64String);
              setLoading(false);
            };
            reader.readAsDataURL(blob);
          }, "image/jpeg");
        };
      };
      reader.onerror = event => {
        console.error("Error occurred while reading the image file:", event);
      };
      reader.readAsDataURL(image);
    }
  };

  return (
    <>
      <Spinner open={loading} />
      <Dialog
        fullWidth={true}
        id={"pictureDialog"}
        open={getPictureConfirmationStatus()}
        classes={{ paper: `${classes.paper}`, paperScrollPaper: `${WebcamDialogStyle.modalHeight}` }}
      >
        <>
          <DialogTitle classes={{ root: `${classes.dialogTitle}` }}>
            <span className={classes.dialogTitleText}>{t("common.selectPicture")}</span>
            {exitIcon()}
          </DialogTitle>

          <DialogContent classes={{ root: `${classes.galleryContent}` }}>
            <div style={dialogStyle}>
              <div style={pictureSelectorIconContainer}>
                <Button
                  data-testid="cameraButton"
                  onClick={() => {
                    onClickThumbnail(url, fetchWrapper);
                    handleGalleryDialog(false);
                    handleError(false);
                  }}
                >
                  <div className={classes.galleryDialogText}>
                    <CameraIcon />
                    {t("common.camera")}
                  </div>
                </Button>
              </div>

              <div style={pictureSelectorIconContainer}>
                <Dropzone onDrop={handleOnDrop} multiple={false} accept={ACCEPTED_PICTUREFORMATS}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button
                        id="galleryButton"
                        data-testid="galleryButton"
                        onClick={() => {
                          handleGalleryDialog(true);
                        }}
                      >
                        <div className={classes.galleryDialogText}>
                          <GalleryIcon />
                          {t("common.gallery")}
                        </div>
                      </Button>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          </DialogContent>

          <DialogContent classes={{ root: `${classes.imageUploadContent}` }}>
            <span style={imageUploadTextStyle}>{error ? t("common.imageUploadErrorMessage") : t("common.imageUploadMessage")}</span>
          </DialogContent>
        </>
      </Dialog>
      <GalleryDialog {...props} selectedImage={selectedImage} />
      <WebcamDialog {...props} />
    </>
  );
});
