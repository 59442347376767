import React from "react";
import { Outcomes as GrapesOutcomes } from "@juakali/juakali-forms-react";
import OutcomesView from "./OutcomesView";

export default class Outcomes extends GrapesOutcomes {
  constructor(props: any) {
    super(props);
  }
  render(): JSX.Element | undefined {
    return <OutcomesView {...this} />
  }
}
