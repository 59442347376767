// @flow
import fetch from "isomorphic-fetch";
import { url } from "./apiConfig";
import { DocumentParams } from "../redux/types";
import { HTTP_METHOD } from "../constants";
const { GET } = HTTP_METHOD;

export function fetchForAllDocuments(): Promise<Response> {
  return fetch(`${url.documentTemplate}/v1/templates`, {
    method: GET,
  });
}

export function generateDoc({ contentId, documentType, processInstanceId }: DocumentParams): Promise<Response> {
  return fetch(`${url.documentTemplate}/raw/${processInstanceId}?contentId=${contentId}&requiredFileFormat=${documentType}`, {
    method: GET,
  });
}
