// @flow
import fetch from "isomorphic-fetch";
import { url } from "./apiConfig";

export function getSettings(): Promise<Response> {
  return fetch(`${url.userSettings}`, {
    method: "GET",
  });
}

export function updateSettings(payload: any): Promise<Response> {
  return fetch(`${url.userSettings}`, {
    method: "PATCH",
    body: JSON.stringify(payload),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
