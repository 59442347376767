import React, { Fragment, useEffect, useState } from "react";
import { Button, CircularProgress, FormHelperText, MuiThemeProvider } from "@material-ui/core";
import { url } from "../../../../services/apiConfig";
import { fetchWrapper } from "../../../../core/fetchWrapper";
import { ACCESS_TEST, CUSTOMTYPE, FIELD_TYPES, STRING } from "../../../../constants";
import { showNotification } from "../../../../util/notification";
import "../../../../Spacing.scss";
import "../styles.scss";
import { accountContext } from "../../../../App";
import { autoFillButtonTheme, outcomesBtnStyle, outcomesViewStyles } from "./style";
import { useTranslation } from "react-i18next";
import { Account } from "../../../../redux/types";
import history from "../../../../core/history";
import { updateFormState } from "../../../../rxjs/formBloc";
import { formLoaderStyle, loaderLogoStyle } from "../../../Dashboard/styles/tableStyles";
import { Grid } from "@material-ui/core";
import { formListStyle } from "../../combinedusertask/styles";

function OutcomesView(props: any): JSX.Element {
  const { t } = useTranslation();

  const [isOutComeButtonDisabled, setIsOutComeButtonDisabled] = useState<boolean>(false);

  let {
    resetView,
    outcomes,
    onSubmit,
    getformError,
    getUploadingError,
    getRetryError,
    getOutcomeStatus,
    getSubmitStatus,
    isItACombinedUserTask,
    autoFill,
    currentProps,
    getFormAutoFillErrorStatus,
    getCutAutoFillErrorStatus,
    getAutofillTriggeredStatus,
    autoFillReset,
    getFormErrorMsg,
  } = props;
  let { type, cutStore } = currentProps;
  const { DRAWING, PICTURE } = FIELD_TYPES;
  const uploadingError: string[] | undefined = getUploadingError();
  const retryError: string[] | undefined = getRetryError();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formHasErrorDuringAutoFill: boolean | undefined = getFormAutoFillErrorStatus();
  const autoFillErrorForm: boolean | string | undefined = getCutAutoFillErrorStatus();
  const isAutoFillTriggered: boolean | undefined = getAutofillTriggeredStatus();
  const isCUT = cutStore ? true : false;
  const { errorMsgStyle, successMsgStyle, autoFillButton, outcomeBtnContainer, formErrorStyle } = outcomesViewStyles(isCUT);
  const formErrorMsg = getFormErrorMsg();
  useEffect(() => {
    if (!isOutComeButtonDisabled) {
      let mdcDrawerContent = (document as HTMLDocument).querySelector(".mdc-drawer-app-content") as HTMLElement;
      mdcDrawerContent && mdcDrawerContent.scroll(0, 0);
    }
  }, [isOutComeButtonDisabled]);

  const handleOutcomeSubmit = (outcomeProps: any): void => {
    const disabledOutcomeAction = getOutcomeStatus();
    if (!disabledOutcomeAction) {
      /* 
       Disable button user clicks for first time to avoid double submission
      */
      setIsLoading(true);
      setIsOutComeButtonDisabled(true);
      onSubmit(outcomeProps);
      /*
        Enable button after successful API call to the server
        We have to enable button again else it will stay in disabled state
        This is handled in Form Library
       */
    } else {
      autoFillReset();
    }
  };

  // Map Outcomes
  const getOutcomes = (outcome: any): JSX.Element => {
    let label = outcome.label;
    let id = `form_${outcome.key}_button`;
    let outcomeProps = {
      outcome,
      fetchWrapper,
      url,
      setIsOutComeButtonDisabled,
    };
    const { outcomeBtn } = outcomesBtnStyle(outcome.key);
    return (
      <Button
        data-testid={id}
        className="outcomeButton"
        onClick={() => {
          handleOutcomeSubmit(outcomeProps);
        }}
        id={id}
        style={outcomeBtn}
        variant="contained"
        color="primary"
      >
        {label}
      </Button>
    );
  };

  const getAutoFillButton = (): JSX.Element | undefined => {
    var acc: Account | undefined = accountContext();
    var privileges = acc && acc.privileges ? acc.privileges : [];
    const isTestUser = privileges.find((privilege: string) => privilege == ACCESS_TEST);
    return isTestUser ? (
      <MuiThemeProvider theme={autoFillButtonTheme(isAutoFillTriggered)}>
        <Button
          data-testid={"autoFill"}
          style={autoFillButton}
          onClick={() => {
            setIsLoading(false);
            autoFill();
          }}
          className="autoFillButton"
          id="autoFill"
          variant="outlined"
          color="primary"
        >
          {t("form.autoFill")}
        </Button>
      </MuiThemeProvider>
    ) : (
      undefined
    );
  };

  const getRequiredOutcomes = (): JSX.Element => {
    switch (type) {
      case "SECTION_SAVE":
      case "ONLY_FORM_SAVE":
        return (
          <>
            {getOutcomes({ key: "save", label: type == "SECTION_SAVE" ? t("common.submit") : t("flow:FORM.ACTION.SAVE") })}
            {getAutoFillButton()}
          </>
        );
      case "SECTION_SUBMIT":
        return (
          <>
            {getOutcomes({ key: "complete", label: t("common.submit") })}
            {getAutoFillButton()}
          </>
        );
      default:
        return (
          <>
            {getOutcomes({ key: "save", label: t("flow:FORM.ACTION.SAVE") })}
            {outcomes && outcomes.type === CUSTOMTYPE && outcomes.outcomeArray && outcomes.outcomeArray.length
              ? outcomes.outcomeArray.map((outcome: any): any => {
                  return getOutcomes(outcome);
                })
              : getOutcomes({ key: "complete", label: t("flow:FORM.ACTION.COMPLETE") })}
            {getAutoFillButton()}
          </>
        );
    }
  };

  const SubmitStatus = (): any => {
    let status = getSubmitStatus();
    switch (status) {
      case "savedTask":
        if (!isItACombinedUserTask) {
          showNotification("success", t("tasks.savedTask"));
          history.push("/");
        }
        break;
      case "completedTask":
        showNotification("success", t("tasks.completedTask"));
        resetView();
        break;
      case "serverErrorTryAgain":
        showNotification("error", t("common.serverErrorTryAgain"));
        break;
      default:
        console.log(status);
        break;
    }
  };
  const AutoFillMessage = () => {
    if (!isAutoFillTriggered) return <></>;
    if (autoFillErrorForm)
      return (
        <FormHelperText style={errorMsgStyle} error={true} data-testid="autoFillErrorForm">
          {typeof autoFillErrorForm === STRING
            ? t("outcome.autoFillCUTSingleFormError", { autoFillErrorForm })
            : t("outcome.autoFillCUTMultipleFormsError")}
        </FormHelperText>
      );
    if (formHasErrorDuringAutoFill)
      return (
        <FormHelperText style={errorMsgStyle} error={true} data-testid="formHasErrorDuringAutoFill">
          {t("outcome.autoFillFormError")}
        </FormHelperText>
      );
    if (!currentProps.cutStore)
      return (
        <FormHelperText style={successMsgStyle} data-testid="successAutoFill">
          {t("outcome.autoFillSuccess")}
        </FormHelperText>
      );
    return <></>;
  };
  const formErrors = (): JSX.Element => {
    return (
      <Fragment>
        {getformError() && (
          <FormHelperText data-testid="outcomeError" style={formErrorStyle}>
            {t(`outcome.${getformError()}`)}
          </FormHelperText>
        )}
        {formErrorMsg ? (
          <FormHelperText id="component-error-text">
            <p style={formListStyle.formHelperTextError}  data-testid="formErrorMsg">
              {t(`tasks.cutErrorMessage${formErrorMsg}`, { autoFillErrorForm })}
            </p>
          </FormHelperText>
        ) : null}
      </Fragment>
    );
  };
  return (
    <div>
      {/* show warning when try to submit with uploading pictures */}
      {uploadingError && uploadingError.includes(PICTURE) && showNotification("warning", t("outcome.pictureUploadingError"))}
      {/* show warning when try to submit with failed to upload pictures */}
      {retryError && retryError.includes(PICTURE) && showNotification("error", t("outcome.pictureRetryError"))}
      {/* show warning when try to submit with uploading signatures */}
      {uploadingError && uploadingError.includes(DRAWING) && showNotification("warning", t("outcome.signatureUploadingError"))}
      {/* show warning when try to submit with failed to upload signatures */}
      {retryError && retryError.includes(DRAWING) && showNotification("error", t("outcome.signatureRetryError"))}
      {/* show if any form errors */}
      {formErrors()}
      {AutoFillMessage()}
      {<Grid style={outcomeBtnContainer}>{getRequiredOutcomes()}</Grid>}
      {SubmitStatus()}

      {isLoading && !getformError() && (
        <div style={formLoaderStyle}>
          <div style={loaderLogoStyle}>
            <CircularProgress size={50} className="loadingAnimation" />
          </div>
        </div>
      )}
    </div>
  );
}
export default OutcomesView;
