import { BehaviorSubject } from "rxjs";

export interface ImageEditorState {
  isZoomVisible: boolean;
  isRotateVisible: boolean;
  isCroppingEnabled: boolean;
  isDisable: boolean;
}

export const defaultImageEditorState = {
  isZoomVisible: false,
  isRotateVisible: false,
  isCroppingEnabled: false,
  isDisable: false,
};

export default class ImageEditorBloc {
  private static instance: ImageEditorBloc;
  private subject: BehaviorSubject<ImageEditorState>;

  private constructor() {
    this.subject = new BehaviorSubject(defaultImageEditorState);
  }

  static getInstance(): ImageEditorBloc {
    if (!ImageEditorBloc.instance) {
      ImageEditorBloc.instance = new ImageEditorBloc();
    }
    return ImageEditorBloc.instance;
  }

  getSubject = (): BehaviorSubject<ImageEditorState> => {
    return this.subject;
  };
  updateState = (value: ImageEditorState): void => {
    this.subject.next({
      ...this.subject.value,
      ...value,
    });
  };
}

export const updateImageEditorBlocState = (args: any): void => {
  const imageEditorBloc = ImageEditorBloc.getInstance();
  imageEditorBloc.updateState(args);
};
