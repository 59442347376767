import { Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, MuiThemeProvider, Radio, RadioGroup } from "@material-ui/core";
import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { getCurrentDropdownOption } from "../../../../../util/helper";
import { DropdownOption } from "../../../../../types/formtypes";
import { dropdownOptionStyle, radioTheme } from "../../styles";
import CustomFieldLabel from "../CustomFieldLabel";
import { checkForAccessTestRole } from "../helper";

const RadioFieldView = (props: any) => {
  let { getActiveStatus, getError, getLabel, getRequiredStatus, getValue, name, onChange, t, getDropdownOptionList } = props;
  let label = getLabel();
  let value = getValue();
  let error = getError();
  let isDisabled = getActiveStatus();
  let required = getRequiredStatus();
  let radioList = getDropdownOptionList();
  const { optionBlock, optionValue } = dropdownOptionStyle();
  const hasAccessTestRole = checkForAccessTestRole();

  let currentValue = getCurrentDropdownOption(radioList, value) as DropdownOption | undefined;

  function StyledRadio(radioProps: any): JSX.Element {
    return <Radio disableRipple {...radioProps} size="small" />;
  }

  function ErrorMessage(): JSX.Element {
    return (
      error ? (
        <FormHelperText key={name} className="errorMessage" data-testid="radioError">
          {t(`validation.${error.type}`, error.params)}
        </FormHelperText>
      ): <></>
    );
  }

  function option(name: string, value: string): JSX.Element {
    return (
      <span style={optionBlock} data-testid="option">
        <span>{name}</span>
        {hasAccessTestRole ? <span style={optionValue}>{`(${value})`}</span> : <></>}
      </span>
    )
  }

  function RadioElement(): JSX.Element {
    return radioList && radioList.length ? (
      <Fragment>
        {radioList.map(({ name: label, value: key }: DropdownOption) => {
          return (
            <FormControlLabel
              onChange={(e: any) => onChange(e.target.value)}
              key={key}
              value={key}
              control={<StyledRadio checked={value === key} />}
              label={option(label, key)}
              data-testid={key}
            />
          );
        })}
      </Fragment>
    ) : (
      <div className="padding-emptyRadio"></div>
    );
  }
  return (
    <div className="field">
      <div className="flex">
        <MuiThemeProvider theme={radioTheme}>
          <FormControl component="head" disabled={isDisabled} required={required}>
            <FormLabel component="article" id={label} required={required} error={!!error} data-testid="radioLabel">
              <CustomFieldLabel label={label} fieldId={name} />
            </FormLabel>
            <RadioGroup defaultValue={value} aria-label={name} name={name} value={currentValue} data-testid="radioField">
              <RadioElement />
            </RadioGroup>
          </FormControl>
        </MuiThemeProvider>
      </div>
      <ErrorMessage />
    </div>
  );
};

export default withTranslation()(RadioFieldView);
