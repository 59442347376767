import React, { useEffect, useState } from "react";

import { ReportTableData, StateType } from "../../redux/types";
import ReportTable from "./ReportTable";
import { toReportDataTable } from "../../services/reportService";
import { connect } from "react-redux";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import { fetchProcessInstances, setProcessLoadingStatus } from "../../redux/actions";
import FullScreenLoader from "../common/Loader";
import { getProcessInstanceState } from "../../redux/selectors/processInstanceSelector";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps extends RouteComponentProps<{ type: string; detailId: string }> {
  //
}

interface StateProps {
  processInstanceState: any;
}

interface DispatchProps {
  fetchProcessInstances: (type: string) => void;
  setProcessLoadingStatus: (type: string) => void;
}

function ReportOverviewTableWithRouter(props: OwnProps & StateProps & DispatchProps): any {
  const { t } = useTranslation();
  let [reportData, setReportData] = useState({});
  let title =
    props.match.params.type === "loan"
      ? t("reports.loanReportHeader")
      : props.match.params.type === "customer"
      ? t("reports.customerReportHeader")
      : t("reports.loanApplicationHeader");

  useEffect(() => {
    if (props.match.params.type === "loan" || props.match.params.type === "loanApplication") {
      props.fetchProcessInstances("LOAN");
    } else if (props.match.params.type === "customer") {
      props.fetchProcessInstances("CUSTOMER");
    }
    mapRecords({}, props.match.params.type);
  }, [props.match.params.type]);

  useEffect(() => {
    if (props.processInstanceState.status == "loaded") {
      mapRecords(props.processInstanceState.ProcessInstance, props.match.params.type);
      setProcessLoadingStatus("unknown");
    }
  }, [props.processInstanceState.status]);

  function mapRecords(data: any, type: string): void {
    var report: ReportTableData;
    if (type === "loanApplication" && Object.keys(data).length) {
      report = toReportDataTable(data, [
        {
          propName: "id",
          header: t("reports.id"),
          type: "string",
          name: "id",
          options: {
            display: false,
            filter: false,
          },
        },
        {
          propName: "loanApplicationDetails.sequenceNumber",
          header: t("reports.id"),
          type: "number",
          name: "sequenceNumber",
          options: {
            filterList: [],
          },
        },
        {
          propName: "loanApplicationDetails.namePart",
          header: t("reports.loanProduct"),
          type: "string",
          name: "loanProduct",
          options: {
            filterList: [],
          },
        },
        {
          propName: "loanApplicationDetails.loanOfficer",
          header: t("reports.loanOfficer"),
          type: "string",
          name: "loanOfficer",
          options: {
            filterList: [],
          },
        },
        {
          propName: "loanApplicationDetails.loanSupervisor",
          header: t("reports.loanSupervisor"),
          type: "string",
          name: "loanSupervisor",
          options: {
            filterList: [],
          },
        },
        {
          propName: "loanApplicationDetails.customerFullName",
          header: t("reports.customerFullName"),
          type: "string",
          name: "customerFullName",
          options: {
            filterList: [],
          },
        },
        {
          propName: "loanApplicationDetails.customerID",
          header: t("reports.customerId"),
          type: "number",
          name: "customerId",
          options: {
            filterList: [],
          },
        },
        {
          propName: "loanApplicationDetails.loanAmount",
          header: t("reports.loanAmount"),
          type: "number",
          name: "loanAmount",
          options: {
            filterList: [],
          },
        },
        {
          propName: "loanApplicationDetails.currency",
          header: t("reports.currency"),
          type: "string",
          name: "currency",
          options: {
            filterList: [],
          },
        },
        {
          propName: "loanApplicationDetails.duration",
          header: t("reports.duration"),
          type: "number",
          name: "duration",
          options: {
            filterList: [],
          },
        },
        {
          propName: "loanApplicationDetails.status",
          header: t("reports.status"),
          type: "string",
          name: "status",
          options: {
            filterList: [],
          },
        },
        {
          propName: "started",
          header: t("reports.applicationDate"),
          type: "date",
          name: "applicationDate",
          options: {
            filterOptions: {
              names: [
                ...new Set(
                  Object.keys(data).map(t => {
                    if (data[t].started) return moment(data[t].started).format("DD/MMM/YYYY");
                  })
                ),
              ],
              logic: (start: any, filters: any) => {
                var date = moment(start).format("DD/MMM/YYYY");
                var ifS = filters.find((fs: any) => fs === date);
                if (ifS) return false;
                else return true;
              },
            },
            customBodyRender: (value: any) => {
              if (value != "") return moment(value).format("DD/MMM/YYYY HH:mm");
              else return "";
            },
          },
        },
        {
          propName: "ended",
          header: t("reports.decisionDate"),
          type: "date",
          name: "decisionDate",
          options: {
            filterOptions: {
              names: [
                ...new Set(
                  Object.keys(data).map(t => {
                    if (data[t].ended) return moment(data[t].ended).format("DD/MMM/YYYY");
                  })
                ),
              ],
              logic: (end: any, filters: any) => {
                var date = moment(end).format("DD/MMM/YYYY");
                var ifE = filters.find((fe: any) => fe === date);
                if (ifE) return false;
                else return true;
              },
            },
            customBodyRender: (value: any) => {
              if (value != "") return moment(value).format("DD/MMM/YYYY HH:mm");
              else return "";
            },
          },
        },
      ]);
      setReportData(report);
    } else if (Object.keys(data).length) {
      report = toReportDataTable(data, [
        {
          propName: "id",
          header: t("reports.id"),
          type: "string",
          name: "id",
          options: {
            display: false,
            filter: false,
          },
        },
        {
          propName: "name",
          header: t("reports.name"),
          type: "string",
          name: "name",
          options: {
            filterList: [],
          },
        },
        {
          propName: "status",
          header: t("reports.status"),
          type: "string",
          name: "status",
          options: {
            filterList: [],
          },
        },
        {
          propName: "started",
          header: t("reports.startDate"),
          type: "date",
          name: "started",
          options: {
            filterOptions: {
              names: [
                ...new Set(
                  Object.keys(data).map(t => {
                    if (data[t].started) {
                      return moment(data[t].started).format("DD/MMM/YYYY");
                    }
                  })
                ),
              ],
              logic: (start: any, filters: any) => {
                var date = moment(start).format("DD/MMM/YYYY");
                var ifS = filters.find((fs: any) => fs === date);
                if (ifS) return false;
                else return true;
              },
            },
            customBodyRender: (value: any) => {
              if (value != "") return moment(value).format("DD/MMM/YYYY");
              return "";
            },
          },
        },
        {
          propName: "ended",
          header: t("reports.endDate"),
          type: "date",
          name: "ended",
          options: {
            filterOptions: {
              names: [
                ...new Set(
                  Object.keys(data).map(t => {
                    if (data[t].ended) {
                      return moment(data[t].ended).format("DD/MMM/YYYY");
                    }
                  })
                ),
              ],
              logic: (start: any, filters: any) => {
                var date = moment(start).format("DD/MMM/YYYY");
                var ifS = filters.find((fs: any) => fs === date);
                if (ifS) return false;
                else return true;
              },
            },
            customBodyRender: (value: any) => {
              if (value != "") return moment(value).format("DD/MMM/YYYY");
              return "";
            },
          },
        },
        {
          propName: "startedBy.fullName",
          header: t("reports.startedBy"),
          name: "startedBy",
          type: "string",
          options: {
            filterList: [],
          },
        },
      ]);
      setReportData(report);
    } else {
      setReportData({});
    }
  }

  function showDetails(id: string): void {
    props.history.push(`/report/${props.match.params.type}/${id}`);
  }

  // if no data show a simple message
  if (!reportData) {
    return <div>{t("reports.noDataAvailable")}</div>;
  }

  // if data we return a table with the
  return reportData && props.processInstanceState.status !== "loading" ? (
    <ReportTable tableData={reportData} onRowClick={showDetails} title={title} type={props.match.params.type} />
  ) : (
    <FullScreenLoader />
  );
}

function mapStateToProps(state: StateType, ownProps: OwnProps): StateProps {
  const processInstanceState = getProcessInstanceState(state);
  return {
    processInstanceState,
  };
}

const mapDispatchToProps: DispatchProps = {
  fetchProcessInstances: (type: any) => fetchProcessInstances(type),
  setProcessLoadingStatus: (type: string) => setProcessLoadingStatus(type),
};

const ReportOverviewTable = connect<StateProps, DispatchProps, OwnProps, StateType>(
  mapStateToProps,
  mapDispatchToProps
)(ReportOverviewTableWithRouter);

export default withRouter(ReportOverviewTable);
