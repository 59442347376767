import { makeStyles } from "@material-ui/styles";
import { Padding } from "../../../../constants";

interface IconProps {
  displayBothIcons?: boolean;
}

export const paddingMultiBoxStyles = (component: any) => {
  const { attributes } = component;
  let { paddingTop, paddingLeft, paddingBottom, paddingRight } = attributes;
  const { PADDINGBOTTOM, PADDINGRIGHT, PADDINGLEFT, PADDINGTOP } = Padding;
  Object.values(Padding).forEach((attribute: string) => {
    switch (attribute) {
      case PADDINGBOTTOM:
        paddingBottom = `${paddingBottom || 32}px`;
        break;
      case PADDINGLEFT:
        paddingLeft = `${paddingLeft || 16}px`;
        break;
      case PADDINGRIGHT:
        paddingRight = `${paddingRight || 16}px`;
        break;
      case PADDINGTOP:
        paddingTop = `${paddingTop || 0}px`;
        break;
    }
  });
  return { paddingLeft, paddingBottom, paddingTop, paddingRight };
};

export const useStyles = makeStyles({
  removeIcon: {
    right: "0px",
  },
  text: { fontSize: "20px", lineHeight: "23px", margin: "16px 0px 13px 16px", width: "100%" },
  addIcon: ({ displayBothIcons }: IconProps) => ({
    right: `${displayBothIcons ? 32 : 0}px`,
  }),
  emptyBox: {
    height: "30px",
  },
});
