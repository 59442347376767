import React from "react";
import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { NumberTextViewProps } from "../types";
import { EVENT } from "../../../../../../constants";
import { styles } from "../../../styles";

export const renderNumberTextView = ({ commonFieldProps, handlers }: NumberTextViewProps): JSX.Element => {
  const { onChange } = handlers;
  const { formulaTextStyle } = styles({});

  return (
    <NumberFormat
      {...commonFieldProps}
      customInput={TextField}
      thousandSeparator={" "}
      inputProps={{
        max: "",
        style: formulaTextStyle as Object,
      }}
      onValueChange={(values: any, sourceInfo: any) => {
        const { source } = sourceInfo;
        // Source tells whether the reason for this function being triggered was an 'event' or due to a 'prop' change
        if (source === EVENT) {
          const { value } = values;
          onChange(value);
        }
      }}
      data-testid="numberField"
    />
  );
};
