import React from "react";

import { Grid } from "@material-ui/core";

import { RouteComponentProps, withRouter } from "react-router";
import ReportDetailTable from "./ReportDetailTable";
import ReportOverviewTable from "./ReportOverviewTable";
import { useTranslation } from "react-i18next";
import { reportStylesheet } from "./reportStyles";

function Report(props: RouteComponentProps<{ type: string; detailId?: string }>): any {
  const { t } = useTranslation();

  let reportBlock;
  if (props.match.params.detailId) {
    reportBlock = <ReportDetailTable />;
  } else {
    reportBlock = <ReportOverviewTable />;
  }

  return (
    <Grid style={reportStylesheet.background}>
      {reportBlock}
      {/* <Row>
        <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
          <Headline6 style={{ paddingLeft: "20px" }}>
            {props.match.params.type === "loan"
              ? t("reports.loanReportHeader")
              : props.match.params.type === "customer"
              ? t("reports.customerReportHeader")
              : t("reports.loanApplicationHeader")}
          </Headline6>
        </Cell>
      </Row>
      <Row>
        <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
          {reportBlock}
        </Cell>
      </Row> */}
    </Grid>
  );
}

export default withRouter(Report);
