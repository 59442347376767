import i18n from "../../i18n";
import { ConfirmationDialogProps } from "../common/ConfirmationDialog";
import { CUT_FORM, CUT_FORM_PATH, DEVELOPMENT, FORM_DESIGNER_PATH, GRAPESJS_FORM, LOCALHOST } from "./constants";
export const defaultConfirmationPropertiesOnBackClick = (): ConfirmationDialogProps => ({
  open: false,
  title: i18n.t("translation:confirmation.cancelChangesTitle"),
  content: i18n.t("translation:confirmation.goBackContent", { name: "" }),
  primaryLabel: i18n.t("translation:reports.backToList"),
  secondaryLabel: i18n.t("translation:confirmation.stayOnPage"),
});


  export const getCurrentFormId = (grapesForms: any, currentForm: string): string => {
    const currentFrom = grapesForms.find((form: any) => form.key === currentForm);
    return currentFrom.id;
  };

  export const redirectToFormDesigner = (formType: string, baseUrl: string, sutId: string, formId: string): void => {
    switch (formType) {
      case GRAPESJS_FORM:
         window.open(`${baseUrl}/${FORM_DESIGNER_PATH}/${formId}`);
      case CUT_FORM:
         window.open(`${baseUrl}/${CUT_FORM_PATH}/${sutId}`);
    }
  };

 export const getRedirectBaseUrl = (): string => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === DEVELOPMENT) {
      // dev code
      return LOCALHOST
    } else {
      //production code
      const base_url = window.location.origin;
      return base_url
    }
  };

