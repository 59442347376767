import React from "react";
import { aviaryBlue, cobalt } from "../theme";

interface FormulaIconProps {
  backgroundColor?: string;
  iconColor?: string;
}

const FormulaIcon = (props: FormulaIconProps): JSX.Element => {
  const { backgroundColor, iconColor } = props;
  const currentColor = backgroundColor || aviaryBlue;
  const icon = iconColor || cobalt;

  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="10" transform="matrix(1 0 0 -1 10 10)" fill={currentColor} />
    <path
      d="M10.1249 4.76033C9.48194 4.2781 8.56158 4.71301 8.52589 5.51593L8.47474 6.66667H10C10.3682 6.66667 10.6667 6.96515 10.6667 7.33334C10.6667 7.70153 10.3682 8.00001 10 8.00001H8.41548L8.15326 13.9001C8.0664 15.8543 5.75788 16.8407 4.28572 15.5525L4.22768 15.5017C3.95058 15.2593 3.9225 14.8381 4.16496 14.561C4.40742 14.2839 4.82859 14.2558 5.10568 14.4983L5.16372 14.5491C5.79465 15.1011 6.78402 14.6784 6.82124 13.8409L7.08084 8.00001H6C5.63182 8.00001 5.33334 7.70153 5.33334 7.33334C5.33334 6.96515 5.63182 6.66667 6 6.66667H7.1401L7.19387 5.45673C7.27714 3.58323 9.4246 2.56846 10.9249 3.69367L11.0667 3.8C11.3612 4.02091 11.4209 4.43879 11.2 4.73333C10.9791 5.02789 10.5612 5.08758 10.2667 4.86667L10.1249 4.76033Z"
      fill={icon}
    />
    <path
      d="M10.7213 10.6975C10.8899 10.6047 11.1017 10.6735 11.1836 10.8476L11.7253 11.9986L9.52852 14.1953C9.26819 14.4556 9.26819 14.8777 9.52852 15.1381C9.78892 15.3985 10.211 15.3985 10.4713 15.1381L12.3287 13.2808L12.8486 14.3857C13.3117 15.3699 14.5782 15.6539 15.4173 14.9617L15.7575 14.6809C16.0415 14.4466 16.0819 14.0265 15.8475 13.7424C15.6132 13.4584 15.1931 13.4181 14.9091 13.6525L14.5687 13.9332C14.4009 14.0716 14.1477 14.0148 14.055 13.818L13.3307 12.2788L15.138 10.4714C15.3984 10.2111 15.3984 9.78893 15.138 9.5286C14.8777 9.26827 14.4556 9.26827 14.1952 9.5286L12.7273 10.9965L12.39 10.2799C11.9805 9.4096 10.9215 9.06567 10.0788 9.52913L9.67865 9.7492C9.35605 9.92667 9.23839 10.332 9.41579 10.6547C9.59325 10.9773 9.99865 11.0949 10.3213 10.9175L10.7213 10.6975Z"
      fill={icon}
    />
  </svg>
}

export default FormulaIcon;
