import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { JuakaliModel, GrapesComponent } from "../../../types/formtypes";
/* load form styles */
import "./styles.scss";
import "../../../Spacing.scss";
import { GrapesJSFormController } from "@juakali/juakali-forms-react";
import { Task, ProcessInstance } from "../../../redux/types";
import { DynamicJson } from "../../../datastore/dataStoreTypes";
import HeaderBase from "../../TeamTask/HeaderBase";
import { Grid } from "@material-ui/core";
import { KeyValuePair } from "../type";
import { loaderWrapper } from "../../Dashboard/styles/tableStyles";

interface OwnProps extends RouteComponentProps<{ processInstanceId?: string }> {
  formData: JuakaliModel<GrapesComponent>;
  task: Task;
  processInstance: ProcessInstance;
  variables: DynamicJson;
  processVariables: any;
  taskId: string;
  dictionaries: any;
  resetView: () => void;
  showOutcomes: boolean;
  disableAllFields: boolean;
  disableValidation: boolean;
  showHeaders: boolean;
  submitVariablesOnSave: boolean;
  fields: KeyValuePair;
  outcomeStore: {
    [key: string]: {
      subject: any;
      state: any;
      init(): void;
      subscribe(): void;
    };
  };
  elements: {
    [key: string]: {
      subject: any;
      state: any;
      init(): void;
      subscribe(): void;
      updateValue(): void;
      getFormula(): void;
    };
  };
}

function GrapesForm(props: OwnProps): JSX.Element {
  const { t } = useTranslation(["translation", "flow"]);
  let { task, processInstance } = props;
  const processName = processInstance && (processInstance.name || processInstance.processDefinitionName);
  return (
    <div className="background-color" data-testid="SUT">
      {/* Header */}
      {
        <Grid className="sutContainer">
          <HeaderBase
            taskDetails={{
              ...task,
              processDefinitionName: processName,
              assigneeName: task && task.assignee ? task.assignee.firstName : t("dashboard.notAssigned"),
            }}
          />
        </Grid>
      }
      {/* Grapes Form Fields */}
      {GrapesJSFormController(props)}
    </div>
  );
}

export default withRouter(GrapesForm);
