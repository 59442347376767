import moment from "moment";
import { TWO_DIGIT, NUMERIC, DATE_TIME_FORMAT } from "../constants";
import Log from "../core/Log";
import { DateOptions } from "../types/commonTypes";
import i18next from "i18next";

const defaultDisplayFormat = "DD MMM YYYY";
const dateDisplayFormat = "YYYY-MM-DD";

const DateUtils = {
  formatDisplay(date?: Date, contentOnError: string = ""): string {
    if (date) {
      try {
        return moment(date).format(defaultDisplayFormat);
      } catch (e) {
        Log.error(e);
      }
    }
    return contentOnError;
  },
};

export default DateUtils;

export const toLocaleShortDate = (date: string | Date): string => {
  const options: DateOptions = { day: TWO_DIGIT, month: TWO_DIGIT, year: NUMERIC };
  return new Date(date).toLocaleDateString(window.navigator.language, options);
};

export const toLocaleDateString = (dueDate: string | Date) => {
  return moment(dueDate).format(dateDisplayFormat);
};

export const toTimeFromNow = (date: string | Date): string => {
  try {
    const dateDiffInMilliseconds = moment().diff(moment(new Date(date)));
    if (isNaN(dateDiffInMilliseconds)) throw new Error();
    var minutes = Math.round(dateDiffInMilliseconds / 60000);

    const isFrenchLanguage = i18next.language === "fr";
    const TASKS_AGO = i18next.t("tasks.ago");
    const TASKS_D = i18next.t("tasks.d");
    const TASKS_H = i18next.t("tasks.h");
    const TASKS_M = i18next.t("tasks.m");

    if (minutes === 0) return isFrenchLanguage ? `(${TASKS_AGO} 1${TASKS_M})` : `(1${TASKS_M} ${TASKS_AGO})`;

    var d = Math.floor(minutes / (60 * 24));
    var h = Math.floor((minutes % (60 * 24)) / 60);
    var m = Math.floor(minutes % 60);

    if (d > 0) return isFrenchLanguage ? `(${TASKS_AGO} ${d}${TASKS_D})` : `(${d}${TASKS_D} ${TASKS_AGO})`;
    if (h > 0) return isFrenchLanguage ? `(${TASKS_AGO} ${h}${TASKS_H})` : `(${h}${TASKS_H} ${TASKS_AGO})`;
    return isFrenchLanguage ? `(${TASKS_AGO} ${m}${TASKS_M})` : `(${m}${TASKS_M} ${TASKS_AGO})`;
  } catch (e) {
    return "--";
  }
};

export const toLocalDateTime = (date: string | Date): string => {
  return moment
    .parseZone(date)
    .local()
    .format(DATE_TIME_FORMAT);
};
