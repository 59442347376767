import React, { useEffect, useState } from "react";

import { StateType, Task, ProcessInstance, defaultProcessInstance, TaskForm, Document, DocumentParams, scope } from "../../redux/types";
import { Button, Paper, ClickAwayListener, Grow, Popper, MenuItem, MenuList } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { fetchCompletedTasksByProcessInstanceId } from "../../redux/actions";
import { fetchDocuments, generateDocument } from "../../redux/actions";
import { getTasksForProcess } from "../../redux/selectors/taskSelector";
import { getTaskForms } from "../../redux/selectors/taskFormSelector";
import { getDocuments } from "../../redux/selectors/documentSelector";
import { Headline6, Overline } from "@material/react-typography";
import ReportDetailTaskTable from "./ReportDetailTaskTable";
import DateUtils from "../../util/dateUtils";
import { getProcessInstance } from "../../redux/selectors/processInstanceSelector";
import { reportStylesheet } from "./reportStyles";
import { EXCEL, REPORTS } from "../../constants";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps extends RouteComponentProps<{ type: string; detailId: string }> {
  //
}

interface StateProps {
  tasks: Task[];
  processInstance: ProcessInstance;
  taskForm: TaskForm[];
  documents: Document[];
}

interface DispatchProps {
  fetchCompletedTasksByProcessInstanceId: (processInstanceId: string) => {};
  fetchDocuments: (scope: scope) => {};
  generateDocument: (payload: DocumentParams) => any;
}

function ReportDetailTableRouter(props: OwnProps & StateProps & DispatchProps): any {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  let title =
    props.match.params.type === "loan"
      ? t("reports.loanReportHeader")
      : props.match.params.type === "customer"
      ? t("reports.customerReportHeader")
      : t("reports.loanApplicationHeader");

  useEffect(() => {
    if (props.match.params.detailId && props.tasks.length === 0) {
      props.fetchCompletedTasksByProcessInstanceId(props.match.params.detailId);
      props.fetchDocuments({ scopeCategory: "LOAN_PRODUCT", scopeId: "DocumentTemplate" });
    }
  }, [props.match.params.detailId]);

  function prepareLoanData(): any {
    let { processInstance, taskForm } = props;
    let mapTasks = {};
    if (processInstance) {
      let exportData = {
        [t("reports.name")]: processInstance.name,
        [t("reports.startedBy")]: processInstance.startedBy.fullName,
        [t("reports.startDate")]: processInstance.started,
        [t("reports.endDate")]: processInstance.ended,
      };
      mapTasks = Object.assign(
        {},
        ...taskForm.map((t: TaskForm) => {
          return Object.assign(
            {},
            ...(t.fields &&
              t.fields.map(f => {
                var obj: { [k: string]: any } = {};
                obj[f.name] = f.value;
                return obj;
              }))
          );
        })
      );
      return [Object.assign({}, exportData, mapTasks)];
    }
    return undefined;
  }
  return (
    <div>
      <Headline6 style={reportStylesheet.spacing}>{title}</Headline6>
      <Button style={reportStylesheet.marginLeft} color="primary" onClick={() => props.history.push("/report/" + props.match.params.type)}>
        <ArrowBack />
        {t("reports.backToList")}
      </Button>
      <div className="mx-1 my-1 px-2 py-1">
        <CSVLink
          className="float-right text-decoration-none"
          data={prepareLoanData()}
          filename={`${props.match.params.type}_report_${new Date().toJSON()}.csv`}
        >
          <Button color="primary">{t("reports.export")}</Button>
        </CSVLink>
        <div className="float-right">
          <Button aria-owns={open ? "menu-list-grow" : undefined} aria-haspopup="true" color="primary" onClick={() => setOpen(!open)}>
            {t("reports.generate")}
          </Button>
          <Popper style={{ zIndex: 2, position: "absolute" }} open={open} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener
                    onClickAway={e => {
                      setOpen(false);
                    }}
                  >
                    <MenuList>
                      {props.documents.map((doc, i) => (
                        <MenuItem
                          key={i}
                          onClick={e => {
                            setOpen(false);
                            props.generateDocument({ processInstanceId: props.match.params.detailId, contentId: doc.id, setStatusForDocumentGeneration:undefined, type: REPORTS, label: "", documentType: EXCEL });
                          }}
                        >
                          {doc.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <Headline6 className="float-left">{props.processInstance.name}</Headline6>
        <Overline>{t("reports.startedByName", { name: props.processInstance.startedBy.fullName })}</Overline>
        <br />
        <Overline>{t("reports.startDateDate", { date: DateUtils.formatDisplay(props.processInstance.started, t("common.noValidDate")) })}</Overline>
        <br />
        <Overline>{t("reports.endDateDate", { date: DateUtils.formatDisplay(props.processInstance.ended) })}</Overline>
      </div>
      {props.tasks.map((task: Task) => (
        <ReportDetailTaskTable key={task.id} task={task} />
      ))}
    </div>
  );
}

function mapStateToProps(state: StateType, ownProps: OwnProps): StateProps {
  const tasks: Task[] = ownProps.match.params.detailId ? getTasksForProcess(state, ownProps.match.params.detailId) : [];
  const processInstanceNull = ownProps.match.params.detailId ? getProcessInstance(state, ownProps.match.params.detailId) : undefined;
  const processInstance: ProcessInstance = processInstanceNull ? processInstanceNull : defaultProcessInstance;
  const taskForm: TaskForm[] = ownProps.match.params.detailId ? getTaskForms(state, tasks) : [];
  const documents: Document[] = ownProps.match.params.detailId ? getDocuments(state) : [];
  return {
    tasks,
    processInstance,
    taskForm,
    documents,
  };
}

const mapDispatchToProps: DispatchProps = {
  fetchCompletedTasksByProcessInstanceId: (processInstanceId: string) => fetchCompletedTasksByProcessInstanceId(processInstanceId),
  fetchDocuments: (scope: scope) => fetchDocuments(scope),
  generateDocument: (payload: DocumentParams) => generateDocument(payload),
};

const ReportDetailTable = connect<StateProps, DispatchProps, OwnProps, StateType>(
  mapStateToProps,
  mapDispatchToProps
)(ReportDetailTableRouter);

export default withRouter(ReportDetailTable);
