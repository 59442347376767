export const PendingTasksActions = {
  SET_TASKS: 'SET_TASKS',
  ADD_TASK: 'ADD_TASK',
  REMOVE_TASK: 'REMOVE_TASK',
}

export const ProcessInstanceType = {
  LOAN: 'LOAN',
  CUSTOMER: 'CUSTOMER',
  UNKNOWN: 'UNKNOWN'
}
