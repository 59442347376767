// @flow strict
import i18n from "i18next";
import { AnyAction, createReducer } from "redux-starter-kit";
import { call, put, takeLatest } from "redux-saga/effects";
import { TypedAction, Document, DocumentData, defaultDocumentState, DocumentParams } from "../types";
import Log from "../../core/Log";
import { setDocuments, fetchDocuments, generateDocument } from "../actions";
import { fetchForAllDocuments, generateDoc } from "../../services/DocumentService";
import { fetchWrapper } from "../../core/fetchWrapper";
import { showNotification } from "../../util/notification";
import { STATUS_CODES } from "../../constants";
import { downloadFile } from "../selectors/documentSelector";

const documentReducer = createReducer<DocumentData, AnyAction>(defaultDocumentState, {
  [setDocuments.type]: (state: DocumentData, action: TypedAction<Document[]>) => {
    if (!(action && action.payload)) {
      return state;
    }
    const payload = action.payload;
    payload.forEach((document: Document): void => {
      state.Documents[document.id] = document;
    });
    return state;
  },
  [fetchDocuments.type]: (state: DocumentData) => {
    return state;
  },
  [generateDocument.type]: (state: DocumentData) => {
    return state;
  },
});

export default documentReducer;

export function* generateForDocuments(action: TypedAction<DocumentParams>): any {
  try {
    var generateDocumentParams = action.payload;
    const response: Response = yield call(fetchWrapper, generateDoc, 
      { processInstanceId: generateDocumentParams!.processInstanceId, contentId: generateDocumentParams!.contentId, documentType: generateDocumentParams!.documentType});
      generateDocumentParams!.setStatusForDocumentGeneration(response.status);
    if (response.status === 200) {
      const body: any = yield response.blob();
      generateDocumentParams && downloadFile(body, generateDocumentParams)
    } else {
      throw new Error(String(response.status));
    }
  } catch (error) {
    const { message } = error as { message: any };
    const { SERVER_TOO_BUSY, NOT_AUTHENTICATED, FORBIDDEN } = STATUS_CODES;
    /** check whether notification needed or not. Disabled for the below errors,
     * rate limit error, 
     * session out, 
     * forbidden error, 
     * flag from input args - type == 'reports') */
    const notifyOnApiError = message != SERVER_TOO_BUSY && message != NOT_AUTHENTICATED && message != FORBIDDEN && generateDocumentParams!.type == "reports";
    generateDocumentParams!.setStatusForDocumentGeneration(message);
    notifyOnApiError ? showNotification("error", i18n.t("common.serverErrorTryAgain")) : undefined;
    Log.error("Status: " + error);
  }
}

export function* watchGenerateForDocument(): any {
  yield takeLatest([generateDocument.type], generateForDocuments);
}

export function* generateFetchForDocuments(action: TypedAction<any>): any {
  try {
    const response: Response = yield call(fetchWrapper, fetchForAllDocuments, action.payload);
    if (response.status === 200) {
      const body: any = yield response.json();
      yield put({ type: [setDocuments.type], payload: body.data });
    } else {
      throw new Error(response.status as any);
    }
  } catch (error) {
    Log.error(error);
    if (error.message && error.message != 401 && error.message != 403) {
      showNotification("error", i18n.t("common.serverErrorTryAgain"));
    }
  }
}

export function* watchFetchForDocument(): any {
  yield takeLatest([fetchDocuments.type], generateFetchForDocuments);
}
