import { makeStyles } from "@material-ui/styles";

export const dialogStyles = makeStyles({
  dialogPaper: (props: any) => ({
    [props.theme && props.theme.breakpoints.between("xs", "xs")]: {
      height: props.fullScreen ? "100vh" : "75vh",
      width: props.fullScreen ? "100vh" : "80vh",
    },
    [props.theme && props.theme.breakpoints.between("sm", "sm")]: {
      height: props.fullScreen ? "100vh" : "70vh",
      width: props.fullScreen ? "100vh" : "70vh",
    },
    [props.theme && props.theme.breakpoints.up("md")]: {
      height: props.fullScreen ? "100vh" : "80vh",
      width: props.fullScreen ? "100vh" : "70vh",
    },
  }),
  paperFullWidth: (props: any) => ({
    maxWidth: props.fullScreen ? "100vw" : "",
  }),
  dialogWidth: { width: 420 },
  dialogContent: {
    overflow: "hidden",
    fontSize: 16,
    color: '#000000',
    letterSpacing: "0.02em",
    
  },
  dialogActions: { flexFlow: "wrap", marginRight: 13 },
  dialogAction: { marginBottom: "10px" },
  dialogPadding: { padding: "0px 24px" },
});
