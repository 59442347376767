import React, { Fragment, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Logout from "./LogoutComponent";
import { logoutStyles } from "../styles";
import { AccountContext } from "../../../App";

export const LogoutView = (props: any) => {
  const { open, handleClose, handleLogout, handleClick } = props;

  const classes: any = logoutStyles();

  const accountContext = useContext(AccountContext);

  const renderUserInfo = () => {
    if (accountContext) {
      return (
        <div className={classes.userInfoBlock} onClick={handleClick}>
          <Typography variant="overline" className={classes.bold + " " + classes.userInfoText} color="primary" display="block">
            {(accountContext ? accountContext.email : "").split("@")[0]}
          </Typography>
          <AccountCircleOutlinedIcon className={classes.userIcon} />
        </div>
      );
    }
    return <div />;
  };

  return (
    <Fragment>
      {renderUserInfo()}
      {accountContext && <Logout open={open} handleClose={handleClose} handleLogout={handleLogout} />}
    </Fragment>
  );
};
