import React from "react";
import { CombinedUserTask } from "@juakali/juakali-forms-react";
import { combinedUserTaskStyle} from "./styles";
import { Grid } from "@material-ui/core";

export const CombinedUserTaskContainer = (props: any): JSX.Element => {
  const {handleGoBackOnTeamView} = props 
  const{ gridStyle } = combinedUserTaskStyle(!handleGoBackOnTeamView)

  return (
    <Grid style={gridStyle as Object} data-testid="CUT">
      {/* Grapes Form Fields */}
      {CombinedUserTask(props)}
    </Grid>
  );
};
