import React from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { DynamicJson, JuakaliUser } from "../../../redux/types";
import { getCurrentAssignee } from "../utils/dashboardHelper";
import { CLEAR, SELECT_OPTION } from "../../../constants";
import { assigneeColumnTheme } from "../styles/dashboardStyles";
import SearchableDropdown from "../../common/SearchableDropdown";

function SearchAssignee(props: {
  currentTeamTask?: (string | undefined)[] | null;
  usersList?: JuakaliUser[];
  setAssignee?: (parameter?: JuakaliUser) => void;
  assignee?: JuakaliUser;
  tableMeta: DynamicJson;
  value?: string;
}): JSX.Element {
  let { currentTeamTask, usersList, setAssignee, tableMeta, value, assignee } = props;
  usersList = usersList || [];
  return currentTeamTask && currentTeamTask[2] === tableMeta.rowData[2] ? (
    <MuiThemeProvider theme={assigneeColumnTheme}>
      <SearchableDropdown
        handleOnChange={(_: object, value: JuakaliUser | undefined, reason: string) => {
          if (setAssignee) {
            if (reason === SELECT_OPTION) return setAssignee(value);
            else if (reason === CLEAR) return setAssignee(undefined);
          }
        }}
        defaultValue={getCurrentAssignee(assignee ? assignee.id : tableMeta.rowData[10], usersList) as JuakaliUser | undefined}
        options={usersList}
      />
    </MuiThemeProvider>
  ) : (
    <span>{value}</span>
  );
}

export default SearchAssignee;
