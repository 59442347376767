// import { url } from './apiConfig';
import fetch from 'isomorphic-fetch'

// Task service
// angular.module('flowableApp').service('TaskService', ['$http', '$q', '$rootScope', 'RelatedContentService',
//    function  ($http, $q, $rootScope, RelatedContentService) {

// fake the flowable contextRoot
const FLOWABLE = { CONFIG: { contextRoot: process.env.REACT_APP_TASK_HOST } }

export function httpAsPromise (info) {
  const options = {}
  if (info.data) {
    options.body = JSON.stringify(info.data)
    options.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }

  options.method = info.method

  return fetch(info.url, options)

  // var deferred = $q.defer();
  // $http(options).
  //     success(function (response, status, headers, config) {
  //         deferred.resolve(response);
  //     })
  //     .error(function (response, status, headers, config) {
  //         deferred.reject(response);
  //     });
  // return deferred.promise;
}

export function getProcessInstanceTasks (processInstanceId, isCompleted) {
  var data = {
    processInstanceId: processInstanceId
  }

  if (isCompleted) {
    data.state = 'completed'
  }

  return httpAsPromise(
    {
      method: 'POST',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/query/tasks',
      data: data
    }
  )
}

export function getCaseInstanceTasks (caseInstanceId, isCompleted) {
  var data = {
    caseInstanceId: caseInstanceId
  }

  if (isCompleted) {
    data.state = 'completed'
  }

  return httpAsPromise(
    {
      method: 'POST',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/query/tasks',
      data: data
    }
  )
}

export function involveUserInTask (userId, taskId) {
  var involveData = {
    userId: userId
  }

  var promise = httpAsPromise(
    {
      method: 'PUT',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks/' + taskId + '/action/involve',
      data: involveData
    }
  )

  return promise
}

export function involveUserInTaskByEmail (email, taskId) {
  var involveData = {
    email: email
  }

  var promise = httpAsPromise(
    {
      method: 'PUT',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks/' + taskId + '/action/involve',
      data: involveData
    }
  )

  return promise
}

export function removeInvolvedUserInTask (user, taskId) {
  var removeInvolvedData = {}
  if (user.id !== null && user.id !== undefined) {
    removeInvolvedData.userId = user.id
  } else {
    removeInvolvedData.email = user.email
  }

  var promise = httpAsPromise(
    {
      method: 'PUT',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks/' + taskId + '/action/remove-involved',
      data: removeInvolvedData
    }
  )

  return promise
}

export function queryTasks (data) {
  return httpAsPromise(
    {
      method: 'POST',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/query/tasks',
      data: data
    }
  )
}

/**
 * Simple completion of a task without submitting form-data.
 */
export function completeTask (taskId) {
  return httpAsPromise(
    {
      method: 'PUT',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks/' + taskId + '/action/complete'
    })

  // TODO check if we need the rootscope broadcasts
  // var deferred = $q.defer();
  // $http( {
  //     method: 'PUT',
  //     url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks/' + taskId + '/action/complete'
  // }).
  // success(function (response, status, headers, config) {
  //     $rootScope.$broadcast('task-completed', {taskId: taskId});
  //     deferred.resolve(response);
  // })
  // .error(function (response, status, headers, config) {
  //     $rootScope.addAlert(response.message, 'error');
  //     deferred.reject(response);
  // });

  // var promise = deferred.promise;
  // return promise;
}

export function claimTaskCall (taskId) {
  var promise = httpAsPromise(
    {
      method: 'PUT',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks/' + taskId + '/action/claim'
    }
  )

  return promise
}

export function getRelatedContent (taskId) {
  // TODO implement when needed
  throw new Error('Not implemented expception [FTaskService.getRelatedContent(taskId)]')
  // var deferred = $q.defer();
  // $http({
  //     method: 'GET',
  //     url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks/' + taskId + '/content'
  // }).
  // success(function (response, status, headers, config) {
  //     // Add raw URL property to all content
  //     if(response && response.data) {
  //         for(var i=0; i< response.data.length; i++) {
  //             RelatedContentService.addUrlToContent(response.data[i]);
  //         }
  //     }
  //     deferred.resolve(response);
  // })
  // .error(function (response, status, headers, config) {
  //     deferred.reject(response);
  // });
  // return deferred.promise;
}

/*
  * Get all subTasks for a task
  */
export function getSubTasks (taskId) {
  return httpAsPromise(
    {
      method: 'GET',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks/' + taskId + '/subtasks?latestFirst=true'
    }
  )
}

export function assignTask (taskId, userId) {
  var assignData = {
    assignee: userId
  }

  var promise = httpAsPromise(
    {
      method: 'PUT',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks/' + taskId + '/action/assign',
      data: assignData
    }
  )

  return promise
}

export function assignTaskByEmail (taskId, email) {
  var promise = httpAsPromise(
    {
      method: 'PUT',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks/' + taskId + '/action/assign',
      data: { email: email }
    }
  )

  return promise
}

export function updateTask (taskId, data) {
  return httpAsPromise(
    {
      method: 'PUT',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks/' + taskId,
      data: data
    }
  )
}

export function createTask (taskData) {
  return httpAsPromise(
    {
      method: 'POST',
      url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks',
      data: taskData
    }
  )
  // TODO check if we ned rootscope broadcasts
  // var deferred = $q.defer();
  // $http({
  //     method: 'POST',
  //     url: FLOWABLE.CONFIG.contextRoot + '/app/rest/tasks',
  //     data: taskData
  // }).
  // success(function (response, status, headers, config) {
  //     $rootScope.$broadcast('new-task-created', response);
  //     deferred.resolve(response);
  // })
  // .error(function (response, status, headers, config) {
  //     $rootScope.addAlert(response.message, 'error');
  //     deferred.reject(response);
  // });

  // var promise = deferred.promise;
  // return promise;
}
