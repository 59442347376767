import React from "react";
import { SvgIcon, SvgIconTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";

const ForwardIcon = (props: DefaultComponentProps<SvgIconTypeMap>): JSX.Element => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2088 6.20701V7.79701C4.20876 8.79701 1.20876 13.797 0.208759 18.797C2.70876 15.297 6.20876 13.697 11.2088 13.697V15.377C11.2088 16.267 12.2888 16.717 12.9188 16.087L17.5088 11.497C17.8988 11.107 17.8988 10.477 17.5088 10.087L12.9188 5.49701C12.2888 4.86701 11.2088 5.31701 11.2088 6.20701ZM19.0188 5.60701C18.3488 4.93701 17.2088 5.41701 17.2088 6.35701C17.2088 6.63701 17.3188 6.90701 17.5188 7.10701L21.2088 10.797L17.5188 14.487C17.3188 14.687 17.2088 14.957 17.2088 15.237C17.2088 16.177 18.3488 16.657 19.0188 15.977L23.4988 11.497C23.8888 11.107 23.8888 10.477 23.4988 10.087L19.0188 5.60701Z"
    />
  </SvgIcon>
);

export default ForwardIcon;
