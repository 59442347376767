import { Padding } from "../../../../constants";
import { checkNestedBox } from "../../../../util/helper";
export const emptyBlockStyles = { height: "30px" };
export const boxContainerstyle = { paddingLeft: "8px", paddingRight: "8px" };

export const paddingBoxStyles = (component: any) => {
  const { attributes, components } = component;
  const isNestedBox = checkNestedBox(components);
  let { paddingTop, paddingLeft, paddingBottom, paddingRight } = attributes;
  const { PADDINGBOTTOM, PADDINGRIGHT, PADDINGLEFT, PADDINGTOP } = Padding;
  Object.values(Padding).forEach((attribute: string) => {
    switch (attribute) {
      case PADDINGBOTTOM:
        paddingBottom = `${paddingBottom || (isNestedBox ? 0 : 32)}px`;
        break;
      case PADDINGLEFT:
        paddingLeft = `${paddingLeft || (isNestedBox ? 0 : 16)}px`;
        break;
      case PADDINGRIGHT:
        paddingRight = `${paddingRight || (isNestedBox ? 0 : 16)}px`;
        break;
      case PADDINGTOP:
        paddingTop = `${paddingTop || 0}px`;
        break;
    }
  });
  return { paddingLeft, paddingBottom, paddingTop, paddingRight };
};
