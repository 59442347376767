import {
  ObjectData,
  defaultObjectData,
  ObjectDataState,
  defaultObjectDataState,
  ObjectDataFilterBody,
  DynamicJson,
  SearchType,
} from "./dataStoreTypes";
const { GET, POST } = HTTP_METHOD;

import { url } from "../services/apiConfig";
import JuakaliReducer from "../genericRedux/JuakaliReducer";
import { HTTP_METHOD, LAST_UPDATED } from "../constants";

export const fetchForAllObjectDataList = (): Promise<Response> => {
  return fetch(url.objectData, {
    method: "GET",
  });
};

export const fetchForObjectData = (key: string, page: number, pageSize: number): Promise<Response> => {
  return fetch(`${url.objectData}/${key}?page=${page}&pageSize=${pageSize}`, {
    method: "GET",
  });
};

export const fetchForDownloadData = (key: string, version: number, filterParams: DynamicJson, searchType: SearchType): Promise<Response> => {
  return fetch(`${url.objectDataDownload}/download/${key}/${version}`, {
    method: "POST",
    body: JSON.stringify({ filterParams, searchType }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const fetchForFilterData = (key: string, { sortParams, ...rest }: ObjectDataFilterBody): Promise<Response> => {
  return fetch(`${url.objectDataQuery}/filter`, {
    method: POST,
    body: JSON.stringify(!sortParams || sortParams.param === LAST_UPDATED ? { key, ...rest } : { key, ...rest, sortParams }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const fetchForBOReportDetail = (id: string): Promise<Response> => {
  return fetch(`${url.objectReportDetail}/${id}`, {
    method: GET,
  });
};

export const objectDataReducer = new JuakaliReducer<ObjectData, ObjectDataState>(
  "ObjectData",
  defaultObjectDataState,
  defaultObjectData,
  fetchForObjectData,
  fetchForObjectData,
  "objectDataState", //this should be same as the property name in store.ts in StateType
  "id", //Property that uniquely identifies the entity
  null,
  [fetchForFilterData]
);
