import React, { useEffect, useState, useContext } from "react";
import List, { ListItem, ListItemGraphic, ListItemText } from "@material/react-list";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import MaterialIcon from "@material/react-material-icon";
import { connect } from "react-redux";
import { StateType } from "../../redux/types";
import { getObjectDefinitionState } from "../../datastore/objectDefinitionSelector";
import { ObjectDefinitionState, ObjectDefinition } from "../../datastore/dataStoreTypes";
import { objectDefinitionReducer } from "../../datastore/objectDefinitionReducer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps extends RouteComponentProps<{}> {
  onDrawerClose: any;
}

// The objects in redux-state that are used by the current component
interface StateProps {
  objectDefinitionState: ObjectDefinitionState;
}

// Redux actions that the component uses
interface DispatchProps {
  getRemoteObjectDefinitions: () => void;
}

function ObjectDefinitionNavList(props: OwnProps & StateProps & DispatchProps): JSX.Element {
  const { getRemoteObjectDefinitions, objectDefinitionState, onDrawerClose } = props;
  const [loaded, setLoaded] = useState(false);

  var objectDefinitions: ObjectDefinition[] = [];
  if (objectDefinitionState.remoteLoadingStatus === "fullyLoaded") {
    objectDefinitions = objectDefinitionState.items
      ? Object.keys(objectDefinitionState.items).map((id: string) => {
          return objectDefinitionState.items[id];
        })
      : [];
  }

  useEffect(() => {
    if (!loaded) {
      getRemoteObjectDefinitions();
      setLoaded(true);
    }
  }, []);
  return (
    <React.Fragment>
      <List singleSelection={true} onClick={onDrawerClose}>
        {objectDefinitions
          ? objectDefinitions.map((x: ObjectDefinition) => {
              return (
                <NavLink key={x.key} exact to={`/objectdata/${x.key}/${x.version}`}>
                  <ListItem className="pointer">
                    <ListItemGraphic graphic={<MaterialIcon icon="assessment" />} />
                    <ListItemText primaryText={x.label} />
                  </ListItem>
                </NavLink>
              );
            })
          : null}
      </List>
    </React.Fragment>
  );
}

function mapStateToProps(state: StateType): StateProps {
  const objectDefinitionState = getObjectDefinitionState(state);
  return {
    objectDefinitionState,
  };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  const actions = {
    getRemoteObjectDefinitions: () => dispatch(objectDefinitionReducer.getEntities()),
  };
  return actions;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ObjectDefinitionNavList));
