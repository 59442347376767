import { createMuiTheme } from "@material-ui/core";
import { CALCULATION, EMPTY, FORMULA } from "../../../../../constants";
import { black, cobalt, lightGrey, rgbaLightGrey, solitude, toolTipGreyColor, white } from "../../../../theme";
import { makeStyles } from "@material-ui/styles";

export const formulaButtonStyles = makeStyles(theme => ({
  rectangle: {
    position: "absolute",
    backgroundColor: white,
    boxShadow: `1px -0.3px 1px -1px ${rgbaLightGrey}, 1px -1px 1px -1px ${rgbaLightGrey}`,
    width: 12,
    height: 12,
    transform: "rotate(-45deg)",
    top: "-4px",
    left: "24px",
    overflow: "hidden",
  },
  text: {
    color: black,
    fontSize: "14px",
    padding: "12px",
    margin: "6px",
    height: "60px",
    backgroundColor: solitude,
    fontWeight: 100,
    borderRadius: "5px",
    overflowY: "auto",
    zIndex: 1,
    overflow: "hidden",
    overflowWrap: "anywhere",
  },
  paper: (stylingProps?: any) => ({
    backgroundColor: white,
    padding: "8px",
    width: "280px",
    height: stylingProps.copyStatus ? "144px" : "140px",
    boxShadow: `0px 2px 6px ${rgbaLightGrey} !important`,
  }),
  innerBlock: { position: "absolute", left: "-8px" },
  outerBlock: (stylingProps?: any) => ({
    position: "absolute",
    display: stylingProps.showToolTip ? "block" : "none",
    boxShadow: "none !important",
    zIndex: 2,
    marginTop: "55px",
  }),
}));

export const buttonStylesForToolTip = (changeButtonColor?: string) => {
  return {
    calculationButtonStyle: {
      fontSize: "0.85rem",
      textTransform: "capitalize" as any,
      fontWeight: "normal !important" as any,
      height: "26px",
      backgroundColor: changeButtonColor == CALCULATION ? solitude : lightGrey,
      color: changeButtonColor == CALCULATION ? cobalt : black,
      margin: "8px 6px 6px 6px",
      border: changeButtonColor == CALCULATION ? `1px solid ${cobalt}` : EMPTY,
    },
    formulaButtonStyle: {
      fontSize: "0.85rem",
      textTransform: "capitalize" as any,
      fontWeight: "normal !important" as any,
      height: "26px",
      backgroundColor: changeButtonColor == FORMULA ? solitude : lightGrey,
      color: changeButtonColor == FORMULA ? cobalt : black,
      margin: "8px 6px 6px 6px",
      border: changeButtonColor == FORMULA ? `1px solid ${cobalt}` : EMPTY,
    },
  };
};

export const CopyContentButtonTheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        backgroundColor: `${lightGrey}`,
        padding: "2px !important",
        "&:hover": { backgroundColor: `${solitude} !important` },
        marginLeft: "45px",
      },
    },
    MuiTooltip: {
      tooltip: {
        color: cobalt,
        backgroundColor: toolTipGreyColor,
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16px",
        fontStyle: "normal",
        height: "15px",
        width: "30px"
      },
      tooltipPlacementBottom: { margin: "0px !important" },
    },
  },
});
