import { AnyAction, createReducer, Action } from "redux-starter-kit";
import { getDrawerStatus, setDrawerStatus } from "../actions";
import { TypedAction } from "../types";

const drawerReducer = createReducer<boolean, AnyAction>(false, {
  [getDrawerStatus.type]: (state: boolean, action: Action<any>) => {
    return state;
  },
  [setDrawerStatus.type]: (state: boolean, action: TypedAction<any>) => {
    state = action.payload;
    return state;
  },
});

export default drawerReducer;
