import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { canClaimTask, ownsTask } from "../../services/TaskService";
import GrapesForm from "./GrapesForm";
import { StoreCreationService } from "@juakali/juakali-forms-react";
import { fields } from "./fields";
import { FormList, FormItem, CombinedUserTaskContainer, Header, FormWrapper, CombinedUserTaskModelData } from "./combinedusertask/index";

import Card from "@material/react-card";
import { IndeterminateLoading } from "../Loaders";
import { AccountContext } from "../../App";
import { callApiFunction, getPromises } from "./serviceCall";

import { EMPTY, PROCESS, TASK } from "../../constants";
import { getFormType, isItADataStoreDeleteProcess, resetHandler } from "./utils";
import { CUT_FORM, DICTIONARY, FORM_MODEL, GRAPES_FORM, OPEN_TASKS, OTHERS, PROCESS_INSTANCE_DETAIL, GET_VARS, TASK_DETAILS } from "./constants";
import { KeyValuePair, Variables } from "./type";
import { ProcessInstance, Task } from "../../redux/types";
import { GrapesForm as GrapesFormModel } from "../../types/formtypes";
import { isEmpty } from "lodash";

function TaskEngineView(props: any) {
  const { t } = useTranslation(["translation", "flow"]);
  let { location, match } = props;

  const [taskId, setTaskId] = useState<string>(match.params.taskId);
  const [processInstanceId, setProcessInstanceId] = useState<string>(props.match.params.processInstanceId);
  const [task, setTask] = useState<Task | undefined>(undefined);
  const [processInstance, setProcessInstance] = useState<ProcessInstance | undefined>(undefined);
  const [formData, setFormData] = useState<GrapesFormModel | undefined>(undefined);
  const [noTasksAvailable, setNoTasksAvailable] = useState<boolean>(false);
  const [taskType, setTaskType] = useState<string>(OTHERS);
  const [combinedUserTaskModelData, setCombinedUserTaskModelData] = useState<CombinedUserTaskModelData | undefined>(undefined);
  const [variablesResult, setVariablesResult] = useState<Variables | undefined>(undefined);
  const [dictionaries, setDictionaries] = useState([]);
  const [isDictionariesLoaded, setDictionariesLoadingStatus] = useState<boolean>(false);
  const [variablesLoaded, setVariablesLoaded] = useState<boolean>(false);
  const [elements, setElements] = useState<KeyValuePair | undefined>(undefined);
  const GRAPES_JS_FORM = "GrapesJSForm-";
  const CUT = "CUT-";

  const {
    promiseForGetVariables,
    promiseForDictionaries,
    promiseForOpenProcessTasks,
    promiseForProcessInstanceDetail,
    promiseForTaskDetails,
    getPromiseForModel,
  } = getPromises({ taskId, processInstanceId });

  const account = useContext(AccountContext);
  let viewType = match.params.processInstanceId ? PROCESS : TASK;
  const mergedVariables = variablesResult ? { ...variablesResult.processVariables, ...variablesResult.taskVariables } : { metaData: {}};
  const stateObjects = {
    setDictionaries, setDictionariesLoadingStatus, setTask, setProcessInstanceId, setTaskType,
    setTaskId, setNoTasksAvailable, setFormData, setCombinedUserTaskModelData, 
    setProcessInstance, setVariablesResult, setVariablesLoaded, loadProcessInstanceData
  };
  const stateParams = {
    viewType,
    taskType,
    task,
    history: props.history
  }
  function loadProcessInstanceData(type?: string) {
    viewType = type || viewType;
    // only load all tasks of the process if we are in process view, in task view, we already have it via the task Id
    if (processInstanceId) {
      if (viewType === PROCESS) {
        // api call for open process tasks
        callApiFunction(promiseForOpenProcessTasks, t, OPEN_TASKS, stateObjects, stateParams);
      }
      // api call for open process instance detail
      callApiFunction(promiseForProcessInstanceDetail, t, PROCESS_INSTANCE_DETAIL, stateObjects, stateParams);
    }
  }
  useEffect(() => {
    loadProcessInstanceData();
  }, [processInstanceId]);

  //get process instance variables
  useEffect(() => {
    if (!isEmpty(processInstanceId) && !isEmpty(taskId)) {
      // api call for open get task& process variables
      callApiFunction(promiseForGetVariables, t, GET_VARS, stateObjects, stateParams);
    }
    // get form field attributes mapped with process variables
  }, [processInstanceId, taskId]);

  useEffect(() => {
    if (taskId !== undefined && processInstanceId !== undefined && task !== undefined) {
      if (task.formKey) {
        const formType = getFormType(task.formKey);
        if (formType) {
          // api call for loading dictionaries
          callApiFunction(promiseForDictionaries, t, DICTIONARY, stateObjects, stateParams);
          setTaskType(formType);
        }
      } else {
        setTaskType(OTHERS);
      }
    }
  }, [processInstanceId, taskId, task]);

  useEffect(() => {
    const modelId = task && (taskType === GRAPES_FORM ? task.formKey.replace(GRAPES_JS_FORM, EMPTY) : taskType === CUT_FORM ? task.formKey.replace(CUT, EMPTY) : EMPTY);
    // api call for grapes form model
    modelId && callApiFunction(getPromiseForModel(modelId, taskType, setElements, setCombinedUserTaskModelData), t, FORM_MODEL, stateObjects, stateParams);
  }, [taskType]);

  useEffect(() => {
    if (taskId) {
      // api call for task details
      callApiFunction(promiseForTaskDetails, t, TASK_DETAILS, stateObjects, stateParams);
    }
  }, [taskId]);

  function resetView() {
    // resets the state
    resetHandler(stateObjects, stateParams);
  }

  /**
   *  This calls the StoreCreationService.createStoresForFormComponents function in the forms library,
   *  which is responsible for creating Rxgs stores for each component(field, box etc) in the forms model.
   *  The stores should be created only once when the form is rendered first time in the form.
   */
  if (task && processInstance && formData && formData.modelJson && isDictionariesLoaded && variablesLoaded && !elements) {
    setElements(
      StoreCreationService.createStoresForFormComponents(
        formData.modelJson,
        mergedVariables,
        dictionaries,
        processInstance,
        variablesResult && variablesResult.processVariables
      )
    );
  }

  const getSingleUserTask = (): JSX.Element => {
    return <GrapesForm
    processInstance={processInstance as ProcessInstance}
    resetView={resetView}
    task={task as Task}
    taskId={taskId}
    variables={mergedVariables}
    processVariables={variablesResult && variablesResult.processVariables}
    formData={formData as GrapesFormModel}
    dictionaries={dictionaries}
    showOutcomes={true}
    disableAllFields={false}
    disableValidation={false}
    showHeaders={true}
    elements={elements as KeyValuePair}
    submitVariablesOnSave={true}
    outcomeStore={StoreCreationService.createStore("outcomes", { outcomeStore: "" })}
    fields={fields}
  />
  };

  const getCombinedUserTask = (): JSX.Element => {
    return <CombinedUserTaskContainer
    combinedUserTaskModelData={combinedUserTaskModelData}
    combinedUserTaskUIComponents={{
      FormWrapper: FormWrapper,
      FormItem: FormItem,
      FormList: FormList,
      Header: Header,
      GrapesForm: GrapesForm,
    }}
    processVariables={variablesResult && variablesResult.processVariables}
    variables={mergedVariables}
    dictionaries={dictionaries}
    task={task}
    taskId={taskId}
    showOutcomes={true}
    submitVariablesOnSave={true}
    submitOnSave={true}
    resetView={resetView}
    fields={fields}
    processInstance={processInstance}
  />
  };

  const redirect = (): JSX.Element => {
    let isDataStoreDeleteProcess = variablesResult ? isItADataStoreDeleteProcess(mergedVariables) : false;
    if (isDataStoreDeleteProcess) {
      return <Redirect to={{
        pathname: `/objectdata/${mergedVariables.metaData.OBJECT_DEFINITION_KEY}/${mergedVariables.metaData.OBJECT_DEFINITION_VERSION}`,
        state: location.state,
      }}
    />
    }
    return <Redirect to="" />;
  }

  const loader = (): JSX.Element => {
    return (
      <div className="mx-1 my-1" data-testid="loader">
        <Card className="my-3 px-1 py-1">
          <IndeterminateLoading />
        </Card>
      </div>
    );
  }

  const generateTaskView = (): JSX.Element => {
    if (
      task &&
      processInstance &&
      isDictionariesLoaded &&
      variablesResult
    ) {
      let taskController;
      const ownOrClaimTask = ownsTask(task, account) || canClaimTask(task, account);
      if (taskType === GRAPES_FORM && formData && elements) {
        taskController = getSingleUserTask();
      } else if (taskType === CUT_FORM && combinedUserTaskModelData) {
        taskController = getCombinedUserTask();
      } else {
        taskController = <></>
      }
      return ownOrClaimTask ? taskController : redirect();
    } else {
      return noTasksAvailable && processInstance ? redirect() : loader();
    }
  }
  return generateTaskView();
}

export default TaskEngineView;
