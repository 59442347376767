import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Button from "@material/react-button";
import { Done } from "@material-ui/icons";
import { Headline5 } from "@material/react-typography";
import { withTranslation, WithTranslation } from "react-i18next";
import i18n from "../../i18n";
import { passwordStyles } from "./styles";

interface OwnProps {
  handleClick: () => void;
}

interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

const ResetSuccessView = (props: OwnProps): JSX.Element => {
  const { handleClick, t } = props;
  const { completeWrapper, resetContainer, resetSuccessIcon, resetSuccessMessage, successGrid } = passwordStyles();

  return (
    <div className={resetContainer}>
      <Grid container direction="column" alignItems="center" spacing={5} className={successGrid}>
        <Grid item className={completeWrapper}>
          <Done className={resetSuccessIcon} data-testid="successIcon" />
          <Grid container direction="column" spacing={2}>
            <Headline5 className={resetSuccessMessage}>{t("resetPassword.successMessage")}</Headline5>
            <Typography variant="body1">{t("resetPassword.successInfo")}</Typography>
          </Grid>
        </Grid>
        <Grid item data-testid="login">
          <Button raised onClick={handleClick}>
            {t("login.logIn")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation()(ResetSuccessView);
