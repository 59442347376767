import { CircularProgress, MuiThemeProvider, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { loaderStyle } from "../Dashboard/styles/tableStyles";
import { messageTheme, documentLoader } from "./theme";

interface LoaderProps {
  message?: string;
  status: number;
}

const defaultProps = (t: any) => {
  return { message: t("reports.warningMessage") };
};

export default function CustomLoader(props: LoaderProps): JSX.Element {
  const { t } = useTranslation();
  const { message, status } = { ...defaultProps(t), ...props };
  return (
    <div style={{ ...loaderStyle, ...documentLoader }}>
      <MuiThemeProvider theme={messageTheme(status)}>
        <div data-testid="errorMessage">
          <Typography variant={"body2"}>{message}</Typography>
        </div>
      </MuiThemeProvider>
      <CircularProgress size={24} />
    </div>
  );
}
