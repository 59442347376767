import React from "react";
import { PhoneNumberField } from "@juakali/juakali-forms-react";
import { withTranslation } from "react-i18next";
import PhoneNumberView from "./PhoneNumberView";

class PhoneNumberComponent extends PhoneNumberField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <PhoneNumberView {...this} />;
  }
}

export default withTranslation()(PhoneNumberComponent as any);
