import { SelectGroupField } from "@juakali/juakali-forms-react";
import React from "react";
import RadioFieldView from "./RadioFieldView";

class RadioComponent extends SelectGroupField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <RadioFieldView {...this} />;
  }
}

export default RadioComponent;
