import { AnyAction } from "redux";
import Dictionary from "../collections/Dictionary";
import { ProcessInstanceType } from "./enums";
import { Alert } from "./reducers/alertReducer";
import { DateType } from "material-ui-pickers/constants/prop-types";
import { ObjectData, ObjectDefinitionState } from "../datastore/dataStoreTypes";
import { DataStoreDeleteProcessState } from "../datastore/dataStoreDeleteProcess/dataStoreDeleteProcessTypes";
import { TaskQueryParams, TaskState, SortType, TaskAssignment, TaskViewRepresentation, QueryParams } from "../types/taskQueryTypes";
import { PagedApiResult } from "../types/commonTypes";
import { getRowsPerPageSize } from "../util/helper";
import { TableKeys } from "../constants/index";

const { TEAM_TASK_LISTS, CANDIDATE_TASK_LISTS, ASSIGNED_TASK_LISTS } = TableKeys;

export interface Account {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  fullName: string;
  groups: any[];
  privileges: string[];
}

export const defaultAccount = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  fullName: "",
  groups: [],
  privileges: [],
};

export interface TypedAction<T> extends AnyAction {
  payload?: T;
}

export interface deleteInstance {
  processInstanceId: string;
  callback: (success: boolean) => void;
}

export interface scope {
  scopeCategory: string;
  scopeId: string;
}

export interface DocumentParams {
  processInstanceId: string;
  contentId: string;
  setStatusForDocumentGeneration: any;
  type: string;
  label: string;
  documentType: "pdf" | "excel";
}

export interface Task {
  id: string;
  name: string;
  processInstanceName?: string;
  assignee: Account;
  caseDefinitionCategory?: string;
  caseDefinitionDeploymentId?: string;
  caseDefinitionDescription?: string;
  caseDefinitionKey?: string;
  caseDefinitionName?: string;
  caseDefinitionVersion?: number;
  caseInstanceName?: string;
  category?: string;
  created: Date;
  description?: string;
  dueDate?: Date;
  duration: number;
  endDate?: Date;
  formKey: string;
  initiatorCanCompleteTask: boolean;
  memberOfCandidateGroup: boolean;
  memberOfCandidateUsers: boolean;
  parentTaskId: string;
  parentTaskName: string;
  priority: number;
  processDefinitionCategory: string;
  processDefinitionDeploymentId: string;
  processDefinitionDescription?: string;
  processDefinitionId: string;
  processDefinitionKey: string;
  processDefinitionName: string;
  processDefinitionVersion: number;
  processInstanceId: string;
  processInstanceStartUserId?: string;
  scopeDefinitionId?: string;
  scopeId?: string;
  scopeType?: string;
}

export interface Document {
  id: any;
  name: string;
  contentAvailable: boolean;
  contentStoreId: string;
  mimeType: string;
  simpleType: string;
  created: string;
  createdBy: string;
  scopeCategory: string;
  scopeId: string;
}

export interface DocumentData {
  Documents: Dictionary<Document>;
  generatedDoc: any;
}

export const defaultDocumentState: DocumentData = { generatedDoc: "", Documents: {} };

export interface AppDefinition {
  appKey: string;
  appName: string;
  category: string;
  processDefinitionId: string;
  processDefinitionKey: string;
  processDefinitionName: string;
}

export interface AppDefinitions {
  appDefinitions: AppDefinition[];
  isLoading: boolean;
}

export const defaultAppDefinitions = {
  appDefinitions: [],
  isLoading: false,
};

export interface TaskForm {
  id: string;
  name: string;
  description?: string;
  key: string;
  fields: FormField[];
  version: number;
  taskId: string;
  outcomes?: [{ name: string }];
  selectedOutcome?: [{ name: string }];
}

export interface SystemDictionaryItem {
  id: string;
  key: string;
  label: string;
  parentItemKey?: string;
}

export interface SystemDictionary {
  id: string;
  key: string;
  label: string;
  items: SystemDictionaryItem[];
  parentKey?: string;
}

export interface FormField {
  id: string;
  name: string;
  value: ValueType;
  expression: string;
  fieldType: string; // FormField
  type: string; // text, number etc
  placeholder?: string;
  layout?: string;
  overrideId: boolean;
  readOnly: boolean;
  params?: {
    required: boolean;
    minLength?: number;
    maxLength?: number;
    minValue?: number;
    maxValue?: number;
    regexPattern?: string;
    dictionaryKey?: string;
    dictionary?: SystemDictionary;
    dictionaryParentPresent?: boolean;
    dictionaryParentValue?: string;
    dictionaryError?: string;
    activeOnField?: string;
    activeOnFieldValue?: string;
    mask?: string;
  };
  options?: OptionItem[];
  isVisible?: boolean;
}

export type ValueType = string | number | boolean | undefined | OptionItem | DateType;

export interface OptionItem {
  id: string;
  name: string;
}

export interface OpenTaskState {
  involvedTasksLoading: boolean;
  candidateTasksLoading: boolean;
  teamTasksLoading: boolean;
  involvedTasksApiResult: PagedApiResult<TaskViewRepresentation>;
  candidateTasksApiResult: PagedApiResult<TaskViewRepresentation>;
  teamTasksApiResult: PagedApiResult<TaskViewRepresentation> | null;
  assignedTaskQueryParams: TaskQueryParams;
  candidateTaskQueryParams: TaskQueryParams;
  teamTaskQueryParams: QueryParams;
  involvedTasksStatus: string;
  candidateTasksStatus: string;
  teamTasksStatus: string;
  users: JuakaliUser[];
}

/**
 * Represents a dynamic json object.
 * @export
 * @interface DynamicJson
 */
export interface DynamicJson {
  [key: string]: any;
}

export interface CustomClaims {
  roles: DynamicJson;
  groups: string[];
  tenantName: string;
  googleTenantId: string;
  [key: string]: any;
}

export interface JuakaliUser {
  /**
   * Unique id by which the user is identified.
   * @type {string}
   * @memberof JuakaliUser
   */
  id: string;
  /**
   * First name of the user.
   * @type {string}
   * @memberof JuakaliUser
   */
  firstName: string;
  /**
   * Last name of the user.
   * @type {string}
   * @memberof JuakaliUser
   */
  lastName: string;
  /**
   * Display name of the user.
   * @type {string}
   * @memberof JuakaliUser
   */
  displayName: string;
  /**
   * email of the JuakaliUser.
   *
   * @type {string}
   * @memberof JuakaliUser
   */
  email: string;
  /**
   * The id of the tenant to which the JuakaliUser belongs to.
   * @type {string}
   * @memberof JuakaliUser
   */
  tenantId: string;
  /**
   * custom claims - Attributes like roles, groups that are associated to the JuakaliUser.
   * @type {string}
   * @memberof JuakaliUser
   */
  customClaims?: CustomClaims;
}

export interface Paging {
  totalCount: number;
  totalAll: number;
  totalNew: number;
  totalToday: number;
  totalThisWeek: number;
  totalOverdue: number;
  page: number;
  size: number;
}

export interface TableItem {
  taskId: string;
  processInstanceId: string;
  processInstanceName?: string;
  processName: string;
  dueDate: string;
  taskName: string;
  assigneeName: string;
  createTime: string;
}

export const defaultPaging = {
  totalCount: 0,
  totalAll: 0,
  totalNew: 0,
  totalToday: 0,
  totalThisWeek: 0,
  totalOverdue: 0,
  page: 0,
  size: 10,
};

export const defaultAssignedTaskQueryParams: TaskQueryParams = {
  page: 0,
  size: getRowsPerPageSize(ASSIGNED_TASK_LISTS),
  state: TaskState.OPEN,
  sortType: SortType.DUE_DATE_ASCENDING,
  assignment: TaskAssignment.INVOLVED,
  filterNew: false,
};
export const defaultTeamTaskQueryParams: QueryParams = {
  page: 0,
  size: getRowsPerPageSize(TEAM_TASK_LISTS),
  sortType: SortType.DUE_DATE_ASCENDING,
};

export const defaultCandidateTaskQueryParams: TaskQueryParams = {
  page: 0,
  size: getRowsPerPageSize(CANDIDATE_TASK_LISTS),
  state: TaskState.OPEN,
  sortType: SortType.DUE_DATE_ASCENDING,
  assignment: TaskAssignment.CANDIDATE,
};

export const defaultOpenTaskState: OpenTaskState = {
  involvedTasksLoading: false,
  candidateTasksLoading: false,
  teamTasksLoading: false,
  involvedTasksStatus: "",
  candidateTasksStatus: "",
  teamTasksStatus: "",
  involvedTasksApiResult: { error: null, result: null },
  candidateTasksApiResult: { error: null, result: null },
  teamTasksApiResult: null,
  assignedTaskQueryParams: defaultAssignedTaskQueryParams,
  candidateTaskQueryParams: defaultCandidateTaskQueryParams,
  teamTaskQueryParams: defaultTeamTaskQueryParams,
  users: [],
};

export interface TaskParams {
  assignment: string;
  page: number;
  size: number;
}

export interface ActiveProcess {
  isLoading: boolean;
  processDefinitionId?: string;
  processInstanceId?: string;
  taskId?: string;
}

export interface CreateProcessInstance {
  isCreating: boolean;
  askConfirmation: boolean;
  createStatus: "pending" | "success" | "error";
  processDefinitionId?: string;
  processInstanceId?: string;
  processName?: string;
  processInstanceName?: string;
}

export const defaultCreateProcessInstance: CreateProcessInstance = {
  isCreating: false,
  askConfirmation: false,
  createStatus: "pending",
  processDefinitionId: undefined,
  processInstanceId: undefined,
  processName: undefined,
  processInstanceName: undefined,
};

export interface ProcessInstance {
  id: string;
  name: string;
  type: string;
  businessKey?: string;
  processDefinitionId: string;
  started: Date;
  ended?: Date;
  startedBy: Account;
  processDefinitionName: string;
  processDefinitionDescription?: string;
  processDefinitionKey: string;
  processDefinitionCategory: string;
  processDefinitionVersion: number;
  processDefinitionDeploymentId: string;
  graphicalNotationDefined: boolean;
  startFormDefined: boolean;
  variables: any[];
  status: "Completed" | "On-going";
}

export interface ProcessInstances {
  ProcessInstance: Dictionary<ProcessInstance>;
  status: string;
}

export const defaultProcessInstance: ProcessInstance = {
  id: "",
  name: "",
  type: ProcessInstanceType.UNKNOWN,
  businessKey: undefined,
  processDefinitionId: "",
  started: new Date(),
  ended: undefined,
  startedBy: defaultAccount,
  processDefinitionName: "",
  processDefinitionDescription: undefined,
  processDefinitionKey: "",
  processDefinitionCategory: "",
  processDefinitionVersion: 0,
  processDefinitionDeploymentId: "",
  graphicalNotationDefined: false,
  startFormDefined: false,
  variables: [],
  status: "On-going",
};

export interface LoanReport {
  isLoading: boolean;
  hasLoadError: boolean;
  loans: ProcessInstance[];
}

export interface UserSettings {
  settings: any;
  status: any;
  error: string;
}

export const defaultSettingsState: UserSettings = { status: "", settings: {}, error: "" };

export interface LoanReportDetailData {
  id: string;
  tasks: Task[];
}

export const defaultLoanReport = {
  isLoading: false,
  hasLoadError: false,
  loans: [],
};

export const defaultProcessInstanceState: ProcessInstances = { status: "", ProcessInstance: {} };

export interface ApiListData<T> {
  size: number;
  start: number;
  total: number;
  data: T[];
}

export interface ReportTableData {
  header?: any[];
  body?: any[];
}

export type FilterType = "dropdown" | "checkbox" | "multiselect" | "textField";

export type Responsive = "scroll" | "stacked" | undefined;

export type Postion =
  | "-moz-initial"
  | "inherit"
  | "initial"
  | "revert"
  | "unset"
  | "fixed"
  | "static"
  | "absolute"
  | "sticky"
  | "-webkit-sticky"
  | "relative"
  | undefined;

export type SelectableRows = "none" | "multiple" | "single" | undefined;

export interface StateType {
  openTasks: OpenTaskState;
  appDefinitions: AppDefinitions;
  createProcessInstance: CreateProcessInstance;
  loanReport: LoanReport;
  loanReportTasks: LoanReportDetailData[];
  processInstances: ProcessInstances;
  tasks: Dictionary<Task>;
  documents: DocumentData;
  taskForms: Dictionary<TaskForm>;
  alerts: Alert[];
  userSettings: UserSettings;
  objectDefinitionState: ObjectDefinitionState;
  [key: string]: any;
  dataStoreDeleteProcessState: DataStoreDeleteProcessState;
  objectReportDetails: ObjectReportDetailTypes;
}

export interface Data {
  processInstanceId: string;
  contentId: any;
}

export interface DownloadAccountRequest {
  /**
   * Identifies the max number of users to be returned in a single call. Should be within 1000
   * @type {string[]}
   * @memberof DownloadAccountRequest
   */
  maxResults: number;
  /**
   * Identifies the nextPageToken to be sent to the identity service on subsequent calls.
   * @type {string[]}
   * @memberof DownloadAccountRequest
   */
  nextPageToken: string;
}

export const defaultDownloadAccountRequest: DownloadAccountRequest = {
  maxResults: 1000,
  nextPageToken: "",
};

export interface UsersResult {
  nextPageToken: any;
  users: JuakaliUser[];
}

export interface ReassignResult {
  error: any;
  result: string;
}

export interface ReassignParams {
  fromUser: string;
  taskId: string;
  toUser: string;
}

export interface Variables {
  processVariables?: any;
  taskVariables?: any;
}

/**
 * Represents the DownloadUserRequest to be sent to the Query Service.
 * @export
 * @interface DownloadUserRequest
 */
export interface DownloadUserRequest {
  /**
   * Identifies the page number from which result to be fetched
   * @type {number}
   * @memberof DownloadUserRequest
   */
  page: number;
  /**
   * Identifies the max number of users to be returned in a single call.
   * @type {number}
   * @memberof DownloadUserRequest
   */
  size: number;
}

export const defaultDownloadUserRequest: DownloadUserRequest = {
  page: 0,
  size: 4000,
};

export interface ObjectReportDetailTypes {
  status: string;
  detail: ObjectData;
}
export const defaultObjectReportDetail: ObjectReportDetailTypes = {
  status: "",
  detail: {
    id: "",
    key: "",
    version: 0,
    data: {},
    remoteLoadingStatus: "unknown",
    created: new Date(),
    updated: new Date(),
    dataVersion: 0,
    remoteSavingStatus: "unknown",
    validated: false,
  },
};
