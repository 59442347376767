import React, { useRef } from "react";
import { FormHelperText, FormLabel } from "@material-ui/core";
import "../../styles.scss";
import { pictureFieldStyles } from "../../styles";
import { showNotification } from "../../../../../util/notification";
import ConfirmationDialog from "../../../../common/ConfirmationDialog";
import ThumbnailView from "./ThumbnailView";
import CustomFieldLabel from "../CustomFieldLabel";
import PictureSelector from "./PictureSelector";
import { exit } from "process";
import { updateImageEditorBlocState } from "../../../../../rxjs/ImageEditorBloc";

export default function PictureFieldView(props: any): JSX.Element {
  const {
    getConfirmDialogStatus,
    getLabel,
    getError,
    name,
    getValue,
    getLoadingStatus,
    getContent,
    handleAccept,
    handleDismiss,
    handlePictureEditor,
    getActiveStatus,
  } = props;
  let label = getLabel();
  let error = getError();
  let isLoading = getLoadingStatus();
  let value = getValue();
  let disabled = getActiveStatus();
  /* Stylesheet for picture Field */
  let { classes, t } = props.props;

  let content =
    getContent() === "delete" ? t("picture.deleteConfirmation", { fieldName: label }) : getContent() === "exit" ? t("picture.exitConfirmation") : "";

  const { requiredText } = pictureFieldStyles(exit);
  const pictureFieldRef = useRef<any>(null);

  const isDeletePhoto = (): boolean => {
    return getContent() === "delete" ? true : false;
  };

  return (
    <div className="field" ref={pictureFieldRef}>
      <ConfirmationDialog
        alignActionsOnRightSide={true}
        open={getConfirmDialogStatus()}
        title={isDeletePhoto() ? t("picture.removePhoto") : t("picture.discardPhoto")}
        content={content}
        primaryLabel={isDeletePhoto() ? t("common.remove") : t("common.exit")}
        secondaryLabel={t("common.cancel")}
        primaryHandler={() => {
          handlePictureEditor(false);
          updateImageEditorBlocState({
            isZoomVisible: false,
            isRotateVisible: false,
            isCroppingEnabled: false,
            isDisable: false,
          });
          handleAccept();
          if (isDeletePhoto()) showNotification("success", t("picture.deleteSuccess", { fieldName: label }));
        }}
        secondaryHandler={() => {
          handleDismiss();
        }}
      />

      <PictureSelector {...props} />
      <div className={classes.pictureBox}>
        <div className={classes.formLabelColumn}>
          {/* Form Label */}
          <FormLabel className={classes.fieldText} component="article" id={name} error={error}>
            <CustomFieldLabel label={label} fieldId={name} />
          </FormLabel>
        </div>
        {!value && disabled && (
          <FormLabel component="article" className={classes.emptyFieldText}>
            {t("picture.noPhoto") as string}
          </FormLabel>
        )}
        <ThumbnailView {...props} pictureFieldRef={pictureFieldRef} />

        {/* Thumnail - Picture Field */}
        {/* show field error if exists */}
        {error && (
          <FormHelperText key={name} className="errorMessage" style={requiredText}>
            {t(`validation.${error.type}`, error.params)}
          </FormHelperText>
        )}
      </div>
    </div>
  );
}
