import { toast } from "react-toastify";
import "./notification.scss";

export function showNotification(type: string = "", message: string = "") {
  switch (type) {
    case "info":
      toast.info(message);
      break;
    case "success":
      toast.success(message);
      break;
    case "warning":
      toast.warn(message);
      break;
    case "error":
      toast.error(message, {
        autoClose: false,
      });
      break;
  }
}
