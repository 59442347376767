import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { checkAndGetSelectedLanguage } from "./views/Dashboard/utils/dashboardHelper";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    interpolation: { escapeValue: false },
    debug: process.env.REACT_APP_DEBUG_I18N ? process.env.REACT_APP_DEBUG_I18N : false,
    lng: checkAndGetSelectedLanguage(),
    saveMissing: false, // send not translated keys to endpoint
    nsSeparator: ":",
    keySeparator: ".",
    fallbackLng: "en",
    ns: ["translation", "flow"],
    defaultNS: "translation",
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
