import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { cobalt, grey, limeGreen } from "../theme";

export const passwordTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&$error": {
          borderColor: "red !important",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "small",
        lineHeight: "1.6",
        margin: "3px 12px 0",
      },
    },
  },
});

const successIconStyle = { color: limeGreen, width: "48px", height: "48px" };

export const passwordStyles = makeStyles(() => ({
  svgIcon: { width: "16px", marginRight: "12px" },
  svgDoneIcon: { color: limeGreen, width: "24px" },
  svgRemoveIcon: { color: grey },
  restrictionTextWrapper: { display: "flex", flexDirection: "row" },
  marginHeaderText: { marginTop: "12px" },
  marginBodyText: { marginTop: "24px" },
  paddingForLabel: { paddingBottom: "20px !important" },
  marginMobileRules: { marginLeft: "0px", marginTop: "12px" },
  marginDesktopRules: { marginLeft: "32px" },
  marginButton: { marginTop: "32px" },
  changeAccountButton: {
    backgroundColor: "transparent !important",
    color: cobalt,
    fontWeight: 600,
    paddingLeft: "0px",
    fontSize: "0.85rem",
    marginBottom: "5px",
  },
  containerWrapper: { marginTop: "8px" },
  informationText: { marginBottom: "16px" },
  successIcon: { ...successIconStyle, marginRight: "24px" },
  completeWrapper: { display: "flex", alignItems: "center", marginTop: "32px" },
  continueButtonWrapper: { marginTop: "64px" },
  marginErrorMessage: { marginRight: "0px" },
  marginMobileChangeButton: { marginBottom: "16px" },
  continueButton: { display: "flex", alignItems: "center" },
  rightIcon: { marginLeft: "16px" },
  linkMessage: { fontSize: "1.04rem" },
  resetSuccessIcon: { ...successIconStyle, marginRight: "40px", marginTop: "-30px" },
  resetContainer: { display: "flex", height: "60vh", alignItems: "center" },
  resetSuccessMessage: { fontSize: "1.2rem", fontWeight: 550 },
  successGrid: { margin: "0px" },
  content: { height: "100vh" },
}));
