import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";

const EmptyColumn = (): JSX.Element => {
  return (
    <Grid item xs={1} sm={2} md={3} lg={3} xl={4}>
      <Fragment></Fragment>
    </Grid>
  );
};

export default EmptyColumn;
