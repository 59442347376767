import React, { useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LogoutIcon from "../../common/LogoutIcon";
import { logoutStyles } from "../styles";
import { AccountContext } from "../../../App";

export default function LogoutButton(props: any): any {
  const { handleLogout } = props;

  const accountContext = useContext(AccountContext);

  const classes: any = logoutStyles();

  const theme = useTheme();

  const { t } = useTranslation(["translation"]);

  return (
    <Button size="large" onClick={() => handleLogout(accountContext)} className={classes.logout}>
      {t("header.logout")}
      <div className={classes.exitIcon}>
        <LogoutIcon />
      </div>
    </Button>
  );
}
