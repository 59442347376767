import React from "react";
import { ExpressionField } from "@juakali/juakali-forms-react";
import ExpressionFieldView from "./ExpressionFieldView";

class ExpressionComponent extends ExpressionField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <ExpressionFieldView {...this} />;
  }
}

export default ExpressionComponent;
