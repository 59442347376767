import React from "react";
import { MultiInstanceBoxField, BoxRenderType } from "@juakali/juakali-forms-react";
import MultiBoxView from "./MultiBoxView";

class MultiBoxItem extends MultiInstanceBoxField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <MultiBoxView {...this} BOX_RENDER_TYPE={BoxRenderType} />;
  }
}

export default MultiBoxItem;
