import { FormHelperText, InputAdornment, IconButton, TextField, Button, FormLabel } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import React, { Fragment, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import FileDropZone from "./FileDropZone";
import { EMPTY, FIELD_TYPES, PDF_FORMAT, STATUS_CODES, FILE_FORMAT_ERROR, FILE_SIZE_ERROR, EXCEL_FORMAT } from "../../../../../constants";
import { uploadFieldStyles } from "../../styles";
import "../../../../../Spacing.scss";
import CustomFieldLabel from "../CustomFieldLabel";
import ClearIcon from "@material-ui/icons/Clear";
import PdfUploadIcon from "../../../../common/PdfUploadIcon";
import ExcelUploadIcon from "../../../../common/ExcelUploadIcon";
import ConfirmationDialog from "../../../../common/ConfirmationDialog";
import { showNotification } from "../../../../../util/notification";
import { url } from "../../../../../services/apiConfig";
import { handleFileDownload } from "./utils";
import { NONE } from "../ErrorMessageComponent/constants";
import { limeGreen } from "../../../../theme";
import "../../../../form/formGenerationEngine/Form.scss";
import "../../styles.scss";
import WarningMessage from "../../../../common/WarningMessage";
import CustomLoader from "../../../../common/CustomLoader";
const UploadFieldView = (props: any): JSX.Element => {
  let {
    getActiveStatus,
    getError,
    getFileName,
    getNonrequiredFieldErrors,
    getLabel,
    getRequiredStatus,
    getValue,
    handleUpdate,
    name,
    t,
    updateFormSubmissionFlag,
    getFormatAndSizeErrors,
    getUploadingStatus,
    handleDelete,
    getDeleteConfirmationStatus,
    handleChange,
    clearFieldErrors,
  } = props;
  const { DEFAULT, SUCCESS, LOADING, SERVER_TOO_BUSY, NOT_FOUND } = STATUS_CODES;
  const [downloadingStatus, setDownloadingStatus] = useState(DEFAULT);
  const statusKey = downloadingStatus == SUCCESS ? "success" : downloadingStatus == NOT_FOUND ? "notFound" : "httpError";
  let {
    doneIconStyle,
    excelTextFieldStyle,
    helperStyle,
    warningMessage,
    downloadButtonStyle,
    emptyFieldText,
    requiredText,
    fieldText,
  } = uploadFieldStyles(downloadingStatus);

  let label = getLabel();
  let error = getError();
  let disabled = getActiveStatus();
  let value = getValue();
  let fileName = getFileName();
  let fileFormat = fileName && fileName.split(".").pop();
  let fileNameWithoutFormat = fileName && fileName.split(".").slice(0, fileName.split(".").length - 1);
  let nonRequiredFieldErrors = getNonrequiredFieldErrors();
  let required = getRequiredStatus();
  let fileFormatAndSizeError = getFormatAndSizeErrors();
  const isUploading = getUploadingStatus();
  const dataLength = value && value.length;
  const formatCondition = fileFormatAndSizeError && fileFormatAndSizeError[FILE_FORMAT_ERROR] && fileFormatAndSizeError[FILE_FORMAT_ERROR] != NONE;
  const sizeCondition = fileFormatAndSizeError && fileFormatAndSizeError[FILE_SIZE_ERROR] && fileFormatAndSizeError[FILE_SIZE_ERROR] != NONE;
  nonRequiredFieldErrors = fileName
    ? nonRequiredFieldErrors && nonRequiredFieldErrors["lengthError"] == EMPTY
      ? EMPTY
      : nonRequiredFieldErrors
    : EMPTY;
  useEffect(() => {
    if (disabled && !fileName && !value) {
      clearFieldErrors();
    }
  }, [disabled]);

  function NonRequiredErrors(): JSX.Element {
    return (
      <Fragment>
        {fileName &&
          nonRequiredFieldErrors &&
          Object.keys(nonRequiredFieldErrors).map((nr: any) => {
            /* show validation errors from excel sheet analyze */
            if (nonRequiredFieldErrors[nr]) {
              return (
                <FormHelperText key={nr} style={helperStyle} className="errorMessage" data-testid="nonRequiredError">
                  {t(`excelUpload.${nonRequiredFieldErrors[nr]}`)}
                </FormHelperText>
              );
            }
          })}
      </Fragment>
    );
  }

  function RowCount(): JSX.Element {
    return (
      <Fragment>
        {fileName && typeof value === FIELD_TYPES.OBJECT && dataLength ? (
          /* show row count from excel sheet */
          <FormHelperText style={helperStyle}>
            {t("excelUpload.readyToLoad", { count: dataLength, plural: dataLength > 1 ? "s" : EMPTY})}
            <DoneIcon style={doneIconStyle} data-testid="doneIcon" />
          </FormHelperText>
        ) : (
          EMPTY
        )}
      </Fragment>
    );
  }
  function formatAndSizeErrorMessage(key?: string, value?: string) {
    return (
      <FormHelperText key={key} style={helperStyle} className="errorMessage" data-testid="formatAndSizeError">
        {t(`excelUpload.${key}`, { name: value })}
      </FormHelperText>
    );
  }

  function FormatAndSizeError(): JSX.Element {
    return (
      <Fragment>
        {fileFormatAndSizeError &&
          !disabled &&
          Object.entries(fileFormatAndSizeError).map(([key, value]) => {
            if (key && value && value != NONE) {
              if (
                key == FILE_SIZE_ERROR &&
                (fileFormatAndSizeError[FILE_FORMAT_ERROR] == NONE || fileFormatAndSizeError[FILE_FORMAT_ERROR] == EMPTY)
              ) {
                return formatAndSizeErrorMessage(FILE_SIZE_ERROR, fileFormatAndSizeError[FILE_SIZE_ERROR]);
              }
              if (key == FILE_FORMAT_ERROR && (value != NONE || value != EMPTY)) {
                return formatAndSizeErrorMessage(FILE_FORMAT_ERROR, fileFormatAndSizeError[FILE_FORMAT_ERROR]);
              }
            }
          })}
      </Fragment>
    );
  }

  function DownloadStatusMessage(): JSX.Element {
    return (
      <Fragment>
        {fileName && value && downloadingStatus && downloadingStatus != DEFAULT && downloadingStatus != SERVER_TOO_BUSY ? (
          <FormHelperText className={downloadingStatus == SUCCESS ? "successMessage" : "errorMessage"}>
            {statusKey == "success"
              ? t(`excelUpload.${statusKey}`, { statusCode: downloadingStatus, fileName: fileNameWithoutFormat })
              : t(`document.${statusKey}`, { statusCode: downloadingStatus })}
          </FormHelperText>
        ) : (
          undefined
        )}
      </Fragment>
    );
  }
  const renderUploadField = (
    <div className="flex">
      <TextField
        name={name}
        label={<CustomFieldLabel label={label} fieldId={name} />}
        required={required}
        error={error}
        value={value && (fileName || EMPTY)}
        id="uploadField"
        InputProps={{
          readOnly: true,
          disabled: disabled,
          endAdornment: (
            <InputAdornment position="end">
              {value && fileName && (
                <IconButton aria-label={"clear"} onClick={() => handleDelete(true)} disabled={disabled}>
                  <ClearIcon data-testid="clearIcon" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        style={excelTextFieldStyle}
        data-testid="fileTextField"
      />
      <FileDropZone
        disabled={disabled}
        handleUpdate={handleUpdate}
        isLoading={isUploading}
        updateFormSubmissionFlag={updateFormSubmissionFlag}
        value={value}
        setDownloadingStatus={setDownloadingStatus}
      />
    </div>
  );
  return (
    <div className="field">
      {disabled ? (
        <>
          {" "}
          {value ? (
            <> {fileFormat == PDF_FORMAT ? <></> : renderUploadField} </>
          ) : (
            <FormLabel className={fieldText} component="article" id={name} error={error}>
              <CustomFieldLabel label={label} fieldId={name} />
            </FormLabel>
          )}{" "}
        </>
      ) : (
        renderUploadField
      )}
      {!value && disabled && (
        <FormLabel component="article" style={emptyFieldText}>
          {t("excelUpload.noFile") as string}
        </FormLabel>
      )}
      {error && !formatCondition && !sizeCondition && !nonRequiredFieldErrors && (
        <FormHelperText key={name} className="errorMessage" style={requiredText}>
          {t(`validation.${error.type}`, error.params)}
        </FormHelperText>
      )}
      {/* Remove format == "pdf" condition to make Excel Download Button Visible to the User */}
      {value && fileName && fileFormat == PDF_FORMAT && (
        <div className="flex">
          <Button
            variant="outlined"
            style={downloadButtonStyle}
            className="downloadButton"
            onClick={() => handleFileDownload(`${url.file}/${value.id}`, fileName, setDownloadingStatus)}
            data-testid="downloadButton"
            disabled={downloadingStatus === LOADING}
          >
            {fileFormat == PDF_FORMAT ? <PdfUploadIcon /> : <ExcelUploadIcon color={limeGreen} />}
            <span className="downloadButtonLabel">{fileNameWithoutFormat}</span>
          </Button>
        </div>
      )}
      <FormatAndSizeError />
      <NonRequiredErrors />
      <RowCount />
      <DownloadStatusMessage />
      <ConfirmationDialog
        open={getDeleteConfirmationStatus()}
        title={t("excelUpload.clearDocumentTitle", { label })}
        content={t("excelUpload.clearDocumentContent", { label })}
        primaryLabel={t("common.yes")}
        secondaryLabel={t("common.no")}
        primaryHandler={() => {
          handleChange();
          showNotification("success", t("excelUpload.deleteSuccess"));
          handleDelete(false);
          setDownloadingStatus(DEFAULT);
        }}
        secondaryHandler={() => {
          handleDelete(false);
        }}
      />
      {!downloadingStatus ? <CustomLoader status={downloadingStatus} /> : undefined}
      <WarningMessage style={warningMessage} status={downloadingStatus} clearWarning={() => setDownloadingStatus(1)} />
    </div>
  );
};

export default withTranslation()(UploadFieldView);
