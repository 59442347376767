import { BehaviorSubject } from "rxjs";

export interface ReportFilterDialogState {
  isExactSearch: boolean;
}

export const defaultReportFilterDialogState: ReportFilterDialogState = {
  isExactSearch: true,
};

export default class ReportFilterDialogBloc {
  private static instance: ReportFilterDialogBloc;
  private subject: BehaviorSubject<ReportFilterDialogState>;

  private constructor() {
    this.subject = new BehaviorSubject(defaultReportFilterDialogState);
  }

  static getInstance(): ReportFilterDialogBloc {
    if (!ReportFilterDialogBloc.instance) {
      ReportFilterDialogBloc.instance = new ReportFilterDialogBloc();
    }
    return ReportFilterDialogBloc.instance;
  }

  getSubject = (): BehaviorSubject<ReportFilterDialogState> => {
    return this.subject;
  };

  updateState = (value: ReportFilterDialogState): void => {
    this.subject.next({
      ...this.subject.value,
      ...value,
    });
  };
}

export const updateReportFilterDialogBlocState = (args: ReportFilterDialogState): void => {
  ReportFilterDialogBloc.getInstance().updateState(args);
};
