import React from "react";
import { cobalt } from "../theme";
const ExcelExportIcon = (props: {color?: string}): JSX.Element => {
  let currentColor = props.color || cobalt;
  return <svg width="24" height="24" viewBox="0 0 24 24" fill={"none"} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.65274 14.8943L5.9545 14.3435H6.44376L5.91934 15.1902L6.48477 16.05H5.9838L5.6586 15.4905L5.34366 16.05H4.8544L5.39346 15.1902L4.86905 14.3435H5.35391L5.65274 14.8943Z"
        fill={currentColor}
      />
      <path d="M7.27631 15.7102H8.05415V16.05H6.85151V14.3435H7.27631V15.7102Z" fill={currentColor} />
      <path
        d="M9.40819 15.5916C9.40819 15.5349 9.38426 15.4895 9.33641 15.4553C9.28856 15.4211 9.20702 15.3904 9.09178 15.363C8.97655 15.3357 8.87938 15.3069 8.80028 15.2766C8.72215 15.2454 8.6577 15.2087 8.60692 15.1667C8.55614 15.1248 8.51854 15.0764 8.49413 15.0217C8.46971 14.9661 8.45751 14.9001 8.45751 14.824C8.45751 14.6775 8.51854 14.5569 8.64061 14.4622C8.76268 14.3674 8.91991 14.3201 9.11229 14.3201C9.32225 14.3201 9.49169 14.3679 9.62059 14.4636C9.75047 14.5583 9.81542 14.6882 9.81542 14.8533H9.39208C9.39208 14.7908 9.36669 14.7385 9.3159 14.6965C9.2661 14.6545 9.19872 14.6335 9.11376 14.6335C9.03856 14.6335 8.97753 14.6501 8.93065 14.6833C8.88378 14.7166 8.86034 14.7581 8.86034 14.8079C8.86034 14.8577 8.88475 14.8982 8.93358 14.9294C8.98241 14.9597 9.06346 14.989 9.17674 15.0173C9.40428 15.0671 9.56688 15.136 9.66454 15.2239C9.76317 15.3118 9.81249 15.4304 9.81249 15.5798C9.81249 15.7322 9.75243 15.8528 9.63231 15.9417C9.51219 16.0295 9.34813 16.0735 9.14012 16.0735C8.99852 16.0735 8.87059 16.0505 8.75633 16.0046C8.64208 15.9587 8.5537 15.8933 8.4912 15.8083C8.42967 15.7234 8.39891 15.6228 8.39891 15.5066H8.82372C8.82372 15.5974 8.85155 15.6628 8.90721 15.7029C8.96385 15.7429 9.04833 15.7629 9.16063 15.7629C9.2368 15.7629 9.29686 15.7473 9.34081 15.7161C9.38573 15.6848 9.40819 15.6433 9.40819 15.5916Z"
        fill={currentColor}
      />
      <path
        d="M10.9351 14.8943L11.2368 14.3435H11.7261L11.2017 15.1902L11.7671 16.05H11.2661L10.9409 15.4905L10.626 16.05H10.1367L10.6758 15.1902L10.1514 14.3435H10.6363L10.9351 14.8943Z"
        fill={currentColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.25 4.25C5.25 3.14543 6.14543 2.25 7.25 2.25H14.75C15.0335 2.25 15.3037 2.37032 15.4933 2.58104L19.9933 7.58104C20.1586 7.76466 20.25 8.00296 20.25 8.25V20.25C20.25 21.3546 19.3546 22.25 18.25 22.25H7.25C6.14543 22.25 5.25 21.3546 5.25 20.25V19.25H3.25C2.69772 19.25 2.25 18.8023 2.25 18.25V12.25C2.25 11.6977 2.69772 11.25 3.25 11.25H5.25V4.25ZM7.25 11.25H13.25C13.8023 11.25 14.25 11.6977 14.25 12.25V18.25C14.25 18.8023 13.8023 19.25 13.25 19.25H7.25V20.25H18.25V9.25H13.75V4.25H7.25V11.25ZM12.25 17.25V13.25H4.25V17.25H12.25Z"
        fill={currentColor}
      />
    </svg>
}

export default ExcelExportIcon;
