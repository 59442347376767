import { createAction } from "redux-starter-kit";

export const initialState = createAction("initialState");

export const setCreateProcessInstance = createAction("setCreateProcessInstance");
export const setAppDefinitionsLoading = createAction("setAppDefinitionsLoading");
export const setAppDefinitions = createAction("setAppDefinitions");

export const setLoanReportLoading = createAction("setLoanReportLoading");
export const setLoanReport = createAction("setLoanReport");

export const setLoanReportDetailData = createAction("setLoanReportDetailData");

export const setTaskForm = createAction("setTaskForm");
export const fetchTaskForm = createAction("fetchTaskForm");

// taskReducer
export const setTasks = createAction("setTasks");
export const fetchCompletedTasksByProcessInstanceId = createAction("fetchCompletedTasksByProcessInstanceId");

// DocumentReducer
export const setDocuments = createAction("setDocuments");
export const fetchDocuments = createAction("fetchDocuments");
export const generateDocument = createAction("generateDocument");

// processInstanceReducer
export const setProcessInstances = createAction("setProcessInstance");
export const deleteProcessInstanceAction = createAction("deleteProcessInstanceAction");
export const deleteProcessInstance = createAction("deleteProcessInstance");
export const fetchProcessInstances = createAction("fetchProcessInstances");
export const setProcessLoadingStatus = createAction("setProcessLoadingStatus");

// alertReducer
export const addAlert = createAction("addAlert");
export const setAlertShown = createAction("setAlertShown");
export const removeAlerts = createAction("removeAlerts");

//userSettingReducer
export const setUserSetting = createAction("setUserSetting");
export const updateUserSettingsRemote = createAction("updateUserSettingsRemote");
export const getUserSettingRemote = createAction("getUserSettingRemote");
export const setLoadingStatus = createAction("setLoadingStatus");
export const setErrorStatus = createAction("setErrorStatus");

export const getDrawerStatus = createAction("getDrawerStatus");
export const setDrawerStatus = createAction("setDrawerStatus");

//BO ReportReducer
export const setObjectReportDetail = createAction("setObjectReportDetail");
export const fetchObjectReportDetailRemote = createAction("fetchObjectReportDetailRemote");
export const resetObjectReportDetailState = createAction("resetObjectReportDetailState");
