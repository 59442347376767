import React from "react";

const PdfIcon = (props: { color?: string }): JSX.Element => {
  const htmlColor = props.color || "#FF402F";
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_904_5642)">
        <path
          d="M19.4125 5.01484L15.9167 1.57363C15.5417 1.20449 15.0333 0.995313 14.5042 0.995313H6C4.89583 0.999414 4 1.88125 4 2.96816V20.0307C4 21.1176 4.89583 21.9994 6 21.9994H18C19.1042 21.9994 20 21.1176 20 20.0307V6.40938C20 5.88848 19.7875 5.38399 19.4125 5.01484ZM17.8375 6.24942H14.6667V3.12813L17.8375 6.24942ZM6 20.0307V2.96816H12.6667V7.23379C12.6667 7.7793 13.1125 8.21817 13.6667 8.21817H18V20.0307H6ZM16.425 14.1367C15.9167 13.6445 14.4667 13.7799 13.7417 13.8701C13.025 13.4395 12.5458 12.8447 12.2083 11.9711C12.3708 11.3107 12.6292 10.3059 12.4333 9.67422C12.2583 8.59961 10.8583 8.70625 10.6583 9.43223C10.475 10.0926 10.6417 11.0113 10.95 12.1844C10.5333 13.1647 9.9125 14.4813 9.475 15.2359C8.64167 15.6584 7.51667 16.3105 7.35 17.1309C7.2125 17.7789 8.43333 19.3949 10.5208 15.8512C11.4542 15.5477 12.4708 15.1744 13.3708 15.0268C14.1583 15.4451 15.0792 15.724 15.6958 15.724C16.7583 15.724 16.8625 14.5674 16.425 14.1367ZM8.17083 17.3277C8.38333 16.7658 9.19167 16.1178 9.4375 15.8922C8.64583 17.135 8.17083 17.3564 8.17083 17.3277ZM11.5708 9.51016C11.8792 9.51016 11.85 10.8268 11.6458 11.1836C11.4625 10.6135 11.4667 9.51016 11.5708 9.51016ZM10.5542 15.1129C10.9583 14.4197 11.3042 13.5953 11.5833 12.8693C11.9292 13.4887 12.3708 13.985 12.8375 14.3254C11.9708 14.5018 11.2167 14.8627 10.5542 15.1129ZM16.0375 14.9078C16.0375 14.9078 15.8292 15.1539 14.4833 14.5879C15.9458 14.4813 16.1875 14.8094 16.0375 14.9078Z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_904_5642">
          <rect width="16" height="21" fill="white" transform="translate(4 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PdfIcon;
