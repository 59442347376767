import { createMuiTheme, makeStyles } from "@material-ui/core";
import { textFieldColorTheme } from "./commonStyles";
import { cobalt, lightBlackHover, midNightBlue, osloGrey } from "../../theme";

export const tabsTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: "none",
        marginLeft: "24px",
      },
      elevation1: {
        boxShadow: "none !important",
      },
    },
    MuiTabs: {
      root: { padding: "12px 0 0px 0", boxShadow: "none" },
    },
    MuiTab: {
      root: {
        fontSize: "24px !important",
        fontStyle: "normal !important",
        fontWeight: 400,
        lineHeight: "28px !important",
        letterSpacing: "0em !important",
        maxWidth: "fit-content",
      },
      textColorPrimary: {
        "&.Mui-selected": {
          color: cobalt,
        },
      },
    },
  },
});

export const SuccessMessageTheme = (): any =>
  (createMuiTheme as any)({
    overrides: {
      MuiTypography: {
        body1: {
          zIndex: 1000,
          border: "1px solid #4CAF50 !important",
          color: "#4CAF50",
          fontSize: "14px !important",
          fontStyle: "normal",
          lineHeight: "22.6px",
          letterSpacing: "0.15",
          textAlign: "left",
          fontWeight: "500 !important",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#FFFFFF",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
          borderRadius: "4px 0px 0px 4px",
          width: "280px",
          height: "72px",
          position: "absolute",
          right: "0",
          margin: "16px 0px 16px 0px",
        },
      },
      MuiSvgIcon: {
        root: {
          fill: "#4CAF50 !important",
          marginLeft: "8px",
          marginTop: "-1em",
          cursor: "pointer",
        },
      },
      MuiGrid: {
        item: {
          paddingTop: "0px",
        },
      },
    },
  });

export const IconButtonTheme = (): any =>
  (createMuiTheme as any)({
    overrides: {
      MuiIconButton: {
        root: {
          padding: "0 !important",
          marginRight: "12px",
        },
      },
    },
  });

export const inlineStyles = (isItMobile: boolean) => ({
  iconColor: {
    fill: "#134A9F",
    color: "#134A9F",
  },
  successMessage: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical" as any,
    WebkitLineClamp: 2,
    display: "-webkit-box",
  },
  padding: {
    padding: "16px",
  },
  tabs: { paddingBottom: isItMobile ? 16 : 0 },
  tabsWrapper: {
    flexDirection: (isItMobile ? "column" : "row") as any,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
});

export const assigneeColumnTheme = createMuiTheme({
  overrides: {
    ...textFieldColorTheme,
  },
});

export const processStarterDialogTheme = createMuiTheme({
  overrides: {
    MuiDialogContent: {
      root: {
        padding: "6px 24px",
      },
    },
    MuiDialogContentText: { root: { marginBottom: 0 } },
    MuiTypography: {
      h6: {
        fontSize: "17px",
        fontWeight: 550,
        lineHeight: "23.44px",
      },
    },
  },
});

export const dashboardCustomStyles = makeStyles({
  taskEditIcon: {
    padding: '4px',
    borderRadius: '50%',

    '&:hover': {
      background: lightBlackHover,
    }
  }
})

export const processStarterStyles = makeStyles({
  button: {
    alignSelf: "flex-end",
  },
  closeIcon: {
    color: osloGrey,
    height: 24,
    width: 24,
  },
  emptyGrid: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: "calc(50%)",
  },
  emptyText: {
    color: midNightBlue,
    fontSize: "1rem",
    paddingTop: "1rem",
  },
  header: {
    color: midNightBlue,
  },
  headerContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "24px",
  },
  iconButton: {
    margin: "-6px",
  },
  loader: {
    color: cobalt,
  },
  loaderBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: "calc(80%)",
  },
  paper: {
    width: "300px",
  },
});

export const processStarterTheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        padding: 6,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      rounded: {
        borderRadius: "0px",
      },
    },
  },
});
