import { MuiThemeProvider, Typography } from "@material-ui/core";
import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { errorMessageTheme } from "../theme";

export interface ErrorMessageProps {
  errorMessage: string;
  handleClear: any;
}

export default function ErrorMessage(props: ErrorMessageProps): JSX.Element {
  let { handleClear, errorMessage } = props;
  return errorMessage ? (
    <MuiThemeProvider theme={errorMessageTheme}>
      <div>
        <Typography>
          {errorMessage}
          <ClearIcon name="ClearIcon" onClick={handleClear} />
        </Typography>
      </div>
    </MuiThemeProvider>
  ) : (
    <></>
  );
}
