import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useRef } from "react";
import IconView from "./IconView";
import CustomFieldLabel from "../FormFields/CustomFieldLabel";
import { BLANK, EMPTY } from "../../../../constants";
import { paddingMultiBoxStyles, useStyles } from "./styles";
import "../styles.scss";

const MultiBoxView = (props: any) => {
  const {
    BOX_RENDER_TYPE,
    desktopWidth,
    getAddIconStatus,
    getLabel,
    getRemoveIconStatus,
    getRenderType,
    handleAdd,
    handleRemove,
    phoneWidth,
    props: multiBoxProps,
    tabWidth,
  } = props;
  const { children, component, instance } = multiBoxProps;
  const multiBoxRef = useRef<any>(null);
  const { DONT_RENDER, RENDER_BOX, RENDER_BOX_WITH_CHILDREN } = BOX_RENDER_TYPE;
  const renderType = getRenderType();
  const displayAddIcon = getAddIconStatus();
  const displayRemoveIcon = getRemoveIconStatus();
  const label = getLabel();
  const customLabel = label + BLANK + (instance === 1 ? EMPTY : instance);
  const fieldId = children[1];
  const { emptyBox, text } = useStyles({});

  switch (renderType) {
    case RENDER_BOX_WITH_CHILDREN:
      return (
        <Grid
          container
          item
          xs={phoneWidth}
          sm={tabWidth}
          md={desktopWidth}
          lg={desktopWidth}
          xl={desktopWidth}
          style={paddingMultiBoxStyles(component)}
        >
          <Grid key={fieldId} container item className={clsx("multiBox", "align-self")} ref={multiBoxRef} data-testid="multiBox">
            <Typography variant="h6" className={text}>
              <CustomFieldLabel label={customLabel} fieldId={fieldId} />
            </Typography>
            {children[0]}
            <IconView
              displayAddIcon={displayAddIcon}
              displayRemoveIcon={displayRemoveIcon}
              handleAdd={handleAdd}
              handleRemove={handleRemove}
              multiBoxRef={multiBoxRef}
            />
          </Grid>
        </Grid>
      );
    case RENDER_BOX:
      return (
        <Grid key={fieldId} container item xs={phoneWidth} sm={tabWidth} md={desktopWidth} lg={desktopWidth} xl={desktopWidth} className="align-self">
          <div className={emptyBox}></div>
        </Grid>
      );
    case DONT_RENDER:
    default:
      return <></>;
  }
};

export default MultiBoxView;
