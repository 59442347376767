import { AnyAction, createReducer } from "redux-starter-kit";
import { fetchObjectReportDetailRemote, setObjectReportDetail, resetObjectReportDetailState } from "../actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchWrapper } from "../../core/fetchWrapper";
import { fetchForBOReportDetail } from "../../datastore/objectDataReducer";
import { ObjectReportDetailTypes, defaultObjectReportDetail } from "../types";
import { showNotification } from "../../util/notification";
import i18n from "../../i18n";
import { EMPTY, STATUS_CODES, SUCCESS } from "../../constants";
const objectReportDetailReducer = createReducer<ObjectReportDetailTypes, AnyAction>(defaultObjectReportDetail, {
  [setObjectReportDetail.type]: (state: ObjectReportDetailTypes, action: AnyAction) => {
    if (!(action && action.payload)) {
      return state;
    }
    return {
      ...state,
      detail: action.payload,
      status: SUCCESS,
    };
  },
  [resetObjectReportDetailState.type]: (state: ObjectReportDetailTypes) => {
    return {
      ...state,
      status: EMPTY,
    };
  },
  [fetchObjectReportDetailRemote.type]: (state: ObjectReportDetailTypes) => {
    return state;
  },
});
export default objectReportDetailReducer;

export function* generateFetchForObjectReport(action: AnyAction): any {
  const { SUCCESS, FORBIDDEN, NOT_FOUND, NOT_AUTHENTICATED } = STATUS_CODES;
  const { id, handleBackToList } = action.payload;
  try {
    const response: Response = yield call(fetchWrapper, fetchForBOReportDetail, id);
    if (response.status === SUCCESS) {
      const body: any = yield response.json();
      yield put({ type: setObjectReportDetail.type, payload: body.result });
    } else {
      throw new Error(response.status as any);
    }
  } catch (error) {
    const { message } = error as { message: any };
    if (message == FORBIDDEN || message == NOT_FOUND) handleBackToList();
    if (message != FORBIDDEN && message != NOT_AUTHENTICATED)
      showNotification("error", message == NOT_FOUND ? i18n.t("common.notFound") : i18n.t("common.serverErrorTryAgain"));
  }
}
export function* watchFetchForObjectReportDetail(): any {
  yield takeLatest(fetchObjectReportDetailRemote.type, generateFetchForObjectReport);
}
