/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import "./Header.scss";

import MaterialIcon from "@material/react-material-icon";
import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
// import Settings from "@material-ui/icons/Settings";
import fetch from "isomorphic-fetch";
import { showNotification } from "../../util/notification";
import { AccountContext } from "../../App";
import { url } from "../../services/apiConfig";
import { fetchWrapper } from "../../core/fetchWrapper";
import { defaultLoginState, updateLogInBlocState } from "../../rxjs/loginBloc";
import Notification from "./notification";
import LogoutView from "./Logout";
import { Account, defaultAssignedTaskQueryParams, defaultCandidateTaskQueryParams } from "../../redux/types";
import ConfirmationDialog, { ConfirmationDialogProps } from "../common/ConfirmationDialog";
import { defaultConfirmationPropertiesOnCancel } from "../Dashboard/utils/dashboardHelper";
import i18n from "../../i18n";
import { QueryParams, TaskQueryParams } from "../../types/taskQueryTypes";
import { fetchTasks, resetTasks } from "../../redux/reducers/openTasksReducer";
import { DASHBOARD_PATH } from "../TeamTask/constants";

const userNameStyle = {
  alignSelf: "center",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "21px",
  letterSpacing: "0em",
  textAlign: "left" as any,
};

const logoutText = {
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.02em",
  textAlign: "left" as any,
  textTransform: "capitalize" as any,
  paddingRight: "10px",
};

const logoutButton = {
  color: "#134A9F",
};

const menuStyle = { color: "#134A9F" };

export const defaultConfirmationPropertiesOnLogOut = (): ConfirmationDialogProps => ({
  open: false,
  title: i18n.t("translation:header.logoutFrom"),
  content: i18n.t("translation:header.logoutConfirmation"),
  primaryLabel: i18n.t("translation:common.yes"),
  secondaryLabel: i18n.t("translation:common.no"),
});

class Header extends Component<any, any, any> {
  accountContext: React.Context<Account | undefined>;
  logoutFunction: () => Promise<Response>;
  constructor(props: any) {
    super(props);
    this.accountContext = AccountContext;
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.logout = this.logout.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.logoutFunction = () => {
      return fetch(url.logout);
    };
    this.state = {
      isLogoutPopupOpen: false,
      confirmationDialogProps: defaultConfirmationPropertiesOnLogOut(),
    };
  }
  toggleDrawer() {
    if (this.props.toggleDrawer) {
      this.props.toggleDrawer();
    }
  }

  handleClick = () => {
    this.setState((prevState: any) => ({ isLogoutPopupOpen: !prevState.isLogoutPopupOpen }));
  };
  handleClose = () => {
    this.setState((prevState: any) => ({ isLogoutPopupOpen: false }));
  };

  handleLogout(account: any) {
    if (!this.state.confirmationDialogProps.open)
      this.setState({
        confirmationDialogProps: {
          ...defaultConfirmationPropertiesOnLogOut(),
          open: true,
          title: i18n.t("translation:header.logoutFrom", { email: account && account.email }),
          primaryHandler: () => {
            this.logout();
            this.setState({ confirmationDialogProps: defaultConfirmationPropertiesOnLogOut() });
          },
          secondaryHandler: () => {
            this.setState({ confirmationDialogProps: defaultConfirmationPropertiesOnLogOut() });
          },
        },
      });
  }

  logout() {
    let { history, updateAcccount, t, fetchTasks } = this.props;

    if (history.location.pathname == DASHBOARD_PATH) {
      resetTasks();
    }
    fetchWrapper(this.logoutFunction)
      .then((res: any) => {
        if (res.status === 200) {
          updateAcccount(undefined);
          /* update rxjs store values */
          updateLogInBlocState(defaultLoginState);
          history.push("/login");
        }
      })
      .catch(() => {
        showNotification("error", t("common.serverErrorTryAgain"));
      });
  }

  renderToggleDrawerIcon(account: any) {
    const currentPath = this.props.location.pathname;
    const showToggleDrawerIcon = !(currentPath === url.login || currentPath === url.changePassword);
    if (account && showToggleDrawerIcon) {
      return (
        <IconButton onClick={() => this.toggleDrawer()} style={menuStyle}>
          <MaterialIcon icon="menu" />
        </IconButton>
      );
    }
    return undefined;
  }

  renderHeaderText(account: any) {
    if (account) {
      switch (this.props.location.pathname) {
        case "/":
          return (
            <Typography
              variant="h2"
              title={"title"}
              style={{
                fontSize: "32px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "38px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              {this.props.t("menu.dashboard")}
            </Typography>
          );
        default:
          break;
      }
    }
  }

  renderActionButtons(account: any) {
    // eslint-disable-next-line react/prop-types
    const currentPath = this.props.location.pathname;
    if (currentPath === url.login || currentPath === url.changePassword) {
      return undefined;
    }
    if (account) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Notification history={this.props.history} />
          <LogoutView
            open={this.state.isLogoutPopupOpen}
            handleClose={this.handleClose}
            handleLogout={this.handleLogout}
            handleClick={this.handleClick}
          />
        </div>
      );
    }
    return undefined;
  }

  renderLogo() {
    const currentPath = this.props.location.pathname;
    return (
      (currentPath === url.passwordReset || currentPath === url.changePassword) && (
        <Typography variant="h6">{<img src={`${process.env.PUBLIC_URL}/images/juakali-logo.png`} alt="Juakali" className="logo" />}</Typography>
      )
    );
  }

  renderHeader(account: any) {
    return (
      <>
        <header
          className="jheader mdc-top-app-bar mdc-drawer-app-content"
          style={{
            boxShadow: "0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)",
          }}
        >
          <div className="mdc-top-app-bar__row">
            <section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
              {!this.props.menuOpen || !this.props.menuOpenSm ? this.renderToggleDrawerIcon(account) : undefined}
              {this.renderHeaderText(account)}
              {this.renderLogo()}
            </section>
            <section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-end">{this.renderActionButtons(account)}</section>
          </div>
        </header>
        <ConfirmationDialog {...this.state.confirmationDialogProps} />
      </>
    );
  }

  render() {
    return <AccountContext.Consumer>{account => this.renderHeader(account)}</AccountContext.Consumer>;
  }
}
function mapDispatchToProps(dispatch: any) {
  const actions = {
    resetTasks: () => dispatch(resetTasks()),
  };
  return actions;
}
export default withRouter(connect(
  null,
  mapDispatchToProps
)(withTranslation()(Header as any)) as any) as any;
