import { Button, FormHelperText } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { generateDocument } from "../../../../../redux/actions";
import { DocumentParams } from "../../../../../redux/types";
import CustomLoader from "../../../../common/CustomLoader";
import ExcelExportIcon from "../../../../common/ExcelExportIcon";
import PdfExportIcon from "../../../../common/PdfExportIcon";
import WarningMessage from "../../../../common/WarningMessage";
import { documentStyles } from "./style";
import { EXCEL, STATUS_CODES, TASKS } from "../../../../../constants";
import { limeGreen, PDF_ICON_COLOR } from "../../../../theme";

/**
 * Redux action functions that the component uses
 */
interface DispatchProps {
  generateDocument: (payload: DocumentParams) => any;
}

function DocumentView(props: any): JSX.Element {
  const { t } = useTranslation();
  const { DEFAULT, SUCCESS, LOADING, SERVER_TOO_BUSY, NOT_FOUND } = STATUS_CODES;
  const { documentId, getLabel, processInstanceId, generateDocument, documentFormat } = props;
  const label = getLabel();
  const [statusOfDocumentBeingGenerated, setStatusForDocumentGeneration] = useState<number>(DEFAULT);
  const statusKey = statusOfDocumentBeingGenerated == SUCCESS ? "success" : statusOfDocumentBeingGenerated == NOT_FOUND ? "notFound" : "httpError";
  const { outlined, warningMessage } = documentStyles(statusOfDocumentBeingGenerated);

  const renderDocumentDownloadButton = (): JSX.Element => {
    return (
      <Button
        style={outlined}
        className="documentButton"
        onClick={() => {
          setStatusForDocumentGeneration(LOADING);
          generateDocument({
            processInstanceId: processInstanceId,
            contentId: documentId,
            setStatusForDocumentGeneration: setStatusForDocumentGeneration,
            type: TASKS,
            label,
            documentType: documentFormat,
          });
        }}
        data-testid={documentFormat === EXCEL ? "excelDocumentButton" : "pdfDocumentButton"}
        variant={"outlined"}
        startIcon={documentFormat === EXCEL ? <ExcelExportIcon color={limeGreen} /> : <PdfExportIcon color={PDF_ICON_COLOR} />}
      >
        <span className="documentLabel">{label}</span>
      </Button>
    );
  };

  return (
    <Fragment>
      <WarningMessage style={warningMessage} status={statusOfDocumentBeingGenerated} clearWarning={() => setStatusForDocumentGeneration(1)} />
      {!statusOfDocumentBeingGenerated ? <CustomLoader status={statusOfDocumentBeingGenerated} /> : undefined}
      <div className="field">
        <div className="flex">{renderDocumentDownloadButton()}</div>
      </div>
      {statusOfDocumentBeingGenerated && statusOfDocumentBeingGenerated != DEFAULT && statusOfDocumentBeingGenerated != SERVER_TOO_BUSY ? (
        <FormHelperText className={statusOfDocumentBeingGenerated == SUCCESS ? "successMessage" : "errorMessage"}>
          {t(`document.${statusKey}`, { statusCode: statusOfDocumentBeingGenerated })}
        </FormHelperText>
      ) : (
        undefined
      )}
    </Fragment>
  );
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  const actions = {
    generateDocument: (payload: DocumentParams) => dispatch(generateDocument(payload)),
  };
  return actions;
}

export default connect(
  null,
  mapDispatchToProps
)(DocumentView);
