import React, { ChangeEvent } from "react";
import { TextField } from "@material-ui/core";
import { FIELD_TYPES } from "../../../../../../constants";
import { DateAndTextViewProps } from "../types";

export const renderDataAndTextView = ({ commonFieldProps, props, handlers, properties }: DateAndTextViewProps): JSX.Element => {
  const { type } = props;
  const { mask } = properties;
  const { handleChange } = handlers;
  const { DATE } = FIELD_TYPES;
  /* 
  TODO
  1. Separate date and text fields
  2. Write separate test cases
  */
  return (
    <TextField
      {...commonFieldProps}
      type={type}
      onChange={handleChange}
      inputProps={{
        max: !mask && type === DATE ? "9999-12-31" : "",
      }}
      data-testid="textField"
    />
  );
};
