import React from "react";

const ExpressionFieldView = (props: any): JSX.Element => {
  return (
    <div className="field">
      <div className="flex textWrap">{props.expression}</div>
    </div>
  );
};

export default ExpressionFieldView;
