import { createMuiTheme } from "@material-ui/core";
import { black, cobalt } from "../theme";

export const muiTableTheme = (createMuiTheme as any)({
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: "none",
      },
    },
    MuiTableCell: {
      root: {
        padding: "4px 4px 4px 24px",
        width: "80px",
        wordWrap: "unset",
        borderBottom: "0.5px solid rgba(0,0,0,1) !important",
      },
      body: {
        wordBreak: "break-all",
      },
      head: {
        wordBreak: "break-word",
      },
      footer: {
        borderBottom: "none !important",
      },
    },
    MuiTableRow: {
      root: {
        cursor: "pointer",
        height: "48px",
      },
    },
    MUIDataTableToolbar: {
      actions: {
        display: "flex",
        flexDirection: "row",
        flex: "initial",
        "& > span > button": {
          color: cobalt,
        },
      },
      filterCloseIcon: {
        color: black,
        opacity: 0.8,
        top: "16px !important",
      },
      icon: {
        "&:hover": {
          color: cobalt,
        },
      },
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        boxShadow: "inset 0 2px 0 rgba(0, 0, 0, 1), inset 0 -0.5px 0 rgba(0, 0, 0, 1)",
        zIndex: 6,
      },
      data: {
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "140%",
      },
    },
    MUIDataTableBodyRow: {
      responsiveSimple: {
        borderBottom: "0.5px solid rgba(0,0,0,1) !important",
        borderTop: "0.5px solid rgba(0,0,0,1) !important",
      },
    },
    MUIDataTableFilterList: {
      chip: {
        marginBottom: "8px",
      },
    },
    MUIDataTableFilter: {
      reset: { alignItems: "center", display: "flex" },
      resetLink: { fontSize: 14 },
    },
    MuiButton: {
      textPrimary: {
        color: cobalt,
      },
    },
    MuiGridList: {
      root: {
        maxHeight: 300,
      },
    },
    MuiSwitch: {
      colorSecondary: {
        "&.Mui-checked": {
          color: cobalt,
        },
        "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: `${cobalt} !important`,
          opacity: 0.38,
        },
      },
    },
  },
});

const getStyles = (status: string | number) => {
  return status
    ? {
        zIndex: 1000,
        fontSize: "16px !important",
        borderRadius: "4px 0px 0px 4px",
        width: "280px",
        position: "fixed",
        right: "0",
        margin: "25px 15px 0 0 !important",
        textAlign: "left",
      }
    : {
        fontSize: "12px !important",
        alignItems: "center",
        margin: "0px 16px 16px 0px !important",
        borderRadius: "4px",
        maxWidth: "400px",
        whiteSpace: "pre-line",
        textAlign: "center",
      };
};

export const messageTheme = (status: string | number): any =>
  (createMuiTheme as any)({
    overrides: {
      MuiTypography: {
        body2: {
          border: `2px solid ${status ? "rgba(255, 152, 0, 1)" : "rgba(213, 0, 0, 0.8)"} !important`,
          backgroundColor: "#FFF4F4",
          color: status ? "rgba(255, 152, 0, 1)" : "rgba(213, 0, 0, 0.8)",
          padding: "16px",
          fontStyle: "normal",
          lineHeight: "24px !important",
          letterSpacing: "0.15",
          fontWeight: "600 !important",
          display: "flex",
          justifyContent: "space-between",
          ...getStyles(status),
        },
      },
    },
  });

export const documentLoader = {
  flexDirection: "column" as any,
  position: "fixed" as any,
  background: "rgba(0, 0, 0, 0.3)",
};

export const typographyStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  display: "-webkit-box",
};
