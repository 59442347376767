import React, { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Headline3 } from "@material/react-typography";
import { createMuiTheme, Grid, LinearProgress, MuiThemeProvider, Paper, useMediaQuery } from "@material-ui/core";
import { Formik, FormikActions } from "formik";
import { EMPTY, OOB_CODE, STATUS_CODES } from "../../constants";
import i18n from "../../i18n";
import { resetUserPassword } from "../../services/PasswordService";
import { NewPassword, PasswordError, PasswordSettingParameters } from "../../types/passwordTypes";
import {
  defaultPasswordError,
  getQueryParams,
  getUnAuthenticatedPasswordSettings,
  passwordValidationSchema,
  redirectToLogin,
  validateOobCode,
} from "./utils";
import { fetchWrapper } from "../../core/fetchWrapper";
import Header from "../layout/Header";
import ResetSuccessView from "./ResetSuccessView";
import LinkExpireView from "./ResetLinkExpireView";
import PasswordSettings from "./PasswordSettings";
import EmptyColumn from "./EmptyColumn";
import ErrorMessage from "../common/ErrorMessage";
import { passwordStyles, passwordTheme } from "./styles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps extends RouteComponentProps<{ key: string }> {}

interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

const ResetPassword = (props: OwnProps): JSX.Element => {
  const { history, t } = props;
  const [settingParams, settingParameters] = useState<PasswordSettingParameters>();
  const [passwordErrors, setPasswordErrors] = useState<PasswordError>(defaultPasswordError);
  const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);
  const [isLinkExpired, setIsLinkExpired] = useState<boolean>(false);
  const [email, setEmail] = useState<String | undefined>(undefined);
  const fullScreen = useMediaQuery(createMuiTheme().breakpoints.down("sm"));
  const { content } = passwordStyles();
  const { SUCCESS } = STATUS_CODES;

  const { oobCode, tenant: tenantId } = getQueryParams();

  //fetch password setting and validate oobCode
  useEffect(() => {
    validateOobCode(oobCode, tenantId, setEmail, setIsLinkExpired);
    getUnAuthenticatedPasswordSettings(tenantId, settingParameters);
  }, []);

  const goToLogin = () => redirectToLogin(history);

  const handleFormSubmit = async (values: NewPassword, { setError, setSubmitting }: FormikActions<NewPassword>) => {
    setSubmitting(true);
    setError(false);
    try {
      const response: Response = await fetchWrapper(resetUserPassword, { email, newPassword: values.password, oobCode, tenantId });
      const result = await response.json();
      const { status } = response;
      status === SUCCESS ? setIsPasswordChanged(true) : (result.error.code as string).includes(OOB_CODE) ? setIsLinkExpired(true) : setError(true);
    } catch (e) {
      setError(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Paper className={content}>
      <Header />
      {isLinkExpired ? (
        <LinkExpireView />
      ) : isPasswordChanged ? (
        <ResetSuccessView handleClick={goToLogin} />
      ) : settingParams && email ? (
        <Formik
          initialValues={{ password: EMPTY }}
          enableReinitialize={true}
          validationSchema={passwordValidationSchema(passwordErrors, t)}
          onSubmit={handleFormSubmit}
        >
          {props => {
            const { error, handleSubmit, setError } = props;

            return (
              <MuiThemeProvider theme={passwordTheme}>
                <form onSubmit={handleSubmit}>
                  <div className="mdc-top-app-bar--fixed-adjust">
                    <Grid container>
                      <EmptyColumn />
                      <Grid item xs={10} sm={8} md={8} lg={8} xl={6}>
                        <Headline3 data-testid="headerText">{t("resetPassword.resetPassword")}</Headline3>
                        <ErrorMessage
                          errorMessage={error && t("changePassword.serverError")}
                          handleClear={() => {
                            setError(false);
                          }}
                        />
                        <PasswordSettings
                          {...props}
                          email={email}
                          fullScreen={fullScreen}
                          handleAccountClick={goToLogin}
                          isChangePasswordPage={false}
                          setPasswordErrorChange={setPasswordErrors}
                          settingParams={settingParams}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </MuiThemeProvider>
            );
          }}
        </Formik>
      ) : (
        <LinearProgress />
      )}
    </Paper>
  );
};

export default withRouter(withTranslation()(ResetPassword));
