import React from "react";
import { HeaderBase } from "@juakali/juakali-forms-react";
import HeaderView from "./HeaderView";

class HeaderComponent extends HeaderBase {
  constructor(props: any) {
    super(props);
  }
  render() {
    return <HeaderView {...this} />;
  }
}

export default HeaderComponent;
