import React from "react";
import { InputField } from "@juakali/juakali-forms-react";
import CheckBoxFieldView from "./CheckboxFieldView";

class CheckBoxComponent extends InputField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <CheckBoxFieldView {...this} />;
  }
}

export default CheckBoxComponent;
