// @flow
import { configureStore, AnyAction, getDefaultMiddleware } from "redux-starter-kit";
import { all } from "redux-saga/effects";
import createSagaMiddleware from "@redux-saga/core";
import openTasksReducer from "./reducers/openTasksReducer";
import createProcessInstanceReducer from "./reducers/createProcessInstanceReducer";
import loanReportReducer from "./reducers/reports/loanReportReducer";
import loanReportTaskReducer from "./reducers/reports/loanReportTaskReducer";

import {
  StateType,
  defaultOpenTaskState,
  defaultLoanReport,
  defaultCreateProcessInstance,
  defaultSettingsState,
  defaultProcessInstanceState,
  defaultDocumentState,
  defaultObjectReportDetail,
  defaultAppDefinitions,
} from "./types";
import taskReducer, { watchGetCompletedTasksByProcessInstanceId } from "./reducers/taskReducer";
import taskFormReducer, { watchGetTaskForm } from "./reducers/taskFormReducer";
import processInstanceReducer, { watchGetProcessInstances, watchDeleteProcessInstance } from "./reducers/processInstanceReducer";
import alertReducer from "./reducers/alertReducer";
import documentReducer, { watchGenerateForDocument, watchFetchForDocument } from "./reducers/documentReducer";
import settingReducer, { watchUpdateUserSettings, watchGetUserSettings } from "./reducers/settingReducer";
import { objectDefinitionReducer } from "../datastore/objectDefinitionReducer";
import { defaultObjectDefinitionState, defaultObjectDataState } from "../datastore/dataStoreTypes";
import { objectDataReducer } from "../datastore/objectDataReducer";
import {
  dataStoreDeleteProcessesReducer,
  watchGetRemoteDataStoreDeleteProcesses,
  watchStartDeleteProcessInstance,
} from "../datastore/dataStoreDeleteProcess/dataStoreDeleteProcessesReducer";
import { defaultDataStoreDeleteProcessState } from "../datastore/dataStoreDeleteProcess/dataStoreDeleteProcessTypes";
import drawerReducer from "./reducers/drawerReducer";
import objectReportDetailReducer, { watchFetchForObjectReportDetail } from "./reducers/objectReportDetailReducer";
import appDefinitionsReducer from "./reducers/appDefinitionsReducer";

const preloadedState: StateType = {
  openTasks: defaultOpenTaskState,
  createProcessInstance: defaultCreateProcessInstance,
  loanReport: defaultLoanReport,
  loanReportTasks: [],
  processInstances: defaultProcessInstanceState,
  tasks: {},
  documents: defaultDocumentState,
  taskForms: {},
  alerts: [],
  userSettings: defaultSettingsState,
  objectDefinitionState: defaultObjectDefinitionState,
  objectDataState: defaultObjectDataState,
  dataStoreDeleteProcessState: defaultDataStoreDeleteProcessState,
  objectReportDetails: defaultObjectReportDetail,
  appDefinitions: defaultAppDefinitions,
};

function* rootSaga(): any {
  yield all([
    watchUpdateUserSettings(),
    watchGetUserSettings(),
    watchGetCompletedTasksByProcessInstanceId(),
    watchGetTaskForm(),
    watchGetProcessInstances(),
    watchDeleteProcessInstance(),
    watchGenerateForDocument(),
    watchFetchForDocument(),
    objectDefinitionReducer.getEntitiesActionWatcher(),
    objectDefinitionReducer.getEntityActionWatcher(),
    objectDataReducer.getEntitiesActionWatcher(),
    objectDataReducer.genericApiActionWatcher(),
    watchGetRemoteDataStoreDeleteProcesses(),
    watchStartDeleteProcessInstance(),
    watchFetchForObjectReportDetail(),
  ]);
}

const sagaMiddleware = createSagaMiddleware();

const store = configureStore<StateType, AnyAction>({
  reducer: {
    openTasks: openTasksReducer,
    appDefinitions: appDefinitionsReducer,
    createProcessInstance: createProcessInstanceReducer,
    loanReport: loanReportReducer,
    loanReportTasks: loanReportTaskReducer,
    processInstances: processInstanceReducer,
    tasks: taskReducer,
    taskForms: taskFormReducer,
    alerts: alertReducer,
    documents: documentReducer,
    userSettings: settingReducer,
    objectDefinitionState: objectDefinitionReducer.entityReducer,
    objectDataState: objectDataReducer.entityReducer,
    dataStoreDeleteProcessState: dataStoreDeleteProcessesReducer,
    drawerStatus: drawerReducer,
    objectReportDetails: objectReportDetailReducer,
  },
  preloadedState,
  middleware: [...getDefaultMiddleware(), sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

// ,
//     posts: postsReducer

export default store;
