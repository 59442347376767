import { makeStyles } from "@material-ui/styles";
import { cobalt } from "../theme";

export const useStyles = makeStyles({
  button: {
    marginLeft: "30px",
    marginTop: "20px",
  },
  buttonColor: { color: cobalt },
  popper: { zIndex: 2, position: "absolute", minWidth: "130px", marginLeft: "-3em", width: "265px", maxHeight: "500px", overflowY: "scroll" },
});
