import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Button from "@material/react-button";
import { withTranslation, WithTranslation } from "react-i18next";
import { ArrowRightAlt, Done } from "@material-ui/icons";
import { passwordStyles } from "./styles";
import i18n from "../../i18n";

interface OwnProps {
  handleClick: () => void;
}

interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

const ChangeSuccessView = (props: OwnProps): JSX.Element => {
  const { handleClick, t } = props;
  const { completeWrapper, continueButton, continueButtonWrapper, rightIcon, successIcon } = passwordStyles();

  return (
    <Grid container direction="column">
      <Grid item className={completeWrapper}>
        <Done className={successIcon} data-testid="successIcon" />
        <Typography variant="body1">{t("changePassword.success")}</Typography>
      </Grid>
      <Grid item data-testid="continue" className={continueButtonWrapper}>
        <Button raised onClick={handleClick}>
          <div className={continueButton}>
            {t("common.continue")} <ArrowRightAlt className={rightIcon} data-testid="rightArrow" />
          </div>
        </Button>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ChangeSuccessView);
