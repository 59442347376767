import fetch from "isomorphic-fetch";
import { url } from "../apiConfig";

// fake the flowable contextRoot
let FLOWABLE = { CONFIG: { contextRoot: process.env.REACT_APP_TASK_HOST } };

export function httpAsPromise(info) {
  const options = {};
  if (info.data) {
    options.body = JSON.stringify(info.data);
    options.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  options.method = info.method;

  return fetch(info.url, options);
}

export function getTaskForm(taskId, processInstanceId) {
  return httpAsPromise({
    method: "GET",
    url: FLOWABLE.CONFIG.contextRoot + "/app/rest/taskForms/" + taskId + "/" + processInstanceId,
  });
}

export function getGrapesJSForm(modelId) {
  var getGrapesJSFormURL = url.grapesJSForms;
  return httpAsPromise({
    method: "GET",
    url: getGrapesJSFormURL + modelId,
  });
}

export function completeTaskForm(taskId, data) {
  var promise = httpAsPromise({
    method: "POST",
    url: FLOWABLE.CONFIG.contextRoot + "/app/rest/task-forms/" + taskId,
    data: data,
  });

  return promise;
}

export function saveTaskForm(taskId, data) {
  var promise = httpAsPromise({
    method: "POST",
    url: FLOWABLE.CONFIG.contextRoot + "/app/rest/task-forms/" + taskId + "/save-form",
    data: data,
  });

  return promise;
}

export function getProcessInstanceVariables(processInstanceId) {
  var promise = httpAsPromise({
    method: "GET",
    url: FLOWABLE.CONFIG.contextRoot + "/app/rest/query/process-instance/variables/" + processInstanceId,
  });

  return promise;
}

export function getVariables(taskId) {
  var promise = httpAsPromise({
    method: "GET",
    url: FLOWABLE.CONFIG.contextRoot + "/app/rest/query/variables/" + taskId,
  });

  return promise;
}

export function completeGrapesForm(taskId, data) {
  var promise = httpAsPromise({
    method: "POST",
    url: FLOWABLE.CONFIG.contextRoot + "/app/rest/taskForms/completeGrapesForm/" + taskId,
    data: data,
  });

  return promise;
}

export function saveGrapesForm(taskId, data) {
  var promise = httpAsPromise({
    method: "POST",
    url: FLOWABLE.CONFIG.contextRoot + "/app/rest/taskForms/saveGrapesForm/" + taskId,
    data: data,
  });

  return promise;
}

export function getStartForm(processDefinitionId) {
  return httpAsPromise({
    method: "GET",
    url: FLOWABLE.CONFIG.contextRoot + "/app/rest/process-definitions/" + processDefinitionId + "/start-form",
  });
}

export function completeStartForm(data) {
  var promise = httpAsPromise({
    method: "POST",
    url: FLOWABLE.CONFIG.contextRoot + "/app/rest/process-instances/",
    data: data,
  });

  return promise;
}

export function getCaseStartForm(caseDefinitionId) {
  return httpAsPromise({
    method: "GET",
    url: FLOWABLE.CONFIG.contextRoot + "/app/rest/case-definitions/" + caseDefinitionId + "/start-form",
  });
}

export function completeCaseStartForm(data) {
  var promise = httpAsPromise({
    method: "POST",
    url: FLOWABLE.CONFIG.contextRoot + "/app/rest/case-instances/",
    data: data,
  });

  return promise;
}
