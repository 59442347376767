var FileSaver = require("file-saver");
import { showNotification } from "../../../../../util/notification";
import i18n from "../../../../../i18n";
import { EIGHT, GOOD_OPTION, HIGH_OPTION, KB_BYTES_DIV, SLICE_SIZE, STANDARD_OPTION, defaultQuality, goodImageQuality } from "../../../constants";
import { EMPTY } from "../../../../../constants";
import * as Sentry from "@sentry/react";
import Compressor from "compressorjs";
export const handleImageDownload = (url: string, label: string, setDisableDownload: any, uuid?: string): void => {
  setDisableDownload(true);
  var promise = fetch(url);
  promise
    .then((res: Response) => res.blob())
    .then((blob: any) => {
      FileSaver.saveAs(blob, `${label}_${uuid || ""}.png`);
      setDisableDownload(false);
    })
    .catch(() => {
      showNotification("error", i18n.t("common.serverErrorTryAgain"));
      setDisableDownload(false);
    });
};

export async function handleImageUpload(imageFile: string, component: any): Promise<any> {
  try {
    const compressedBase64 = await compressBase64Image(imageFile, component);
    return compressedBase64;
  } catch (error) {
    console.error("Error:", error);
  }
}

const compressBase64Image = async (originalBase64: string, component: any): Promise<any> => {
  const imageQuality = component.attributes.pictureQuality || defaultQuality;
  const blob = b64toBlob(originalBase64, "image/jpeg", SLICE_SIZE);
  try {
    const compressedFile = await compressImage(blob, imageQuality);
    const compressedBase64 = await getBase64FromFile(compressedFile);

    return compressedBase64;
  } catch (error) {
    console.error("Error compressing image:", error);
  }
};

export const getBase64FromFile = (file: any): any => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result.split(",")[1]);
      }
    };

    reader.onerror = error => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

function b64toBlob(b64Data: string, contentType: string, sliceSize: number): any {
  contentType = contentType || EMPTY;
  sliceSize = sliceSize || SLICE_SIZE;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

const calculateMaxWidthOrHeight = (blob: Blob, targetFileSizeKB: number): number => {
  const qualityOption = 1;
  // Calculate the original file size in kilobytes
  const originalFileSizeKB = blob.size / KB_BYTES_DIV;
  // Calculate the original image dimensions using the formula: fileSize = (width * height * quality) / 8
  const originalImageDimensions = Math.sqrt((originalFileSizeKB * EIGHT * KB_BYTES_DIV) / qualityOption);
  // Calculate the scaling factor to achieve the target file size
  const scalingFactor = Math.sqrt(targetFileSizeKB / originalFileSizeKB);
  // Calculate the new dimensions by applying the scaling factor
  const newImageDimensions = originalImageDimensions * scalingFactor;
  // Return the rounded value as the new maxWidthOrHeight
  return Math.round(newImageDimensions);
};
export const addSentryLogs = (size: number, name: string, quality: string, originalImageSize: number) => {
  Sentry.captureException(
    new Error(`Image Compression Error- Compressed Size: ${size}, Name: ${name},Quality:${quality},Original Image Size:${originalImageSize}`)
  );
};

export const compressImage = (file: any, quality: any): any => {
  const qualityOptions: Record<string, number> = {
    standard: STANDARD_OPTION,
    good: GOOD_OPTION,
    high: HIGH_OPTION,
  };

  const imageQuality: Record<string, number> = {
    standard: 0.7,
    good: 0.8,
    high: 0.9,
  };

  const dimensions = calculateMaxWidthOrHeight(file, qualityOptions[quality]);
  const compressQuality = imageQuality[quality];

  return new Promise((resolve, reject) => {
    new Compressor(file, {
      width: dimensions,
      height: dimensions,
      quality: compressQuality,
      success(result) {
        const compressedFile = new File([result], file.name, {
          type: result.type,
        });
        resolve(compressedFile);
      },
      error(err) {
        console.error(err.message);
        reject(err);
      },
    });
  });
};
