import React, { Fragment } from "react";
import { Checkbox, FormControlLabel, FormHelperText, MuiThemeProvider } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { checkboxTheme } from "../../styles";
import CustomFieldLabel from "../CustomFieldLabel";

const CheckBoxFieldView = (props: any) => {
  let { getActiveStatus, getError, getLabel, getRequiredStatus, getValue, name, onChange, t } = props;

  let required = getRequiredStatus();
  let label = getLabel(); /* get input field label */
  let value = getValue(); /* get input field value */
  let error = getError(); /* get field error */
  let isDisabled = getActiveStatus(); /* get active status */
  const handleChange = (e: any): void => onChange(e.target.checked); /* handles input value changes */

  return (
    <div className="field">
      <Fragment>
        <MuiThemeProvider theme={checkboxTheme}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkBoxField"
                disabled={isDisabled}
                readOnly={isDisabled}
                required={required}
                name={name}
                onChange={handleChange}
                checked={!!value}
              />
            }
            name={name}
            label={<CustomFieldLabel label={label} fieldId={name} />}
          />
        </MuiThemeProvider>
        {/* show field error if exists */}
        {error && (
          <FormHelperText key={name} className="errorMessage" data-testid="checkBoxError">
            {t(`validation.${error.type}`, error.params)}
          </FormHelperText>
        )}
      </Fragment>
    </div>
  );
};

export default withTranslation()(CheckBoxFieldView);
