import React from "react";
import Dropzone from "react-dropzone";
import { Button, CircularProgress } from "@material-ui/core";
import { FIELD_TYPES, ACCEPTED_FORMATS } from "../../../../../constants";
import { withTranslation, WithTranslation } from "react-i18next";
import i18n from "../../../../../i18n";
import { fetchWrapper } from "../../../../../core/fetchWrapper";
import { fetchToUploadFile } from "../../../../../services/TaskService";

interface OwnProps {
  disabled: boolean | undefined;
  handleUpdate: any;
  updateFormSubmissionFlag: any;
  value: any;
  isLoading: boolean;
  setDownloadingStatus: any;
}
interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

const FileDropZone = (props: OwnProps): JSX.Element => {
  let { disabled, handleUpdate, t, updateFormSubmissionFlag, value, isLoading, setDownloadingStatus } = props;
  const handleOnDrop = (file: any) => {
    updateFormSubmissionFlag(true);
    handleUpdate(file, getFileUploadWrapper);
    setDownloadingStatus(1);
  };
  return (
    <div className={"mth-1 ml-2"}>
      <Dropzone onDrop={handleOnDrop} accept={ACCEPTED_FORMATS}>
        {({ getRootProps, getInputProps }: any) => (
          <div className="container">
            <div
              {...getRootProps({
                className: "dropzone",
              })}
            >
              <input {...getInputProps()} data-testid="dropInput" disabled={disabled} />
              {isLoading ? (
                <CircularProgress data-testid="loadingProgress" />
              ) : (
                <Button variant="contained" color="primary" data-testid="excelUploadButton" disabled={disabled}>
                  {typeof value === FIELD_TYPES.OBJECT && value && (value.length || Object.keys(value).length)
                    ? t("excelUpload.replace")
                    : t("excelUpload.load")}
                </Button>
              )}
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default withTranslation()(FileDropZone);

const getFileUploadWrapper = (formData: any, id?: string): any => {
  return fetchWrapper(fetchToUploadFile, { formData, id });
};
