import React, { useState, useContext, useEffect } from "react";
import { withRouter, NavLink } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer, { DrawerAppContent, DrawerContent, DrawerHeader } from "@material/react-drawer";
import Typography from "@material-ui/core/Typography";
import MaterialIcon from "@material/react-material-icon";
import { IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import List, { ListItem, ListItemGraphic, ListItemText, ListDivider, ListGroupSubheader, ListGroup } from "@material/react-list";
import "../../App.scss";
import { useTranslation } from "react-i18next";
import { Overline } from "@material/react-typography";
import { AccountContext } from "../../App";
import Header from "./Header";
import { createMuiTheme, Hidden } from "@material-ui/core";
import ObjectDefinitionNavList from "../reports/ObjectDefinitionsNavList";
import LanguageSelector from "../common/LanguageSelector";
import { masterStyles } from "./styles";
import { connect } from "react-redux";
import { getDrawerStatus } from "../../redux/selectors/drawerSelector";
import { setDrawerStatus } from "../../redux/actions";

function Master(props: any) {
  const { t } = useTranslation();
  const accountContext = useContext(AccountContext);
  const theme = createMuiTheme();
  const [menuOpen, setMenuOpen] = useState(true);
  const [menuOpenSm, setMenuOpenSm] = useState(false);
  const isItMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isItTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = masterStyles();
  var privileges = accountContext && accountContext.privileges ? accountContext.privileges : [];

  useEffect(() => {
    props.setDrawerStatus(true);
  }, []);

  function renderDrawerContent() {
    return (
      <DrawerContent className={classes.drawerPaperBody}>
        <ListDivider tag="div" />
        <List singleSelection onClick={onDrawerClose}>
          <NavLink exact to={"/"}>
            <ListItem className="pointer">
              <ListItemGraphic graphic={<MaterialIcon icon="dashboard" />} />
              <ListItemText primaryText={t("menu.dashboard")} />
            </ListItem>
          </NavLink>
        </List>

        <ListDivider tag="div" />
        <ListGroup>
          <ListGroupSubheader>
            <Overline>{t("menu.reports")}</Overline>
          </ListGroupSubheader>
        </ListGroup>
        <div className={classes.drawerContent}>
          <ListDivider tag="div" />
          {privileges && privileges.indexOf("access-bo") !== -1 && <ObjectDefinitionNavList onDrawerClose={onDrawerClose}></ObjectDefinitionNavList>}
        </div>

        <div className={classes.drawerLanguageBar}>
          <ListDivider tag="div" />
          <div className={classes.languageBar}>
            <LanguageSelector />
          </div>
        </div>
      </DrawerContent>
    );
  }

  function toggleDrawer() {
    if (isItTablet) {
      setMenuOpenSm(!menuOpenSm);
      props.setDrawerStatus(!menuOpenSm);
    } else {
      setMenuOpen(!menuOpen);
      props.setDrawerStatus(!menuOpen);
    }
  }

  function onDrawerClose() {
    setMenuOpenSm(!setMenuOpenSm);
    props.setDrawerStatus(false);
  }

  return (
    <div className="drawer-container">
      <Hidden smDown>
        <Drawer dismissible open={menuOpen}>
          <DrawerHeader className={classes.drawerHeader}>
            <Typography variant="h6" className={classes.logo}>
              {<img src={`${process.env.PUBLIC_URL}/images/juakali-logo.png`} alt="Juakali" className="logo" />}
            </Typography>
            <IconButton onClick={toggleDrawer} style={{ float: "right" }}>
              <ChevronLeftIcon
                style={{
                  width: "1.5em",
                  height: "1.5em",
                  fill: "rgba(0, 33, 113, 0.87)",
                }}
                onClick={toggleDrawer}
              />
            </IconButton>
          </DrawerHeader>
          {renderDrawerContent()}
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          style={{ zIndex: 200 }}
          open={menuOpenSm}
          modal
          onClose={() => {
            setMenuOpenSm(false);
            props.setDrawerStatus(true);
          }}
        >
          <DrawerHeader className={classes.drawerHeader}>
            <Typography variant="h6" className={classes.logo}>
              {<img src={`${process.env.PUBLIC_URL}/images/juakali-logo.png`} alt="Juakali" className="logo" />}
            </Typography>
          </DrawerHeader>
          {renderDrawerContent()}
        </Drawer>
      </Hidden>
      <DrawerAppContent className="drawer-app-content">
        <Header
          menuOpen={menuOpen}
          menuOpenSm={menuOpenSm}
          authInfo={props.authInfo}
          updateAcccount={props.updateAcccount}
          toggleDrawer={toggleDrawer}
          mobileView={isItMobile}
        />
        <div>{props.children}</div>
      </DrawerAppContent>
    </div>
  );
}

function mapDispatchToProps(dispatch: any) {
  const actions = {
    setDrawerStatus: (status: any) => dispatch(setDrawerStatus(status)),
  };
  return actions;
}

function mapStateToProps(state: any) {
  const drawerStatus = getDrawerStatus(state);
  return {
    drawerStatus,
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Master) as any) as any;
