import { showNotification } from "../../../../../util/notification";

// request gps coordinates , on success update the gps corrdinates value
export const getCoordinates = (onSuccess: any, t: any) => {
  let state: any = {
    isLoading: true,
  };
  onSuccess(state);
  if (navigator && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position: any) => {
        const { latitude, longitude } = position.coords;
        onSuccess({
          ...state,
          isLoading: false,
          value: [latitude.toFixed(5), longitude.toFixed(5)],
        });
        showNotification("success", t("gps.successContent"));
      },
      (error: any) => {
        onSuccess({
          ...state,
          isLoading: false,
        });
        showNotification("error", t("gps.errorContent"));
      },
      {
        timeout: 10000, // Timeout value 10 seconds
        maximumAge: 0,
        //   /* Flag to get a location info with better Accuracy - Future enhancement */
        //   /* enableHighAccuracy: true */
      }
    );
  }
};
