export const AuthenticationStatus = {
  PENDING: "PENDING",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  REQUIRE_PASSWORD_CHANGE: "REQUIRE_PASSWORD_CHANGE",
};

export const LOGIN = "Login";
export const LOGIN_FAILED = "Login Failed";
export const LOGIN_SUCCESS = "Login Success";

export const Other = {};

export const Languages = {
  ENGLISH: { key: "en", label: "English" },
  SPANISH: { key: "es", label: "Español" },
  FRENCH: { key: "fr", label: "Français" },
};

export const JUAKALI_LANGUAGE = "JuakaliPreferredLanguage";
