import React from "react";
import { DocumentField } from "@juakali/juakali-forms-react";
import { withTranslation } from "react-i18next";
import DocumentView from "./DocumentView";

class DocumentComponent extends DocumentField {
  constructor(props: any) {
    super(props);
  }

  render(): JSX.Element {
    return <DocumentView {...this} />;
  }
}

export default withTranslation()(DocumentComponent as any);
