import { FormHelperText, MuiThemeProvider, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { withTranslation } from "react-i18next";
import { dropdownOptionStyle, searchableDropdownStyle } from "../../styles";
import { getCurrentDropdownOption } from "../../../../../util/helper";
import { DropdownOption } from "../../../../../types/formtypes";
import { EMPTY } from "../../../../../constants";
import CustomFieldLabel from "../CustomFieldLabel";
import { checkForAccessTestRole } from "../helper";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";

const DropdownFieldView = (props: any) => {
  let { getActiveStatus, getError, getLabel, getRequiredStatus, getValue, name, onChange, t, getDropdownOptionList } = props;

  let label = getLabel();
  let value = getValue();
  let error = getError();
  let isDisabled = getActiveStatus();
  let required = getRequiredStatus();
  const { optionBlock, optionValue, popupIcon } = dropdownOptionStyle();
  const renderKeyValue = checkForAccessTestRole();

  let DropdownOptionList = getDropdownOptionList();

  let currentValue = getCurrentDropdownOption(DropdownOptionList, value) as DropdownOption | undefined;

  const handleChange = (e: any, selectedOption: DropdownOption | null): void => {
    e;
    onChange((selectedOption && selectedOption.value) || EMPTY);
  };

  const renderOption = (option: DropdownOption) =>
    option ? (
      renderKeyValue ? (
        <div style={optionBlock}>
          <span>{option.name}</span>
          <span style={optionValue}>{`(${option.value})`}</span>
        </div>
      ) : (
        option.name
      )
    ) : (
      EMPTY
    );

  return (
    <div className="field">
      <div>
        <MuiThemeProvider theme={searchableDropdownStyle}>
          <Autocomplete
            data-testid="dropdownField"
            disabled={isDisabled}
            fullWidth={true}
            clearText={t("common.clear")}
            closeText={t("common.close")}
            popupIcon={<ArrowDropDownRoundedIcon style={popupIcon} />}
            openText={t("common.open")}
            noOptionsText={EMPTY}
            id={name}
            value={currentValue || null}
            defaultValue={currentValue || null}
            options={DropdownOptionList}
            autoHighlight
            onChange={handleChange}
            getOptionLabel={option => option.name}
            renderOption={renderOption}
            renderInput={params => (
              <TextField
                {...params}
                label={<CustomFieldLabel label={label} fieldId={name} />}
                variant="outlined"
                error={!!error}
                required={required}
              />
            )}
          />
        </MuiThemeProvider>
      </div>
      {/* show field error if exists */}
      {error && (
        <FormHelperText key={name} className="errorMessage" data-testid="dropdownError">
          {t(`validation.${error.type}`, error.params)}
        </FormHelperText>
      )}
    </div>
  );
};

export default withTranslation()(DropdownFieldView);
